import { useEffect } from 'react';

import { useFlyInDispatchContext, useFlyInStateContext } from '../contexts/flyInContext';

export const useFlyInNavigationCloseInterceptor = (close?: () => void) => {
    const { unsetTriedToClose } = useFlyInDispatchContext();

    const { setTriedToClose } = useFlyInDispatchContext();

    const context = useFlyInStateContext();
    const triedToClose = context?.triedToClose;
    const closeBlocked = context?.block?.closeBlocked;

    useEffect(() => {
        if (!closeBlocked && triedToClose && !!close) {
            unsetTriedToClose();
            close();
        }
    }, [triedToClose, closeBlocked, close, unsetTriedToClose]);

    return {
        close: setTriedToClose,
    };
};

export const useFlyInNavigationBackInterceptor = (back?: () => void) => {
    const { unsetTriedToBack } = useFlyInDispatchContext();

    const { setTriedToBack } = useFlyInDispatchContext();

    const context = useFlyInStateContext();
    const triedToBack = context?.triedToBack;
    const backBlocked = context?.block?.backBlocked;

    useEffect(() => {
        if (!backBlocked && triedToBack && !!back) {
            unsetTriedToBack();
            back();
        }
    }, [triedToBack, backBlocked, back, unsetTriedToBack]);

    return {
        back: setTriedToBack,
    };
};

export const useFlyInNavigationOutsideClickInterceptor = (outsideClick?: () => void) => {
    const { unsetTriedToOutsideClick } = useFlyInDispatchContext();

    const { setTriedToOutsideClick } = useFlyInDispatchContext();

    const context = useFlyInStateContext();
    const triedToOutsideClick = context?.triedToOutsideClick;
    const outsideClickBlocked = context?.block?.outsideClickBlocked;

    useEffect(() => {
        if (!outsideClickBlocked && triedToOutsideClick && !!outsideClick) {
            unsetTriedToOutsideClick();
            outsideClick();
        }
    }, [outsideClick, outsideClickBlocked, unsetTriedToOutsideClick, triedToOutsideClick]);

    return {
        outsideClick: setTriedToOutsideClick,
    };
};
