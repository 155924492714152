import type { FC } from 'react';
import * as React from 'react';

import NoticeRibbon, { NoticeRibbonType } from '../../../common/components/atoms/NoticeRibbon';
import type { ApiBaseException } from '../../../common/exceptions/exceptionDefinitions';
import { ExceptionCode } from '../../../common/exceptions/exceptionDefinitions';
import { useGroupError } from '../../../common/hooks/useGroupError';
import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { modalSelectors } from '../../../common/selectors/modalSelectors';
import { ErrorGroup } from '../../../common/store/structureDefinitions/errorsState';

export const HeaderCartErrorNotice: FC<React.PropsWithChildren<unknown>> = () => {
    const isAnyModalOpen = useAppSelector(modalSelectors.isAnyModalOpen);
    return <CartErrorNotice additionalShowCondition={() => !isAnyModalOpen} fixed={false} />;
};

interface CartErrorNoticeProps {
    additionalShowCondition?: (error: ApiBaseException) => boolean;
    fixed?: boolean;
}

const CartErrorNotice: FC<React.PropsWithChildren<CartErrorNoticeProps>> = (props) => {
    const handleCloseClick = () => {
        clearGroupErrors();
    };

    const { firstError: cartError, clearGroupErrors } = useGroupError(ErrorGroup.Cart);

    const show = (error: ApiBaseException | null) => {
        const condition =
            props.additionalShowCondition && !!error ? props.additionalShowCondition(error) : true;

        return (
            condition && !!error?.friendlyMessage && error.code !== ExceptionCode.dinnerOnlyStore
        );
    };

    return (
        <NoticeRibbon
            type={NoticeRibbonType.warning}
            show={show(cartError)}
            handleCloseClick={handleCloseClick}
            hideAfterMiliseconds={5000}
            fixed={props.fixed}
        >
            {cartError?.friendlyMessage}
        </NoticeRibbon>
    );
};

export default CartErrorNotice;
