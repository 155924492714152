import _ from 'underscore';

export const createBatcher = <T>(
    callback: (batchToSend: T[]) => void,
    batchDebounceTime: number = 200,
) => {
    let currentBatch: T[] = [];

    const sendDebounced = _.debounce(() => {
        if (!currentBatch.length) return;
        callback(currentBatch);
        currentBatch = [];
    }, batchDebounceTime);

    return (itemToBatch: T) => {
        currentBatch.push(itemToBatch);
        sendDebounced();
    };
};
