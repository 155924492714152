import trackingFlow from '../../flow/trackingFlow';
import { failSilently } from '../utils/failSilently';

export const enum ProductDataProvider {
    Loop54,
}

const trackRecipeClick = () => (identifier: string) => {
    trackingFlow.recipeClick({ recipeId: identifier });
};

const trackAddToCart = () =>
    failSilently((productData: ProductTrackingData[]) => trackingFlow.addToCart(productData));

const trackAddToCartBulk = () =>
    failSilently((productData: ProductTrackingData[]) => {
        trackingFlow.addToCart(productData);
    });

const trackPurchase = () => (purchaseData: PurchaseTrackingData) => {
    trackingFlow.purchase({
        orderId: purchaseData.transactionId,
        revenue: purchaseData.totalValue,
        products: purchaseData.cartItems.map((product) => ({
            productId: product.identifier,
            quantity: product.quantity,
            itemPrice: product.price / (product.quantity || 1),
        })),
    });
};

const trackRemoveFromCart = (productData: ProductTrackingData) => {
    trackingFlow.removeFromCart(productData);
};

const trackRemoveFromCartList = (recommendationId: string, productData: ProductTrackingData) => {
    trackingFlow.removeFromCart(productData);
};

const trackSlotClick = (productId: string, slotId: string | undefined) => {
    if (slotId) {
        trackingFlow.slotClick({ productId, slotId });
    }
};

const trackCartItemQuantityUpdate = (productData: ProductTrackingData) => {
    trackingFlow.addToCart([productData]);
};

const trackUserLogin = () => {
    trackingFlow.login();
};

export const personalizationTracking = {
    trackAddToCart,
    trackAddToCartBulk,
    trackSlotClick,
    trackRemoveFromCart,
    trackCartItemQuantityUpdate,
    trackPurchase,
    trackUserLogin,
    trackRemoveFromCartList,
    trackRecipeClick,
};
