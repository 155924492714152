import { appConfig } from '../../../appConfig';
import { SearchFromPageType } from '../../base/models/SearchFromPageType';
import type { GlobalSearchResults } from '../models/models';
import { SearchResultType } from '../models/SearchResultType';
import { globalSearchRoutes } from './globalSearchRoutes';

export const mapSearchFromPageToExpectedResultType = (
    searchFromPage?: SearchFromPageType,
): SearchResultType | undefined => {
    switch (searchFromPage) {
        case SearchFromPageType.Ecommerce:
            return SearchResultType.Products;
        case SearchFromPageType.Recipes:
            return SearchResultType.Recipes;
        case SearchFromPageType.Member:
            return SearchResultType.Pointshop;
        case SearchFromPageType.Stores:
            return SearchResultType.Stores;
        case SearchFromPageType.StartPage:
        case SearchFromPageType.Finance:
        case SearchFromPageType.Other:
        default:
            return undefined;
    }
};

const formattedSearchResultTypeMap = {
    [SearchResultType.Recipes]: 'Recept',
    [SearchResultType.Products]: 'Varor',
    [SearchResultType.Stores]: 'Butiker',
    [SearchResultType.Articles]: 'Artiklar',
    [SearchResultType.Pointshop]: 'Erbjudanden',
} as const;

export const getSearchResultCountName = (result: SearchResultCount) => {
    return `${result.itemCount} ${getFormattedSearchResultType(result.resultType).toLowerCase()}`;
};

export const getSearchResultTypeAriaLabel = (totalHits: number, resultType: SearchResultType) => {
    return `visa alla ${totalHits} träffar för ${getFormattedSearchResultType(resultType).toLowerCase()}`;
};

export const getFormattedSearchResultType = (type?: SearchResultType) => {
    return (type && formattedSearchResultTypeMap[type]) || '';
};

export interface SearchResultCount {
    resultType: SearchResultType;
    itemCount: number;
}

// Used in the context of Global Search Result page
export const getFullSearchResultLink = (
    resultType: SearchResultType | undefined,
    searchTerm: string,
    originalSearchTerm?: string,
) => {
    const originalQuery = `${
        originalSearchTerm && originalSearchTerm !== searchTerm ? `&oq=${originalSearchTerm}` : ''
    }`;

    switch (resultType) {
        case SearchResultType.Products:
            return {
                type: 'absolute' as const,
                path: `${appConfig.coopSettings.ecommerce.search.url}?q=${searchTerm}${originalQuery}`,
            };

        case SearchResultType.Recipes:
            return {
                type: 'absolute' as const,
                path: `${appConfig.coopSettings.recipe.url}?query=${searchTerm}${originalQuery}`,
            };

        case SearchResultType.Stores:
            return {
                type: 'relative' as const,
                path: `${globalSearchRoutes.stores}?query=${searchTerm}${originalQuery}`,
            };

        case SearchResultType.Articles:
            return {
                type: 'relative' as const,
                path: `${globalSearchRoutes.articles}?query=${searchTerm}${originalQuery}`,
            };

        case SearchResultType.Pointshop:
            return {
                type: 'relative' as const,
                path: `${globalSearchRoutes.pointOffers}?query=${searchTerm}${originalQuery}`,
            };
        default:
            return {
                type: 'relative' as const,
                path: `?query=${searchTerm}${originalQuery}`,
            };
    }
};

// Used in any context, will always generate absolute links
export const getFullSearchResultAbsoluteLink = (
    resultType: SearchResultType | undefined,
    searchTerm: string,
    originalSearchTerm?: string,
) => {
    const originalQuery = `${
        originalSearchTerm && originalSearchTerm !== searchTerm ? `&oq=${originalSearchTerm}` : ''
    }`;

    switch (resultType) {
        case SearchResultType.Products:
            return {
                type: 'absolute' as const,
                path: `${appConfig.coopSettings.ecommerce.search.url}?q=${searchTerm}${originalQuery}`,
            };

        case SearchResultType.Recipes:
            return {
                type: 'absolute' as const,
                path: `${appConfig.coopSettings.recipe.url}?query=${searchTerm}${originalQuery}`,
            };
        case SearchResultType.Stores:
            return {
                type: 'absolute' as const,
                path: `${globalSearchRoutes.stores}?query=${searchTerm}${originalQuery}`,
            };
        case SearchResultType.Articles:
            return {
                type: 'absolute' as const,
                path: `${globalSearchRoutes.articles}?query=${searchTerm}${originalQuery}`,
            };
        case SearchResultType.Pointshop:
            return {
                type: 'absolute' as const,
                path: `${globalSearchRoutes.pointOffers}?query=${searchTerm}${originalQuery}`,
            };
        default:
            return {
                type: 'absolute' as const,
                path: `${globalSearchRoutes.root}?query=${searchTerm}${originalQuery}`,
            };
    }
};

export const getNonEmptyResultTypes = (result?: GlobalSearchResults) => {
    if (!result) {
        return null;
    }
    const existingResultCounts: SearchResultCount[] = [
        {
            itemCount: result?.productResults?.totalResults,
            resultType: SearchResultType.Products,
        },
        {
            itemCount: result?.recipeResults?.totalResults,
            resultType: SearchResultType.Recipes,
        },
        {
            itemCount: result?.storeResults?.totalResults,
            resultType: SearchResultType.Stores,
        },
        {
            itemCount: result?.articleResults?.totalResults,
            resultType: SearchResultType.Articles,
        },
        {
            itemCount: result?.pointshopResults?.totalResults,
            resultType: SearchResultType.Pointshop,
        },
    ]
        .filter((item) => item.itemCount && item.itemCount > 0)
        .map<SearchResultCount>((item) => ({
            itemCount: item.itemCount!,
            resultType: item.resultType,
        }));

    return existingResultCounts;
};

export const getFullSearchResultUrlWithOrder = (
    searchTerm: string,
    originalSearchTerm: string | undefined,
    searchResultType?: SearchResultType,
) => {
    const originalQuery = `${
        originalSearchTerm && originalSearchTerm !== searchTerm ? `&oq=${originalSearchTerm}` : ''
    }`;

    switch (searchResultType) {
        case undefined:
            return {
                type: 'absolute' as const,
                path: `${appConfig.coopSettings.globalSearch.url}?query=${searchTerm}${originalQuery}`,
            };
        default:
            return {
                type: 'absolute' as const,
                path: `${
                    appConfig.coopSettings.globalSearch.url
                }?query=${searchTerm}${originalQuery}#${getFormattedSearchResultType(
                    searchResultType,
                )}`,
            };
    }
};
