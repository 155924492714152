import styles from './Search.module.scss';
import type { SearchSize, SearchTheme } from './Search.types';

export const getClassForTheme = (theme: SearchTheme) => {
    switch (theme) {
        case 'greenBorder':
            return styles['Search--greenBorder'];
        case 'grayBorder':
            return styles['Search--grayBorder'];
        case 'shadowed':
            return styles['Search--shadowed'];
        default:
            return '';
    }
};

export const getClassForSize = (theme: SearchSize) => {
    switch (theme) {
        case 'small':
            return styles['Search--small'];
        case 'medium':
            return styles['Search--medium'];
        case 'large':
            return styles['Search--large'];
        default:
            return '';
    }
};
