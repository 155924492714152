import type { CancelToken } from 'axios';
import axios from 'axios';

export const getCancellationToken = (abortSignal?: AbortSignal): CancelToken => {
    const source = axios.CancelToken.source();
    if (abortSignal) {
        abortSignal.addEventListener('abort', () => {
            source.cancel();
        });
    }

    return source.token;
};
