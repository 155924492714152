import type {
    CoopContentPageSize,
    CoopLayoutSize,
} from '../../../../utility/coopSwiperBreakpoints';
import type { PriceType } from '../../models/priceData/priceData';

export interface SharedUiState {
    hasSideBar: boolean;
    hideFooterMobile: boolean;
    hideSubMenu: boolean;
    layoutSize: CoopLayoutSize;
    contentPageSize: CoopContentPageSize;
    hideLoginReminder: boolean;
    userName: string | null;
    featureFlags: FeatureFlagsState;
    preferredPriceType?: PriceType;
}

export interface CncUiState {
    stepTrail: CncStep[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentStepNavigationParams: any; // can be anything when something needs to be passed from one step to another
    reloadOnClose: boolean;
    previewPostalCode: string | null;
}

export interface FeatureFlagsState {
    hidePreHeader: boolean;
}

export enum CncStep {
    MiniCart = 'MiniCart',
    TimeSlots = 'TimeSlots',
    Map = 'Map',
    PostalCodeSelector = 'PostalCodeSelector',
    PostalCodePreview = 'PostalCodePreview',
}
