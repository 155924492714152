import type { NonInteractionUserEvent } from '../analytics/analyticsHandler';
import { appConfig } from '../appConfig';
import orderFlow from '../microApps/common/flow/orderFlow';
import { Browser } from '../utility/browser';
import { Cookies } from '../utility/cookies';

export const gaNonInteractionUserEventHandler = () => {
    const EXPENSIVE_GA_AJAX_CALL_SENT = 'gaOcs';

    // send this event only once per session (session cookie),
    if (!Cookies.read(EXPENSIVE_GA_AJAX_CALL_SENT)) {
        const gaNonInteractionUserEvent = Browser.createNewEvent(
            'ga:nonInteractionUser',
        ) as NonInteractionUserEvent;

        Promise.all([
            fetch('/GoogleAnalytics/NonInteractionUserEventData'),
            appConfig.coopUserSettings.isAuthenticated ? orderFlow.getOrderCount() : undefined,
        ])
            .then(async ([gaResponse, orderCountResponse]) => [
                await gaResponse.json(),
                orderCountResponse,
            ])
            .then((data) => {
                const localApiData = data[0] as {
                    pointShopPoints: string;
                    selectionSupport: string;
                };
                const orderCount = data[1] as number | undefined;

                gaNonInteractionUserEvent.pointShopPoints = localApiData.pointShopPoints;
                gaNonInteractionUserEvent.utbud = localApiData.selectionSupport;
                if (orderCount !== undefined) {
                    gaNonInteractionUserEvent.dimension23 = orderCount.toString();
                }
            })
            .finally(() => {
                Cookies.create(EXPENSIVE_GA_AJAX_CALL_SENT, 'yes'); // always add this, we dont want to kill api with ons of requests if get orders throws exception
                window.dispatchEvent(gaNonInteractionUserEvent);
            });
    }
};
