import type { AxiosInstance } from 'axios';

import { appConfig } from '../../../../appConfig';
import { createDefaultException } from '../../exceptions/exceptionUtils';
import axiosClientFactory from './clientFactory';
import type { CustomErrorInterceptor } from './localApiAxiosErrorInterceptors';

type ApiException = {
    code: string;
    message: string;
    detail: string;
};

const errorInterceptor: CustomErrorInterceptor<ApiException> = (error) => {
    let code = '';
    let message = '';
    if (error.response.data.detail === 'The provided security code was incorrect.') {
        code = '200';
        message = 'Koden är fel eller har gått ut.';
    } else {
        code = '500';
        message = 'Något gick fel.';
    }

    const exception = createDefaultException(message, code);
    return exception;
};

const getDirectMessageRelayAxiosClient = (accessToken: string): AxiosInstance => {
    const client = axiosClientFactory.createDirectApiClient(
        {
            baseURL: appConfig.coopSettings.serviceAccess.messageRelayApiUrl,
            headers: {
                Accept: 'application/json',
                'Ocp-Apim-Trace': 'true',
                'Ocp-Apim-Subscription-Key':
                    appConfig.coopSettings.serviceAccess.messageRelayApiSubscriptionKey,
                Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
            },
            params: {
                'api-version': appConfig.coopSettings.serviceAccess.messageRelayApiVersion,
            },
        },
        [errorInterceptor],
    );

    return client;
};

export default getDirectMessageRelayAxiosClient;
