import styles from './Loader.module.scss';
import type { LoaderSize, LoaderTheme } from './Loader.types';

export const getClassForTheme = (theme: LoaderTheme) => {
    switch (theme) {
        case 'green':
            return styles['Loader--green'];
        case 'greenDark':
            return styles['Loader--greenDark'];
        case 'white':
            return styles['Loader--white'];
        case 'yellowLight':
            return styles['Loader--yellowLight'];
        case 'currentColor':
            return styles['Loader--currentColor'];
        default:
            return null;
    }
};

export const getClassForSize = (theme: LoaderSize) => {
    switch (theme) {
        case 'small':
            return styles['Loader--small'];
        case 12:
            return styles['Loader--size12'];
        case 16:
            return styles['Loader--size16'];
        case 20:
            return styles['Loader--size20'];
        case 24:
            return styles['Loader--size24'];
        case 28:
            return styles['Loader--size28'];
        case 'default':
        default:
            return null;
    }
};
