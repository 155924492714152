import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { appConfig } from '../../../appConfig';
import { appInsights } from '../../../appInsights';
import { getAuthToken } from '../api/auth/getAuthToken';
import { SyncronQueue } from '../api/SyncronQueue';
import { userAddressApi } from '../api/user/userAddressApi';
import type { ApiModifyUserAddressRequest } from '../api/user/userAddressModels';
import { userApi } from '../api/user/userApi';
import userProfileApi from '../api/userProfile/userProfileApi';
import persistance from '../persistance/persistance';
import type { AppAsyncThunk } from '../store';
import { municipalUserInfoActions } from '../store/slices/user/municipalUserInfoSlice';
import { userAddressActions } from '../store/slices/user/userAddressesSlice';
import { userInfoActions } from '../store/slices/user/userInfoSlice';
import { userTypeActions } from '../store/slices/user/userTypeSlice';
import type {
    MunicipalUserInfo,
    UserAddress,
    UserInfo,
    UserType,
} from '../store/structureDefinitions/userState';
import { isB2BWindowLocation, isInB2BCrossContext } from '../utils/b2bUtils';
import { type CustomerGroup, getAnonymousCustomerGroups } from '../utils/customerGroupsUtils';

const userFlowQueue = new SyncronQueue();
const userInfoQueue = new SyncronQueue();
const customersGroupQueue = new SyncronQueue();
const userAddressesQueue = new SyncronQueue();

const createAddress = (request: ApiModifyUserAddressRequest): AppAsyncThunk<UserAddress> => {
    return async (dispatch) => {
        dispatch(userAddressActions.fetching());
        try {
            const userAddress = await userAddressApi.create(request);
            dispatch(userAddressActions.create(userAddress));
            return userAddress;
        } finally {
            dispatch(userAddressActions.idle());
        }
    };
};

const editAddress = (address: UserAddress): AppAsyncThunk<UserAddress> => {
    return async (dispatch) => {
        dispatch(userAddressActions.fetching());
        try {
            await userAddressApi.update(address);
            dispatch(userAddressActions.update(address));
            return address;
        } finally {
            dispatch(userAddressActions.idle());
        }
    };
};

const deleteAddress = (addressId: string): AppAsyncThunk<string> => {
    return async (dispatch) => {
        dispatch(userAddressActions.fetching());
        try {
            await userAddressApi.remove(addressId);
            dispatch(userAddressActions.remove(addressId));
            return addressId;
        } finally {
            dispatch(userAddressActions.idle());
        }
    };
};

const getUserType = (): AppAsyncThunk<UserType> => {
    return async (dispatch) => {
        return userFlowQueue.run(
            async () => {
                const resultAction = await userApi.getTypeOfUser();
                dispatch(userTypeActions.set(resultAction));
                return resultAction;
            },
            { acceptRunningPromise: true, dontPushBack: true, uniqueName: 'getUserType' },
        );
    };
};

const customerGroupsMap: Record<string, CustomerGroup> = {
    businessgroup: 'CUSTOMER_COMPANY',
    b2bgroup: 'CUSTOMER_COMPANY',
    privatecustomergroup: 'CUSTOMER_PRIVATE',
    // this is being used in search - if search does not know this value it will break,
    // talk with others/loop if you want to uncomment this
    // ikpgroup: 'CUSTOMER_IKP',
};

const loadCustomerGroups = async () => {
    const userId = appConfig.coopUserSettings.shoppingUserId;
    try {
        const { token } = await getAuthToken();
        const result = await userApi.getCustomerGroups(token)(userId);
        const hybrisCustomerGroups =
            result?.userGroups?.filter((x) => !!x.uid).map((x) => x.uid) ?? [];
        const customersGroup = hybrisCustomerGroups.reduce((groups, hGroup) => {
            if (customerGroupsMap[hGroup]) {
                return [...groups, customerGroupsMap[hGroup]];
            }
            return groups;
        }, [] as CustomerGroup[]);

        // this information is still unreliable in hybris, so we still take it from login
        if (
            !appConfig.coopUserSettings.isShoppingForAnother &&
            appConfig.coopUserSettings.isMedmeraCustomer
        ) {
            customersGroup.push('CUSTOMER_MEDMERA');
        }

        return customersGroup;
    } catch (error) {
        const exError: Error = {
            name: 'Frontend - customer groups load fail',
            message: `Frontend - unable to load customer groups for user: ${userId}`,
            stack: undefined,
        };

        appInsights.trackException({
            exception: exError,
            severityLevel: SeverityLevel.Error,
        });

        // fallback to local customer groups
        return getAnonymousCustomerGroups();
    }
};

const getUserCustomerGroups = (userId: string | null, isAuthenticated: boolean) =>
    customersGroupQueue.run(
        async () => {
            if (!isAuthenticated || !userId) {
                return getAnonymousCustomerGroups();
            }

            let customerGroups = persistance.customerGroupsSession.get(userId);

            if (customerGroups === undefined) {
                customerGroups = await loadCustomerGroups();
                persistance.customerGroupsSession.add(customerGroups, userId);
            }

            return customerGroups;
        },
        { acceptRunningPromise: true, dontPushBack: true, uniqueName: 'getCustomerGroups' },
    );

const getUserInfo = (noCache = false): AppAsyncThunk<UserInfo> => {
    return async (dispatch) => {
        return userInfoQueue.run(
            async () => {
                const resultAction = await userApi.getUserInfo(noCache);
                dispatch(userInfoActions.set(resultAction));
                return resultAction;
            },
            { acceptRunningPromise: true, dontPushBack: true, uniqueName: 'getUserInfo' },
        );
    };
};

const getMunicipalUserInfo = (): AppAsyncThunk<MunicipalUserInfo> => {
    return async (dispatch) => {
        return userInfoQueue.run(
            async () => {
                const resultAction = await userApi.getMunicipalUserInfo();
                dispatch(municipalUserInfoActions.set(resultAction));
                return resultAction;
            },
            { acceptRunningPromise: true, dontPushBack: true, uniqueName: 'getMunicipalUserInfo' },
        );
    };
};

const getPunchoutUnit = async () => {
    const { token, shoppingUserId } = await getAuthToken();
    const punchoutUnit = await userApi.getUserPunchoutUnit(token)(shoppingUserId);
    return punchoutUnit;
};

const fetchPunchoutAddresses = async () => {
    const { token, shoppingUserId } = await getAuthToken();
    const punchoutUnit = await userApi.getUserPunchoutUnit(token)(shoppingUserId);
    const punchoutAddresses = await userAddressApi.fetchAllPunchout(token)(
        punchoutUnit.orgUnit.uid,
    );
    return punchoutAddresses.addresses;
};

const fetchAddresses = (isPunchout: boolean): AppAsyncThunk<UserAddress[]> => {
    return async (dispatch) => {
        return userAddressesQueue.run(
            async () => {
                dispatch(userAddressActions.fetching());
                try {
                    let userAddresses: UserAddress[] = [];
                    if (isPunchout) {
                        userAddresses = await fetchPunchoutAddresses();
                    } else {
                        userAddresses = await userAddressApi.fetchAll();
                    }

                    dispatch(userAddressActions.setAll(userAddresses));
                    return userAddresses;
                } finally {
                    dispatch(userAddressActions.idle());
                }
            },
            { acceptRunningPromise: true, dontPushBack: true, uniqueName: 'fetchUserAddresses' },
        );
    };
};

const fetchUserAddresses = async (take?: number, removeDublicateAddresses?: boolean) => {
    let userAddresses: UserAddress[] = [];
    userAddresses = await userAddressApi.fetchAll();

    userAddresses.reverse();

    if (removeDublicateAddresses) {
        // Function to check if two addresses are the same
        const areAddressesEqual = (address1: UserAddress, address2: UserAddress) => {
            return (
                address1.firstName === address2.firstName &&
                address1.lastName === address2.lastName &&
                address1.town === address2.town &&
                address1.postalCode === address2.postalCode &&
                address1.line1 === address2.line1
            );
        };

        // Remove duplicate addresses
        userAddresses = userAddresses.filter((address, index, self) => {
            return self.findIndex((a) => areAddressesEqual(address, a)) === index;
        });
    }

    if (take) {
        userAddresses = userAddresses.slice(0, take);
    }

    return userAddresses;
};

const fetchProfile = async () => {
    const response = await userProfileApi.getCurrentUserProfile();

    return response;
};

const updateFoodRegisteredCompany = async (foodRegisteredCompany: 'TRUE' | 'FALSE' | 'NOT_SET') => {
    const { token, shoppingUserId } = await getAuthToken();
    return userApi.updateProfile(token)(shoppingUserId, foodRegisteredCompany);
};

const getCompensationVochers = async () => {
    const { token, shoppingUserId } = await getAuthToken();
    return userApi.getCompensationVochers(token, shoppingUserId);
};

/**
 * Get customer groups for the user.
 * If called inside B2B context, it will return fake customer groups so that cross-context mode feels like anonymous mode.
 */
const getB2BContextCustomerGroups = async (): Promise<CustomerGroup[]> => {
    if (appConfig.coopSettings.featureFlags.enableB2B) {
        const { privateUserOnCompanySite, companyUserOnPrivateSite } = isInB2BCrossContext({
            isB2BRoute: isB2BWindowLocation(),
            isCompany: appConfig.coopUserSettings.isCompany,
            isAuthenticated: appConfig.coopUserSettings.isAuthenticated,
        });
        if (privateUserOnCompanySite) {
            return ['CUSTOMER_COMPANY'];
        }
        if (companyUserOnPrivateSite) {
            return ['CUSTOMER_PRIVATE', 'CUSTOMER_MEDMERA'];
        }
    }

    return userFlow.getUserCustomerGroups(
        appConfig.coopUserSettings.shoppingUserId,
        appConfig.coopUserSettings.isAuthenticated,
    );
};

const userFlow = {
    createAddress,
    updateAddress: editAddress,
    removeAddress: deleteAddress,
    fetchAddresses,
    fetchUserAddresses,
    getUserType,
    getUserInfo,
    getUserCustomerGroups,
    getB2BContextCustomerGroups,
    fetchProfile,
    getMunicipalUserInfo,
    updateFoodRegisteredCompany,
    getCompensationVochers,
    getPunchoutUnit,
};

export default userFlow;
