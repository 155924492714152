import { sendInteractionEvent } from '../utils';
import { POPUP_CHANGE_DELIVERY_OPTION } from './utils';

const createSendStoreChangeEvent = (eventAction: string, eventLabel: string) => () => {
    sendInteractionEvent({ eventAction, eventLabel });
};

export default {
    storeChange: {
        deliveryOption: {
            accept: createSendStoreChangeEvent(POPUP_CHANGE_DELIVERY_OPTION, 'Fortsätt'),
            decline: createSendStoreChangeEvent(POPUP_CHANGE_DELIVERY_OPTION, 'Avbryt'),
        },
    },
};
