import type { CustomerDataMinimized } from '../common/models/kimProxy/customerData';

const kimPhoneNumber = (customerData?: CustomerDataMinimized) => {
    const phoneNumber =
        customerData?.addresses?.find((x) => x.typeOfAddress === 'TEL_MOB')?.address || null;

    return phoneNumber;
};

const kimPhoneNumberVerified = (customerData?: CustomerDataMinimized) => {
    const phoneNumberVerified =
        customerData?.addresses?.find((x) => x.typeOfAddress === 'TEL_MOB')?.addressVerified ||
        false;

    return phoneNumberVerified;
};

export default {
    kimPhoneNumber,
    kimPhoneNumberVerified,
};
