import { LocalStorageFacade } from '../../../../facades/storage/localStorageFacade';

const KEY = 'coop-abtests';

interface AbTestStorage {
    [key: string]: AbTest | undefined;
}

export interface AbTest {
    name: string;
    percentage: number;
    created: Date;
    modified: Date;
    abTestValue: string;
}

const getMainStorage = () => LocalStorageFacade.instance.getObject<AbTestStorage>(KEY);

const abTestStorage = {
    all: () => {
        const storage = getMainStorage();
        if (!storage) {
            return [];
        }

        return Object.values(storage).filter(Boolean);
    },
    get: (name: string) => {
        const storage = getMainStorage();
        return storage && storage[name];
    },
    set: (abTest: AbTest) => {
        let storage = getMainStorage();
        if (!storage) {
            storage = {};
        }

        storage[abTest.name] = abTest;
        LocalStorageFacade.instance.addObject<AbTestStorage>(KEY, storage);
    },
    remove: (names: string[]) => {
        const storage = getMainStorage();
        if (!storage) return;

        Object.keys(storage)
            .filter((key) => names.includes(key))
            .forEach((key) => delete storage[key]);

        LocalStorageFacade.instance.addObject<AbTestStorage>(KEY, storage);
    },
};

export default abTestStorage;
