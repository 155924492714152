import { useCallback, useContext, useEffect, useRef } from 'react';

import { ModalContextState } from './ModalContext';

/**
 * Traps the users focus made with tab and shift+tab on the ref
 * caution: clicking outside the ref area will cause the focus to not be trapped
 */
export const useTrapFocus = <TElement extends HTMLElement>() => {
    const ref = useRef<TElement>(null);

    // We need a getFunction because querySelectorAll returns a snapshot and our React children
    // can update its children nodes or not completely rendered
    const getElements = useCallback(
        () => [
            ...Array.from(ref.current?.querySelectorAll<HTMLElement>(ACCEPTABLE_SELECTORS) || []),
        ],
        [],
    );

    const ACCEPTABLE_SELECTORS = 'a[href], button, textarea, input, select, [tabindex]';

    useEffect(() => {
        const focusIsOutsideOfTrap = (acceptableElements: Element[]) =>
            document.activeElement && !acceptableElements.includes(document.activeElement);

        const handleNext = (event: Event) => {
            const foundElements = getElements();
            if (!foundElements?.length) {
                return;
            }

            const currentFocusIsLast =
                foundElements[foundElements.length - 1] === document.activeElement;

            if (currentFocusIsLast || focusIsOutsideOfTrap(foundElements)) {
                event.preventDefault();
                foundElements[0].focus();
            }
        };

        const handlePrev = (event: Event) => {
            const foundElements = getElements();
            if (!foundElements?.length) {
                return;
            }

            const currentFocusIsFirst = foundElements[0] === document.activeElement;

            if (currentFocusIsFirst || focusIsOutsideOfTrap(foundElements)) {
                event.preventDefault();
                foundElements[foundElements.length - 1].focus();
            }
        };

        const handleUserKeyPress = (event: KeyboardEvent) => {
            if (!event.shiftKey && event.key?.toLowerCase() === 'Tab'.toLowerCase()) {
                handleNext(event);
            }

            if (event.shiftKey && event.key?.toLowerCase() === 'Tab'.toLowerCase()) {
                handlePrev(event);
            }
        };

        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [getElements]);

    return ref;
};

export const useEscapeKey = (callback: () => void) => {
    useEffect(() => {
        const handleUserKeyPress = (event: KeyboardEvent) => {
            if (event.key?.toLowerCase() === 'Escape'.toLowerCase()) {
                callback();
            }
        };

        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [callback]);
};

/**
 * Used to check if a component is mounted inside a modal
 */
export const useIsInsideModal = () => {
    const maybeParentModalContext = useContext(ModalContextState);
    const isInsideAnotherModal = !!maybeParentModalContext;

    return isInsideAnotherModal;
};

export const useModalContext = () => {
    const context = useContext(ModalContextState);
    return context;
};
