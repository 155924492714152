import classNames from 'classnames';
import * as React from 'react';

import { appConfig } from '../../../../appConfig';
import { ga4NavigationTracking } from '../../../common/tracking/ga4/navigation';
import { useIsB2BRoute } from '../../../common/utils/b2bUtils';
import useReactRouting from '../../../ecommerce/hooks/useReactRouting';
import styles from './UserTypeModeSwitch.module.less';

interface UserTypeModeSwitchProps {
    theme: 'white' | 'green';
}

/**
 * Switch for B2B / B2C sites
 */
const UserTypeModeSwitch = (props: UserTypeModeSwitchProps) => {
    const { push } = useReactRouting();
    const isB2BRoute = useIsB2BRoute();

    return (
        <div
            className={classNames(
                styles.UserModeSwitch,
                props.theme === 'white' && styles['UserModeSwitch--white'],
                props.theme === 'green' && styles['UserModeSwitch--green'],
            )}
        >
            <a
                href={appConfig.coopSettings.b2c.startPage.url}
                className={classNames(
                    styles.UserMode,
                    styles['UserMode--topIndicator'],
                    !isB2BRoute && styles.UserModeActive,
                )}
                onClick={(e) => {
                    e.preventDefault();
                    ga4NavigationTracking.headerNavigationClick(
                        e.currentTarget.innerText,
                        e.currentTarget.href,
                    );
                    push(e.currentTarget.href);
                }}
                aria-label="Gå till Coop privat"
                aria-current={!isB2BRoute ? 'page' : undefined}
            >
                Privat
            </a>
            <a
                href={appConfig.coopSettings.b2b.startPage?.url}
                className={classNames(
                    styles.UserMode,
                    styles['UserMode--topIndicator'],
                    isB2BRoute && styles.UserModeActive,
                )}
                onClick={(e) => {
                    e.preventDefault();
                    ga4NavigationTracking.headerNavigationClick(
                        e.currentTarget.innerText,
                        e.currentTarget.href,
                    );
                    push(e.currentTarget.href);
                }}
                aria-label="Gå till Coop företag"
                aria-current={isB2BRoute ? 'page' : undefined}
            >
                Företag
            </a>
        </div>
    );
};
export default UserTypeModeSwitch;
