import { LockIcon } from '@coop/icons';
import * as React from 'react';

import { AccessibleIcon } from '../Icon';
import { StyledTooltip } from '../Tooltip';
import styles from './TextInput.module.scss';

const DisabledTooltip = (props: {
    disabledReason: React.ReactNode;
    container: HTMLElement | null;
}) => {
    return (
        <StyledTooltip.Provider>
            <StyledTooltip.Root delayDuration={0}>
                <StyledTooltip.Trigger asChild>
                    <div className={styles.LockIcon}>
                        <AccessibleIcon icon={LockIcon} tabIndex={-1} color="black" label="lock" />
                    </div>
                </StyledTooltip.Trigger>
                <StyledTooltip.Portal container={props.container}>
                    <StyledTooltip.Content
                        style={{ zIndex: 1 }}
                        collisionPadding={{
                            right: 10,
                            left: 10,
                        }}
                    >
                        {props.disabledReason}
                        <StyledTooltip.Arrow />
                    </StyledTooltip.Content>
                </StyledTooltip.Portal>
            </StyledTooltip.Root>
        </StyledTooltip.Provider>
    );
};

export default DisabledTooltip;
