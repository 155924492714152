import type { EntityState } from '@reduxjs/toolkit';

export interface MunicipalUserInfoState {
    isInitialized: boolean;
    info?: MunicipalUserInfo;
    isShoppingForAnother: boolean;
}

export interface UserInfo {
    selfOrder: boolean;
    defaultAddress: UserAddress;
    orderPickerMessage: string;
    invoiceReference: string;
    globalLocationNumber: string;
    redistributorOfFood?: 'TRUE' | 'FALSE' | 'NOT_SET';
    companyName: string;
    firstName: string;
    lastName: string;
}

export interface MunicipalUserInfo {
    firstName: string;
    lastName: string;
    municipalityUnitCode: string;
    municipalityCode: string;
    commentAboutCustomer: string;
}

export interface UserAddresses {
    isFetching: boolean;
    error: string;
    selectedAddressId: string;
    addresses: EntityState<UserAddress>;
}

export interface UserAddress {
    id: string;
    firstName: string;
    lastName: string;
    town: string;
    postalCode: string;
    email: string;
    doorCode?: string;
    companyName?: string;
    reference?: string;
    defaultAddress?: boolean;
    formattedAddress?: string;
    line1: string;
    line2?: string;
    phone: string;
    stairs?: string;
    title?: string;
    titleCode?: string;
}

export interface InvoiceAddress {
    id: string;
    firstName: string;
    lastName: string;
    town: string;
    postalCode: string;
    email: string;
    doorCode?: string;
    companyName?: string;
    reference?: string;
    /**
     * This does not actually belong address object in hybris, so it should be removed?
     */
    organizationNumber?: string;
    defaultAddress?: boolean;
    formattedAddress?: string;
    line1: string;
    line2?: string;
    phone: string;
    stairs?: string;
    title?: string;
    titleCode?: string;
}

export const enum UserType {
    anonymous = 'Anonymous',
    private = 'Private',
    company = 'Company',
    municipal = 'Municipal',
    punchout = 'Punchout',
}
