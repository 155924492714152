import type { FC } from 'react';
import * as React from 'react';

import { BrowserSize } from '../../../../utility/browser';
import { useFromBrowserMediaQuery } from '../../../common/components/atoms/ResponsiveBreakpoints';
import GlobalSearchBar from '../GlobalHeader/GlobalSearchBar';
import SearchInContent from '../SearchInContent';

interface GlobalSearchInContentProps {
    performFullSearch?: (query: string, originalQuery?: string) => void;
    globalSearchDisabled?: boolean;
}

const GlobalSearchInContent: FC<React.PropsWithChildren<GlobalSearchInContentProps>> = (props) => {
    const isFromLarge = useFromBrowserMediaQuery(BrowserSize.lg);

    return (
        <SearchInContent
            searchSlot={
                !props.globalSearchDisabled && (
                    <GlobalSearchBar
                        performFullSearch={props.performFullSearch}
                        size={isFromLarge ? 'large' : 'medium'}
                    />
                )
            }
        />
    );
};

export default GlobalSearchInContent;
