import { appConfig } from '../../../appConfig';
import { ensureSlashes, trimTrailingSlash } from '../../common/utils/stringUtils';

const GLOBAL_SEARCH_PAGE = trimTrailingSlash(
    ensureSlashes(appConfig.coopSettings.globalSearch.url),
);

export const globalSearchRoutes = {
    root: GLOBAL_SEARCH_PAGE,
    articles: `${GLOBAL_SEARCH_PAGE}/artiklar`,
    stores: `${GLOBAL_SEARCH_PAGE}/butiker`,
    pointOffers: `${GLOBAL_SEARCH_PAGE}/erbjudanden`,
};
