import type { IApiStore } from '../../../../models/ecommerce/store/storeModel';
import type { ApiSwitchStoreResult } from '../cart/responses/cartResponses';
import hybrisAxiosClient from '../clients/hybrisAxiosClient';
import { getCancellationToken } from '../utils';

const fetchStoreById = async (storeId: string): Promise<IApiStore> => {
    const response = await hybrisAxiosClient.get(`/store/detailed?id=${storeId}`);
    return response.data;
};

const searchPickupStores = async (
    searchterm: string,
    hits: number | null,
    abortSignal?: AbortSignal,
): Promise<IApiStore[]> => {
    const cancelToken = getCancellationToken(abortSignal);

    const response = await hybrisAxiosClient.get('/store/list', {
        params: {
            searchterm: searchterm || '',
            hits: hits || '',
        },
        cancelToken,
    });
    const stores: IApiStore[] = response.data;
    return stores;
};

const fetchPickupStores = async (): Promise<IApiStore[]> => {
    return searchPickupStores('', null);
};

const fetchStoreByPostalCode = async (zipCode: string): Promise<IApiStore> => {
    const response = await hybrisAxiosClient.get<IApiStore>(`/store?zipCode=${zipCode}`);
    return response.data;
};

const updatePickupStore = async (storeId: string): Promise<IApiStore> => {
    const response = await hybrisAxiosClient.put('/store/pickupStore', {
        uid: storeId,
    });
    return response.data;
};

/**
 * This doesnt change postalcode if there is any items in the cart that will be lost in the change.
 * Flag "simulate" on response tells you if the change only been simulated or not.
 * @param zipCode
 */
const updateHomeDeliveryStoreUnconfirmed = async (
    postalCode: string,
    baseStoreId: string | null = null,
): Promise<ApiSwitchStoreResult> => {
    const response = await hybrisAxiosClient.post('/store/current/unconfirmed', {
        zipCode: postalCode,
        baseStoreId,
    });
    return response.data;
};

/**
 * Flag "simulate" on response tells you if the change only been simulated or not.
 */
const updatePickupStoreUnconfirmed = async (storeId: string): Promise<ApiSwitchStoreResult> => {
    const response = await hybrisAxiosClient.put('/store/pickupStore/unconfirmed', {
        uid: storeId,
    });
    return response.data;
};

const updateHomeDeliveryStore = async (
    postalCode: string,
    baseStoreId: string | null = null,
): Promise<IApiStore> => {
    const response = await hybrisAxiosClient.post('/store/current', {
        zipCode: postalCode,
        baseStoreId,
    });
    return response.data;
};

export const storeApi = {
    fetchStoreByPostalCode,
    fetchPickupStores,
    fetchStoreById,
    searchPickupStores,
    updatePickupStore,
    updateHomeDeliveryStore,
    updatePickupStoreUnconfirmed,
    updateHomeDeliveryStoreUnconfirmed,
};
