import { combineReducers } from 'redux';

import cartReducer from './slices/cart/cartSlice';
import checkoutReducer from './slices/checkout/checkoutSlice';
import currentPageReducer from './slices/currentPage/currentPageSlice';
import { errorsReducer } from './slices/errors/errorsSlice';
import { modalReducer } from './slices/modal/modalSlice';
import storeReducer from './slices/store/storeSlice';
import uiReducer from './slices/ui/uiSlice';
import unsyncedCartItemsReducer from './slices/unsyncedCartItemsSlice';
import userReducer from './slices/user/userSlice';

const rootReducer = combineReducers({
    store: storeReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    user: userReducer,
    modal: modalReducer,
    errors: errorsReducer,
    ui: uiReducer,
    unsyncedCartItems: unsyncedCartItemsReducer,
    currentPage: currentPageReducer,
});

export default rootReducer;
