import { Button, ModalHeader, Notification } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import TextInputRHF from '../../../../../common/components/molecules/TextInputRHF';
import messageRelayFlow from '../../../../../common/flow/messageRelayFlow';
import styles from './FormValidate.module.less';

type VerifyFormInputs = {
    code: string;
};

interface FormValidateProps {
    phoneNumber: string;
    onSubmitSuccess: () => void;
    timeLeftFirstAttempt: number;
    onClose: (newPhoneNumber: string) => void;
}

const FormValidate: React.FC<React.PropsWithChildren<FormValidateProps>> = (
    props: FormValidateProps,
) => {
    const [sendAgainLoading, setSendAgainLoading] = React.useState(false);
    const [timeLeft, setTimeLeft] = useState(props.timeLeftFirstAttempt);
    const [sendAgainErrorMsgShow, setSendAgainErrorMsgShow] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const sendVerificationMutation = useMutation({
        mutationFn: (phone: string) => messageRelayFlow.sendVerification(phone),
        onSuccess: (data: number) => {
            if (data) {
                setTimeLeft(data);
            }
        },
        onError: (error) => {
            setErrorMessage(error.friendlyMessage);
        },
    });

    const { control, handleSubmit, setError } = useForm<VerifyFormInputs>();

    useEffect(() => {
        // If timeLeft is 0, stop the countdown
        if (timeLeft === 0) {
            setSendAgainErrorMsgShow(false);
            return;
        }

        // Set interval to decrease timeLeft every second
        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        // Cleanup interval when component unmounts or timeLeft changes
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const confirmVerificationMutation = useMutation({
        mutationFn: (code: string) => messageRelayFlow.confirmVerification(code),
        onSuccess: () => {
            props.onSubmitSuccess();
        },
        onError: (error) => {
            setErrorMessage(error.friendlyMessage);
            if (error.code !== '500') {
                setErrorMessage('Koden är fel eller har gått ut.');
                setError('code', {
                    type: 'manual',
                    message: 'Koden är fel eller har gått ut.',
                });
            }
        },
    });

    const onCodeChange = (code: string) => {
        if (!code || code.length < 6 || code.length > 6) {
            return false;
        }
        return true;
    };

    const checkedCodeLength = (code: string) => {
        if (!code || code.length !== 6) {
            return false;
        }

        return true;
    };

    const onSubmit: SubmitHandler<VerifyFormInputs> = (data) => {
        confirmVerificationMutation.mutate(data.code);
    };

    const sendAgain = () => {
        if (timeLeft > 0) {
            setSendAgainErrorMsgShow(true);
            return;
        }

        // Make a loader so that the user understans sms is sent
        setSendAgainLoading(true);
        setTimeout(() => {
            setSendAgainLoading(false);
        }, 3000); // 3 sec

        sendVerificationMutation.mutate(props.phoneNumber);
    };

    return (
        <div>
            <Notification
                header="Något blev fel"
                behaviour="autoclosing"
                type="alert"
                show={!!errorMessage}
                message={errorMessage}
                close={() => setErrorMessage('')}
            />
            <ModalHeader className={styles.Headline}>Nästan klart!</ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} className="u-sizeFull">
                <div className={styles.InnerContent}>
                    <div className={styles.PhoneNumber}>
                        Ange verifikationskod som skickats till
                        <br />
                        <b>{props.phoneNumber}</b>
                    </div>
                    <div className={styles.InputDiv}>
                        <Controller
                            render={({ field, fieldState }) => {
                                let validationText = 'Koden ska innehålla 6 siffror';
                                if (!!field.value && !checkedCodeLength(field.value))
                                    validationText = 'Koden har inte rätt längd';
                                else if (fieldState.error?.message)
                                    validationText = fieldState.error?.message;

                                return (
                                    <TextInputRHF
                                        autoFocus
                                        label="Kod"
                                        aria-label={`Ange verifikationskod som skickats till ${
                                            props.phoneNumber
                                        }`}
                                        value={field.value || ''}
                                        name={field.name}
                                        onChange={field.onChange}
                                        ref={field.ref}
                                        validationText={validationText}
                                        isInvalid={
                                            !!fieldState.error ||
                                            (!!field.value && !onCodeChange(field.value))
                                        }
                                    />
                                );
                            }}
                            rules={{
                                validate: (value) => onCodeChange(value) || 'Fel kod',
                            }}
                            name="code"
                            control={control}
                        />
                    </div>

                    <div className={styles.ButtonContainer}>
                        <Button
                            type="submit"
                            theme="primary"
                            fullWidth
                            isLoading={confirmVerificationMutation.isPending}
                        >
                            Verifiera
                        </Button>

                        <Button
                            type="button"
                            theme="primaryOutline"
                            fullWidth
                            onClick={sendAgain}
                            isLoading={sendAgainLoading}
                            loadingText="Skickar..."
                        >
                            Skicka ny kod
                        </Button>
                        <div aria-live="polite" className="u-hiddenVisually">
                            {sendAgainLoading && <b>Verifieringskoden har skickats</b>}
                        </div>
                        <div aria-live="polite">
                            {sendAgainErrorMsgShow && (
                                <b>
                                    Du måste vänta en minut innan
                                    <br />
                                    du kan skicka en ny kod.
                                </b>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FormValidate;
