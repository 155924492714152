/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import './window.abtest.utils';

import { appConfig } from '../appConfig';
import { userQueryKeys } from '../microApps/common/hooks/useUserType';
import { coopQueryClient } from '../microApps/common/init/coopQueryClient';
import { PriceType } from '../microApps/common/models/priceData/priceData';
import persistance from '../microApps/common/persistance/persistance';
import store from '../microApps/common/store';
import { cartEditOrderActions } from '../microApps/common/store/slices/cart/cartEditOrderSlice';
import debugSlice from '../microApps/common/store/slices/ui/debugSlice';
import { sharedUiActions } from '../microApps/common/store/slices/ui/sharedUiSlice';
import { municipalUserInfoActions } from '../microApps/common/store/slices/user/municipalUserInfoSlice';
import { UserType } from '../microApps/common/store/structureDefinitions/userState';
import { punchoutQueryKeys } from '../microApps/ecommerce/hooks/usePunchoutOrgUnit';
import { isDevelopmentEnvironment, isProductionEnvironment } from './environments';

window.console.logDev = (...params) => {
    if (isDevelopmentEnvironment) {
        console.log(...params);
    }
};

window.console.logNotProd = (...params) => {
    if (!isProductionEnvironment) {
        console.log(...params);
    }
};

window.console.errorDev = (...params) => {
    if (isDevelopmentEnvironment) {
        console.error(...params);
    }
};

// This code will not be available in production builds
if (!isProductionEnvironment) {
    (window as any).mockOrderReclamationUI = () => {
        store.dispatch(debugSlice.actions.mockReclamationUI(true));
    };

    (window as any).mockMunicipalUI = (
        firstName: string = 'Firstname',
        lastName: string = 'Lastname',
        comments: string = 'comments',
    ) => {
        store.dispatch(
            municipalUserInfoActions.set({
                firstName,
                lastName,
                commentAboutCustomer: comments,
                municipalityCode: '345',
                municipalityUnitCode: '123',
            }),
        );

        store.dispatch(municipalUserInfoActions.mockShoppingForAnother());
    };

    (window as any).mockEditOrderUi = () => {
        store.dispatch(cartEditOrderActions.mock());
    };

    (window as any).mockPunchoutUI = (orgUnitName: string = 'Awesome Punchout Customer') => {
        coopQueryClient.setQueryData(userQueryKeys.type, () => UserType.punchout);
        coopQueryClient.setQueryData(punchoutQueryKeys.orgUnit, () => ({
            orgUnit: {
                uid: '123',
                name: orgUnitName,
                agreementId: '',
                gln: '',
            },
        }));
    };

    (window as any).addProductsInDocument = () => {
        Array.from(document.querySelectorAll<HTMLElement>('.AddToCart--new button')).forEach(
            (item) => item.click(),
        );
    };
}

(window as any).setPriceType = (priceType: PriceType) => {
    const userId = appConfig.coopUserSettings.shoppingUserId;

    if (priceType === PriceType.IncludeTax) {
        persistance.priceTypeStorage.setPricesWithTax(userId);
        store.dispatch(sharedUiActions.setPriceType({ preferredPriceType: PriceType.IncludeTax }));
        console.log(
            'Prices switched to IncludeTax. Switch only for presentation purposes. For proper testing, use B2C user.',
        );
    }

    if (priceType === PriceType.ExcludeTax) {
        persistance.priceTypeStorage.setPricesWithoutTax(userId);
        store.dispatch(sharedUiActions.setPriceType({ preferredPriceType: PriceType.ExcludeTax }));
        console.log(
            'Prices switched to ExcludeTax. Switch only for presentation purposes, APIs might not return some B2B prices when B2C user is used. For proper testing, use B2B user.',
        );
    }
};

(window as any).setExcludeTaxPriceType = () => {
    (window as any).setPriceType('ExcludeTax');
};

(window as any).setIncludeTaxPriceType = () => {
    (window as any).setPriceType('IncludeTax');
};

(window as any).getRenderedSSRprops = () => {
    return Array.from(document.querySelectorAll<HTMLElement>('[data-react-props]'))
        .filter((node) => !!node.dataset?.reactProps)
        .map((node) => JSON.parse(node.dataset.reactProps!));
};

(window as any).togglePreheader = (status: boolean) => {
    store.dispatch(sharedUiActions.setFeatureFlag({ hidePreHeader: !status }));
};

(window as any).trackActiveElement = (time: number = 500) => {
    setInterval(() => {
        console.log(document.activeElement);
    }, time);
};
