import { useEffect, useRef } from 'react';

import { SyncronQueue } from '../api/SyncronQueue';

const createScriptElement = (scriptElement: HTMLScriptElement) => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    if (scriptElement.src) {
        script.src = scriptElement.src;
    }
    if (scriptElement.innerHTML) {
        script.append(scriptElement.innerHTML);
    }

    return script;
};

const useHtmlScriptParser = (html: string, enabled: boolean) => {
    const queue = useRef(new SyncronQueue());
    const scriptsContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (enabled) {
            const template = document.createElement('div');
            template.innerHTML = html;
            const scriptsFromRawHtml = Array.from(template.getElementsByTagName('script'));

            // we need to create new elements so browser parses them
            const scriptElements = scriptsFromRawHtml.map((script) => createScriptElement(script));

            // all scripts dynamically added to the DOM are ALWAYS asynchronous
            // that means that inline scripts that wait for their external ones will fail when added instantly to DOM as they were not loaded yet
            // so resolve scripts in a queue in the order they were added in html string
            const promises = scriptElements.map((exScript) => () => {
                const promise = new Promise((resolve, reject) => {
                    if (exScript.src) {
                        // eslint-disable-next-line no-param-reassign
                        exScript.onload = () => {
                            resolve(`${exScript.src} loaded`);
                        };

                        // eslint-disable-next-line no-param-reassign
                        exScript.onerror = reject;
                        scriptsContainerRef.current?.append(exScript);
                    } else {
                        scriptsContainerRef.current?.append(exScript);
                        resolve('inline script loaded');
                    }
                });

                return promise;
            });

            promises.forEach((func) => queue.current.run(func));
        }
    }, [html, enabled]);

    return scriptsContainerRef;
};

export default useHtmlScriptParser;
