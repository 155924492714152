import { sendInteractionEvent } from '../utils';

// Checkout - Step 3 - Leverans - Hämta i butik
const sendPickupDeliveryTabInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction: 'Checkout - Leverans - Hämta i butik',
        eventLabel,
    });
};

const sendGaPickupDeliveryTabLocationInteractionClickAdd = () => {
    sendPickupDeliveryTabInteractionClick('Hämtas på - Lägg till');
};

const sendGaPickupDeliveryTabLocationInteractionClickChange = () => {
    sendPickupDeliveryTabInteractionClick('Hämtas på - Ändra');
};

const sendGaPickupDeliveryTabContactDetailsInteractionClickAdd = () => {
    sendPickupDeliveryTabInteractionClick('Kontaktuppgifter - Lägg till');
};

const sendGaPickupDeliveryTabContactDetailsInteractionClickChange = () => {
    sendPickupDeliveryTabInteractionClick('Kontaktuppgifter - Ändra');
};

const sendGaPickupDeliveryTabPickupDateInteractionClickAdd = () => {
    sendPickupDeliveryTabInteractionClick('Avhämtningstid - Lägg till');
};

const sendGaPickupDeliveryTabPickupDateInteractionClickChange = () => {
    sendPickupDeliveryTabInteractionClick('Avhämtningstid - Ändra');
};

// Checkout - Leverans - Hämta i butik - Fakturaadress
const sendPickupDeliveryBillingTabInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction: 'Checkout - Leverans - Hämta i butik - Fakturadress',
        eventLabel,
    });
};

const sendGaPickupDeliveryTabBillingAddressInteractionClickAdd = () => {
    sendPickupDeliveryBillingTabInteractionClick('Lägg till fakturaadress');
};

const sendGaPickupDeliveryTabBillingAddressInteractionClickChange = () => {
    sendPickupDeliveryBillingTabInteractionClick('Ändra fakturaadress');
};

// Checkout - Step 3 - Leverans - Hämta i butik - Kontaktuppgifter
const sendPickupDeliveryAddressInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction: 'Checkout - Leverans - Hämta i butik - Kontaktuppgifter',
        eventLabel,
    });
};

const sendGaPickupDeliveryAddressInteractionClickAdd = () => {
    sendPickupDeliveryAddressInteractionClick('Lägg till kontaktuppgifter');
};

const sendGaPickupDeliveryAddressInteractionClickEdit = () => {
    sendPickupDeliveryAddressInteractionClick('Redigera kontaktuppgifter');
};

const sendGaPickupDeliveryAddressInteractionClickSelect = () => {
    sendPickupDeliveryAddressInteractionClick('Ändra kontaktuppgifter');
};

const sendGaPickupDeliveryAddressInteractionClickClose = () => {
    sendPickupDeliveryAddressInteractionClick('Stäng');
};

// Checkout - Step 3 - Leverans - Hämta i butik - Kontaktuppgifter - Redigera adress
const sendPickupDeliveryEditAddressInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction: 'Checkout - Leverans - Hämta i butik - Kontaktuppgifter - Redigera adress',
        eventLabel,
    });
};

const sendGaPickupDeliveryEditAddressInteractionClickBack = () => {
    sendPickupDeliveryEditAddressInteractionClick('Tillbaka');
};

const sendGaPickupDeliveryEditAddressInteractionClickClose = () => {
    sendPickupDeliveryEditAddressInteractionClick('Stäng');
};

const sendGaPickupDeliveryEditAddressInteractionClickSaveAddress = () => {
    sendPickupDeliveryEditAddressInteractionClick('Spara ändringar');
};

const sendGaPickupDeliveryEditAddressInteractionClickRemoveAddress = () => {
    sendPickupDeliveryEditAddressInteractionClick('Ta bort adress');
};

const sendGaPickupDeliveryEditAddressInteractionClickRemoveAddressYes = () => {
    sendPickupDeliveryEditAddressInteractionClick('Ta bort adress - Ja');
};

const sendGaPickupDeliveryEditAddressInteractionClickRemoveAddressNo = () => {
    sendPickupDeliveryEditAddressInteractionClick('Ta bort adress - Avbryt');
};

// Checkout - Step 3 - Leverans - Hämta i butik - Kontaktuppgifter - Redigera adress
const sendPickupDeliveryAddAddressInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction:
            'Checkout - Leverans - Hämta i butik - Kontaktuppgifter - Lägg till kontaktuppgifter',
        eventLabel,
    });
};

const sendGaPickupDeliveryAddAddressInteractionClickSaveAddress = () => {
    sendPickupDeliveryAddAddressInteractionClick('Spara ändringar');
};

const sendGaPickupDeliveryAddAddressInteractionClickBack = () => {
    sendPickupDeliveryAddAddressInteractionClick('Gå tillbaka');
};

const sendGaPickupDeliveryAddAddressInteractionClickClose = () => {
    sendPickupDeliveryAddAddressInteractionClick('Stäng');
};

const pickupTracking = {
    pickupStore: {
        add: sendGaPickupDeliveryTabLocationInteractionClickAdd,
        change: sendGaPickupDeliveryTabLocationInteractionClickChange,
    },
    contactAddress: {
        add: sendGaPickupDeliveryTabContactDetailsInteractionClickAdd,
        change: sendGaPickupDeliveryTabContactDetailsInteractionClickChange,
        flyIn: {
            add: sendGaPickupDeliveryAddressInteractionClickAdd,
            editClick: sendGaPickupDeliveryAddressInteractionClickEdit,
            select: sendGaPickupDeliveryAddressInteractionClickSelect,
            close: sendGaPickupDeliveryAddressInteractionClickClose,
            edit: {
                back: sendGaPickupDeliveryEditAddressInteractionClickBack,
                close: sendGaPickupDeliveryEditAddressInteractionClickClose,
                save: sendGaPickupDeliveryEditAddressInteractionClickSaveAddress,
                remove: sendGaPickupDeliveryEditAddressInteractionClickRemoveAddress,
                removeConfirm: sendGaPickupDeliveryEditAddressInteractionClickRemoveAddressYes,
                removeCancel: sendGaPickupDeliveryEditAddressInteractionClickRemoveAddressNo,
            },
            create: {
                save: sendGaPickupDeliveryAddAddressInteractionClickSaveAddress,
                back: sendGaPickupDeliveryAddAddressInteractionClickBack,
                close: sendGaPickupDeliveryAddAddressInteractionClickClose,
            },
        },
    },
    billingAddress: {
        add: sendGaPickupDeliveryTabBillingAddressInteractionClickAdd,
        change: sendGaPickupDeliveryTabBillingAddressInteractionClickChange,
    },
    timeslot: {
        add: sendGaPickupDeliveryTabPickupDateInteractionClickAdd,
        change: sendGaPickupDeliveryTabPickupDateInteractionClickChange,
    },
};

export default pickupTracking;
