import { useEffect, useRef } from 'react';

export const useSetTimeout = (condition: boolean, timeout: number, callback: () => void) => {
    const timeoutHandle = useRef<NodeJS.Timeout | null>(null);

    const callbackRef = useRef(callback);

    useEffect(() => {
        if (condition) {
            timeoutHandle.current = setTimeout(() => {
                callbackRef.current();
            }, timeout);
        }

        return () => {
            if (timeoutHandle.current) {
                clearTimeout(timeoutHandle.current);
            }
        };
    }, [condition, timeout]);
};
