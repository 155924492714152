/* eslint-disable react/jsx-props-no-spreading */
import { Button, ChipIconButton } from '@coop/components';
import { CloseIcon } from '@coop/icons';
import classNames from 'classnames';
import * as React from 'react';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './TooltipNotification.module.scss';

type RestrictedButtonProps = Omit<React.ComponentProps<typeof Button>, 'theme' | 'fullWidth'>;

interface TooltipNotificationProps {
    isOpen: boolean;
    close: () => void;
    header: string;
    content?: string;
    animated?: boolean;
    primaryButtonProps: RestrictedButtonProps;
    secondaryButtonProps: RestrictedButtonProps;
}

const TooltipNotification = ({ animated = true, ...props }: TooltipNotificationProps) => {
    const transitionRef = useRef(null);

    return (
        <CSSTransition
            nodeRef={transitionRef}
            in={props.isOpen}
            timeout={animated ? 200 : 0}
            classNames={{
                enterActive: classNames(styles['is-visible']),
                enterDone: classNames(styles['is-visible']),
            }}
            appear
            unmountOnExit
        >
            <div
                ref={transitionRef}
                role="tooltip"
                className={classNames(styles.Wrapper, animated && styles['is-animated'])}
            >
                <div className={styles.Tooltip}>
                    <div className={styles.Heading}>{props.header}</div>
                    <div className={styles.Content}>{props.content}</div>
                    <div className={styles.Close}>
                        <ChipIconButton
                            theme="whiteGreen"
                            onClick={props.close}
                            size={32}
                            icon={CloseIcon}
                            label="Stäng"
                        />
                    </div>
                    <div className={styles.Footer}>
                        <Button {...props.primaryButtonProps} theme="primary" fullWidth />
                        <Button {...props.secondaryButtonProps} theme="primaryOutline" fullWidth />
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default TooltipNotification;
