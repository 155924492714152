/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */

// docs: https://github.com/upscopeio/js-api

import { GdprCookiePurposes, hasAcceptedGdprPurpose } from './gdpr';

/**
 * Download, initialize Upscope and create session to keep downloading the script on further pages when user navigates on coop.se during the session
 */
export const initUpscope = () => {
    if (hasAcceptedGdprPurpose(GdprCookiePurposes.Functional)) {
        // @ts-ignore
        (function (w, u, d) {
            var i = function () {
                // @ts-ignore
                i.c(arguments);
            };
            // @ts-ignore
            i.q = [];
            // @ts-ignore
            i.c = function (args: any) {
                // @ts-ignore
                i.q.push(args);
            };
            const l = function () {
                const s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://code.upscope.io/qPrDRknSJ5.js';
                const x = d.getElementsByTagName('script')[0];
                x.parentNode?.insertBefore(s, x);
            };
            if (typeof u !== 'function') {
                w.Upscope = i;
                l();
            }
        })(window, window.Upscope, document);
        window.Upscope('init');
        window.sessionStorage.setItem('coop_allowupscope', 'true');
    }
};

const getLookupCode = (cb: (lookupCode: string) => void) => {
    if (!window.Upscope) {
        initUpscope();
    }
    window.Upscope('getLookupCode', function (code: string) {
        cb(code);
    });
};

export default {
    getLookupCode,
};
