import type { FC } from 'react';
import * as React from 'react';

import { MicroAppContextProvider, MicroAppContextType } from '../common/contexts/microAppContext';
import ReactAppWrapper from '../common/init/ReactAppWrapper';
import renderMicroApp from '../common/init/renderMicroApp';
import { getEpiPageByUrlQueryKey } from '../episerver/components/hooks/useCurrentEpiPage';
import StartPage, { startPageQueryKeys } from './StartPage';

interface StartAppDomProps {
    currentPage: {
        stringifiedModel: string;
        depth: number;
    };
    variationPage: {
        stringifiedModel: string;
        depth: number;
    };
}

interface StartAppProps {
    ssrData?: StartAppDomProps;
}

const StartApp: FC<StartAppProps> = (props) => {
    const initialQueryClientData = React.useRef(
        [
            props.ssrData?.currentPage
                ? {
                      key: getEpiPageByUrlQueryKey(window.location.pathname, {
                          depth: props.ssrData.currentPage.depth,
                      }),
                      data: JSON.parse(props.ssrData.currentPage.stringifiedModel),
                  }
                : undefined,
            props.ssrData?.variationPage
                ? {
                      key: startPageQueryKeys.variationPage,
                      data: JSON.parse(props.ssrData.variationPage.stringifiedModel),
                  }
                : undefined,
        ].filter(Boolean),
    );

    return (
        <MicroAppContextProvider microAppType={MicroAppContextType.Start}>
            <ReactAppWrapper initialQueryClientData={initialQueryClientData.current}>
                <StartPage />
            </ReactAppWrapper>
        </MicroAppContextProvider>
    );
};

renderMicroApp(StartApp);
