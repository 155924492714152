import type { RootState } from '../store/structureDefinitions/cartState';

const selectCurrentStep = (state: RootState) =>
    state.ui.cnc.stepTrail && !!state.ui.cnc.stepTrail.length
        ? state.ui.cnc.stepTrail[state.ui.cnc.stepTrail.length - 1]
        : null;

const selectPreviousStep = (state: RootState) =>
    state.ui.cnc.stepTrail && state.ui.cnc.stepTrail.length > 1
        ? state.ui.cnc.stepTrail[state.ui.cnc.stepTrail.length - 2]
        : null;

const selectStepNavigationParam = (state: RootState) => state.ui.cnc.currentStepNavigationParams;

const cncSelectors = {
    selectCurrentStep,
    selectPreviousStep,
    selectStepNavigationParam,
};

export const uiSelectors = {
    cnc: cncSelectors,
};
