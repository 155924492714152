export const sendSplittedData = <T>(
    data: T[],
    maxCountPerSplit: number,
    sendEventFunction: (splittedData: T[]) => void,
) => {
    setTimeout(() => {
        if (!data?.length) return;

        let dataSplitted: T[];

        do {
            dataSplitted = data.splice(0, maxCountPerSplit);
            if (dataSplitted && dataSplitted.length > 0) {
                sendEventFunction(dataSplitted);
            }
        } while (dataSplitted.length > 0);
    }, 0);
};
