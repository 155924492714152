import type React from 'react';
import { useCallback, useEffect, useRef } from 'react';

export const useOutsideClick = (
    ref: React.MutableRefObject<HTMLElement | null>,
    onClickOutside: ((e: Event) => void) | undefined,
    whenTrigger: boolean,
) => {
    const savedHandler = useRef(onClickOutside);
    useEffect(() => {
        savedHandler.current = onClickOutside;
    });

    const memoizedCallback = useCallback(
        (event: Event) => {
            if (
                ref.current &&
                !ref.current.contains(event.target as Element) &&
                savedHandler.current
            ) {
                savedHandler.current(event);
            }
        },
        [ref],
    );

    useEffect(() => {
        if (whenTrigger) {
            document.addEventListener('click', memoizedCallback, true);
            document.addEventListener('ontouchstart', memoizedCallback, true);

            return () => {
                document.removeEventListener('click', memoizedCallback, true);
                document.removeEventListener('ontouchstart', memoizedCallback, true);
            };
        }
    }, [ref, onClickOutside, whenTrigger, memoizedCallback]);
};

export const useMultipleOutsideClickCallbacked = (
    refs: React.MutableRefObject<HTMLElement>[],
    onClickOutside: (e: Event) => void,
    whenTrigger: boolean,
    options: {
        conditionStrategy: 'meetAll' | 'meetAny';
    },
) => {
    const savedHandler = useRef(onClickOutside);
    useEffect(() => {
        savedHandler.current = onClickOutside;
    });

    const memoizedCallback = useCallback(
        (e: Event) => {
            if (whenTrigger && refs && refs.length && savedHandler.current) {
                const containsCondition = (elem: HTMLElement) =>
                    elem && !elem.contains(e.target as Element);

                const elements = refs.map((x) => x.current).filter(Boolean);

                if (options.conditionStrategy === 'meetAll') {
                    const conditionMetByAll = elements.every(containsCondition);

                    if (conditionMetByAll) {
                        savedHandler.current(e);
                    }
                    return;
                }

                if (options.conditionStrategy === 'meetAny') {
                    const conditionMetBySome = elements.some(containsCondition);
                    if (conditionMetBySome) {
                        savedHandler.current(e);
                    }
                }
            }
        },
        [options.conditionStrategy, refs, whenTrigger],
    );

    useEffect(() => {
        if (whenTrigger) {
            document.addEventListener('click', memoizedCallback, true);
            document.addEventListener('ontouchstart', memoizedCallback, true);

            return () => {
                document.removeEventListener('click', memoizedCallback, true);
                document.removeEventListener('ontouchstart', memoizedCallback, true);
            };
        }
    }, [refs, onClickOutside, whenTrigger, memoizedCallback]);
};
