/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */

'use client';

import { CheckmarkIcon, Warning1Icon } from '@coop/icons';
import classNames from 'classnames';
import * as React from 'react';

import { Icon } from '../Icon';
import { ModalContextHeader } from '../Modal';
import styles from './Notification.module.scss';
import type { NotficationThemeType } from './Notification.types';

const NotificationContent = ({
    size = 'default',
    ...props
}: {
    show: boolean;
    header?: string;
    message: string | undefined;
    bottomSlot?: React.ReactNode;
    size?: 'default' | 'productCard';
    type: 'alert' | 'success' | 'custom';
    theme?: NotficationThemeType;
    iconSlot?: React.ComponentProps<typeof Icon>['icon'];
    asModal?: boolean;
}) => {
    let colorTheme: NotficationThemeType | undefined = props.theme;
    switch (props.type) {
        case 'alert':
            colorTheme = 'darkPink';
            break;
        case 'success':
            colorTheme = 'darkGreen';
            break;
        case 'custom':
            colorTheme = props.theme;
            break;
        default:
            break;
    }

    const HeaderTag = props.asModal ? ModalContextHeader : 'p';
    const MessageTag = props.asModal && !props.header ? ModalContextHeader : 'p';

    return (
        <div
            className={classNames(
                styles.Content,
                size && styles[`Content-size--${size}`],
                colorTheme && styles[`Content-theme--${colorTheme}`],
            )}
        >
            <div className={styles.Icon} aria-hidden>
                {(() => {
                    switch (props.type) {
                        case 'alert':
                            return <Icon icon={Warning1Icon} />;
                        case 'success':
                            return <Icon icon={CheckmarkIcon} />;
                        case 'custom':
                            return props.iconSlot && <Icon icon={props.iconSlot} />;
                        default:
                            return null;
                    }
                })()}
            </div>
            <div className={styles.TextContent}>
                {!!props.header && <HeaderTag className={styles.Header}>{props.header}</HeaderTag>}
                {!!props.message && (
                    <MessageTag className={styles.Message}>{props.message}</MessageTag>
                )}
            </div>
            {props.bottomSlot}
        </div>
    );
};

export default NotificationContent;
