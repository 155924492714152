import { combineReducers, createAction, createSlice } from '@reduxjs/toolkit';

import { productsAdapter } from '../../adapters/productsAdapter';
import { recipesAdapter } from '../../adapters/recipesAdapter';
import cartLoadingReducer, {
    getInitialState as getCartLoadingInitialState,
} from './cartApiStateSlice';
import { cartEditedOrderItemsReducer } from './cartEditedOrderItemsSlice';
import {
    cartEditOrderReducer,
    getInitialState as getCartEditOrderInitialState,
} from './cartEditOrderSlice';
import cartItemsProductDataReducer from './cartItemsProductDataSlice';
import cartItemsReducer from './cartItemsSlice';
import { cartPreviousOrderItemIdsReducer } from './cartPreviousOrderItemIdsSlice';
import cartPromosReducer from './cartPromosSlice';
import cartRecipesReducer from './cartRecipesSlice';
import { cartSectionsReducer } from './cartSectionsSlice';
import cartSummaryReducer, {
    getInitialState as getCartSummaryInitialState,
} from './cartSummarySlice';
import {
    cartTimeslotReducer,
    getInitialState as getTimeslotInitialState,
} from './cartTimeslotSlice';

type CartState = ReturnType<typeof combined>;

const getInitialState = (): CartState => ({
    summary: getCartSummaryInitialState(),
    items: [],
    itemsProductData: productsAdapter.getInitialState(),
    previousOrderItemIds: [],
    editedOrderItems: [],
    recipes: recipesAdapter.getInitialState(),
    promos: [],
    editOrder: getCartEditOrderInitialState(),
    cartLoading: getCartLoadingInitialState(),
    timeslot: getTimeslotInitialState(),
    sections: [],
});

export const setFullCart = createAction<CartState>('cart/setFullCart');

const combined = combineReducers({
    summary: cartSummaryReducer,
    items: cartItemsReducer,
    itemsProductData: cartItemsProductDataReducer,
    previousOrderItemIds: cartPreviousOrderItemIdsReducer,
    editedOrderItems: cartEditedOrderItemsReducer,
    promos: cartPromosReducer,
    recipes: cartRecipesReducer,
    sections: cartSectionsReducer,
    editOrder: cartEditOrderReducer,
    cartLoading: cartLoadingReducer,
    timeslot: cartTimeslotReducer,
});

const cartSlice = createSlice({
    name: 'cart',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setFullCart, (state, action) => {
            return action.payload;
        });
        builder.addDefaultCase((state, action) => combined(state, action));
    },
});

export default cartSlice.reducer;
