import { HybrisResponseFieldsType } from '../../models/hybris/hybrisConstants';
import type { HybrisPointOfService } from '../../models/hybris/hybrisPointOfService';
import type { HybrisStoreSimple } from '../../models/hybris/hybrisStoreSimple';
import getDirectHybrisAxiosClient from '../clients/directHybrisAxiosClient';

const getPointOfService = async (id: string) => {
    const queryString = new URLSearchParams();
    queryString.set('id', id);
    queryString.set('fields', HybrisResponseFieldsType.Default);

    const response = await getDirectHybrisAxiosClient(undefined).post<HybrisPointOfService>(
        `pointofservice/${encodeURIComponent(id)}?${queryString.toString()}`,
    );
    return response.data;
};

const getStoreByPostCode = async (postCode: string) => {
    const queryString = new URLSearchParams();
    queryString.set('postCode', postCode);

    const response = await getDirectHybrisAxiosClient(undefined).get<HybrisStoreSimple>(
        `coopstore/?${queryString.toString()}`,
    );
    return response.data;
};

export const directStoreApi = {
    getPointOfService,
    getStoreByPostCode,
};
