import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { SubscriptionFrequency } from '../../structureDefinitions/checkoutState';
import { checkoutResetAction } from './checkoutActions';

const slice = createSlice({
    name: 'subscriptionFrequency',
    initialState: null as SubscriptionFrequency | null,
    reducers: {
        set: (state, action: PayloadAction<SubscriptionFrequency | null>) => {
            return action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(checkoutResetAction, () => {
            return null;
        });
    },
});

export const { actions: subscriptionFrequencyActions, reducer: subscriptionFrequencyReducer } =
    slice;
