import type { IApiStore } from '../../../models/ecommerce/store/storeModel';
import type { ApiSwitchStoreResult } from '../api/cart/responses/cartResponses';
import { storeApi } from '../api/store/storeApi';
import { currentCartFlow } from '../flow/cart/currentCartFlow';
import type { StoreUpdateSimulationResult } from '../models/store/StoreUpdateSimulationResult';
import type { AppAsyncThunk, AppThunk } from '../store';
import { storeActions } from '../store/slices/store/storeSlice';
import type { StoreState } from '../store/structureDefinitions/storeState';
import { DeliveryMode } from '../store/structureDefinitions/storeState';

export const storeThunks = {
    syncStoreContext: (state: StoreState): AppThunk => {
        return (dispatch) => {
            dispatch(storeActions.syncStore(state));
        };
    },
    syncStoreWithCookie: (): AppThunk => {
        return (dispatch) => {
            dispatch(storeActions.syncStoreWithCookie());
        };
    },

    updatePickupStore: (storeId: string): AppAsyncThunk<IApiStore> => {
        return async (dispatch) => {
            const store = await storeApi.updatePickupStore(storeId);
            dispatch(storeActions.syncStoreWithCookie());
            return store;
        };
    },

    updatePickupStoreUnconfirmed: (storeId: string): AppAsyncThunk<StoreUpdateSimulationResult> => {
        return async (dispatch) => {
            const simulateResult = await storeApi.updatePickupStoreUnconfirmed(storeId);
            const mapped = mapSimulationResult(simulateResult);
            dispatch(storeActions.syncStoreWithCookie());
            return mapped;
        };
    },

    updateHomeDeliveryStore: (
        zipCode: string,
        baseStoreId: string | null = null,
    ): AppAsyncThunk<IApiStore> => {
        return async (dispatch) => {
            const store = await storeApi.updateHomeDeliveryStore(zipCode, baseStoreId);
            dispatch(storeActions.syncStoreWithCookie());
            return store;
        };
    },

    updateHomeDeliveryStoreUnconfirmed: (
        zipCode: string,
        baseStoreId: string | null = null,
    ): AppAsyncThunk<StoreUpdateSimulationResult> => {
        return async (dispatch) => {
            const simulateResult = await storeApi.updateHomeDeliveryStoreUnconfirmed(
                zipCode,
                baseStoreId,
            );
            const mapped = mapSimulationResult(simulateResult);
            dispatch(storeActions.syncStoreWithCookie());
            return mapped;
        };
    },

    confirmMissingItemsReplacement: (
        deliveryType: DeliveryMode,
        productsToBulkAdd: {
            productId: string;
            variantId?: string;
            quantity: number;
            itemReplaceable?: boolean;
        }[],
        storeId: string,
        postalCode?: string,
    ): AppAsyncThunk<'success'> => {
        return async (dispatch) => {
            if (deliveryType === DeliveryMode.pickup) {
                await dispatch(storeThunks.updatePickupStore(storeId));
            }

            if (deliveryType === DeliveryMode.homedelivery && postalCode) {
                await dispatch(storeThunks.updateHomeDeliveryStore(postalCode, storeId));
            }
            if (productsToBulkAdd?.length) {
                const cartId = await currentCartFlow.getCurrentCartId();
                if (!cartId) {
                    throw new Error('No active cart!');
                }
                await currentCartFlow.addItemsBulk(cartId, productsToBulkAdd);
            }

            return 'success';
        };
    },
};

const mapSimulationResult = (result: ApiSwitchStoreResult): StoreUpdateSimulationResult => {
    // update simulation success, store HAS BEEN CHANGED ALREADY!!! The items in cart were not changed
    if (result && result.success && !result.simulated) {
        return {
            fail: false,
            success: true,
            forStoreId: result.forStoreId,
        };
    }

    // update simulation failed, store has not been changed and there will be changes to cart items if you change to this postcode
    if (result && result.simulated) {
        return {
            fail: true,
            success: false,
            removedCartItems: result.removedCartItems,
            forStoreId: result.forStoreId,
        };
    }

    return {
        fail: false,
        success: false,
        forStoreId: undefined,
    };
};
