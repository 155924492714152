import type { SpinnerSize } from '../Loader';
import type { ChipSize } from './Chip.types';

export const getSpinnerSizeForChipSize = (chipSize: ChipSize): SpinnerSize => {
    switch (chipSize) {
        case 24:
            return 12;
        case 32:
            return 16;
        default:
            return exhaustiveCheck(chipSize);
    }
};

const exhaustiveCheck = (value: never): never => {
    throw new Error(`Unhandled value: ${value}`);
};
