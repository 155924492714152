import { createListenerMiddleware } from '@reduxjs/toolkit';

import type { RootState } from '../structureDefinitions/cartState';
import { currentPageMiddleware } from './middlewares/currentPageMiddleware';
import { unsyncedAddToCartGaTrackingMiddleware } from './middlewares/unsyncedAddToCartGaTrackingMiddleware';
import { updateCartItemGaTrackingMiddleware } from './middlewares/updateCartItemGaTrackingMiddleware';
import { updateCartRecipeGaTrackingMiddleware } from './middlewares/updateCartRecipeGaTrackingMiddleware';

const listenerMiddleware = createListenerMiddleware<RootState>();

unsyncedAddToCartGaTrackingMiddleware(listenerMiddleware);
currentPageMiddleware(listenerMiddleware);
updateCartItemGaTrackingMiddleware(listenerMiddleware);
updateCartRecipeGaTrackingMiddleware(listenerMiddleware);

export { listenerMiddleware };
