const CLASS_UNMUTED = 'is-unmuted';

const videoMuteHandler = (muteButton: HTMLElement) => {
    if (!muteButton) {
        return null;
    }
    const targetVideo = muteButton.parentNode?.querySelector<HTMLVideoElement>('.js-videoMedia');
    if (!targetVideo) {
        return null;
    }

    if (targetVideo.muted) {
        targetVideo.muted = false;
        muteButton.classList.add(CLASS_UNMUTED);
    } else {
        targetVideo.muted = true;
        muteButton.classList.remove(CLASS_UNMUTED);
    }
};

export default videoMuteHandler;
