import { appConfig } from '../../../appConfig';
import { Browser } from '../../../utility/browser';
import type { TrackPurchaseRequest } from '../../search/models/models';
import trackingDirectApi from '../../tracking/api/trackingApi';
import type {
    ApiAnoymousPageviewRequest,
    ApiEventEntity,
    ApiEventsRequest,
    ApiTrackEvent,
} from '../../tracking/api/trackingRequests';
import persistance from '../persistance/persistance';
import { failSilently } from '../tracking/utils/failSilently';
import dynamicYieldFlow, { getDynamicYieldData } from './dynamicYield/dynamicYieldFlow';

/** Sync dynamic yield context, needed for sending events to dynamic yield */
const trackPageView = (data: DynamicYieldPageContext, url: string) => {
    failSilently(async () => {
        const dynamicYieldIds = getDynamicYieldData();
        const request: ApiAnoymousPageviewRequest = {
            ...dynamicYieldIds,
            context: {
                page: {
                    location: url,
                    type: data.type,
                    data: data.data,
                },
            },
        };
        const device = Browser.platformType();

        const response =
            dynamicYieldIds.user.dyid && dynamicYieldIds.session?.dy
                ? await trackingDirectApi.trackPageView(request, device)
                : await trackingDirectApi.trackAnonymousPageView(request, device);

        // Keep session in sync with user
        const sessionId = persistance.dynamicYield.getSessionId();
        dynamicYieldFlow.syncCookies(response.cookies);
        const updatedSessionId = persistance.dynamicYield.getSessionId();
        if (sessionId !== updatedSessionId) {
            if (appConfig.coopUserSettings.isAuthenticated) {
                identify(appConfig.coopUserSettings.shoppingUserId, 'id'); // id is also used as cuidType in the mobile app, thats why its used
                profile(appConfig.coopUserSettings.isCompany);
            }
        }
    })();
};

const recipeClick = (clickEvent: RecipeClickTrackingData) => {
    const eventEntity: ApiEventEntity = {
        id: clickEvent.recipeId,
        type: 'RecipesEntity',
    };

    const eventsRequest: ApiEventsRequest = {
        events: [
            {
                type: 'click',
                entity: eventEntity,
            },
        ],
    };

    const device = Browser.platformType();
    failSilently(() => {
        trackingDirectApi.track(eventsRequest, device);
    })();
};

/** Use to track if any interaction is used on product inside DY campaign
 * https://dy.dev/docs/engagement
 */
const slotClick = (clickEvent: SlotClickTrackingData) => {
    const eventEntity: ApiEventEntity = {
        id: clickEvent.productId,
        slotId: clickEvent.slotId,
        type: 'Product',
    };

    const event: ApiTrackEvent = {
        type: 'slotclick',
        entity: eventEntity,
    };
    defaultTrackEvent(event);
};

/** Use to track click event to personalization api (will go to loop54) */
const click = (clickEvent: ClickTrackingData) => {
    const eventEntity: ApiEventEntity = {
        id: clickEvent.productId,
        type: 'Product',
    };

    const eventsRequest: ApiEventsRequest = {
        events: [
            {
                type: 'click',
                entity: eventEntity,
            },
        ],
    };
    const device = Browser.platformType();
    failSilently(() => {
        trackingDirectApi.track(eventsRequest, device);
    })();
};

const addToCart = (productData: ProductTrackingData[]) => {
    const events = productData.map((data) => {
        const { price, quantity } = calculateTrackingData(data);
        const event: ApiTrackEvent = {
            type: 'addtocart',
            entity: {
                id: data.identifier,
                type: 'Product',
            },
            quantity,
            revenue: price,
        };
        return event;
    });
    defaultTrackEvent(...events);
};

const removeFromCart = (productData: ProductTrackingData) => {
    const { price, quantity } = calculateTrackingData(productData);

    const event: ApiTrackEvent = {
        type: 'removefromcart',
        entity: {
            id: productData.identifier,
            type: 'Product',
        },
        quantity,
        revenue: price,
    };
    defaultTrackEvent(event);
};

const profile = (isCompany: boolean) => {
    const event: ApiTrackEvent = {
        type: 'profile',
        b2bUser: isCompany,
    };
    defaultTrackEvent(event);
};

const identify = (cuid: string, cuidType: string) => {
    const event: ApiTrackEvent = {
        type: 'identifyuser',
        cuid,
        cuidType,
    };
    defaultTrackEvent(event);
};

const login = () => {
    const event: ApiTrackEvent = {
        type: 'login',
    };
    defaultTrackEvent(event);
};

const purchase = (purchaseEvent: TrackPurchaseRequest) => {
    const event: ApiTrackEvent = {
        type: 'purchase',
        orderId: purchaseEvent.orderId,
        revenue: purchaseEvent.revenue,
        cart: purchaseEvent.products.map((e) => ({
            productId: e.productId,
            quantity: e.quantity,
            itemPrice: e.itemPrice,
        })),
    };

    defaultTrackEvent(event);
};

const calculateTrackingData = (productData: ProductTrackingData) => {
    let { quantity } = productData;
    if (productData.previousQuantity) {
        quantity = Math.abs(productData.quantity - productData.previousQuantity);
    }

    const trackedPrice = productData.promotionPrice
        ? productData.promotionPrice
        : productData.price || 0;
    const price = trackedPrice && (quantity * (trackedPrice * 100)) / 100;

    return {
        price,
        quantity,
    };
};

const defaultTrackEvent = (...events: ApiTrackEvent[]) => {
    const dynamicYieldIds = getDynamicYieldData();
    const eventsRequest: ApiEventsRequest = {
        ...dynamicYieldIds,
        events,
    };

    const device = Browser.platformType();
    failSilently(() => {
        trackingDirectApi.track(eventsRequest, device);
    })();
};

const trackingFlow = {
    slotClick,
    click,
    addToCart,
    purchase,
    recipeClick,
    trackPageView,
    profile,
    identify,
    removeFromCart,
    login,
};

export default trackingFlow;
