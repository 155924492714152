import type { ListenerMiddlewareInstance } from '@reduxjs/toolkit';

import cartItemsSelectors from '../../../../cart/selectors/cartItemsSelectors';
import { setFullCart } from '../../slices/cart/cartSlice';
import type { RootState } from '../../structureDefinitions/cartState';

export const updateCartRecipeGaTrackingMiddleware = (
    middlewareInstance: ListenerMiddlewareInstance<RootState>,
) => {
    middlewareInstance.startListening({
        actionCreator: setFullCart,
        effect: async (action, listenerApi) => {
            const cartState = listenerApi.getState();
            const message: EcommerceTrackingMetadata = {
                ecommerce_cart_recipes: cartState.cart.recipes.ids
                    .filter(
                        (recipeId) =>
                            typeof recipeId === 'number' ||
                            !Number.isNaN(Number.parseInt(recipeId, 10)),
                    )
                    .join(','),
                edit_order: cartState.cart.editOrder.isInEditOrderMode,
            };

            const cartItems = cartItemsSelectors.selectAllCartItems(cartState);
            if (cartItems) {
                const fullCart = cartItems.map((item) => {
                    const trackItem: GaEcommerceItemMinimal = {
                        id: item.cartItemData.productId,
                        quantity: item.cartItemData.quantity,
                    };
                    if (item.cartItemData.variantId) {
                        trackItem.variantId = item.cartItemData.variantId;
                    }
                    return trackItem;
                });
                // eslint-disable-next-line func-names
                const updateDatalayer = function (): void {
                    // @ts-ignore
                    this.set('ecommerce_all_items', null);
                    // @ts-ignore
                    this.set('ecommerce_all_items', fullCart);
                };
                window.dataLayer.push(updateDatalayer);
            }
            window.dataLayer.push(message);
        },
    });
};
