import { Confirm, ConfirmAlert, ConfirmInfo, Notification } from '@coop/components';
import type { FC } from 'react';
import * as React from 'react';
import { useReducer } from 'react';

const DEFAULT_NOTIFICATION_MILLIS = 2000;

const initialState: NotificationContextStateType = {
    confirmData: {
        type: 'confirm',
        title: '',
        message: undefined,
        confirm: undefined,
        cancel: undefined,
        onConfirm: () => {},
        onCancel: undefined,
        isOpen: false,
        isLoading: false,
        close: () => {},
    },
    notificationData: {
        show: false,
        type: 'success',
        message: undefined,
        hideAfterMiliseconds: DEFAULT_NOTIFICATION_MILLIS,
    },
};

interface NotificationContextStateType {
    confirmData: {
        type: 'confirm' | 'alert' | 'info';
        title: string;
        message?: string;
        confirm?: string;
        cancel?: string;
        onConfirm: () => void;
        onCancel?: () => void;
        close: () => void;
        isOpen: boolean;
        isLoading?: boolean;
    };
    notificationData: {
        show: boolean;
        type: 'success' | 'alert';
        message: string | undefined;
        hideAfterMiliseconds: number;
    };
}

export type NotificationContextActions =
    | { type: 'set_confirm_is_open'; isOpen: boolean }
    | {
          type: 'set_confirm_data';
          confirmData: {
              type: 'confirm' | 'alert' | 'info';
              title: string;
              message: string;
              confirm: string;
              cancel: string;
              onConfirm: () => void;
              onCancel?: () => void;
              close: () => void;
              isOpen: boolean;
              topLevel?: boolean;
          };
      }
    | {
          type: 'set_notification_data';
          notificationData: {
              show: boolean;
              type: 'success' | 'alert';
              message: string;
              hideAfterMiliseconds?: number;
          };
      }
    | { type: 'close_notification' }
    | { type: 'set_loading'; status: boolean };

const NotificationContextDispatch = React.createContext<React.Dispatch<NotificationContextActions>>(
    () => null,
);

const NotificationContextState = React.createContext<NotificationContextStateType>(initialState);

const reducer = (state: NotificationContextStateType, action: NotificationContextActions) => {
    switch (action.type) {
        case 'set_confirm_is_open': {
            return {
                ...state,
                confirmData: {
                    ...state.confirmData,
                    isOpen: action.isOpen,
                    isLoading: false,
                },
            };
        }
        case 'set_loading': {
            return {
                ...state,
                confirmData: {
                    ...state.confirmData,
                    isLoading: action.status,
                },
            };
        }
        case 'set_confirm_data': {
            return {
                ...state,
                confirmData: {
                    ...state.confirmData,
                    ...action.confirmData,
                },
            };
        }
        case 'set_notification_data': {
            return {
                ...state,
                notificationData: {
                    ...state.notificationData,
                    ...action.notificationData,
                    hideAfterMiliseconds:
                        action.notificationData.hideAfterMiliseconds || DEFAULT_NOTIFICATION_MILLIS,
                },
            };
        }
        case 'close_notification': {
            return {
                ...state,
                notificationData: {
                    ...state.notificationData,
                    show: false,
                },
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

const NotificationStateProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <NotificationContextDispatch.Provider value={dispatch}>
            {children}
            <NotificationContextState.Provider value={state}>
                {state.confirmData.type === 'confirm' && (
                    <Confirm
                        heading={state.confirmData.title}
                        message={state.confirmData.message}
                        confirmButtonText={state.confirmData.confirm}
                        cancelButtonText={state.confirmData.cancel}
                        show={state.confirmData.isOpen}
                        onConfirm={state.confirmData.onConfirm}
                        onCancel={state.confirmData.onCancel}
                        isLoading={state.confirmData.isLoading}
                        close={state.confirmData.close}
                    />
                )}

                {state.confirmData.type === 'alert' && (
                    <ConfirmAlert
                        heading={state.confirmData.title}
                        message={state.confirmData.message}
                        confirmButtonText={state.confirmData.confirm}
                        cancelButtonText={state.confirmData.cancel}
                        show={state.confirmData.isOpen}
                        onConfirm={state.confirmData.onConfirm}
                        onCancel={state.confirmData.onCancel}
                        isLoading={state.confirmData.isLoading}
                        close={state.confirmData.close}
                    />
                )}

                {state.confirmData.type === 'info' && (
                    <ConfirmInfo
                        heading={state.confirmData.title}
                        message={state.confirmData.message}
                        show={state.confirmData.isOpen}
                        isLoading={state.confirmData.isLoading}
                        close={state.confirmData.close}
                    />
                )}

                <Notification
                    show={state.notificationData.show}
                    type={state.notificationData.type}
                    close={() => dispatch({ type: 'close_notification' })}
                    message={state.notificationData.message}
                    hideAfterMiliseconds={state.notificationData.hideAfterMiliseconds}
                />
            </NotificationContextState.Provider>
        </NotificationContextDispatch.Provider>
    );
};

export { NotificationContextDispatch, NotificationContextState, NotificationStateProvider };
