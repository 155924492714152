import * as React from 'react';

import styles from './Footer.module.less';

const FooterColumn = ({ column }: { column: FooterColumn }) => {
    return (
        <div className={styles.FooterColumn}>
            <div className={styles.FooterHeading}>
                <a href={column.headerLink?.url}>{column.header}</a>
            </div>
            <ul className={styles.FooterList}>
                {column.links
                    .filter((_link, index) => index > 0)
                    .map((link) => (
                        <li className={styles.FooterItem} key={link.contentId}>
                            <a href={link.url}>{link.name}</a>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default FooterColumn;
