import { sendInteractionEvent } from '../utils';
import deliveryOption from './deliveryOptionEvents';
import header from './headerEvents';
import miniCart from './miniCartEvents';
import notification from './notificationEvents';
import selectPostalCode from './selectPostalCodeEvents';
import { OPEN_CNC_EVENT_ACTION } from './utils';

const sendOpenCncEvent = (hasSelectedZip: boolean, eventLabel: string) => {
    const zipCodeAlreadyChosen = hasSelectedZip
        ? '​Postnummer populerat'
        : 'Postnummer icke populerat​';

    sendInteractionEvent({
        eventAction: OPEN_CNC_EVENT_ACTION,
        eventLabel,
        zipCodeAlreadyChosen,
    });
};

const sendOpenFromAddToCart = (hasSelectedZip: boolean) => {
    sendOpenCncEvent(hasSelectedZip, 'Add to Cart');
};

const sendOpenFromIcon = (hasSelectedZip: boolean) => {
    sendOpenCncEvent(hasSelectedZip, 'Cart ikon');
};

const sendOpenFromNoticeRibbonDefaultStore = (hasSelectedZip: boolean) => {
    sendOpenCncEvent(hasSelectedZip, 'Default store notice ribbon');
};

const cncTracking = {
    openFrom: {
        cartIcon: sendOpenFromIcon,
        addToCart: sendOpenFromAddToCart,
        noticeRibbonDefaultStore: sendOpenFromNoticeRibbonDefaultStore,
    },
    notification,
    deliveryOption,
    header,
    miniCart,
    selectPostalCode,
};

export default cncTracking;
