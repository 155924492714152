import { Cookies } from '../../../../utility/cookies';
import type { ApiDyCookie } from '../../../tracking/api/trackingRequests';

// leaving cookie name for cookie erase
const DY_ID_COOKIE_NAME = '_dyid';
const DY_SESSION_COOKIE_NAME = '_dyjsession';
const DY_SERVER_COOKIE_NAME = '_dyid_server';
const validCookies = [DY_ID_COOKIE_NAME, DY_SESSION_COOKIE_NAME, DY_SERVER_COOKIE_NAME];
const dynamicYield = {
    getDyId: () => {
        const rawCookie = Cookies.read(DY_ID_COOKIE_NAME) || Cookies.read(DY_SERVER_COOKIE_NAME);
        return rawCookie;
    },
    getSessionId: () => {
        const rawCookie = Cookies.read(DY_SESSION_COOKIE_NAME);
        return rawCookie;
    },
    setCookies: (cookie: ApiDyCookie) => {
        if (validCookies.includes(cookie.name)) {
            // session, server is coming from choose
            Cookies.create(
                cookie.name === DY_SERVER_COOKIE_NAME ? DY_ID_COOKIE_NAME : cookie.name,
                cookie.value,
                cookie.maxAge / (60 * 60 * 24),
            );
        }
    },
    removeCookies: () => {
        validCookies.forEach((name) => Cookies.eraseCookie(name));
    },
};

export default dynamicYield;
