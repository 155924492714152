import type { ListenerMiddlewareInstance } from '@reduxjs/toolkit';

import { sendGaVirtualPageView } from '../../../tracking/virtualPageView';
import { currentPageActions } from '../../slices/currentPage/currentPageSlice';
import type { RootState } from '../../structureDefinitions/cartState';

/**
 * The virtualPageView event is send inside, only because we need to send it FROM the second URL change onwards - the first page load is tracked in GTM as a normal page view, not virtual
 */
export const currentPageMiddleware = (
    middlewareInstance: ListenerMiddlewareInstance<RootState>,
) => {
    middlewareInstance.startListening({
        actionCreator: currentPageActions.setPageGaData,
        effect: async (action, listenerApi) => {
            listenerApi.cancelActiveListeners();

            const { mountCounter, canonicalPathName, gaPageTypeName, title, queryString } =
                listenerApi.getState().currentPage;

            if (mountCounter > 1 && gaPageTypeName && canonicalPathName && title) {
                sendGaVirtualPageView({
                    pathName: canonicalPathName, // pathname is from canonical, but the query string is from current location, since canonical will not have full query string parameters
                    queryString,
                    pageType: gaPageTypeName,
                    pageTitle: title,
                });
            }
        },
    });
};
