import { createSlice } from '@reduxjs/toolkit';

import type { CartItemUniqueId } from '../../structureDefinitions/cartState';

const cartPreviousOrderItemIdsSlice = createSlice({
    name: 'previousOrderItemIds',
    initialState: [] as CartItemUniqueId[],
    reducers: {},
});

export const {
    actions: cartPreviousOrderItemIdsActions,
    reducer: cartPreviousOrderItemIdsReducer,
} = cartPreviousOrderItemIdsSlice;
