export interface HeaderAlert {
    heading: string;
    details: string;
    level: number;
    id: string;
}

export const AlertLevels = [1, 2, 3, 4] as const;

export type AlertLevel = (typeof AlertLevels)[number];

/**
 * Same as HeaderAlertsReactProps on backend
 */
export interface HeaderAlertsPortalDOMProps {
    alerts: HeaderAlert[];
}
