import { useEffect, useRef } from 'react';

export const useInitialFocus = (triggerFocus: boolean) => {
    const ref = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (triggerFocus && ref.current) {
            ref.current.focus();
        }
    }, [triggerFocus]);

    return ref;
};
