import type { AxiosInstance, AxiosRequestConfig } from 'axios';

import axiosClientFactory from '../../common/api/clients/clientFactory';

const getDirectContentApiClient = (config?: AxiosRequestConfig): AxiosInstance => {
    const directContentClient = axiosClientFactory.createLocalApiClient({
        headers: {
            'Accept-Language': 'sv',
            'Content-Api': true, // important, thats how content api knows it should use its own routing for friendly url
            ...config?.headers,
        },
        ...config,
    });

    return directContentClient;
};

export default getDirectContentApiClient;
