import { appConfig } from '../../../appConfig';
import type {
    ApiBaseException,
    ApiCartException,
    ApiDefaultException,
    ApiException,
    ApiOpenIdException,
    ApiRedirectException,
    ApiValidationException,
} from './exceptionDefinitions';

export const isLocalApiBaseException = (error: unknown): error is ApiBaseException => {
    return error
        ? (error as ApiBaseException).type !== undefined ||
              !!(error as ApiBaseException).friendlyMessage
        : false;
};

export const isLocalApiDefaultException = (error: unknown): error is ApiDefaultException => {
    return error ? (error as ApiBaseException).type === 'Default' : false;
};

export const isLocalApiOpenIdException = (error: unknown): error is ApiOpenIdException => {
    return error ? (error as ApiBaseException).type === 'OpenId' : false;
};

export const isLocalApiValidationException = (error: unknown): error is ApiValidationException => {
    return error ? (error as ApiBaseException).type === 'Validation' : false;
};

export const isLocalRedirectApiException = (error: unknown): error is ApiRedirectException => {
    return error ? (error as ApiBaseException).type === 'Redirect' : false;
};

export const isCartException = (error: unknown): error is ApiCartException => {
    return (<ApiCartException>error)?.type === 'Cart';
};

export const isLocalApiKnownException = (error: unknown): error is ApiException => {
    return isLocalApiDefaultException(error) || isLocalApiValidationException(error);
};

export const extractLocalApiValidationErrors = (error: ApiBaseException) => {
    return isLocalApiValidationException(error) ? error.validationErrors : undefined;
};

export const createDefaultException = (
    message?: string,
    code?: string,
    field?: string,
): ApiException => {
    const defaultException: ApiDefaultException = {
        friendlyMessage: message || appConfig.coopSettings.ecommerce?.errorMessage?.general,
        code: code || null,
        field: field || null,
        type: 'Default',
    };

    return defaultException;
};
