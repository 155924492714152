/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ApiBaseException } from '../../../exceptions/exceptionDefinitions';
import type { ErrorState } from '../../structureDefinitions/errorsState';
import { ErrorGroup } from '../../structureDefinitions/errorsState';

const initialState: ErrorState = {
    [ErrorGroup.Cart]: [],
    [ErrorGroup.Critical]: [],
    [ErrorGroup.Modal]: [],
    [ErrorGroup.Global]: [],
};

interface AddErrorPayload {
    group: ErrorGroup;
    error: ApiBaseException;
}

const slice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        addSingle: (state: ErrorState, action: PayloadAction<AddErrorPayload>) => {
            if (action.payload.error) {
                state[action.payload.group].push(action.payload.error);
            }
        },
        clear: (state: ErrorState, action: PayloadAction<ErrorGroup>) => {
            state[action.payload] = [];
        },
    },
});

export const { actions: errorsActions, reducer: errorsReducer } = slice;
