import type { AxiosInstance } from 'axios';

import { appConfig } from '../../../../appConfig';
import axiosClientFactory from '../clients/clientFactory';

const getDirectArticleServiceClient = (): AxiosInstance => {
    const directArticleServiceClient = axiosClientFactory.createDirectApiClient({
        baseURL: appConfig.coopSettings.serviceAccess.articleServiceApiUrl,
        params: {
            'api-version': appConfig.coopSettings.serviceAccess.articleServiceApiVersion,
        },
        headers: {
            'Ocp-Apim-Subscription-Key':
                appConfig.coopSettings.serviceAccess.articleServiceSubscriptionKey,
        },
    });

    return directArticleServiceClient;
};

export default getDirectArticleServiceClient;
