import { getAuthToken } from '../api/auth/getAuthToken';
import { messageRelayDirectApi } from '../api/messageRelay/messageRelayApi';

const messageRelayFlow = {
    sendVerification: async (phoneNumber: string) => {
        const identity = await getAuthToken();
        const secondsLeftUntilCodeCanSendAgain = await messageRelayDirectApi.sendVerification(
            identity.token,
            phoneNumber,
        );
        return secondsLeftUntilCodeCanSendAgain;
    },
    confirmVerification: async (code: string) => {
        const identity = await getAuthToken();
        await messageRelayDirectApi.confirmVerification(identity.token, code);
    },
};

export default messageRelayFlow;
