import { SearchFromPageType } from '../../../../base/models/SearchFromPageType';
import { failSilently } from '../../utils/failSilently';
import { sendGA4Event } from '../shared/sendGa4Event';

type SearchType = 'resultPage' | 'overlay';

const mapSearchFeatureName = (searchFromPage?: SearchFromPageType): SearchFeatureName => {
    switch (searchFromPage) {
        case SearchFromPageType.StartPage:
            return 'homepage';
        case SearchFromPageType.Ecommerce:
            return 'products';
        case SearchFromPageType.Recipes:
            return 'recipes';
        case SearchFromPageType.Stores:
            return 'stores';
        case SearchFromPageType.Member:
            return 'member';
        case SearchFromPageType.Finance:
            return 'bank';
        case SearchFromPageType.Other:
        default:
            return 'other';
    }
};

const mapSearchTypeToGa = (searchType: SearchType) => {
    switch (searchType) {
        case 'overlay':
            return 'autocomplete';
        case 'resultPage':
            return 'enter';
        default:
            return null;
    }
};

const search =
    (searchFromPage?: SearchFromPageType) =>
    (actionTrigger: SearchTermActionTriggerType) =>
    ({
        actualSearchTerm,
        originalSearchTerm,
        totalResultNumber,
    }: {
        actualSearchTerm?: string;
        originalSearchTerm?: string;
        totalResultNumber?: number;
    }) => {
        const featureName = mapSearchFeatureName(searchFromPage);

        const event: SearchEvent = {
            event: 'search',
            search_feature_name: featureName,
            search_term_action_type_trigger: actionTrigger,
            search_term: originalSearchTerm?.length === 0 ? undefined : originalSearchTerm,
            search_term_inferred: actualSearchTerm,
            search_results_number: totalResultNumber,
        };

        sendGA4Event(event);
    };

const searchDropdownAction =
    (searchFromPage?: SearchFromPageType) => (actionTrigger: SearchTermActionTriggerType) => {
        const featureName = mapSearchFeatureName(searchFromPage);

        const event: SearchDropdownActionEvent = {
            event: 'search_dropdown_action',
            search_feature_name: featureName,
            type: actionTrigger,
        };

        sendGA4Event(event);
    };

const searchDropdownItemAction =
    (searchFromPage?: SearchFromPageType) =>
    (actionTrigger: SearchTermActionTriggerType) =>
    ({ position, itemId, itemName }: { position?: number; itemId?: string; itemName?: string }) => {
        const featureName = mapSearchFeatureName(searchFromPage);

        const event: SearchDropdownItemActionEvent = {
            event: 'search_dropdown_action',
            search_feature_name: featureName,
            type: actionTrigger,
            position,
            item_id: itemId,
            item_name: itemName,
        };

        sendGA4Event(event);
    };

const viewSearchResults =
    (searchFromPage?: SearchFromPageType) =>
    ({
        actualSearchTerm,
        originalSearchTerm,
        totalResultNumber,
    }: {
        actualSearchTerm: string;
        originalSearchTerm: string;
        totalResultNumber: number;
    }) => {
        const featureName = mapSearchFeatureName(searchFromPage);

        const event: ViewSearchResultsEvent = {
            event: 'view_search_results',
            search_term: originalSearchTerm,
            search_term_inferred: actualSearchTerm,
            search_feature_name: featureName,
            search_results_number: totalResultNumber,
        };

        sendGA4Event(event);
    };

const viewSearchResultSuggestionClick = (linkText: string, linkUrl: string) => {
    const event: ViewSearchResultsSuggestionClickEvent = {
        event: 'view_search_results_other_suggestions_clicks',
        type: 'other_suggestions',
        click_text: linkText,
        click_path: linkUrl,
    };

    sendGA4Event(event);
};

const noSearchResults =
    (searchType: SearchType) => (searchTerm: string) => (searchFromPage?: SearchFromPageType) => {
        const featureName = mapSearchFeatureName(searchFromPage);
        const type = mapSearchTypeToGa(searchType);

        const event: NoSearchResultsEvent = {
            event: 'no_search_results',
            feature_name: featureName,
            type,
            search_term: searchTerm,
        };

        sendGA4Event(event);
    };

const searchIconClick = (searchFromPage?: SearchFromPageType) => {
    const featureName = mapSearchFeatureName(searchFromPage);

    const event: SearchIconClickEvent = {
        event: 'search_icon_click',
        search_feature_name: featureName,
    };

    sendGA4Event(event);
};

export default {
    viewSearchResultSuggestionClick: failSilently(viewSearchResultSuggestionClick),
    viewSearchResults: failSilently(viewSearchResults),
    search: failSilently(search),
    searchDropdownAction: failSilently(searchDropdownAction),
    searchDropdownItemAction: failSilently(searchDropdownItemAction),
    noSearchResults: failSilently(noSearchResults),
    searchIconClick: failSilently(searchIconClick),
};
