/* eslint-disable no-underscore-dangle */
import { Button, Icon } from '@coop/components';
import { ShieldIcon } from '@coop/icons';
import * as React from 'react';

const ConsentButton = () => {
    const showPopup = () => {
        if (window && window.__cmp) {
            window.__cmp('showScreen');
        }
    };

    return (
        <Button onClick={showPopup} rightSlot={<Icon icon={ShieldIcon} />} theme="light">
            Inst&auml;llningar f&ouml;r cookies
        </Button>
    );
};

export default ConsentButton;
