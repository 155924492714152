import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/* eslint-disable no-console */
import { appInsights } from '../../../appInsights';
import { ensureLeadingSlash } from '../utils/urlUtils';

const sendPageViewToApplicationInisghts = (pathname: string) => {
    appInsights.trackPageView({ uri: pathname });
    console.logNotProd('PageView sent to AI:', ensureLeadingSlash(pathname));
};

const pathnamesAlreadySent: string[] = [];

export const usePageConsumptionTracking = (when: boolean = true) => {
    const location = useLocation();

    useEffect(() => {
        if (when && !pathnamesAlreadySent.includes(location.pathname)) {
            sendPageViewToApplicationInisghts(location.pathname);
            pathnamesAlreadySent.push(location.pathname);
        }
    }, [when, location.pathname]);
};
