import type { PayloadAction } from '@reduxjs/toolkit';
import { combineReducers, createAction, createReducer, createSlice } from '@reduxjs/toolkit';

import { userAddressAdapter } from '../../adapters/userAddressAdapter';
import type { UserAddress } from '../../structureDefinitions/userState';

const userAddressesSlice = createSlice({
    name: 'userAddresses',
    initialState: userAddressAdapter.getInitialState(),
    reducers: {
        setUserAddresses: (state, action: PayloadAction<UserAddress[]>) => {
            userAddressAdapter.setAll(state, action.payload);
        },
        updateUserAddress: (state, action: PayloadAction<UserAddress>) => {
            userAddressAdapter.upsertOne(state, action.payload);
        },
        createUserAddress: (state, action: PayloadAction<UserAddress>) => {
            userAddressAdapter.addOne(state, action.payload);
        },
        removeUserAddress: (state, action: PayloadAction<string>) => {
            userAddressAdapter.removeOne(state, action.payload);
        },
    },
});

const fetchingAction = createAction('userAddresses/fetching');
const idleAction = createAction('userAddresses/idle');

const isFetchingReducer = createReducer(false, {
    [fetchingAction.type]: () => true,
    [idleAction.type]: () => false,
});

const combined = combineReducers({
    addresses: userAddressesSlice.reducer,
    isFetching: isFetchingReducer,
});

export default combined;

const { updateUserAddress, createUserAddress, removeUserAddress, setUserAddresses } =
    userAddressesSlice.actions;

export const userAddressActions = {
    setAll: setUserAddresses,
    create: createUserAddress,
    update: updateUserAddress,
    remove: removeUserAddress,
    fetching: fetchingAction,
    idle: idleAction,
};
