import { isMountableReactComponent } from './mountableReactComponents';

export const buildReactComponentSelectorByName = (componentName: string) =>
    `.js-reactComponent[data-react-component=${componentName}]`;

export const buildReactComponentSelectorById = (id: string) =>
    `.js-reactComponent[data-react-id="${id}"]`;

export const buildReactPageSelector = (pageName?: string) =>
    `.js-reactPage${pageName ? `[data-react-component=${pageName}]` : null}`;

export interface ReactComponentDomData<TProps extends object = object> {
    id: string;
    name: string;
    props: TProps;
    html: HTMLElement;
    isPartialPage: boolean;
    error: string;
}

export const getReactComponentData = <TProps extends object = object>(element: HTMLElement) => {
    const cmpId = element.dataset.reactId;
    const cmpName = element.dataset.reactComponent;
    const cmpPropsString = element.dataset.reactProps;
    const cmpError = element.dataset.reactError;
    const cmpPropsJson = <TProps>(cmpPropsString ? JSON.parse(cmpPropsString) : {});
    const isPartialPage = element.dataset.reactIsPartialPage?.toLowerCase() === 'true';

    return {
        id: cmpId,
        name: cmpName,
        props: cmpPropsJson,
        html: element,
        error: cmpError,
        isPartialPage,
    } as ReactComponentDomData<TProps>;
};

export const getReactBlocksDataFromDom = () => {
    // crawl DOM only once for all components that should be mounted
    const blocksData =
        Array.from(document.querySelectorAll<HTMLElement>('.js-reactComponent'))
            .map(getReactComponentData)
            .filter(Boolean)
            .filter((item) => isMountableReactComponent(item.name))
            .map((data) => ({ id: data.id, name: data.name })) || [];

    return blocksData;
};

export const getReactPageFromDom = () => {
    const reactPageDomElement = document.querySelector<HTMLElement>('.js-reactPage');
    if (!reactPageDomElement) {
        return null;
    }
    const { name: pageName } = getReactComponentData(reactPageDomElement);
    return pageName;
};
