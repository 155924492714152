import type { IApiStore } from '../../../../models/ecommerce/store/storeModel';
import { sendInteractionEvent } from '../utils';
import { POSTAL_CODE_SELECTOR_EVENT_ACTION } from './utils';

const sendPostalCodeSelectorEvent = (eventLabel: string, additionalData?: object) => {
    sendInteractionEvent({
        eventAction: POSTAL_CODE_SELECTOR_EVENT_ACTION,
        eventLabel,
        ...additionalData,
    });
};

const sendPostalCodeZipCodeUpdateInteraction = (
    eventLabel: string,
    zipCode: string,
    newStore: IApiStore,
) => {
    sendPostalCodeSelectorEvent(eventLabel, {
        zipCode,
        deliverySearch: zipCode,
        deliveryMethod: 'Hemleverans - Leveranssätt ej vald av kund',
        storeId: newStore?.id,
        storeName: newStore?.name,
        Utbud: 'Fullsortiment',
    });
};

const sendPostalCodeSelectorZipFirstSelect = (zipCode: string, newStore: IApiStore) => {
    sendPostalCodeZipCodeUpdateInteraction('Angett postnummer', zipCode, newStore);
};

const sendPostalCodeSelectorZipUpdated = (zipCode: string, newStore: IApiStore) => {
    sendPostalCodeZipCodeUpdateInteraction('Ändra postnummer', zipCode, newStore);
};

const sendPostalCodeSelectorZipSelectedWithPreviousAddress = (
    zipCode: string,
    newStore: IApiStore,
) => {
    sendPostalCodeZipCodeUpdateInteraction('Förvalt postnummer valt', zipCode, newStore);
};

const sendPostalCodeSelectorZipSelectedError = (zipCode: string) => {
    sendPostalCodeSelectorEvent('Postnummer finns ej', {
        Utbud: 'Utbud hos butik hittades ej',
        deliveryMethod: 'Leveranssätt och butik hittades ej',
        deliverySearch: zipCode,
        eventLabel: 'Postnummer finns ej',
        storeId: 'StoreID hittades ej',
        storeName: 'Store Name hittades ej',
        zipCode: 'name not picked',
    });
};

const sendPostalCodeSelectorGoToStoreSearch = () => {
    sendPostalCodeSelectorEvent('Gå till sök efter butik');
};

const sendPostalCodeSelectorGoToCreateLogin = () => {
    sendPostalCodeSelectorEvent('Gå till Skapa Inloggning');
};

const sendPostalCodeSelectorGoToLogin = () => {
    sendPostalCodeSelectorEvent('Gå till Logga in');
};

export default {
    select: {
        inputFirst: sendPostalCodeSelectorZipFirstSelect,
        inputUpdate: sendPostalCodeSelectorZipUpdated,
        address: sendPostalCodeSelectorZipSelectedWithPreviousAddress,
        error: sendPostalCodeSelectorZipSelectedError,
    },
    goToMap: sendPostalCodeSelectorGoToStoreSearch,
    goToCreateLogin: sendPostalCodeSelectorGoToCreateLogin,
    goToLogin: sendPostalCodeSelectorGoToLogin,
};
