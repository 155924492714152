/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { MessageToDriverState } from '../../structureDefinitions/checkoutState';
import { checkoutResetAction } from './checkoutActions';

const initialState: MessageToDriverState = {
    isFetching: false,
    isInitialized: false,
    message: '',
};

const slice = createSlice({
    name: 'messageToDriver',
    initialState,
    reducers: {
        startFetch: (state: MessageToDriverState) => {
            state.isFetching = true;
        },
        finishFetch: (state: MessageToDriverState, action: PayloadAction<string>) => {
            state.isFetching = false;
            state.message = action.payload;
            state.isInitialized = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(checkoutResetAction, () => {
            return initialState;
        });
    },
});

export default slice.reducer;

export const { actions } = slice;
