import alertsDismissed from './cookie/alertsDismissed';
import dynamicYield from './cookie/dynamicYield';
import recipeRatings from './cookie/recipeRatings';
import storeContext from './cookie/storeContext';
import abTestStorage from './localStorage/abTestStorage';
import ecommerceSections from './localStorage/ecommerceSections';
import priceTypeStorage from './localStorage/priceTypeStorage';
import consentShownSession from './sessionStorage/consentShownSession';
import customerGroupsSession from './sessionStorage/customerGroupsSession';

const persistance = {
    ecommerceSections,
    priceTypeStorage,
    abTestStorage,
    customerGroupsSession,
    alertsDismissed,
    recipeRatings,
    storeContext,
    dynamicYield,
    consentShownSession,
};
export default persistance;
