import { GdprCookieVendors, hasAcceptedGdprCookieVendorsSync } from '../../../../utility/gdpr';
import type { ApiDyCookie } from '../../../tracking/api/trackingRequests';
import type {
    ApiDYBaseRequest,
    ApiDYPageContext,
    ApiDYProductRecommendationsRequest,
} from '../../api/products/apiProductRequests';
import { productApi } from '../../api/products/productApi';
import persistance from '../../persistance/persistance';
import { mapProductRecommendations } from '../product/mapProductResponse';
import userFlow from '../userFlow';

export const getStaticPageContext = () => {
    if (window.DY) {
        return DY.recommendationContext;
    }

    return null;
};

export const hasAcceptedDynamicYieldConsents = () => {
    return hasAcceptedGdprCookieVendorsSync([
        GdprCookieVendors.DynamicYield,
        GdprCookieVendors.GoogleAnalytics,
    ]);
};

export const getDynamicYieldData = (): ApiDYBaseRequest => {
    const hasConsent = hasAcceptedDynamicYieldConsents();
    const dynamicYieldIds: ApiDYBaseRequest = {
        user: {
            active_consent_accepted: hasConsent,
        },
    };

    if (hasConsent) {
        const dyId = persistance.dynamicYield.getDyId();
        const dySession = persistance.dynamicYield.getSessionId();

        if (dyId) {
            dynamicYieldIds.user = {
                active_consent_accepted: true,
                dyid: dyId,
            };
        }

        if (dySession) {
            dynamicYieldIds.session = {
                dy: dySession,
            };
        }
    }

    return dynamicYieldIds;
};

export interface AbTestCustomData<TTestValue> {
    isAbTest: boolean;
    chosenVariant?: TTestValue;
}

interface RecommendationRequest {
    recommendationId: string;
    onlyPromotions: boolean;
}

const syncCookies = (cookies: ApiDyCookie[]) => {
    cookies?.forEach((c) => persistance.dynamicYield.setCookies(c));
};

const getProductRecommendations = async (
    recommendationRequests: Array<RecommendationRequest>,
    storeId: string,
    pageContext: ApiDYPageContext,
    pageAttributes?: unknown,
) => {
    const dynamicYieldIds = getDynamicYieldData();
    const args = recommendationRequests.reduce((prev, curr) => {
        return { ...prev, [curr.recommendationId]: { promotions: curr.onlyPromotions } };
    }, {});

    const recommendationNames = recommendationRequests.map((x) => x.recommendationId);

    const request: ApiDYProductRecommendationsRequest = {
        ...dynamicYieldIds,
        selector: {
            names: recommendationNames,
            args,
        },
        context: {
            page: pageContext,
            pageAttributes,
        },
        options: {
            returnAnalyticsMetadata: true,
        },
    };

    const customerGroups = await userFlow.getB2BContextCustomerGroups();
    const response = await productApi.getRecommendations(storeId, request, customerGroups);
    const productRecommendatons = mapProductRecommendations(response);
    syncCookies(response.cookies);
    return productRecommendatons;
};

const getProductRecommendation = async (
    recommendationRequest: RecommendationRequest,
    storeId: string,
    pageContext: ApiDYPageContext,
    pageAttributes?: unknown,
) => {
    const productRecommendations = await getProductRecommendations(
        [recommendationRequest],
        storeId,
        pageContext,
        pageAttributes,
    );
    const productRecommendation = productRecommendations.find(
        (recommendation) => recommendation.selectorName === recommendationRequest.recommendationId,
    );

    if (!productRecommendation) return null;

    return productRecommendation;
};

const dynamicYieldFlow = {
    getProductRecommendations,
    getProductRecommendation,
    syncCookies,
};

export default dynamicYieldFlow;
