/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';

import { Icon } from '../Icon';
import type { BadgeProps } from './Badge.types';
import { getBadgeClasses } from './Badge.utils';

const Badge = React.forwardRef<HTMLDivElement, React.PropsWithChildren<BadgeProps>>(
    (props, ref) => {
        const classes = getBadgeClasses(props);

        return (
            <div
                ref={ref}
                className={classes}
                style={{
                    top: props.top,
                    right: props.right,
                    bottom: props.bottom,
                    left: props.left,
                    transform: [
                        props.offsetX !== undefined && `translateX(${props.offsetX})`,
                        props.offsetY !== undefined && `translateY(${props.offsetY})`,
                    ]
                        .filter(Boolean)
                        .join(' '),
                }}
            >
                {props.leftIcon && (
                    <Icon icon={props.leftIcon} aria-hidden style={{ marginRight: 4 }} />
                )}
                {props.children}
            </div>
        );
    },
);

export default Badge;
