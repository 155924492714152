/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { UserInfo } from '../../structureDefinitions/userState';

interface UserInfoState {
    isInitialized: boolean;
    info?: UserInfo;
}

const getInitialState = (): UserInfoState => {
    return {
        isInitialized: false,
        info: undefined,
    };
};

const slice = createSlice({
    name: 'userInfo',
    initialState: getInitialState(),
    reducers: {
        set: (state, action: PayloadAction<UserInfo>) => {
            if (!action.payload) return getInitialState();
            state.isInitialized = true;
            state.info = action.payload;
        },
    },
});

export const { actions: userInfoActions, reducer: userInfoReducer } = slice;
