import { useQuery } from '@tanstack/react-query';

import userFlow from '../../common/flow/userFlow';
import { useUserTypeQuery } from '../../common/hooks/useUserType';
import { UserType } from '../../common/store/structureDefinitions/userState';

export const punchoutQueryKeys = {
    orgUnit: ['punchout:orgUnit'],
};

export const usePunchoutOrgUnit = () => {
    const userTypeQuery = useUserTypeQuery();

    const query = useQuery({
        queryFn: userFlow.getPunchoutUnit,
        queryKey: punchoutQueryKeys.orgUnit,
        staleTime: Infinity,
        enabled: userTypeQuery.isSuccess && userTypeQuery.data === UserType.punchout,
    });

    return query;
};
