/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { PaymentCode, PaymentState } from '../../structureDefinitions/checkoutState';

const initialPaymentState: PaymentState = {
    code: null,
    isInitialized: false,
};

const slice = createSlice({
    name: 'paymentState',
    initialState: initialPaymentState,
    reducers: {
        setMethod: (state: PaymentState, action: PayloadAction<PaymentCode>) => {
            state.code = action.payload;
            state.isInitialized = false;
        },
        setInitialized: (state: PaymentState, action: PayloadAction<boolean>) => {
            state.isInitialized = action.payload;
        },
        setKlarnaInitialized: (state: PaymentState, action: PayloadAction<boolean>) => {
            state.isKlarnaLoaded = action.payload;
        },
        setKlarnaPostalCode: (state: PaymentState, action: PayloadAction<string>) => {
            state.klarnaPostalCode = action.payload;
        },
        setState: (state: PaymentState, action: PayloadAction<PaymentState>) => {
            return action.payload;
        },
    },
});

export const { actions: paymentMethodActions, reducer: paymentMethodReducer } = slice;
