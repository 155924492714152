import { Cookies } from '../../../../utility/cookies';
import { RECIPE_RATINGS_COOKIE_NAME } from './cookieKeys';

const recipeRatings = {
    get: (recipeId: string) => {
        const rawCookie = Cookies.read(RECIPE_RATINGS_COOKIE_NAME);
        if (rawCookie) {
            const cookieRating = JSON.parse(rawCookie) as Record<string, number>;
            const recipeRating = cookieRating[recipeId];
            return recipeRating || 0;
        }
    },
};

export default recipeRatings;
