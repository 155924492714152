import classNames from 'classnames';
import * as React from 'react';

import styles from './Badge.module.scss';

const BadgePositioning = (props: React.PropsWithChildren<{ className?: string }>) => {
    return (
        <div className={classNames(props.className, styles.BadgePositioning)}>{props.children}</div>
    );
};

export default BadgePositioning;
