import type { LightSearchRecipe } from '../../../search/models/models';

export interface DynamicYieldAnalyticsMetadata {
    analyticsMetadata: DynamicYieldAnalyticsMetadataBody;
}

export interface DynamicYieldAnalyticsMetadataBody {
    campaignId: number;
    campaignName: string;
    experienceId: number;
    experienceName: string;
    variationId: number;
    variationName: string;
}

export enum RecommendationType {
    Product,
    Recipe,
}

export type ProductRecommendationSlot = ApiProduct & {
    type: RecommendationType.Product;
    slotId: string;
};

export type RecipeRecommendationSlot = LightSearchRecipe & {
    type: RecommendationType.Recipe;
    slotId?: string;
};

export type RecommendationItem = ProductRecommendationSlot | RecipeRecommendationSlot;

export interface Recommendation extends DynamicYieldAnalyticsMetadata {
    recommendationId: string;
    selectorName: string;
    selectedVariationId: string;
    items: RecommendationItem[];
    title: string;
}

export interface CustomCampaign<TCustomData> extends DynamicYieldAnalyticsMetadata {
    id: string;
    selectorName: string;
    selectedVariationId: string;
    custom: TCustomData;
}
