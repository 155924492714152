import { useEffect } from 'react';

import { storeApi } from '../api/store/storeApi';
import { unsyncedCartItemsSelectors } from '../selectors/unsyncedCartItemsSelectors';
import { storeActions } from '../store/slices/store/storeSlice';
import { unsyncedCartItemsActions } from '../store/slices/unsyncedCartItemsSlice';
import { cartThunks } from '../thunks/cartThunks';
import { useAsyncDispatcher } from './useAsyncDispatcher';
import { useAppDispatch, useAppSelector } from './useThunkDispatch';

/**
 * Use this if you want to keep in sync Store context cookie with Redux store, usually should be used once in an active component tree
 * If you want only the current values, get them directly from Redux store
 */
const useCurrentStoreSync = () => {
    const dispatch = useAppDispatch();

    const unsyncedCartItems = useAppSelector(unsyncedCartItemsSelectors.selectUnsyncedCartItems);

    const setPickupStore = async (pickupPointId: string) => {
        const store = await storeApi.fetchStoreById(pickupPointId);
        dispatch(storeActions.selectPickupStore({ store, pickupPointId }));
        if (unsyncedCartItems?.length) {
            await dispatch(cartThunks.addItems(unsyncedCartItems));
            dispatch(unsyncedCartItemsActions.removeUnsyncedCartItems());
        }
    };

    const setDeliveryStore = async (postalCode: string, baseStoreId: string) => {
        const store = await storeApi.fetchStoreById(baseStoreId);
        dispatch(
            storeActions.selectHomeDeliveryStore({
                store,
                postalCode,
            }),
        );
        if (unsyncedCartItems?.length) {
            await dispatch(cartThunks.addItems(unsyncedCartItems));
            dispatch(unsyncedCartItemsActions.removeUnsyncedCartItems());
        }
    };

    const { execute: setPickupStoreExecute } = useAsyncDispatcher((pickupPointId: string) =>
        setPickupStore(pickupPointId),
    );

    const { execute: setDeliveryStoreExecute } = useAsyncDispatcher(
        (postalCode: string, baseStoreId: string) => setDeliveryStore(postalCode, baseStoreId),
    );

    const { selectedPickupPointId, selectedZipCode, productionUnitId } = useAppSelector(
        (state) => state.store,
    );

    useEffect(() => {
        if (selectedPickupPointId) {
            setPickupStoreExecute(selectedPickupPointId);
        }
    }, [setPickupStoreExecute, selectedPickupPointId]);

    useEffect(() => {
        if (selectedZipCode && productionUnitId) {
            setDeliveryStoreExecute(selectedZipCode, productionUnitId);
        }
    }, [setDeliveryStoreExecute, selectedZipCode, productionUnitId]);
};

export default useCurrentStoreSync;
