import { Notification } from '@coop/components';
import type { FC } from 'react';
import * as React from 'react';

import { useGroupError } from '../../../hooks/useGroupError';
import { ErrorGroup } from '../../../store/structureDefinitions/errorsState';

/**
 * We can't recover from it, i.e. checkout unmounts. We need to reload a page
 */
const CriticalErrorNotification: FC<React.PropsWithChildren<unknown>> = () => {
    const { firstError } = useGroupError(ErrorGroup.Critical);

    const isOpen = !!firstError;

    return (
        <Notification
            behaviour="modal"
            type="alert"
            header="Någonting gick snett."
            message="Vänligen ladda om sidan eller försök igen"
            show={isOpen}
            ctaProps={{
                children: 'Ladda om sidan',
                onClick: () => {
                    window.location.reload();
                },
            }}
        />
    );
};

export default CriticalErrorNotification;
