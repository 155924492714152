/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { appConfig } from '../../../../../appConfig';

interface UserProfileState {
    id: string;
    medmeraId?: number;
    isCompany?: boolean;
    isMunicipal?: boolean;
    firstName: string;
    lastName: string;
    email: string;
    isAuthenticated: boolean;
    hasKimCustomerId: boolean;
}

const getInitialState = () => {
    if (!appConfig.coopUserSettings.isAuthenticated || !appConfig.coopUserSettings.profile) {
        return null;
    }

    const profile: UserProfileState = {
        id: appConfig.coopUserSettings.profile.id,
        medmeraId: appConfig.coopUserSettings.profile.medmeraId,
        isCompany: appConfig.coopUserSettings.profile.isCompany,
        isMunicipal: appConfig.coopUserSettings.profile.isMunicipal,
        firstName: appConfig.coopUserSettings.profile.firstName,
        lastName: appConfig.coopUserSettings.profile.lastName,
        email: appConfig.coopUserSettings.profile.email,
        isAuthenticated: appConfig.coopUserSettings.isAuthenticated,
        hasKimCustomerId: appConfig.coopUserSettings.hasKimCustomerId,
    };

    return profile;
};

const slice = createSlice({
    name: 'userProfile',
    initialState: getInitialState(),
    reducers: {},
});

export const { actions: userProfileActions, reducer: userProfileReducer } = slice;
