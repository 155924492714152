import classNames from 'classnames';
import * as React from 'react';

import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { MainPageLayoutContext } from './MainPageLayoutContext';

/**
 * This is a layout component that should be used by all Coop.se pages, to ensure a consistent layout.
 */
const MainPageLayout = (
    props: React.PropsWithChildren<{
        heroSlot?: React.ReactNode;
        heroEdgeToEdge?: boolean;
        heroBleedViewport?: boolean;

        subHeroSlot?: React.ReactNode;
        sideMenuSlot?: React.ReactNode;

        disablePaddingOnMobile?: boolean;
    }>,
) => {
    const hasSideBar =
        useAppSelector((state) => state.ui.shared.hasSideBar) && !!props.sideMenuSlot;

    const id = React.useId();

    const topMargin = classNames('u-marginTmd u-lg-marginTxlg');

    const parentContext = React.useContext(MainPageLayoutContext);
    const thisContext = React.useMemo(
        () => ({
            isInsideAnotherLayout: parentContext !== undefined,
        }),
        [parentContext],
    );

    if (thisContext.isInsideAnotherLayout) {
        return (
            <MainPageLayoutContext.Provider value={thisContext}>
                {!!props.heroSlot && (
                    <LayoutHero
                        id={id}
                        edgeToEdge
                        bleedViewport={props.heroBleedViewport}
                        topMargin={undefined}
                        disablePaddingOnMobile={props.disablePaddingOnMobile}
                    >
                        {props.heroSlot}
                    </LayoutHero>
                )}

                <div id={`LayoutContentWrapper_${id}`}>
                    <LayoutContent
                        id={id}
                        subHeroSlot={props.subHeroSlot}
                        sideMenuSlot={props.sideMenuSlot}
                        topMargin={props.heroSlot ? topMargin : undefined}
                    >
                        {props.children}
                    </LayoutContent>
                </div>
            </MainPageLayoutContext.Provider>
        );
    }

    return (
        <MainPageLayoutContext.Provider value={thisContext}>
            {!!props.heroSlot && (
                <LayoutHero
                    id={id}
                    edgeToEdge={props.heroEdgeToEdge}
                    bleedViewport={props.heroBleedViewport}
                    topMargin={topMargin}
                    disablePaddingOnMobile={props.disablePaddingOnMobile}
                >
                    {props.heroSlot}
                </LayoutHero>
            )}

            <div
                id={`LayoutContentWrapper_${id}`}
                className={classNames(
                    'Main-container',
                    'Main-container--padding',
                    hasSideBar && 'Main-container--full',
                    props.disablePaddingOnMobile && 'Main-container--noPaddingOnMobile',
                    topMargin,
                    'u-marginBxlg',
                )}
            >
                <LayoutContent
                    id={id}
                    subHeroSlot={props.subHeroSlot}
                    sideMenuSlot={props.sideMenuSlot}
                    topMargin={topMargin}
                >
                    {props.children}
                </LayoutContent>
            </div>
        </MainPageLayoutContext.Provider>
    );
};

const LayoutHero = (
    props: React.PropsWithChildren<{
        id: string;
        edgeToEdge: boolean | undefined;
        bleedViewport: boolean | undefined;
        topMargin: string | undefined;
        disablePaddingOnMobile: boolean | undefined;
    }>,
) => (
    <div
        id={`LayoutHero_${props.id}`}
        className={classNames(
            'Main-container',
            'Main-container--fullBleed',
            props.edgeToEdge || props.bleedViewport
                ? 'Main-container--fullBleedViewport'
                : 'Main-container--padding Main-container--fullBleedXXlg',
            !props.edgeToEdge && props.topMargin,
            props.disablePaddingOnMobile && 'Main-container--noPaddingOnMobile',
        )}
    >
        {props.children}
    </div>
);

const LayoutContent = (
    props: React.PropsWithChildren<{
        id: string;
        subHeroSlot: React.ReactNode | undefined;
        sideMenuSlot: React.ReactNode | undefined;
        topMargin: string | undefined;
    }>,
) => (
    <>
        {!!props.subHeroSlot && props.subHeroSlot}

        {props.sideMenuSlot ? (
            <div
                id={`LayoutContent_${props.id}`}
                className={classNames('Grid Grid--gutterHxlg Grid--dynamicWidth', props.topMargin)}
            >
                <div className="Grid-cell Grid-cell--fixedWidth u-hidden u-lg-block">
                    {props.sideMenuSlot}
                </div>
                <div className="Grid-cell Grid-cell--grownWidth u-block">
                    <div>{props.children}</div>
                </div>
            </div>
        ) : (
            <div id={`LayoutContent_${props.id}`} className={props.topMargin}>
                {props.children}
            </div>
        )}
    </>
);

export default MainPageLayout;
