import { appConfig } from '../../../../appConfig';
import type { ApiCartException } from '../../exceptions/exceptionDefinitions';
import { createDefaultException } from '../../exceptions/exceptionUtils';
import axiosClientFactory from './clientFactory';
import type { AxiosException, CustomErrorInterceptor } from './localApiAxiosErrorInterceptors';

export const getUserIdUrlSegment = (userId: string): string => {
    return userId || 'anonymous';
};

const getDirectHybrisAxiosClient = <TException extends ApiHybrisErrors>(
    accessToken?: string,
    errorInterceptors?: CustomErrorInterceptor<HybrisApiError<TException>>[],
) => {
    const directHybrisAxiosClient = axiosClientFactory.createDirectApiClient<
        HybrisApiError<TException>
    >(
        {
            baseURL: `${appConfig.coopSettings.serviceAccess.hybrisApiUrl}/coop`,
            headers: {
                'Ocp-Apim-Subscription-Key':
                    appConfig.coopSettings.serviceAccess.hybrisApiSubscriptionKey,
                Accept: 'application/json',
                'Ocp-Apim-Trace': 'true',
                Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
            },
            params: {
                'api-version': appConfig.coopSettings.serviceAccess.hybrisApiVersion,
            },
        },
        [...(errorInterceptors || []), hybrisErrorInterceptor],
    );
    return directHybrisAxiosClient;
};

const hybrisErrorInterceptor = <TException extends ApiHybrisErrors>(
    error: AxiosException<HybrisApiError<TException>>,
) => {
    if (!Array.isArray(error?.response.data.errors)) {
        return null;
    }

    const hybrisErrors = error.response.data.errors;
    for (let i = 0; i < hybrisErrors.length; i += 1) {
        const hybrisError = hybrisErrors[i];
        if (isApiHybrisBaseError(hybrisError)) {
            if (hybrisError.type === 'CartError') {
                return <ApiCartException>{
                    type: 'Cart',
                    code: hybrisError.reason,
                    friendlyMessage: 'Unable to find cart.',
                };
            }
            if (hybrisError.type === 'StoreNotFoundError') {
                return <ApiCartException>{
                    type: 'Cart',
                    code: 'StoreNotFound',
                    friendlyMessage: 'Kunde inte hitta butiken.',
                };
            }
            if (
                hybrisError.type === 'ValidationError' &&
                hybrisError.subjectType === 'Cart' &&
                hybrisError.reason === 'TIME_WINDOW_NOT_VALID'
            ) {
                return <ApiCartException>{
                    type: 'Cart',
                    code: hybrisError.reason,
                    friendlyMessage:
                        'Din reserverade tid är tyvärr ej längre tillgänglig. Varorna finns kvar i din varukorg, så reservera gärna en ny tid och försök igen.',
                };
            }

            if (
                hybrisError.type === 'ValidationError' &&
                hybrisError.subjectType === 'Cart' &&
                hybrisError.reason === 'TOTAL_NOT_VALID'
            ) {
                return <ApiCartException>{
                    type: 'Cart',
                    code: hybrisError.reason,
                    friendlyMessage:
                        'Av någon anledning är ditt ordervärde för lågt för att lägga en order. Fyll gärna på med något mer du behöver för att uppnå gränsen!',
                };
            }
        }
    }

    return createDefaultException();
};

export interface HybrisApiError<T> {
    errors: T[];
}

export interface ApiHybrisBaseError {
    message: string;
    reason: string;
    subjectType: string;
    type: string;
}

export interface ApiValidateCartError {
    errorCode: string;
    errorMessage: string;
}

export const isApiHybrisBaseError = (error: unknown): error is ApiHybrisBaseError => {
    return (error as ApiHybrisBaseError)?.type !== undefined;
};

export type ApiHybrisErrors = ApiHybrisBaseError | ApiValidateCartError;
export default getDirectHybrisAxiosClient;
