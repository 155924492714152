import type { IGaPromoData } from '../../models/IGaPromoData';

export const isPromoDataAcceptable = ({
    gaPromoId,
    gaPromoName,
    gaPromoCreative,
    gaPromoPosition,
}: IGaPromoData): boolean => {
    const foundValues = [gaPromoId, gaPromoName, gaPromoCreative, gaPromoPosition].filter(Boolean);

    if (foundValues.length >= 2) {
        return true;
    }

    return false;
};

const formatGaCreativeSlot = (position: string | undefined) => {
    if (position !== undefined) {
        if (position === '') {
            return undefined;
        }

        if (!Number.isNaN(Number(position))) {
            return Number(position);
        }
        return position;
    }
};

export const mapToGaPromotion = (promotion: IGaPromoData) => {
    const idAndNameMissing = !promotion.gaPromoId && !promotion.gaPromoName; // only if both empty, otherwise GA allows to send just id or name

    const gaPromo: GaPromotion = {
        promotion_id: idAndNameMissing ? 'Error - Name and ID is missing' : promotion.gaPromoId,
        promotion_name: idAndNameMissing ? 'Error - Name and ID is missing' : promotion.gaPromoName,
        creative_name: promotion.gaPromoCreative,
        creative_slot: formatGaCreativeSlot(promotion.gaPromoPosition),
    };

    return gaPromo;
};
