import { useEffect, useRef } from 'react';

import type { SearchFromPageType } from '../../../base/models/SearchFromPageType';
import { ga4SearchTracking } from '../ga4/search';

export const useSearchOverlayGA4ActionTracking = (
    originalQuery: string | undefined,
    queryUsed: string | undefined,
    searchFromPage: SearchFromPageType | undefined,
    isLoading: boolean,
) => {
    const originalQueryToTrack = isLoading ? undefined : originalQuery;
    const queryUsedToTrack = isLoading ? undefined : queryUsed;

    const previousOriginalQuery = useRef<string | undefined>(undefined);
    useEffect(() => {
        if (previousOriginalQuery.current !== originalQueryToTrack) {
            previousOriginalQuery.current = undefined;
        }
    }, [originalQueryToTrack]);

    const trackSearchAction = (
        actionTrigger: SearchTermActionTriggerType,
        totalResultNumber?: number,
    ) => {
        if (
            previousOriginalQuery.current === originalQueryToTrack &&
            originalQueryToTrack !== undefined
        ) {
            return;
        }

        previousOriginalQuery.current = originalQueryToTrack;

        ga4SearchTracking.search(searchFromPage)?.(actionTrigger)({
            originalSearchTerm: originalQueryToTrack,
            actualSearchTerm: queryUsedToTrack,
            totalResultNumber,
        });
    };

    const trackSearchDropdownAction = ga4SearchTracking.searchDropdownAction(searchFromPage);
    const trackSearchDropdownItemAction =
        ga4SearchTracking.searchDropdownItemAction(searchFromPage);

    const trackAbortAction = () => {
        trackSearchAction('abort');
        trackSearchDropdownAction?.('abort');
    };

    const trackCloseClickAction = () => {
        trackSearchAction('click_x');
        trackSearchDropdownAction?.('click_x');
    };

    const trackListObjectClick = (itemId?: string, itemName?: string, position?: number) => {
        trackSearchAction('click_list_object');
        trackSearchDropdownItemAction?.('click_list_object')({ itemId, itemName, position });
    };

    const trackEnterAction = () => {
        trackSearchAction('enter');
        trackSearchDropdownAction?.('enter');
    };

    const trackSeeAllResultsClickAction = () => {
        trackSearchAction('see_all_results');
        trackSearchDropdownAction?.('see_all_results');
    };

    const trackLabelClick = () => {
        trackSearchAction('click_label');
        trackSearchDropdownAction?.('click_label');
    };

    const trackAddToCartClickAction = (itemId: string, itemName: string, position: number) => {
        trackSearchAction('add_to_cart');
        trackSearchDropdownItemAction?.('add_to_cart')({ itemId, itemName, position });
    };

    const trackRemoveFromCartClickAction = (itemId: string, itemName: string, position: number) => {
        trackSearchAction('remove_from_cart');
        trackSearchDropdownItemAction?.('remove_from_cart')({ itemId, itemName, position });
    };

    return {
        trackSearchDropdownAction,
        trackSearchDropdownItemAction,
        trackSearchAction,
        trackAbortAction,
        trackCloseClickAction,
        trackListObjectClick,
        trackEnterAction,
        trackSeeAllResultsClickAction,
        trackLabelClick,
        trackAddToCartClickAction,
        trackRemoveFromCartClickAction,
    };
};
export const useEcommerceSearchOverlayGA4ActionTracking = (
    originalQuery: string | undefined,
    queryUsed: string | undefined,
    searchFromPage: SearchFromPageType | undefined,
    isLoading: boolean,
) => {
    const { trackSearchAction, trackSearchDropdownAction, trackSearchDropdownItemAction } =
        useSearchOverlayGA4ActionTracking(originalQuery, queryUsed, searchFromPage, isLoading);

    const trackAbortAction = (totalResults?: number) => {
        const totalResultsToTrack = isLoading ? undefined : totalResults;
        trackSearchAction('abort', totalResultsToTrack);
        trackSearchDropdownAction?.('abort');
    };

    const trackCloseClickAction = (totalResults?: number) => {
        const totalResultsToTrack = isLoading ? undefined : totalResults;
        trackSearchAction('click_x', totalResultsToTrack);
        trackSearchDropdownAction?.('click_x');
    };

    const trackAutocompleteItemClick = (totalResults?: number) => {
        const totalResultsToTrack = isLoading ? undefined : totalResults;
        trackSearchAction('other_suggestions', totalResultsToTrack);
        trackSearchDropdownAction?.('other_suggestions');
    };

    const trackListObjectClick = (
        totalResults: number | undefined,
        itemId: string,
        itemName: string,
        position: number,
    ) => {
        const totalResultsToTrack = isLoading ? undefined : totalResults;
        trackSearchAction('click_list_object', totalResultsToTrack);
        trackSearchDropdownItemAction?.('click_list_object')({ itemId, itemName, position });
    };

    const trackEnterAction = (totalResults?: number) => {
        const totalResultsToTrack = isLoading ? undefined : totalResults;
        trackSearchAction('enter', totalResultsToTrack);
        trackSearchDropdownAction?.('enter');
    };

    const trackSeeAllResultsClickAction = (totalResults?: number) => {
        const totalResultsToTrack = isLoading ? undefined : totalResults;
        trackSearchAction('see_all_results', totalResultsToTrack);
        trackSearchDropdownAction?.('see_all_results');
    };

    const trackAddToCartClickAction = (
        totalResults: number | undefined,
        itemId: string,
        itemName: string,
        position: number,
    ) => {
        trackSearchAction('add_to_cart', totalResults);
        trackSearchDropdownItemAction?.('add_to_cart')({ itemId, itemName, position });
    };

    const trackRemoveFromCartClickAction = (
        totalResults: number | undefined,
        itemId: string,
        itemName: string,
        position: number,
    ) => {
        trackSearchAction('remove_from_cart', totalResults);
        trackSearchDropdownItemAction?.('remove_from_cart')({ itemId, itemName, position });
    };

    return {
        trackAbortAction,
        trackCloseClickAction,
        trackAutocompleteItemClick,
        trackListObjectClick,
        trackEnterAction,
        trackSeeAllResultsClickAction,
        trackAddToCartClickAction,
        trackRemoveFromCartClickAction,
    };
};
