/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ApiFullCartItem } from '../../../api/cart/responses/cartResponses';
import type { CartItemState } from '../../structureDefinitions/cartState';
import type { UpdateCartItemPayload } from './cartActions';
import { updateCartItem } from './cartActions';

interface ReplaceabilityChangePayload {
    productId: string;
    variantId?: string;
    replaceable: boolean;
}

interface SetItemPayload {
    productId: string;
    variantId?: string;
    cartItem?: CartItemState;
}

interface SubsituteProductPayload {
    newCartItem: ApiFullCartItem;
    prevItemId: string;
    prevProductId: string;
    prevVariantId?: string;
}

const slice = createSlice({
    name: 'items',
    initialState: [] as CartItemState[],
    reducers: {
        changeCartItemQuantity: (
            state: CartItemState[],
            action: PayloadAction<UpdateCartItemPayload>,
        ) => {
            const index = state.findIndex(
                (item: CartItemState) =>
                    item.productId === action.payload.productId &&
                    item.variantId === action.payload.variantId,
            );
            if (index === -1) return;
            state[index].quantity = action.payload.quantity;
        },
        changeCartItemReplaceability: (
            state: CartItemState[],
            action: PayloadAction<ReplaceabilityChangePayload>,
        ) => {
            const index = state.findIndex(
                (item: CartItemState) =>
                    item.productId === action.payload.productId &&
                    item.variantId === action.payload.variantId,
            );
            if (index === -1) return;
            state[index].itemReplaceable = action.payload.replaceable;
        },
        setAllCartItemsReplaceability: (state: CartItemState[], action: PayloadAction<boolean>) => {
            for (let i = 0; i < state.length; i += 1) {
                state[i].itemReplaceable = action.payload;
            }
        },
        substituteProduct: (
            state: CartItemState[],
            action: PayloadAction<SubsituteProductPayload>,
        ) => {
            const index = state.findIndex(
                (item: CartItemState) =>
                    item.id === action.payload.prevItemId &&
                    item.productId === action.payload.prevProductId &&
                    item.variantId === action.payload.prevVariantId,
            );
            if (index === -1) return;
            const { recipeMetaData, id, totalPriceData } = state[index];
            state[index] = { ...action.payload.newCartItem, recipeMetaData, id, totalPriceData };
        },
        restoreItem: (state: CartItemState[], action: PayloadAction<SetItemPayload>) => {
            const index = state.findIndex(
                (item: CartItemState) =>
                    item.productId === action.payload.productId &&
                    item.variantId === action.payload.variantId,
            );

            // remove
            if (!action.payload.cartItem && index > -1) {
                state.splice(index, 1);
                return;
            }

            // add
            if (action.payload.cartItem && index === -1) {
                state.push(action.payload.cartItem);
                return;
            }

            // set
            if (action.payload.cartItem && index > -1) {
                state[index] = action.payload.cartItem;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            updateCartItem,
            (state: CartItemState[], action: PayloadAction<UpdateCartItemPayload>) => {
                const itemIndex = state.findIndex(
                    (item: CartItemState) =>
                        item.productId === action.payload.productId &&
                        item.variantId === action.payload.variantId,
                );

                // add
                if (itemIndex === -1 && action.payload.quantity > 0) {
                    state.push({
                        productId: action.payload.productId,
                        variantId: action.payload.variantId,
                        quantity: action.payload.quantity,
                    });
                    return;
                }

                // remove
                if (itemIndex > -1 && action.payload.quantity <= 0) {
                    state.splice(itemIndex, 1);
                    return;
                }

                // update
                if (itemIndex > -1 && action.payload.quantity > 0) {
                    state[itemIndex].quantity = action.payload.quantity;
                }
            },
        );
    },
});

export default slice.reducer;

export const cartActions = {
    updateCartItem,
    ...slice.actions,
};
