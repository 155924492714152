import { createSelector } from '@reduxjs/toolkit';

import { cartItemUniqueIdPredicate } from '../../cart/selectors/cartItemsSelectors';
import type { UnsyncedCartItem } from '../store/slices/unsyncedCartItemsSlice';
import type { CartItemUniqueId, RootState } from '../store/structureDefinitions/cartState';

const selectUnsyncedCartItems = (state: RootState): UnsyncedCartItem[] =>
    state.unsyncedCartItems.items;

const selectUnsyncedCartItemByUniqueId = createSelector(
    selectUnsyncedCartItems,
    (_: RootState, cartItemId: CartItemUniqueId) => cartItemId,
    (items: UnsyncedCartItem[], cartItemId: CartItemUniqueId) =>
        items.find((item) => cartItemUniqueIdPredicate(item, cartItemId)),
);

export const unsyncedCartItemsSelectors = {
    selectUnsyncedCartItems,
    selectUnsyncedCartItemByUniqueId,
};
