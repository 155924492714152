import _ from 'underscore';

import type { AnalyticsListOptions } from '../../../../../analytics/analyticsHandler';
import { createBatcher } from '../../utils/createBatcher';
import { failSilently } from '../../utils/failSilently';
import { sendSplittedData } from '../../utils/sendSplittedData';
import { mapGaList } from '../shared/ga4SharedMappers';
import { sendGA4Event } from '../shared/sendGa4Event';
import {
    REPLACED_ITEM_LIST_ID,
    REPLACED_ITEM_LIST_NAME,
    UNAVAILABLE_ITEM_LIST_ID,
    UNAVAILABLE_ITEM_LIST_NAME,
} from './ga4Items.consts';
import { getGaProductsWithVariantsList, mapProductToGaItemWithDetails } from './ga4Items.utils';

const buildViewItemListEvent = (
    items: (ApiProduct & {
        index?: number;
        listId?: string;
        listName?: string;
    })[],
) => {
    const mappedItems = items.map((item) => ({
        ...mapProductToGaItemWithDetails(item, 1),
        index: item.index,
        item_list_id: item.listId,
        item_list_name: item.listName,
    }));

    const event: ViewItemListEvent = {
        event: 'view_item_list',
        ecommerce: {
            item_list_id: mappedItems.map((item) => item.item_list_id).filter(Boolean)?.[0],
            item_list_name: mappedItems.map((item) => item.item_list_name).filter(Boolean)?.[0],
            items: mappedItems,
        },
    };

    return event;
};

const viewItemList = (
    items: {
        product: ApiProduct;
        analyticsOptions?: AnalyticsListOptions;
    }[],
) => {
    const mappedItems = items.map((item) => {
        const gaList =
            (item.analyticsOptions?.list && mapGaList(item.analyticsOptions.list)) || undefined;

        return {
            ...item.product,
            quantity: 1,
            index: item.analyticsOptions?.position,
            listId: gaList?.item_list_id,
            listName: gaList?.item_list_name,
        };
    });

    const event = buildViewItemListEvent(mappedItems);

    sendGA4Event(event);
};

const viewItem = async (productId: string, variantId: string | undefined, storeId: string) => {
    const productsWithVariantsAndQuantity = await getGaProductsWithVariantsList(
        [
            {
                productId,
                variantId,
                quantity: 1,
            },
        ],
        storeId,
    );

    const mappedItem = productsWithVariantsAndQuantity.map(({ productData, inputData }) =>
        mapProductToGaItemWithDetails(productData, inputData.quantity, inputData.variantId),
    )?.[0];

    const event: ViewItemEvent = {
        event: 'view_item',
        ecommerce: {
            currency: 'SEK',
            value: mappedItem?.price,
            items: [mappedItem],
        },
    };

    sendGA4Event(event);
};

const selectItem = async (
    productId: string,
    variantId: string | undefined,
    storeId: string,
    index?: number,
    analyticsOptions?: AnalyticsListOptions,
) => {
    const productsWithVariantsAndQuantity = await getGaProductsWithVariantsList(
        [
            {
                productId,
                variantId,
                quantity: 1,
                index,
            },
        ],
        storeId,
    );

    const mappedItem = productsWithVariantsAndQuantity.map(({ productData, inputData }) =>
        mapProductToGaItemWithDetails(productData, inputData.quantity, inputData.variantId),
    )?.[0];

    const gaList = (analyticsOptions?.list && mapGaList(analyticsOptions.list)) || undefined;

    const items = [{ ...mappedItem, index, ...gaList }];

    const event: SelectItemEvent = {
        event: 'select_item',
        ecommerce: {
            ...gaList,
            items,
        },
    };

    sendGA4Event(event);
};

const viewReplacedItemList = (
    originalProductId: string,
    items: (ApiProduct & { quantity: number })[],
) => {
    const event: ViewReplacedItemsListEvent = {
        original_product_id: originalProductId,
        ...buildViewItemListEvent(
            items.map((item, index) => ({
                ...item,
                index,
                listId: REPLACED_ITEM_LIST_ID,
                listName: REPLACED_ITEM_LIST_NAME,
            })),
        ),
    };

    sendGA4Event(event);
};

const unavailableItemsViewItemList = (items: (ApiProduct & { quantity: number })[]) => {
    const event = buildViewItemListEvent(
        items.map((item, index) => ({
            ...item,
            index,
            listId: UNAVAILABLE_ITEM_LIST_ID,
            listName: UNAVAILABLE_ITEM_LIST_NAME,
        })),
    );

    sendGA4Event(event);
};

const MAX_VIEW_ITEM_LIST_COUNT = 20;

const viewItemListBatcher = createBatcher<{
    product: ApiProduct;
    analyticsOptions: AnalyticsListOptions;
}>(
    (
        products: {
            product: ApiProduct;
            analyticsOptions?: AnalyticsListOptions;
        }[],
    ) => {
        sendSplittedData(products, MAX_VIEW_ITEM_LIST_COUNT, (dataChunk) => {
            viewItemList(_.unique(dataChunk, (item) => item.product.id));
        });
    },
    1000,
);

const viewItemListChunked = (product: ApiProduct, analyticsOptions: AnalyticsListOptions) =>
    viewItemListBatcher({ product, analyticsOptions });

export default {
    viewItemList: failSilently(viewItemListChunked),
    viewItem: failSilently(viewItem),
    selectItem: failSilently(selectItem),
    viewReplacedItemList: failSilently(viewReplacedItemList),
    viewUnavailableItemList: failSilently(unavailableItemsViewItemList),
};
