import { appConfig } from '../../../appConfig';
import { isB2BMode } from './b2bUtils';

const allCustomerGroups = ['CUSTOMER_PRIVATE', 'CUSTOMER_COMPANY', 'CUSTOMER_MEDMERA'] as const;
export type CustomerGroup = (typeof allCustomerGroups)[number];

export const isCustomerGroupSupported = (customerGroup: string): customerGroup is CustomerGroup => {
    return allCustomerGroups.includes(customerGroup as CustomerGroup);
};

export const getAnonymousCustomerGroups = () => {
    const customerGroups: CustomerGroup[] = [];

    if (appConfig.coopUserSettings.isCompany || isB2BMode()) {
        customerGroups.push('CUSTOMER_COMPANY');
    } else {
        customerGroups.push('CUSTOMER_PRIVATE');
    }

    return customerGroups;
};
