/* eslint-disable react/jsx-props-no-spreading */
import { Modal as BaseModal } from '@coop/components';
import classNames from 'classnames';
import { type PropsWithChildren } from 'react';
import * as React from 'react';

import { FlyInContextProvider } from '../../contexts/flyInContext';
import { SiteContextProvider, SiteContextType } from '../../contexts/siteContext';
import { useFlyInNavigationOutsideClickInterceptor } from '../../hooks/useFlyInNavigationInterceptor';
import { useAppSelector } from '../../hooks/useThunkDispatch';
import { ErrorGroup } from '../../store/structureDefinitions/errorsState';
import ErrorGroupNotification from '../molecules/GroupErrorNotifications/ErrorGroupNotification';
import FlyInOverlayLoader from './FlyInOverlayLoader';

export const MODAL_TOGGLE_TIME = 500;

type ModalProps = React.ComponentPropsWithoutRef<typeof BaseModal>;

export const Modal = (props: ModalProps) => {
    return (
        <SiteContextProvider siteContext={SiteContextType.Modal}>
            <BaseModal {...props}>
                {props.children}
                <ModalErrorNotification />
            </BaseModal>
        </SiteContextProvider>
    );
};

type FlyInAlignment = 'left' | 'right';

type FlyInSizes = 250 | 375 | 460 | 510 | 540 | 1000;

export interface FlyInProps extends Omit<ModalProps, 'toggleTime'> {
    sizePx?: FlyInSizes;
    alignment?: FlyInAlignment;
    additionalClasses?: string;
}

// If you want to use a flyin that has it's own route and works with React Router, use FlyInRouted instead
export const FlyIn = ({
    alignment = 'right',
    sizePx = 540,
    ...props
}: React.PropsWithChildren<FlyInProps>) => {
    return (
        <FlyInContextProvider>
            <FlyInInner
                isOpen={props.isOpen}
                afterClose={props.afterClose}
                close={props.close}
                alignment={alignment}
                sizePx={sizePx}
                additionalClasses={props.additionalClasses}
                disableOutsideClick={props.disableOutsideClick}
                asideModalContainerStyle={props.asideModalContainerStyle}
                idForDebug={props.idForDebug}
                renderTrigger={props.renderTrigger}
                afterOutsideClick={props.afterOutsideClick}
                disableOverlay={props.disableOverlay}
                initialFocusOnDialog={props.initialFocusOnDialog}
                animation={props.animation}
                additionalContainerClasses={props.additionalContainerClasses}
            >
                {props.children}
            </FlyInInner>
        </FlyInContextProvider>
    );
};

const FlyInInner = (props: PropsWithChildren<FlyInProps>) => {
    const blockFlyIn = useAppSelector((state) => state.modal.blocked);

    const { outsideClick } = useFlyInNavigationOutsideClickInterceptor(props.close);
    return (
        <Modal
            alignment={props.alignment}
            isOpen={props.isOpen}
            afterClose={props.afterClose}
            close={outsideClick}
            disableOutsideClick={blockFlyIn || props.disableOutsideClick}
            asideModalContainerStyle={{
                ...props.asideModalContainerStyle,
            }}
            additionalClasses={classNames(
                'FlyIn u-flex u-flexDirectionColumn u-heightAll u-textCenter mixinScroll u-bgWhite',
                getFlyInSizeClass(props.sizePx),
                props.additionalClasses,
                props.alignment === 'left' && 'FlyIn--left',
                props.alignment === 'right' && 'FlyIn--right',
            )}
            idForDebug={props.idForDebug}
            renderTrigger={props.renderTrigger}
            afterOutsideClick={props.afterOutsideClick}
            disableOverlay={props.disableOverlay}
            initialFocusOnDialog={props.initialFocusOnDialog}
            toggleTime={MODAL_TOGGLE_TIME}
            animation={props.animation}
            additionalContainerClasses={props.additionalContainerClasses}
        >
            <FlyInOverlayLoader />
            {props.children}
            <ModalErrorNotification />
        </Modal>
    );
};

const getFlyInSizeClass = (sizePx?: FlyInSizes) => {
    switch (sizePx) {
        case 250:
            return 'FlyIn--size250';
        case 375:
            return 'FlyIn--size375';
        case 510:
            return 'FlyIn--size510';
        case 460:
            return 'FlyIn--size460';
        case 1000:
            return 'FlyIn--size1000';
        case 540:
        default:
            return 'FlyIn--size540';
    }
};

const ModalErrorNotification = () =>
    ErrorGroupNotification({
        errorGroup: ErrorGroup.Modal,
    });
