/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */

'use client';

import { useOutsideClick, useSetTimeout } from '@coop/hooks';
import * as React from 'react';

import NotificationContent from './NotificationContent';

const DEFAULT_NOTIFICATION_HIDE_TIME = 2000;

const AutoClosingNotification = React.forwardRef<
    HTMLDivElement,
    Omit<React.ComponentProps<typeof NotificationContent>, 'asModal'> & {
        close: () => void;
        hideAfterMiliseconds?: number;
        hasOutsideClick?: boolean;
    }
>(
    (
        { hideAfterMiliseconds = DEFAULT_NOTIFICATION_HIDE_TIME, hasOutsideClick = true, ...props },
        ref,
    ) => {
        useOutsideClick(
            ref as React.MutableRefObject<HTMLDivElement>,
            props.close,
            !!hasOutsideClick && props.show,
        );

        useSetTimeout(props.show, hideAfterMiliseconds, () => props.close());

        return (
            <NotificationContent
                show={props.show}
                type={props.type}
                iconSlot={props.iconSlot}
                header={props.header}
                message={props.message}
                size={props.size}
                theme={props.theme}
            />
        );
    },
);

export default AutoClosingNotification;
