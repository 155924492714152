/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AnalyticsListOptions } from '../../../../analytics/analyticsHandler';

export interface UnsyncedCartItem {
    productId: string;
    variantId?: string;
    quantity: number;
    analyticsOptions: AnalyticsListOptions | null;
}

export interface UnsyncedCartItemsState {
    items: Array<UnsyncedCartItem>;
}

const getInitialState = (): UnsyncedCartItemsState => ({
    items: [],
});

const slice = createSlice({
    name: 'unsyncedCartItems',
    initialState: getInitialState(),
    reducers: {
        addUnsyncedCartItem: (
            state: UnsyncedCartItemsState,
            action: PayloadAction<UnsyncedCartItem>,
        ) => {
            const existingIndex = state.items.findIndex(
                (item) =>
                    item.productId === action.payload.productId &&
                    item.variantId === action.payload.variantId,
            );

            if (existingIndex !== -1) {
                state.items[existingIndex] = action.payload;
            } else {
                state.items.push(action.payload);
            }
        },
        addUnsyncedCartItems: (
            state: UnsyncedCartItemsState,
            action: PayloadAction<UnsyncedCartItem[]>,
        ) => {
            action.payload.forEach((unsyncedItem) => {
                const existingIndex = state.items.findIndex(
                    (item) =>
                        item.productId === unsyncedItem.productId &&
                        item.variantId === unsyncedItem.variantId,
                );

                if (existingIndex !== -1) {
                    state.items[existingIndex] = unsyncedItem;
                } else {
                    state.items.push(unsyncedItem);
                }
            });
        },
        removeUnsyncedCartItems: (state: UnsyncedCartItemsState) => {
            state.items = [];
        },
    },
});

export const { actions: unsyncedCartItemsActions } = slice;

export default slice.reducer;
