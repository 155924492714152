import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { userApi } from '../api/user/userApi';
import userFlow from '../flow/userFlow';
import { getInitialUserType } from '../store/slices/user/userTypeSlice';
import { useAsyncDispatcher } from './useAsyncDispatcher';
import { useAppDispatch, useAppSelector } from './useThunkDispatch';

const useUserType = () => {
    const userTypeState = useAppSelector((state) => state.user.type);
    const dispatch = useAppDispatch();
    const userState = useAsyncDispatcher(() => dispatch(userFlow.getUserType()));

    const { execute: loadUserType } = userState;
    useEffect(() => {
        if (!userTypeState.isInitialized) {
            loadUserType();
        }
    }, [userTypeState.isInitialized, loadUserType]);

    return userTypeState;
};

export const userQueryKeys = {
    type: ['user:type'],
};

export const useUserTypeQuery = () => {
    const query = useQuery({
        queryFn: userApi.getTypeOfUser,
        queryKey: userQueryKeys.type,
        initialData: getInitialUserType(),
    });

    return query;
};

export { useUserType };
