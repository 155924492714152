import type { FC } from 'react';
import * as React from 'react';
import { createContext, useContext, useMemo } from 'react';

interface IRouterContext {}

const RouterContext = createContext<IRouterContext | null>(null);

const useRouterContext = () => {
    const routerContext = useContext(RouterContext);
    return routerContext;
};

interface RouterContextProviderProps {}

const RouterContextProvider: FC<React.PropsWithChildren<RouterContextProviderProps>> = ({
    children,
}) => {
    const contextValue = useMemo(() => ({}), []);

    return <RouterContext.Provider value={contextValue}>{children}</RouterContext.Provider>;
};

export { RouterContextProvider, useRouterContext };
