/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { isProductionEnvironment } from '../../../../../utility/environments';
import type { ApiEditOrderPayment } from '../../../api/cart/responses/cartResponses';
import type { PriceData } from '../../../models/priceData/priceData';

export interface CartEditOrderSummaryState {
    editOrderSubtotalDifferencePriceData: PriceData;
    editOrderSubtotalPriceData: PriceData;
    editOrderTotalDifferencePriceData: PriceData;
    editOrderTotalPriceData: PriceData;
}

interface CartEditOrderState {
    isInEditOrderMode: boolean;
    editOrderNumber: string | null;
    editOrderTimeout: string | null;
    editOrderPayment?: ApiEditOrderPayment;
    editOrderSummary: CartEditOrderSummaryState;
}

export const getInitialState = (): CartEditOrderState => ({
    isInEditOrderMode: false,
    editOrderNumber: null,
    editOrderTimeout: null,
    editOrderSummary: {
        editOrderSubtotalDifferencePriceData: {
            inclTaxPrice: undefined,
            exclTaxPrice: undefined,
        },
        editOrderSubtotalPriceData: {
            inclTaxPrice: undefined,
            exclTaxPrice: undefined,
        },
        editOrderTotalDifferencePriceData: {
            inclTaxPrice: undefined,
            exclTaxPrice: undefined,
        },
        editOrderTotalPriceData: {
            inclTaxPrice: undefined,
            exclTaxPrice: undefined,
        },
    },
    editOrderPayment: undefined,
});

const cartEditOrderSlice = createSlice({
    name: 'editOrder',
    initialState: getInitialState(),
    reducers: {
        mock: (state) => {
            if (!isProductionEnvironment) {
                state.isInEditOrderMode = true;
                state.editOrderNumber = '123456789';
                state.editOrderTimeout = new Date(
                    new Date().setHours(new Date().getHours() + 1),
                ).toISOString();
            } else {
                // eslint-disable-next-line no-console
                console.error('Not available in production environment');
            }
        },
    },
});

export const { actions: cartEditOrderActions, reducer: cartEditOrderReducer } = cartEditOrderSlice;
