/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { CartSummaryState } from '../../structureDefinitions/cartState';
import { DeliveryMode } from '../../structureDefinitions/storeState';

export const getInitialState = (): CartSummaryState => {
    return {
        postCode: undefined,
        appliedVouchers: [],
        quantity: 0,
        cartId: '',
        deliveryMode: DeliveryMode.homedelivery,
        extraAmountToBeReserved: 0,
        leftToCheckout: 0,
        inCredit: 0,
        tax: 0,
        appliedOrderPromotions: [],
        deliveryFeePriceData: {
            exclTaxPrice: undefined,
            inclTaxPrice: undefined,
        },
        totalPriceData: {
            exclTaxPrice: undefined,
            inclTaxPrice: undefined,
        },
        productDiscountPriceData: {
            exclTaxPrice: undefined,
            inclTaxPrice: undefined,
        },
        totalProductPriceWithDiscounts: {
            exclTaxPrice: undefined,
            inclTaxPrice: undefined,
        },
        totalDepositSum: {
            exclTaxPrice: undefined,
            inclTaxPrice: undefined,
        },
        productSumPriceData: {
            exclTaxPrice: undefined,
            inclTaxPrice: undefined,
        },
        productSumWithoutDiscountPriceData: {
            exclTaxPrice: undefined,
            inclTaxPrice: undefined,
        },
        totalDiscountPriceData: {
            exclTaxPrice: undefined,
            inclTaxPrice: undefined,
        },
        totalPriceExclReservationFee: {
            exclTaxPrice: undefined,
            inclTaxPrice: undefined,
        },
        minimumShoppingValue: {
            exclTaxPrice: undefined,
            inclTaxPrice: undefined,
        },
    };
};

const slice = createSlice({
    name: 'summary',
    initialState: getInitialState(),
    reducers: {
        updateQuantity: (state, action: PayloadAction<number>) => {
            state.quantity = action.payload;
            return state;
        },
    },
});

export const cartSummaryActions = { ...slice.actions };
export default slice.reducer;
