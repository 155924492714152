import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'promos',
    initialState: [] as EcomPromotion[],
    reducers: {},
});

export const { actions, reducer } = slice;

export default reducer;
