import { isBefore } from 'date-fns';

import { appConfig } from '../../../../appConfig';
import { LocalStorageFacade } from '../../../../facades/storage/localStorageFacade';

interface SectionStorage {
    sessionId: string;
    userId: string;
    zipCode: string | undefined;
    pickupPointId: string | undefined;
    isCompany: boolean;
    sectionList: ApiSection[];
    insertedAt: string;
    context: 'b2b' | 'b2c';
}

const SECTION_CACHE_EXPIRE_TIME_MINUTES = 30;

const ECOMMERCE_SECTIONS_KEY = 'Ecommerce:SectionsList';

const ecommerceSectionsLS = {
    get: (
        currentPostalCode: string | undefined,
        currentPickupPointId: string | undefined,
        context: 'b2b' | 'b2c',
    ) => {
        const sectionListStorage =
            LocalStorageFacade.instance.getObject<SectionStorage>(ECOMMERCE_SECTIONS_KEY);

        const now = new Date();

        if (
            !sectionListStorage ||
            !sectionListStorage.insertedAt || // previous visitors wont have that value, so cache will be invalidated for them
            isBefore(
                new Date(sectionListStorage.insertedAt),
                new Date(now.setMinutes(now.getMinutes() - SECTION_CACHE_EXPIRE_TIME_MINUTES)),
            ) // keep invalidating sections every X minutes
        )
            return undefined;

        if (
            sectionListStorage.sessionId === LocalStorageFacade.instance.getSessionId() &&
            sectionListStorage.zipCode === currentPostalCode &&
            sectionListStorage.pickupPointId === currentPickupPointId &&
            sectionListStorage.isCompany === appConfig.coopUserSettings.isCompany &&
            sectionListStorage.userId === appConfig.coopUserSettings.userId &&
            sectionListStorage.context === context
        )
            return sectionListStorage.sectionList;

        LocalStorageFacade.instance.remove(ECOMMERCE_SECTIONS_KEY);
        return undefined;
    },
    save: (
        sectionList: ApiSection[],
        currentPostalCode: string | undefined,
        currentPickupPointId: string | undefined,
        context: 'b2b' | 'b2c',
    ): void => {
        const sectionTreeStorage: SectionStorage = {
            sessionId: LocalStorageFacade.instance.getSessionId(),
            userId: appConfig.coopUserSettings.userId,
            isCompany: appConfig.coopUserSettings.isCompany,
            zipCode: currentPostalCode,
            pickupPointId: currentPickupPointId,
            sectionList,
            insertedAt: new Date().toISOString(),
            context,
        };
        LocalStorageFacade.instance.addObject<SectionStorage>(
            ECOMMERCE_SECTIONS_KEY,
            sectionTreeStorage,
        );
    },
};

export default ecommerceSectionsLS;
