/* eslint-disable max-classes-per-file */
import * as _ from 'underscore';

interface Deffered<T> {
    resolve?: (value: T | PromiseLike<T>) => void;
    reject?: (reason?: unknown) => void;
    promise: Promise<T>;
}

const createDeferred = <T>(): Deffered<T> => {
    let resolveFunc: ((value: T | PromiseLike<T>) => void) | undefined;
    let rejectFunc: ((reason?: unknown) => void) | undefined;

    const promise = new Promise<T>((resolve, reject) => {
        resolveFunc = resolve;
        rejectFunc = reject;
    });

    return {
        resolve: resolveFunc,
        reject: rejectFunc,
        promise,
    };
};

const MAX_CHUNK_IDS_SIZE = 200;

const constructFullCacheKey = (id: string, productionUnit: string) => `${id}___${productionUnit}`;

export class ResourceProvider<TItem, TParams extends unknown[] = unknown[]> {
    private ongoingFetchCalls: FetchContext<TItem>[];

    private cachedItems: Map<string, TItem>;

    private getItems: (
        ids: string[],
        productionUnit: string,
        ...params: TParams
    ) => Promise<TItem[]>;

    protected itemUniqueKey: (item: TItem) => string;

    constructor(
        getItems: (ids: string[], productionUnit: string, ...params: TParams) => Promise<TItem[]>,
        itemUniqueKey: (item: TItem) => string,
    ) {
        this.ongoingFetchCalls = [];
        this.itemUniqueKey = itemUniqueKey;
        this.getItems = getItems;
        this.cachedItems = new Map();
    }

    protected getItemsById(
        _ids: string[],
        productionUnit: string,
        ...params: TParams
    ): Promise<TItem[]> {
        const self = this;
        const deferred = createDeferred<TItem[]>();
        if (!_ids || !_ids.length) {
            return Promise.resolve(<TItem[]>[]);
        }

        const ids = _.uniq(_ids);

        const { resolvedFromCacheIds, idsToFetch } = self.splitItemsToFetch(ids, productionUnit);

        // there is no items to fetch, return cached ones
        if (!idsToFetch.length)
            return Promise.resolve(this.getItemsFromCache(resolvedFromCacheIds, productionUnit));

        const pendingFetchPromise = self.getOrGreateItemFetchpromise(
            idsToFetch,
            productionUnit,
            ...params,
        );

        pendingFetchPromise.deferred.promise.then(
            (items: TItem[]) => {
                self.insertItems(items, productionUnit);
                self.removeFetchPromise(pendingFetchPromise);
                deferred.resolve?.(this.getItemsFromCache(ids, productionUnit));
            },
            () => {
                self.removeFetchPromise(pendingFetchPromise);
                deferred.resolve?.(this.getItemsFromCache(resolvedFromCacheIds, productionUnit));
            },
        );

        // eslint-disable-next-line no-plusplus
        pendingFetchPromise.queuePosition++;
        // debounce this baby to minimize number of calls
        pendingFetchPromise.debouncedFetch.apply(self);
        return deferred.promise;
    }

    insertItems(items: TItem[], productionUnit: string) {
        const self = this;
        if (!items || !items.length) return;

        _.each(items, (item) => {
            self.cachedItems.set(
                constructFullCacheKey(this.itemUniqueKey(item), productionUnit),
                item,
            );
        });
    }

    getItemsFromCache(itemIds: string[], productionUnit: string) {
        return itemIds.map((id) => this.getItemFromCache(id, productionUnit)).filter(Boolean);
    }

    getItemFromCache(id: string, productionUnit: string) {
        return this.cachedItems.get(constructFullCacheKey(id, productionUnit));
    }

    private splitItemsToFetch(ids: string[], productionUnit: string) {
        const self = this;

        const result = ids.reduce(
            (acc, id) => {
                const cachedItem = self.cachedItems.get(constructFullCacheKey(id, productionUnit));
                if (cachedItem) {
                    return {
                        ...acc,
                        resolvedFromCacheIds: [...acc.resolvedFromCacheIds, id],
                    };
                }
                return {
                    ...acc,
                    idsToFetch: [...acc.idsToFetch, id],
                };
            },
            { resolvedFromCacheIds: [], idsToFetch: [] } as {
                resolvedFromCacheIds: string[];
                idsToFetch: string[];
            },
        );

        return result;
    }

    private getOrGreateItemFetchpromise(
        idsToFetch: string[],
        productionUnit: string,
        ...params: TParams
    ): FetchContext<TItem> {
        const self = this;

        if (!self.ongoingFetchCalls) {
            self.ongoingFetchCalls = [];
        }

        let pendingFetchPromise = self.ongoingFetchCalls.find((ongoingFetchCall) => {
            return ongoingFetchCall.started === false;
        });
        if (!pendingFetchPromise) {
            pendingFetchPromise = new FetchContext<TItem>();
            pendingFetchPromise.debouncedFetch = _.debounce(() => {
                if (pendingFetchPromise) {
                    pendingFetchPromise.started = true;
                    if (!pendingFetchPromise.itemIds.length) {
                        pendingFetchPromise.deferred.resolve?.([]);
                        return;
                    }

                    const chunks = _.chunk(pendingFetchPromise.itemIds, MAX_CHUNK_IDS_SIZE);
                    const promises = chunks.reduce(
                        (acc, chunkedIds) => [
                            ...acc,
                            self.getItems(chunkedIds, productionUnit, ...params),
                        ],
                        [] as Promise<TItem[]>[],
                    );

                    Promise.all(promises)
                        .then((items) => {
                            if (pendingFetchPromise)
                                pendingFetchPromise.deferred.resolve?.(items.flat());
                        })
                        .catch(() => {
                            if (pendingFetchPromise) {
                                pendingFetchPromise.deferred.reject?.([]);
                            }
                        });
                }
            }, 30);
            self.ongoingFetchCalls.push(pendingFetchPromise);
        }
        _.each(idsToFetch, (itemId) => {
            if (pendingFetchPromise) {
                pendingFetchPromise.itemIds.push(itemId);
            }
        });
        return pendingFetchPromise;
    }

    private removeFetchPromise(pendingFetchPromise: FetchContext<TItem>) {
        const self = this;
        // eslint-disable-next-line no-plusplus, no-param-reassign
        pendingFetchPromise.queuePosition--;
        if (pendingFetchPromise.queuePosition <= 0) {
            self.ongoingFetchCalls = _.reject(self.ongoingFetchCalls, (ongoingFetchCall) => {
                return ongoingFetchCall === pendingFetchPromise;
            });
        }
    }
}

class FetchContext<T> {
    deferred: Deffered<T[]>;

    itemIds: string[];

    started: boolean;

    debouncedFetch: Function;

    queuePosition: number;

    constructor() {
        this.queuePosition = 0;
        this.started = false;
        this.itemIds = [];
        this.deferred = createDeferred<T[]>();
        this.debouncedFetch = () => {};
    }
}
