/* eslint-disable no-param-reassign */
import type { EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { recipesAdapter } from '../../adapters/recipesAdapter';
import type { CartRecipe } from '../../structureDefinitions/cartState';

interface PortionChangePayload {
    recipeId: string;
    portions: number;
}

const slice = createSlice({
    name: 'recipes',
    initialState: recipesAdapter.getInitialState(),
    reducers: {
        removeRecipe: (state: EntityState<CartRecipe>, action: PayloadAction<string>) => {
            recipesAdapter.removeOne(state, action.payload);
        },
        changeRecipePortions: (
            state: EntityState<CartRecipe>,
            action: PayloadAction<PortionChangePayload>,
        ) => {
            recipesAdapter.updateOne(state, {
                id: action.payload.recipeId,
                changes: { portions: action.payload.portions },
            });
        },
    },
});

export const { actions: cartRecipeActions } = slice;

export default slice.reducer;
