export const enum StoreChangeNotificationType {
    assortmentChange = 'assortmentChange',
    expressDeliverySelected = 'expressDeliverySelected',
    deliveryTimeslotSelected = 'deliveryTimeslotSelected',
    pickupTimeslotSelected = 'pickupTimeslotSelected',
    cancelEditOrder = 'cancelEditOrder',
}

export enum FoodRegisteredCompanyModalType {
    registerConfirmation = 'registerConfirmation',
}

export enum WeeklyMenuModalType {
    addToCart = 'weeklyMenuAddToCart',
    confirmation = 'confirmation',
}

export enum LastOrdersModalType {
    buyAgain = 'lastOrderBuyAgain',
}

export enum SavedCartsModalType {
    buyAgain = 'savedCartsBuyAgain',
}

export const enum ReplaceMissingItemsNotificationType {
    itemRemovedFromCart = 'itemRemovedFromCart',
    itemReplaced = 'itemReplaced',
    exitInterruption = 'exitInterruption',
}

export const enum MyOrdersModalType {
    orderReclamation = 'orderReclamation',
}

export const enum EditOrderNotificationType {
    startEditOrder = 'startEditOrder',
}

export const enum ShoppingListNotificationType {
    listDeleteWarning = 'listDeleteWarning',
}

export const enum ShoppingListType {
    addToShoppingList = 'addToShoppingList',
}

export const enum ZoomProductImageType {
    zoomProductImage = 'zoomProductImage',
}

export enum FlyInType {
    SiteNavigation = 'SiteNavigation',
    CncFlyIn = 'CncFlyIn',
    ProductSearchResultFilters = 'ProductSearchResultFilters',
    SustainabilityDeclaration = 'SustainabilityDeclaration',
    SaveCart = 'SaveCart',
    PointShopProductDetails = 'PointShopProductDetails',
    MyReceipts = 'MyReceipts',
    SubscriptionAddressChange = 'SubscriptionAddressChange',
    SubscriptionTimeslotChange = 'SubscriptionTimeslotChange',
    SubscriptionPaymentChange = 'SubscriptionPaymentChange',
    RecipePageFilter = 'RecipePageFilter',
    EcommerceSideMenu = 'EcommerceSideMenu',
    MyProfileMenu = 'MyProfileMenu',
}

export enum StoreModalType {
    StoreSearchMap = 'StoreSearchMap',
    StoreInformation = 'StoreInformation',
}

export enum RecipeModalType {
    RecipeModal = 'RecipeModal',
    RecipeItemsReplacementModal = 'RecipeItemsReplacementModal',
}

export const enum DropdownType {
    Search = 'search',
    MyFavorites = 'myFavorites',
}

export const enum FullOverlayType {
    MobilePrimaryNavigation = 'mobilePrimaryNavigation',
    CustomerServiceScreening = 'customerServiceScreening',
    CustomerServiceForm = 'customerServiceForm',
}

export const enum TimeslotModalType {
    TimeslotExpiration = 'timeslotExpiration',
    TiemslotMaxOrderTimePassed = 'tiemslotMaxOrderTimePassed',
    MobileTimeslotPreview = 'mobileTimeslotPreview',
}

export type ProductModalType = 'ReplaceItemNotification' | 'MaxPromotionUsage';
export type CheckoutModalType = 'ReservationInfo' | PunchoutModalType;
type PunchoutModalType =
    | 'PunchoutInternalReferenceNotification'
    | 'PunchoutContactPhoneNotification';

export type BonusPointsInfoModalType = 'BonusPointsInfo';

export type ModalType =
    | FlyInType
    | DropdownType
    | StoreChangeNotificationType
    | EditOrderNotificationType
    | ShoppingListNotificationType
    | ShoppingListType
    | MyOrdersModalType
    | RecipeModalType
    | ReplaceMissingItemsNotificationType
    | FullOverlayType
    | TimeslotModalType
    | StoreModalType
    | WeeklyMenuModalType
    | FoodRegisteredCompanyModalType
    | ProductModalType
    | ZoomProductImageType
    | LastOrdersModalType
    | PunchoutModalType
    | BonusPointsInfoModalType
    | CheckoutModalType;

export interface ModalState {
    type: ModalType | string | null;
    id: string | null; // if the same multiple components are visible that use the same ModalType, fill this field to differenciate them
    isLoading: boolean;
    blocked: boolean;
    isOpen: boolean;
}
