import classNames from 'classnames';

import styles from './Badge.module.scss';
import type { BadgeColor, BadgeProps, BadgeSize } from './Badge.types';

export const getBadgeClasses = (props: BadgeProps): string => {
    return classNames(
        props.className,
        styles.Badge,
        props.wide ? styles['Badge-wide'] : '',
        getSizeClass(props.size),
        getColorClass(props.color),
    );
};

const getSizeClass = (size: BadgeSize): string => {
    switch (size) {
        case 'xsmall':
            return styles['Badge--xsmall'];
        case 'small':
            return styles['Badge--small'];
        case 'medium':
        default:
            return styles['Badge--medium'];
    }
};

const getColorClass = (color: BadgeColor): string => {
    switch (color) {
        case 'white':
            return styles['Badge--white'];
        case 'ctaGreen':
            return styles['Badge--ctaGreen'];
        case 'greenLight2':
            return styles['Badge--greenLight2'];
        default:
            return styles['Badge--ctaGreen'];
    }
};
