export namespace Cloudinary {
    const IMAGE_MATCH = /^(.+upload)(.*?)(\/v?(?:\d+)?.+)$/;
    // eslint-disable-next-line no-useless-escape
    const DOMAIN_MATCH = /^.*:?[\/\/]?(res\.cloudinary\.com.*)/;

    /**
     * Defaults cloudinary URLs to JPG format and protocoll insensitive
     * @param  {String} url Raw cloudinary url
     * @return {String}     Formated url
     */
    export const sanitize = (url: string, extension?: string) => {
        if (url && url.match(DOMAIN_MATCH)) {
            return (
                url
                    // Remove any transformation
                    .replace(IMAGE_MATCH, '$1$3')
                    // Default to jpg images
                    .replace(/^[^.]+$|\.(?!(png)$)([^.]+$)/, extension ?? '.jpg')
                    // Remove protocol
                    .replace(DOMAIN_MATCH, '//$1')
            );
        }

        return url;
    };

    export const makeImageIdentifierSeoFriendly = (
        imgIdentifier: string,
        altText: string,
    ): string => {
        let id = imgIdentifier;
        if (id) {
            // replace last dot (.) with "/altText.". Eg: /v1442921161/52045.jpg -> /v1442921161/52045/altText.jpg
            id = imgIdentifier.replace(/.([^.]*)$/, `/${encodeURIComponent(altText)}.$1`);
        }
        return id;
    };

    export const makeImagePathSeoFriendly = (imgPath: string): string => {
        let path = imgPath;
        if (!path) {
            return path;
        }
        path = path.replace('/image/upload', '/images');
        path = path.replace('/video/upload', '/videos');
        path = path.replace('/raw/upload', '/files');
        return path;
    };

    export const imageIdentifier = (url: string) => {
        const match = url.match(IMAGE_MATCH);

        return (match && match[3]?.replace(' ', '%20').replace(/\/$/, '')) || undefined;
    };

    export const imagePath = (url: string) => {
        const match = url.match(IMAGE_MATCH);

        return (match && `${match[1].replace(/\/$/, '')}/`) || undefined;
    };
}
