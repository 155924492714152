import getDirectMessageRelayAxiosClient from '../clients/directMessageRelayAxiosClient';

export const messageRelayDirectApi = {
    sendVerification: async (token: string, phoneNumber: string) => {
        const response = await getDirectMessageRelayAxiosClient(token).post<boolean>(
            'verification/phone-number',
            {
                phoneNumber,
            },
        );

        return response.headers['x-seconds-left'];
    },

    confirmVerification: async (token: string, code: string) => {
        await getDirectMessageRelayAxiosClient(token).put<boolean>('verification/phone-number', {
            code,
        });
    },
};
