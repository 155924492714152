import { sendInteractionEvent } from '../utils';

const sendGaSubscriptionInteractionEvent = (week: number | null, eventLabel: string) => {
    let interval;

    if (week) {
        if (week === 1) {
            interval = 'Varje vecka';
        } else if (week === 2) {
            interval = 'Varannan vecka';
        } else if (week === 3) {
            interval = 'Var tredje vecka';
        } else if (week === 4) {
            interval = 'Var fjärde vecka';
        }
    }

    sendInteractionEvent({
        eventAction: 'Checkout - Leverans - Abonnemang',
        eventLabel,
        subscriptionInterval: interval,
    });
};

const sendGaSubscriptionInteractionStart = (week: number | null) => {
    sendGaSubscriptionInteractionEvent(week, 'Starta abonnemang');
};

const sendGaSubscriptionInteractionEdit = (week: number | null) => {
    sendGaSubscriptionInteractionEvent(week, 'Ändra abonnemang');
};

const sendGaSubscriptionInteractionCancel = (week: number | null) => {
    sendGaSubscriptionInteractionEvent(week, 'Avsluta abonnemang');
};

const sendGaSubscriptionInteractionCancelConfirm = () => {
    sendGaSubscriptionInteractionEvent(null, 'Avsluta abonnemang - Ja');
};

const sendGaSubscriptionInteractionCancelCancel = () => {
    sendGaSubscriptionInteractionEvent(null, 'Avsluta abonnemang - Avbryt');
};

const sendGaSubscriptionInteractionOpen = () => {
    sendGaSubscriptionInteractionEvent(null, 'Öppna dropdown');
};

const sendGaSubscriptionInteractionClose = () => {
    sendGaSubscriptionInteractionEvent(null, 'Stäng dropdown');
};

const subscriptionTracking = {
    start: sendGaSubscriptionInteractionStart,
    edit: sendGaSubscriptionInteractionEdit,
    cancel: sendGaSubscriptionInteractionCancel,
    cancelConfirm: sendGaSubscriptionInteractionCancelConfirm,
    cancelCancel: sendGaSubscriptionInteractionCancelCancel,
    open: sendGaSubscriptionInteractionOpen,
    close: sendGaSubscriptionInteractionClose,
};

export default subscriptionTracking;
