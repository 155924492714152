import type { ApiRecipe } from '../../../../models/recipe/recipeModel';
import { Helpers } from '../../../../utility/helpers';
import type {
    RecipeCalculationType,
    RecipeModalTab,
    RecipePrioritizationType,
} from '../../../recipe/models';
import { sendEvent, sendInteractionEvent } from '../utils';
import type {
    ModalPopupGaEvent,
    RecipeEcommerceInteractionGaEvent,
    RecipeInteractionGaEvent,
    RecipeModalInteractionGaEvent,
    RecipeSubstituteProductGaEvent,
} from './recipesTrackingTypes';

const sendModalPopupEvent = (
    recipe: ApiRecipe,
    modalTabToDisplay: RecipeModalTab,
    portions?: number,
) => {
    if (recipe) {
        const { name, externalId, recipePart, cookingTime, totalTime } = recipe;
        const actualCookingTime = totalTime || cookingTime;
        const amountOfIngredients =
            recipePart?.reduce((acc, curr) => acc + curr.ingredients.length, 0) || 0;

        sendEvent<ModalPopupGaEvent>({
            event: 'modalPopup',
            recipeName: name,
            modalName: `Receptmodal - ${name}`,
            modalTitle: `${window.location.pathname}receptmodal/${Helpers.slugify(name)}`,
            recipeId: externalId.toString(),
            recipeCookingTime: actualCookingTime,
            recipeStartTab: modalTabToDisplay === 2 ? 'Varor' : 'Recept',
            recipePrefilledPortions: portions?.toString() || 'undefined',
            recipeAmountOfIngredients: amountOfIngredients.toString(),
        });
    }
};

const sendRecipeBuyInteractionEvent = (recipeName: string, recipeId: string, portions?: number) => {
    sendInteractionEvent<RecipeEcommerceInteractionGaEvent>({
        event: 'interaction',
        eventLabel: 'Köp varor',
        eventAction: 'Receptmodal',
        recipeName,
        recipeId,
        portions: portions?.toString() || 'undefined',
    });
};

const sendRecipePortionChangeInteractionEvent = (
    recipeName: string,
    recipeId: string,
    previousPortions?: number,
    portions?: number,
) => {
    let eventLabel = '';
    if (portions !== undefined && previousPortions !== undefined) {
        if (previousPortions > portions) {
            eventLabel = 'Minska portioner';
        } else {
            eventLabel = 'Öka portioner';
        }
    }

    sendInteractionEvent<RecipeEcommerceInteractionGaEvent>({
        event: 'interaction',
        eventLabel,
        eventAction: 'Receptmodal',
        recipeName,
        recipeId,
        portions: portions?.toString() || 'undefined',
    });
};

const sendModalButtonClickEvent = (recipeName: string, recipeId: string) => {
    sendInteractionEvent<RecipeModalInteractionGaEvent>({
        event: 'interaction',
        eventLabel: 'Öppna receptmodal - Knapp',
        eventAction: 'Köpklara recept',
        recipeName,
        recipeId,
    });
};

const sendModalLinkClickEvent = (recipeName: string, recipeId: string) => {
    sendInteractionEvent<RecipeModalInteractionGaEvent>({
        event: 'interaction',
        eventLabel: 'Öppna receptmodal - Bild',
        eventAction: 'Köpklara recept',
        recipeName,
        recipeId,
    });
};

const sendRecipeCalculationInteractionEvent = (
    recipeName: string,
    recipeId: string,
    calculationType: RecipeCalculationType,
    prioritization: RecipePrioritizationType | null,
    portions?: number,
) => {
    let eventLabel = '';

    switch (calculationType) {
        case 'price':
            eventLabel = 'Ändra kalkyleringstyp - Billigt';
            break;
        case 'waste':
            if (prioritization === 'organic') {
                eventLabel = 'Ändra kalkyleringstyp - Eko';
                break;
            }
            eventLabel = 'Ändra kalkyleringstyp - Bästa val';
            break;
        default:
            break;
    }

    sendInteractionEvent<RecipeEcommerceInteractionGaEvent>({
        event: 'interaction',
        eventLabel,
        eventAction: 'Receptmodal',
        recipeName,
        recipeId,
        portions: portions?.toString() || 'undefined',
    });
};

const sendCustomInteractionEvent = <T extends {}>(eventData: T) => {
    sendEvent({
        event: 'interaction',
        eventCategory: 'Recept',
        ...eventData,
    });
};

const sendCustomRecipeInteractionEvent = (
    eventAction: string,
    eventLabel: string,
    recipeName: string,
) => {
    sendCustomInteractionEvent<RecipeInteractionGaEvent>({
        event: 'interaction',
        eventAction,
        eventLabel,
        recipeName,
    });
};

const sendRecipeLinkInteractionEvent = (
    recipeName: string,
    recipeId: string,
    portions?: number,
) => {
    sendInteractionEvent<RecipeEcommerceInteractionGaEvent>({
        event: 'interaction',
        eventLabel: 'Gå till receptsidan',
        eventAction: 'Receptmodal',
        recipeName,
        recipeId,
        portions: portions?.toString() || 'undefined',
    });
};

const sendSubstituteProductEvent = (
    recipeName: string,
    recipeId: string,
    productId: string,
    productName: string,
) => {
    sendInteractionEvent<RecipeSubstituteProductGaEvent>({
        event: 'interaction',
        eventLabel: 'Byt Vara',
        eventAction: 'Köpklara recept',
        recipeName,
        recipeId,
        productId,
        productName,
    });
};

export default {
    modalPopup: sendModalPopupEvent,
    modalButtonClick: sendModalButtonClickEvent,
    modalLinkClick: sendModalLinkClickEvent,
    buyRecipe: sendRecipeBuyInteractionEvent,
    portionChange: sendRecipePortionChangeInteractionEvent,
    calculationChange: sendRecipeCalculationInteractionEvent,
    linkClick: sendRecipeLinkInteractionEvent,
    substituteProduct: sendSubstituteProductEvent,
    interaction: sendCustomRecipeInteractionEvent,
};
