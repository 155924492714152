import { isB2BMode } from '../../../utils/b2bUtils';
import { sendEvent } from '../../utils';

const getCustomerSegment = () => {
    return isB2BMode() ? 'Företag' : 'Privat';
};

const showAllPointsHistory = () =>
    sendEvent({
        event: 'interaction',
        eventCategory: 'Poängtrappan',
        eventAction: 'Poänghistorik',
        customerSegment: getCustomerSegment(),
    });

const showWithdrawnPointsHistory = () =>
    sendEvent({
        event: 'interaction',
        eventCategory: 'Poängtrappan',
        eventAction: 'Uttagna poäng',
        customerSegment: getCustomerSegment(),
    });

const togglePositiveTransaction = () =>
    sendEvent({
        event: 'interaction',
        eventCategory: 'Poängtrappan',
        eventAction: 'Utökad flik insamlade poäng',
        customerSegment: getCustomerSegment(),
    });

const toggleNegativeTransaction = () =>
    sendEvent({
        event: 'interaction',
        eventCategory: 'Poängtrappan',
        eventAction: 'Utökad flik uttagna poäng',
        customerSegment: getCustomerSegment(),
    });

export default {
    showAllPointsHistory,
    showWithdrawnPointsHistory,
    togglePositiveTransaction,
    toggleNegativeTransaction,
};
