import { appConfig } from '../../../appConfig';
import { Helpers } from '../../../utility/helpers';

const NEOID_HEADER = 'neoId';
const SESSION_STORAGE_NEO_IDENTITY = 'user.identity.neo';
/**
 * This will return neoId if it exists as a query parameter.
 * If user is not authenticated, it will store neoId to session storage.
 * If user doesn't have it in query parameter and is still not authenticated it will return neoId from session storage.
 */
export const getNeoId = () => {
    const neoId = Helpers.getParameterByName(NEOID_HEADER);

    if (!appConfig.coopUserSettings.isAuthenticated) {
        if (neoId) {
            window.sessionStorage.setItem(SESSION_STORAGE_NEO_IDENTITY, neoId);
        } else {
            return window.sessionStorage.getItem(SESSION_STORAGE_NEO_IDENTITY);
        }
    }

    return neoId;
};
