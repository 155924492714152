import type { ListenerMiddlewareInstance } from '@reduxjs/toolkit';

import cartItemsSelectors from '../../../../cart/selectors/cartItemsSelectors';
import { cartActions } from '../../slices/cart/cartItemsSlice';
import type { RootState } from '../../structureDefinitions/cartState';

export const updateCartItemGaTrackingMiddleware = (
    middlewareInstance: ListenerMiddlewareInstance<RootState>,
) => {
    middlewareInstance.startListening({
        actionCreator: cartActions.updateCartItem,
        effect: async (action, listenerApi) => {
            listenerApi.cancelActiveListeners();

            const cartState = listenerApi.getState();
            const cartItems = cartItemsSelectors.selectAllCartItems(cartState);
            if (cartItems) {
                const fullCart = cartItems.map((item) => {
                    const trackItem: GaEcommerceItemMinimal = {
                        id: item.cartItemData.productId,
                        quantity: item.cartItemData.quantity,
                    };
                    if (item.cartItemData.variantId) {
                        trackItem.variantId = item.cartItemData.variantId;
                    }
                    return trackItem;
                });
                // eslint-disable-next-line func-names
                window.dataLayer.push(function (): void {
                    // @ts-ignore
                    this.set('ecommerce_all_items', null);
                    // @ts-ignore
                    this.set('ecommerce_all_items', fullCart);
                });
            }
        },
    });
};
