/* eslint-disable no-console */
import { appConfig } from '../../../../appConfig';
import { Cookies } from '../../../../utility/cookies';

const STORE_CONTEXT_COOKIE = 'coop-storeContext';
let storeContextCache: StoreContextCookie | undefined;

interface StoreContextCookie {
    zipCode?: string | null;
    productionUnitId: string;
    storeName?: string | null;
    pickupPointId: string | null;
}

const storeContext = {
    get: (): StoreContextCookie => {
        const storeContextCookie = Cookies.read(STORE_CONTEXT_COOKIE);
        if (storeContextCookie) {
            try {
                const decodedCookie = decodeURIComponent(escape(atob(storeContextCookie)));
                const storeContextSettings = JSON.parse(decodedCookie) as StoreContextCookie;
                return storeContextSettings;
                // eslint-disable-next-line no-empty
            } catch {}
        }

        return storeContext.getDefaultStoreContext();
    },

    getCached() {
        if (!storeContextCache) {
            storeContextCache = storeContext.get();
        }

        return storeContextCache;
    },
    /** Please use carefully! */
    set: (context: StoreContextCookie) => {
        if (!context) {
            Cookies.create(
                STORE_CONTEXT_COOKIE,
                btoa(JSON.stringify(storeContext.getDefaultStoreContext())),
                90,
            );
            storeContextCache = undefined;
        } else {
            const storeContextCookie: StoreContextCookie = {
                productionUnitId: context.productionUnitId,
                pickupPointId: context.pickupPointId || null,
                storeName: context.storeName || null,
                zipCode: context.zipCode || null,
            };
            Cookies.create(STORE_CONTEXT_COOKIE, btoa(JSON.stringify(storeContextCookie)), 90);
            storeContextCache = storeContextCookie;
        }
    },

    getDefaultStoreContext: () => {
        const context: StoreContextCookie = {
            productionUnitId: appConfig.coopSettings.ecommerce.defaultStoreId,
            pickupPointId: null,
            storeName: null,
            zipCode: null,
        };
        return context;
    },
};

export default storeContext;
