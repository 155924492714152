import { getCancellationToken } from '../../common/api/utils';
import getDirectPersonalizationSearchAxiosClient, {
    prepareRequestParams,
} from './directPersonalizationAxiosClient';
import type {
    ApiLoopAutocompleteRequest,
    ApiLoopGlobalSearchRequest,
    ApiLoopSearchByAttributeRequest,
    ApiLoopSearchRequest,
} from './searchRequests';

const searchLoyaltyProducts = async ({
    loopSearchRequest,
}: {
    loopSearchRequest: ApiLoopSearchRequest;
}): Promise<ApiLoopSearchResponse<ApiLoopLoyaltyProduct>> => {
    const client = getDirectPersonalizationSearchAxiosClient();

    const response = await client.post<ApiLoopSearchResponse<ApiLoopLoyaltyProduct>>(
        '/search/pointshop',
        loopSearchRequest,
        {
            params: {
                channel: 'Coopse',
            },
        },
    );
    return response.data;
};

const searchRecipes = async ({
    loopSearchRequest,
}: {
    loopSearchRequest: ApiLoopSearchRequest;
}): Promise<ApiLoopRecipeSearchResponse> => {
    const client = getDirectPersonalizationSearchAxiosClient();

    const response = await client.post<ApiLoopRecipeSearchResponse>(
        '/search/recipes',
        loopSearchRequest,
    );
    return response.data;
};

const searchProducts = async (
    loopSearchRequest: ApiLoopSearchRequest,
    storeId: string,
    abortSignal: AbortSignal | undefined,
    searchService: string | null,
    customerGroups?: string[],
    device?: string,
    quickSearch?: boolean,
): Promise<ApiLoopSearchResponse<ApiLoopProduct>> => {
    const token = getCancellationToken(abortSignal);
    const client = getDirectPersonalizationSearchAxiosClient();

    const response = await client.post<ApiLoopSearchResponse<ApiLoopProduct>>(
        '/search/products',
        loopSearchRequest,
        {
            cancelToken: token,
            params: prepareRequestParams(
                storeId,
                customerGroups,
                device,
                quickSearch,
                searchService,
            ),
        },
    );
    return response.data;
};

const searchArticles = async (
    loopSearchRequest: ApiLoopSearchRequest,
    abortSignal?: AbortSignal,
): Promise<ApiLoopSearchResponse<ApiLoopSearchArticle>> => {
    const token = getCancellationToken(abortSignal);
    const client = getDirectPersonalizationSearchAxiosClient();
    const response = await client.post<ApiLoopSearchResponse<ApiLoopSearchArticle>>(
        '/search/articles',
        loopSearchRequest,
        { cancelToken: token },
    );
    return response.data;
};
const searchPointshop = async (
    loopSearchRequest: ApiLoopSearchRequest,
    abortSignal?: AbortSignal,
): Promise<ApiLoopSearchResponse<ApiLoopLoyaltyProduct>> => {
    const token = getCancellationToken(abortSignal);
    const client = getDirectPersonalizationSearchAxiosClient();
    const response = await client.post<ApiLoopSearchResponse<ApiLoopLoyaltyProduct>>(
        '/search/pointshop',
        loopSearchRequest,
        { cancelToken: token },
    );
    return response.data;
};

const getLoyaltyProductsById = async (
    ids: number[],
): Promise<ApiLoopSearchResponse<ApiLoopLoyaltyProduct>> => {
    const client = getDirectPersonalizationSearchAxiosClient(false);
    const response = await client.post<ApiLoopSearchResponse<ApiLoopLoyaltyProduct>>(
        '/search/pointshops/by-id',
        ids,
    );
    return response.data;
};

const searchStores = async (
    loopSearchRequest: ApiLoopSearchRequest,
    abortSignal?: AbortSignal,
): Promise<ApiLoopSearchResponse<ApiLoopSearchStore>> => {
    const token = getCancellationToken(abortSignal);
    const client = getDirectPersonalizationSearchAxiosClient();
    const response = await client.post<ApiLoopSearchResponse<ApiLoopSearchStore>>(
        '/search/stores',
        loopSearchRequest,
        { cancelToken: token },
    );
    return response.data;
};

const searchGlobal = async (
    loopGlobalSearchRequest: ApiLoopGlobalSearchRequest,
    storeId: string,
    abortSignal?: AbortSignal,
    customerGroups?: string[],
    device?: string,
    quickSearch?: boolean,
): Promise<ApiLoopGlobalSearchResponse> => {
    const token = getCancellationToken(abortSignal);
    const client = getDirectPersonalizationSearchAxiosClient();

    const response = await client.post<ApiLoopGlobalSearchResponse>(
        '/search/global',
        loopGlobalSearchRequest,
        {
            cancelToken: token,
            params: prepareRequestParams(storeId, customerGroups, device, quickSearch),
        },
    );
    return response.data;
};

const searchByAttribute = async (
    loopSearchRequest: ApiLoopSearchByAttributeRequest,
    storeId: string,
    customerGroups?: string[],
    device?: string,
): Promise<ApiLoopSearchResponse<ApiLoopProduct>> => {
    const client = getDirectPersonalizationSearchAxiosClient();

    const response = await client.post<ApiLoopSearchResponse<ApiLoopProduct>>(
        '/search/entities/by-attribute',
        loopSearchRequest,
        { params: prepareRequestParams(storeId, customerGroups, device, false) },
    );
    return response.data;
};

const autoComplete = async (
    loopAutocompleteRequest: ApiLoopAutocompleteRequest,
    abortSignal: AbortSignal | undefined,
    storeId: string,
    searchService: string | null,
    customerGroups?: string[],
    device?: string,
) => {
    const token = getCancellationToken(abortSignal);
    const client = getDirectPersonalizationSearchAxiosClient();
    const response = await client.post<ApiLoopAutocompleteResponse>(
        '/search/auto-complete',
        loopAutocompleteRequest,
        {
            cancelToken: token,
            params: prepareRequestParams(storeId, customerGroups, device, false, searchService),
        },
    );
    return response.data;
};

export const searchDirectApi = {
    getLoyaltyProductsById,
    searchRecipes,
    searchLoyaltyProducts,
    searchProducts,
    searchArticles,
    searchPointshop,
    searchStores,
    searchGlobal,
    searchByAttribute,
    autoComplete,
};
