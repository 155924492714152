import { Cookies } from '../../../../utility/cookies';
import { ALERTS_DISMISSED_COOKIE_PREFIX } from './cookieKeys';

const COOKIE_TIMEOUT_DAYS = 3;

const getAlertKey = (alertId: string) => {
    return `${ALERTS_DISMISSED_COOKIE_PREFIX}${alertId}`;
};

const alertsDismissed = {
    isDismissed: (alertId: string) => {
        const cookieName = getAlertKey(alertId);
        const rawCookie = Cookies.read(cookieName);
        return rawCookie === 'true';
    },
    dismiss: (alertId: string) => {
        const cookieName = getAlertKey(alertId);
        Cookies.create(cookieName, 'true', COOKIE_TIMEOUT_DAYS);
    },
};

export default alertsDismissed;
