import { NoticeRibbonType } from '../../../common/components/atoms/NoticeRibbon';
import type { AlertLevel } from './HeaderAlerts.types';
import { AlertLevels } from './HeaderAlerts.types';

export const isAlertLevelSupported = (num: number): num is AlertLevel => {
    return AlertLevels.includes(num as AlertLevel);
};

export const getNoticeTypeForAlertLevel = (level?: AlertLevel) => {
    switch (level) {
        case 4:
            return NoticeRibbonType.alertLevel4;
        case 3:
            return NoticeRibbonType.alertLevel3;
        case 2:
            return NoticeRibbonType.alertLevel2;
        case 1:
            return NoticeRibbonType.alertLevel1;
        default: {
            // eslint-disable-next-line no-console
            console.error('Unsupported alert level. Using Level1 as fallback');
            return NoticeRibbonType.alertLevel1;
        }
    }
};
