import type { AnalyticsInteractionClickEvent } from '../../../analytics/analyticsHandler';
import { Browser } from '../../../utility/browser';

export const sendGaNavigationInteractionEvent = (currRoute: string, clickedRoute: string) => {
    const gaEvent = <AnalyticsInteractionClickEvent>(
        Browser.createNewEvent('ga:interactionWithoutCustomerSegment')
    );
    gaEvent.eventCategory = 'Ecommerce';
    gaEvent.eventAction = `Checkout - ${currRoute}`;
    gaEvent.eventLabel = clickedRoute;
    window.dispatchEvent(gaEvent);
};

export const sendGaInteractionWithoutCustomerSegment = (
    category: string,
    action: string,
    label: string,
) => {
    const gaEvent = <AnalyticsInteractionClickEvent>(
        Browser.createNewEvent('ga:interactionWithoutCustomerSegment')
    );
    gaEvent.eventCategory = category;
    gaEvent.eventAction = action;
    gaEvent.eventLabel = label;
    window.dispatchEvent(gaEvent);
};
