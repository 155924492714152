import { useEffect, useRef } from 'react';

export const useElementEvent = (
    target: EventTarget | null,
    event: string,
    callback: () => void,
    runImmediately: boolean,
    addOptions: boolean | AddEventListenerOptions | undefined = undefined,
    when: boolean = true,
) => {
    const addOptionsRef = useRef(addOptions);

    useEffect(() => {
        if (when && target) {
            // Add event listener
            target.addEventListener(event, callback, addOptionsRef.current);

            // Call handler right away so state gets updated with initial window size
            if (runImmediately) {
                callback();
            }
        }
        // Remove event listener on cleanup
        return () => target?.removeEventListener(event, callback);
    }, [when, target, event, runImmediately, callback]);
};
