import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { isB2BWindowLocation } from '../../utils/b2bUtils';
import { HEADER_SITE_MODE } from '../headerKeys';
import type { CustomErrorInterceptor } from './localApiAxiosErrorInterceptors';
import {
    defaultApiErrorInterceptor,
    localApiExceptionInterceptor,
    localApiOpenIdInterceptor,
} from './localApiAxiosErrorInterceptors';

const internalCreateClient = <TError = unknown>(
    config?: AxiosRequestConfig,
    customErrorInterceptors?: CustomErrorInterceptor<TError>[],
) => {
    const clientInstance = axios.create(config);

    clientInstance.interceptors.response.use((response) => {
        return response;
    }, defaultApiErrorInterceptor(customErrorInterceptors));

    return clientInstance;
};

/**
 * Direct APIs are the ones that don't go through our servers, but they go directly from the browser to external APIs
 */
const createDirectApiClient = <TApiError = unknown>(
    config?: AxiosRequestConfig,
    customErrorInterceptors?: CustomErrorInterceptor<TApiError>[],
) => {
    const clientInstance = internalCreateClient(config, customErrorInterceptors);
    return clientInstance;
};

/**
 * Local API is the one that goes through our coop.se servers
 */
const createLocalApiClient = <TError = unknown>(
    config?: AxiosRequestConfig,
    customErrorInterceptors?: CustomErrorInterceptor<TError>[],
) => {
    let errorInterceptors: CustomErrorInterceptor<TError>[] = [
        localApiExceptionInterceptor,
        localApiOpenIdInterceptor,
    ];
    if (customErrorInterceptors) {
        errorInterceptors = [...errorInterceptors, ...customErrorInterceptors];
    }

    const newConfig = {
        ...(config || {}),
        headers: {
            ...(config?.headers || {}),
            [HEADER_SITE_MODE]: isB2BWindowLocation() ? 'company' : 'private',
        },
    };

    const clientInstance = internalCreateClient(newConfig, errorInterceptors);
    return clientInstance;
};

const axiosClientFactory = {
    createDirectApiClient,
    createLocalApiClient,
};

export default axiosClientFactory;
