/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import persistance from '../../../persistance/persistance';
import type { StoreState } from '../../structureDefinitions/storeState';
import { DeliveryMode } from '../../structureDefinitions/storeState';
import type {
    SelectHomeDeliveryStorePayload,
    SelectPickupStorePayload,
    SyncStorePayload,
} from './storeActions';
import {
    selectHomeDeliveryStore,
    selectPickupStore,
    syncStore,
    syncStoreWithCookie,
} from './storeActions';

const getInitialState = (): StoreState => {
    const storeContext = persistance.storeContext.get();
    let deliveryMode: DeliveryMode | undefined;

    if (!storeContext.zipCode && storeContext.pickupPointId) {
        deliveryMode = DeliveryMode.pickup;
    }

    if (storeContext.zipCode && !storeContext.pickupPointId) {
        deliveryMode = DeliveryMode.homedelivery;
    }

    return {
        deliveryMode,
        selectedStore: undefined,
        selectedZipCode: storeContext.zipCode || undefined,
        selectedPickupPointId: storeContext.pickupPointId || undefined,
        productionUnitId: storeContext.productionUnitId,
        storeName: storeContext.storeName || undefined,
    };
};

const slice = createSlice({
    name: 'store',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            selectPickupStore,
            (state: StoreState, action: PayloadAction<SelectPickupStorePayload>) => {
                state.deliveryMode = DeliveryMode.pickup;
                state.selectedStore = action.payload.store;
                state.selectedZipCode = undefined;
                state.selectedPickupPointId = action.payload.pickupPointId;
                state.productionUnitId = action.payload.store.productionUnitIdentifier;
                state.storeName = action.payload.store.name;
            },
        );
        builder.addCase(
            selectHomeDeliveryStore,
            (state: StoreState, action: PayloadAction<SelectHomeDeliveryStorePayload>) => {
                state.deliveryMode = DeliveryMode.homedelivery;
                state.selectedStore = action.payload.store;
                state.selectedPickupPointId = undefined;
                state.selectedZipCode = action.payload.postalCode;
                state.productionUnitId = action.payload.store.productionUnitIdentifier;
                state.storeName = action.payload.store.name || undefined;
            },
        );
        /** Sync with direct hybris calls */
        builder.addCase(syncStore, (state: StoreState, action: PayloadAction<SyncStorePayload>) => {
            state.deliveryMode = action.payload.deliveryMode || undefined;
            state.selectedPickupPointId = action.payload.pickupPointId || undefined;
            state.productionUnitId = action.payload.productionUnitId;
            state.selectedZipCode = action.payload.postalCode || undefined;
            state.storeName = action.payload.storeName || undefined;
        });
        /** Sync with cookie from episerver/backend */
        builder.addCase(syncStoreWithCookie, (state: StoreState) => {
            const cookieState = getInitialState();
            state.deliveryMode = cookieState.deliveryMode || undefined;
            state.selectedPickupPointId = cookieState.selectedPickupPointId || undefined;
            state.productionUnitId = cookieState.productionUnitId;
            state.selectedZipCode = cookieState.selectedZipCode || undefined;
            state.storeName = cookieState.storeName || undefined;
        });
    },
});

export const storeActions = {
    ...slice.actions,
    selectPickupStore,
    selectHomeDeliveryStore,
    syncStore,
    syncStoreWithCookie,
};

export default slice.reducer;
