/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-classes-per-file */
import { appConfig } from '../appConfig';

type EventNames =
    | 'transaction'
    // so it doesnt collide with legacy checkout event
    | 'checkout2'
    | 'purchase'
    | 'recipeClick'
    | 'interaction'
    | 'virtualPageView'
    | 'nonInteraction'
    | 'refund'
    | 'emptyCart'
    | 'modalPopup'
    | 'error';

export interface AnalyticsInteractionClickEvent extends Event {
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    filterCombination?: string;
    callback: Function;
    additionalData?: object;
}

export interface NonInteractionUserEvent extends Event {
    pointShopPoints: string;
    utbud: string; // selection support
    dimension23: string; // antalKop
}

interface InteractionFieldObject {
    event: string;
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    eventValue?: string;
    filterCombination?: string;
    eventCallback?: Function;
}

interface InteractionUserFieldObject extends InteractionFieldObject {
    pointShopPoints: string;
    utbud: string;
    dimension23: string;
}

/**
 * Those properties are mostly set in Epi blocks, see IGaPromoData interface on backend
 * If product views are loaded via AJAX, the initial render will only have list name, and the item position will be filled once fetching is done.
 * */
export interface AnalyticsListOptions {
    list?: string; // list name, if available
    listPosition?: number; // index of a list, if multiple lists exist (i.e. start page), 0-based
    position?: number; // index of item (product) in a list, 0-based
}

export interface AnalyticsEmptyCartEvent extends Event {
    eventAction: string;
}

export interface AnalyticsVoucherInteractionEvent extends Event {
    campaignCode: string;
    eventLabel: string;
}

interface ActionFieldObject {
    event?: EventNames;
    eventCategory?: string;
    eventLabel?: string;
    eventAction?: string;
    ecommerce?: EcommerceObject;
}

interface EcommerceObject {
    impressions?: ProductFieldObject[];
    detail?: DetailFieldObject;
    refund?: RefundFieldObject;
}

interface RefundFieldObject {
    actionField: OrderActionFieldObject;
}

interface DetailFieldObject {
    products: ProductDetailFieldObject[];
}

interface ProductDetailFieldObject {
    id: string;
    price: string;
    EAN: string;
    name: string;
    brand: string;
    category: string;
    variant: string;
    dimension89: string;
}

export interface ProductFieldObject extends BaseProductObject {
    list?: string;
}

interface BaseProductObject {
    id: string;
    price: number | string;
    variant: string;
    quantity?: number;
    EAN?: string;
    name: string;
    brand: string;
    category: string;
    position?: number | string;
}

interface OrderActionFieldObject {
    id: string;
}

interface VoucherInteractionObject {
    event: EventNames;
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
    campaignCode: string;
}

interface SubscriptionInteractionObject {
    event: EventNames;
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
    subscriptionInterval: string;
    checkoutStep: string;
}

interface DeliveryInfoObject {
    event: EventNames;
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
    checkoutStep: string;
}

export class AnalyticsHandler {
    private static initialized = false;

    public static initialize() {
        if (AnalyticsHandler.initialized) return;
        AnalyticsHandler.initialized = true;
        window.addEventListener(
            'ga:interactionWithoutCustomerSegment',
            AnalyticsHandler.interactionClickWithoutCustomerSegment as any,
        );
        window.addEventListener(
            'ga:nonInteractionUser',
            AnalyticsHandler.nonInteractionUserEvent as any,
        );
        window.addEventListener('ga:emptyCart', AnalyticsHandler.emptyCart as any);
        window.addEventListener(
            'ga:voucherInteraction',
            AnalyticsHandler.voucherInteraction as any,
        );
        window.addEventListener('ga:deliveryInfo', AnalyticsHandler.deliveryInfo);
    }

    // Uses adapter pattern instead of updating lots of coupled interfaces for legacy events and relate new
    private static adaptWithLoginStatus<TEvent = object>(gaEvent: TEvent) {
        return {
            ...gaEvent,
            loginStatus: AnalyticsHelper.getLoginStatus(),
        };
    }

    private static interactionClickWithoutCustomerSegment(
        interactionEvent: AnalyticsInteractionClickEvent,
    ) {
        setTimeout(() => {
            if (
                !interactionEvent ||
                !interactionEvent.eventCategory ||
                !interactionEvent.eventAction
            )
                return;

            const { eventCategory, eventAction, eventLabel, filterCombination } = interactionEvent;

            let gaEvent: InteractionFieldObject = {
                event: 'interaction',
                eventCategory,
                eventAction,
                eventLabel,
                filterCombination,
            };

            if (interactionEvent.additionalData) {
                gaEvent = Object.assign(gaEvent, interactionEvent.additionalData);
            }

            const analyticsData = <InteractionFieldObject>JSON.parse(JSON.stringify(gaEvent));
            analyticsData.eventCallback = interactionEvent.callback;
            window.dataLayer.push(AnalyticsHandler.adaptWithLoginStatus(analyticsData));
        }, 0);
    }

    private static nonInteractionUserEvent(nonInteractionUserEvent: NonInteractionUserEvent) {
        setTimeout(() => {
            const gaEvent: InteractionUserFieldObject = {
                event: 'nonInteractionUser',
                eventCategory: 'Dimensioner',
                eventAction: 'Dimensioner inskickade',
                pointShopPoints: nonInteractionUserEvent.pointShopPoints,
                utbud: nonInteractionUserEvent.utbud,
                dimension23: nonInteractionUserEvent.dimension23,
            };

            window.dataLayer.push(JSON.parse(JSON.stringify(gaEvent)));
        }, 0);
    }

    private static emptyCart(event: AnalyticsEmptyCartEvent) {
        setTimeout(() => {
            if (!event) return;

            const gaEvent: ActionFieldObject = {
                event: 'emptyCart',
                eventAction: event.eventAction,
                eventLabel: 'Töm varukorgen',
            };

            const analyticsData = JSON.parse(JSON.stringify(gaEvent));
            window.dataLayer.push(analyticsData);
        }, 0);
    }

    private static voucherInteraction(event: AnalyticsVoucherInteractionEvent) {
        setTimeout(() => {
            if (!event) {
                return;
            }

            const gaEvent: VoucherInteractionObject = {
                event: 'interaction',
                eventCategory: 'Ecommerce',
                eventAction: 'Checkout - Varukorg',
                eventLabel: event.eventLabel,
                campaignCode: event.campaignCode,
            };

            const analyticsData = <VoucherInteractionObject>JSON.parse(JSON.stringify(gaEvent));
            window.dataLayer.push(AnalyticsHandler.adaptWithLoginStatus(analyticsData));
        }, 0);
    }

    private static deliveryInfo() {
        setTimeout(() => {
            const gaEvent = {
                event: 'interaction',
                eventCategory: 'Ecommerce',
                eventAction: 'Leverans',
                eventLabel: 'Lägg till leveransmetod',
                checkoutStep: '3',
            } as SubscriptionInteractionObject;

            const analyticsData = <DeliveryInfoObject>JSON.parse(JSON.stringify(gaEvent));
            window.dataLayer.push(AnalyticsHandler.adaptWithLoginStatus(analyticsData));
        }, 0);
    }
}

export class AnalyticsHelper {
    public static getLoginStatus() {
        const isMember = appConfig.coopUserSettings.isMedmeraCustomer;
        const isLoggedIn = appConfig.coopUserSettings.isAuthenticated;

        if (isMember && isLoggedIn) {
            return 'Inloggad medlem';
        }

        if (isLoggedIn) {
            return 'Inloggad ej medlem';
        }

        if (isLoggedIn === false) {
            return 'Utloggad';
        }

        return undefined;
    }

    public static getLocalOrGlobalProductString(productAttributeIds: string[]) {
        const isLocal = !!productAttributeIds?.find(
            (item) => item === ApiProductAttributeIds.LocalProduct,
        );
        return isLocal ? 'Lokalt sortiment' : 'Globalt sortiment';
    }
}

export enum ApiProductAttributeIds {
    LocalProduct = 'LOCAL_PRODUCT',
}
