import { trimEnd } from '../utils/stringUtils';
import { ensureTrailingSlash } from '../utils/urlUtils';
import { sendEvent } from './utils';

interface GaVirtualPageData {
    pathName: string;
    pageTitle: string;
    pageType?: string;
    queryString?: string;
}

export const sendGaVirtualPageView = (data: GaVirtualPageData) => {
    const { pathName } = data;
    if (!pathName) {
        return;
    }

    let virtualPageURL = window.location.origin + pathName;
    if (data.queryString) {
        virtualPageURL = `${trimEnd(virtualPageURL, '/')}${data.queryString}`;
    } else {
        virtualPageURL = ensureTrailingSlash(virtualPageURL);
    }

    sendEvent({
        event: 'virtualPageView',
        virtualPageURL,
        virtualPageTitle: data.pageTitle,
        pageType: data.pageType,
    });
};
