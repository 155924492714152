import { appConfig } from '../../../../appConfig';
import { SearchResultType } from '../../../search/models/SearchResultType';
import { SearchFromPageType } from '../../models/SearchFromPageType';

export const getTypedSearchResult = (searchFromPageString: string) => {
    const isEnum: boolean = Object.values(SearchResultType).includes(
        searchFromPageString as SearchResultType,
    );

    const searchFromPage = isEnum ? (searchFromPageString as SearchResultType) : null;
    return searchFromPage;
};

export const getTypedCurrentPage = (currentPageString?: string) => {
    const isEnum: boolean =
        !!currentPageString &&
        Object.values(SearchFromPageType)
            .map((item) => item.toLocaleLowerCase())
            .includes(currentPageString.toLowerCase() as SearchFromPageType);

    const searchFromPage = isEnum
        ? (currentPageString as SearchFromPageType)
        : SearchFromPageType.Other;

    return searchFromPage;
};

export const getSearchDefaultContentLinks = (
    currentPage?: SearchFromPageType,
): SearchOverlayShortcut[] => {
    return appConfig.coopSettings.globalSearch.searchOverlayShortcuts.filter(
        (item) => item.searchedFromPage === currentPage,
    );
};
