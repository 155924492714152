import { useCallback, useRef, useState } from 'react';

import { BrowserSize } from '../../../../utility/browser';
import { useFromBrowserMediaQuery } from '../../../common/components/atoms/ResponsiveBreakpoints';
import { useElementEvent } from '../../../common/hooks/useElementEvent';

type ScrollDirection = 'up' | 'down' | null;

export const useScrolDirection = () => {
    const [direction, setDirection] = useState<ScrollDirection>(null);

    const lastKnownPos = useRef(0);
    const checkDirection = useCallback(() => {
        const currentScroll = window.scrollY;
        const isScrolledToEnd =
            Math.abs(document.documentElement.scrollHeight - window.innerHeight - window.scrollY) <=
            1;
        // scroll-bouncing in Safari has a nevative scrollY when it happens, dont trigger the state if scroll bouncing is detected
        if (currentScroll >= 0 && !isScrolledToEnd) {
            if (currentScroll > lastKnownPos.current) {
                setDirection('down');
            } else {
                setDirection('up');
            }
            lastKnownPos.current = currentScroll;
        }
    }, []);

    useElementEvent(window, 'scroll', checkDirection, true);

    return direction;
};

export const useIsMobileHeader = () => {
    const isFromLarge = useFromBrowserMediaQuery(BrowserSize.lg);

    return !isFromLarge;
};
