/* eslint-disable no-console */
const enum Environment {
    Production = 'production',
    QA = 'qa',
    Development = 'development',
    Storybook = 'storybook',
}

export const isProductionEnvironment = process.env.APP_ENV === Environment.Production;

export const isTestingEnvironment = process.env.APP_ENV === Environment.QA;

export const isDevelopmentEnvironment = process.env.APP_ENV === Environment.Development;

export const isStorybookEnvironment = process.env.APP_ENV === Environment.Storybook;

if (isTestingEnvironment) {
    console.log('Using testing environment');
}

if (isDevelopmentEnvironment) {
    console.log('Using development environment');
}
