import { parseISO } from 'date-fns';

import type { ApiRecipe } from '../../../models/recipe/recipeModel';
import { mapLoopProduct } from '../../common/flow/product/mapProduct';
import type {
    AutocompleteResponse,
    GlobalSearchResults,
    LightSearchRecipe,
    LoopNoQueryProductResults,
    LoopSearchProductResults,
    SearchArticle,
    SearchArticleResults,
    SearchPointshop,
    SearchPointshopResults,
    SearchRecipe,
    SearchRecipeCategory,
    SearchRequest,
    SearchResultFacet,
    SearchStore,
    SearchStoreResults,
} from '../models/models';
import { SearchFacetType } from '../models/models';

const mapFacets = (
    searchResults: ApiLoopSearchResultsBase,
    facetType: SearchFacetType,
): SearchResultFacet[] => {
    const facetsByType = searchResults.facets?.find((facet) => facet.name === facetType);
    let facets: SearchResultFacet[] = [];

    if (facetsByType) {
        facets = facetsByType.items.map((item) => {
            return {
                name: item.item,
                value: item.item,
                count: item.count,
                isSelected: item.selected,
            };
        });
    }

    return facets;
};

export const mapLoopSearchArticle = (loopArticle: ApiLoopSearchArticle): SearchArticle => ({
    ...loopArticle,
    published: loopArticle.published ? parseISO(loopArticle.published) : undefined,
});

export const mapLoopArticleSearchResponse = (
    searchResponse: ApiLoopSearchResponse<ApiLoopSearchArticle>,
    searchRequest: SearchRequest,
): SearchArticleResults => {
    const articles: SearchArticle[] = searchResponse.results.items.map(mapLoopSearchArticle);

    return {
        query: searchRequest.searchTerm,
        items: articles,
        totalResults: searchResponse.results.count,
        makeSense: true,
        spellingSuggestions: [],
    };
};

export const mapLoopPointshopSearchResponse = (
    searchResponse: ApiLoopSearchResponse<ApiLoopLoyaltyProduct>,
    searchRequest: SearchRequest,
): SearchPointshopResults => {
    const pointshopItems: SearchPointshop[] = searchResponse.results.items
        .filter((x) => x.type === 'PartneroffersEntity' || x.type === 'PointshopEntity')
        .map(mapLoopSearchPointshop);

    return {
        query: searchRequest.searchTerm,
        items: pointshopItems,
        totalResults: searchResponse.results.count,
        makeSense: true,
        spellingSuggestions: [],
    };
};

export const mapLoopSearchStore = (loopStore: ApiLoopSearchStore): SearchStore => ({
    id: loopStore.id,
    ledgerAccountNumber: loopStore.ledger_account_number,
    address: loopStore.address,
    city: loopStore.city,
    hasDR: loopStore.hasDR,
    imageUrl: loopStore.imageUrl,
    latitude: loopStore.latitude,
    longitude: loopStore.longitude,
    name: loopStore.name,
    services: loopStore.services || [],
    url: loopStore.url,
    zip: loopStore.zip,
    openingHours:
        loopStore.opening_hours?.map((openingHour) => ({
            ...openingHour,
            date: openingHour.date ? parseISO(openingHour.date) : undefined,
        })) || [],
});

export const mapLoopSearchPointshop = (loopPointshop: ApiLoopLoyaltyProduct): SearchPointshop => ({
    id: loopPointshop.id,
    brand: loopPointshop.brand, // saknas från partnererbjudanden
    headline: loopPointshop.headline, // saknas
    imageUrl: loopPointshop.imageUrl,
    name: loopPointshop.name,
    pointPrice: loopPointshop.pointPrice,
    preamble: loopPointshop.preamble,
    url: loopPointshop.url,
    category: loopPointshop.category,
    instruction: loopPointshop.instruction,
    fulfillmentProcess: loopPointshop.fulfillmentProcess,
    validityStartDate: loopPointshop.validityStartDate,
    validityEndDate: loopPointshop.validityEndDate,
    condition: loopPointshop.condition,
    body: loopPointshop.body,
    type: loopPointshop.type === 'PartneroffersEntity' ? 'PartneroffersEntity' : 'PointshopEntity',
    published: loopPointshop.published, // new
    contentType: loopPointshop.contentType, // new "partnerpage"
});

export const mapLoopSearchRecipeCategory = (
    loopRecipeCategory: ApiLoopSearchRecipeCategory,
): SearchRecipeCategory => {
    return {
        name: loopRecipeCategory.page_title,
        url: loopRecipeCategory.url,
        imageUrl: loopRecipeCategory.full_image,
        preamble: loopRecipeCategory.preamble,
    };
};

export const mapLoopSearchRecipe = (loopRecipe: ApiLoopSearchRecipe): SearchRecipe => {
    const recipe: SearchRecipe = {
        id: loopRecipe.id,
        name: loopRecipe.name,
        averageRating: loopRecipe.average_rating,
        categories: loopRecipe.categories,
        alternativeTitle: loopRecipe.alternative_title,
        cookingInstructions: loopRecipe.cooking_instructions,
        cookingTime: loopRecipe.cooking_time,
        ingredients: loopRecipe.ingredients,
        keywords: loopRecipe.keywords,
        numberOfVotes: loopRecipe.number_of_votes,
        ovenTemperature: loopRecipe.oven_temperature,
        preamble: loopRecipe.preamble,
        preparationTime: loopRecipe.preparation_time,
        servingTips: loopRecipe.serving_tips,
        tips: loopRecipe.tips,
        totalTime: loopRecipe.total_time,
        url: loopRecipe.url,
        yieldAlternative: loopRecipe.yield_alternative,
        yieldUnit: loopRecipe.yield_unit,
        carbGram: loopRecipe.carb_gram,
        carbPercent: loopRecipe.carb_percent,
        climateImpactKg: loopRecipe.climate_impact_kg || null,
        fatGram: loopRecipe.fat_gram,
        fatPercent: loopRecipe.fat_percent,
        proteinGram: loopRecipe.protein_gram,
        proteinPercent: loopRecipe.protein_percent,
        publicationDate: loopRecipe.publication_date
            ? parseISO(loopRecipe.publication_date)
            : undefined,
        saltGram: loopRecipe.salt_gram,
        saturatedFatGram: loopRecipe.saturated_fat_gram,
        yieldValue: loopRecipe.yield_value,
        energyValueKcal: 0, // TODO: missing?
        energyValueKj: 0, // TODO: missing?
        imageUrl: loopRecipe.image_url,
        isFoodBox: loopRecipe.is_food_box || false,
    };

    return recipe;
};

export const mapApiRecipe = (apiRecipe: ApiRecipe): LightSearchRecipe => {
    const recipe: LightSearchRecipe = {
        id: apiRecipe.externalId.toString(),
        name: apiRecipe.name,
        averageRating: apiRecipe.avgRating,
        numberOfVotes: apiRecipe.numberOfVotes,
        preamble: apiRecipe.preamble,
        url: apiRecipe.url,
        imageUrl: apiRecipe.imageUrl,
        cookingTime: apiRecipe.cookingTime,
        relativeUrl: apiRecipe.relativeUrl,
        climateImpactKg: apiRecipe.climateImpactKg,
        isFoodBox: apiRecipe.isFoodBox,
    };

    return recipe;
};

export const mapLoopStoreSearchResponse = (
    searchResponse: ApiLoopSearchResponse<ApiLoopSearchStore>,
    searchRequest: SearchRequest,
): SearchStoreResults => {
    const stores: SearchStore[] = searchResponse.results.items.map(mapLoopSearchStore);

    return {
        query: searchRequest.searchTerm,
        items: stores,
        totalResults: searchResponse.results.count,
        makeSense: true,
        spellingSuggestions: [],
    };
};

export const mapLoopProductNoQuerySearchResponse = (
    searchResponse: ApiLoopSearchResponse<ApiLoopProduct>,
): LoopNoQueryProductResults => {
    const brandFacets = mapFacets(searchResponse.results, SearchFacetType.Brand);
    const environmentalFacets = mapFacets(
        searchResponse.results,
        SearchFacetType.EnvironmentalLabels,
    );

    const categoryIdsFacets = mapFacets(searchResponse.results, SearchFacetType.CategoryIds);
    const topCategoryFacets = mapFacets(searchResponse.results, SearchFacetType.TopCategory);

    environmentalFacets.sort((a, b) => a.name.localeCompare(b.name));

    const products = searchResponse.results.items.map(mapLoopProduct);
    const relatedProducts = searchResponse.relatedResults?.items?.map(mapLoopProduct) || [];

    return {
        brandFacets,
        environmentalFacets,
        categoryIdsFacets,
        topCategoryFacets,
        items: products,
        totalResults: searchResponse.results.count,
        relatedResult: {
            totalResults: searchResponse.relatedResults?.count || 0,
            items: relatedProducts,
        },
    };
};

export const mapLoopProductSearchResponse = (
    searchResponse: ApiLoopSearchResponse<ApiLoopProduct>,
    searchRequest: SearchRequest,
): LoopSearchProductResults => {
    const response = mapLoopProductNoQuerySearchResponse(searchResponse);

    let spellingSuggestions: string[] = [];
    if (searchResponse.spellingSuggestions && searchResponse.spellingSuggestions.count > 0) {
        spellingSuggestions = searchResponse.spellingSuggestions.items.map(
            (suggestion) => suggestion.query,
        );
    }

    return {
        ...response,
        query: searchRequest.searchTerm,
        spellingSuggestions,
        makeSense: searchResponse.makeSense,
        queryUsed: searchResponse.queryUsed,
    };
};

export const mapLoopAutocompleteResponse = (
    loopAutocompleteResponse: ApiLoopAutocompleteResponse,
    query: string,
): AutocompleteResponse => {
    return {
        query,
        hints: loopAutocompleteResponse.queries.items.map((item) => item.query),
    };
};

export const mapLoopGlobalSearchResponse = (
    searchResponse: ApiLoopGlobalSearchResponse,
    searchTerm: string,
): GlobalSearchResults => {
    let spellingSuggestions: string[] = [];
    if (searchResponse.spellingSuggestions && searchResponse.spellingSuggestions.count > 0) {
        spellingSuggestions = searchResponse.spellingSuggestions.items.map(
            (suggestion) => suggestion.query,
        );
    }

    const mappedResponse: GlobalSearchResults = {
        searchTerm,
        queryUsed: searchResponse.queryUsed,
        makeSense: searchResponse.makeSense,
        spellingSuggestions,
    };

    if (searchResponse.results) {
        mappedResponse.productResults = {
            items: searchResponse.results.items.map(mapLoopProduct),
            totalResults: searchResponse.results.count,
        };
    }

    if (searchResponse.editorialContentResults) {
        mappedResponse.articleResults = {
            items: searchResponse.editorialContentResults.results.items.map(mapLoopSearchArticle),
            totalResults: searchResponse.editorialContentResults.results.count,
        };
    }

    if (searchResponse.storesResults) {
        mappedResponse.storeResults = {
            items: searchResponse.storesResults.results.items.map(mapLoopSearchStore),
            totalResults: searchResponse.storesResults.results.count,
        };
    }

    if (searchResponse.pointshopResults) {
        mappedResponse.pointshopResults = {
            items: searchResponse.pointshopResults.results.items
                .filter((x) => x.type === 'PartneroffersEntity' || x.type === 'PointshopEntity')
                .map(mapLoopSearchPointshop),
            totalResults: searchResponse.pointshopResults.results.count,
        };
    }

    if (searchResponse.recipesResults) {
        mappedResponse.recipeResults = {
            items: searchResponse.recipesResults.results.items.map(mapLoopSearchRecipe),
            totalResults: searchResponse.recipesResults.results.count,
        };
    }

    if (searchResponse.recipeCategoriesResults) {
        mappedResponse.recipeCategoryResults = {
            items: searchResponse.recipeCategoriesResults.results.items.map(
                mapLoopSearchRecipeCategory,
            ),
            totalResults: searchResponse.recipeCategoriesResults.results.count,
        };
    }

    return mappedResponse;
};
