import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store/structureDefinitions/cartState';
import { DeliveryMode } from '../store/structureDefinitions/storeState';

const selectedDeliveryMode = (state: RootState) =>
    state.store.deliveryMode || DeliveryMode.homedelivery;
const currentStore = (state: RootState) => state.store.selectedStore;
const currentProductionUnit = (state: RootState) => state.store.productionUnitId;
const currentPostalCode = (state: RootState) => state.store.selectedZipCode;
const currentPickupPointId = (state: RootState) => state.store.selectedPickupPointId;
const isHomeDeliverySelected = (state: RootState) =>
    state.store && state.store.selectedZipCode && !state.store.selectedPickupPointId;
const isPickupSelected = (state: RootState) =>
    state.store && state.store.selectedPickupPointId && !state.store.selectedZipCode;
const hasActiveStoreSelection = createSelector(
    isHomeDeliverySelected,
    isPickupSelected,
    (_isHomeDeliverySelected, _isPickupSelected) =>
        !!_isHomeDeliverySelected || !!_isPickupSelected,
);

const currentProductionUnitOrDefault = createSelector(
    selectedDeliveryMode,
    currentProductionUnit,
    (deliveryMode, _currentProductionUnit) => {
        return _currentProductionUnit;
    },
);

export const storeSelectors = {
    selectedDeliveryMode,
    currentStore,
    currentProductionUnit: currentProductionUnitOrDefault,
    currentPostalCode,
    isHomeDeliverySelected,
    isPickupSelected,
    hasActiveStoreSelection,
    currentPickupPointId,
};
