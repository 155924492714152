/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { isProductionEnvironment } from '../../../../../utility/environments';

const initialState = {
    mockReclamationUI: false,
};

const slice = createSlice({
    name: 'debug',
    initialState,
    reducers: {
        mockReclamationUI: (state, action: PayloadAction<boolean>) => {
            if (!isProductionEnvironment) {
                state.mockReclamationUI = action.payload;
            } else {
                // eslint-disable-next-line no-console
                console.error('Not available in production environment');
            }
        },
    },
});

export default slice;
