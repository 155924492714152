import classNames from 'classnames';

import type { CoopLayoutSize } from '../../../utility/coopSwiperBreakpoints';

export const getMainContainerClassByLayout = (layoutSize: CoopLayoutSize, hasSideBar: boolean) => {
    return classNames(
        'Main-container Main-container--padding',
        (layoutSize === 'full' || (layoutSize === 'default' && hasSideBar)) &&
            'Main-container--full',
    );
};
