import { sendInteractionEvent } from '../utils';
import { MINI_CART_EVENT_ACTION } from './utils';

const createSendMiniCartEvent = (eventLabel: string) => () => {
    sendInteractionEvent({ eventAction: MINI_CART_EVENT_ACTION, eventLabel });
};

export default {
    goToCheckout: createSendMiniCartEvent('Gå till kassan'),
    startShopping: createSendMiniCartEvent('Börja handla'),
    startEmptyCart: createSendMiniCartEvent('Töm varukorg'),
    saveCart: {
        open: createSendMiniCartEvent('Spara varukorg'),
        save: createSendMiniCartEvent('Bekräfta spara varor'),
        close: createSendMiniCartEvent('Avbryt spara varor'),
    },
};
