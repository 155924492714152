import type { AnalyticsListOptions } from '../../../analytics/analyticsHandler';
import { ga4CartTracking } from './ga4/cart';

export const sendGaModifyCartEvent = (
    productId: string,
    variantId: string | undefined,
    newQuantity: number,
    previousQuantity: number,
    storeId: string,
    analyticsOptions: AnalyticsListOptions | null,
) => {
    ga4CartTracking.modifyCartItems(
        [
            {
                productId,
                variantId,
                newQuantity,
                previousQuantity,
                index: analyticsOptions?.position,
            },
        ],
        storeId,
        analyticsOptions,
    );
};
