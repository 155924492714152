import { useCallback, useEffect } from 'react';

import { appConfig } from '../../../appConfig';
import type { ApiBaseException, ApiDefaultException } from '../exceptions/exceptionDefinitions';
import { isLocalApiDefaultException } from '../exceptions/exceptionUtils';
import { errorsSelectors } from '../selectors/errorsSelectors';
import { errorsActions } from '../store/slices/errors/errorsSlice';
import { ErrorGroup } from '../store/structureDefinitions/errorsState';
import { useAppDispatch, useAppSelector } from './useThunkDispatch';

// remove when react query supports our error type
const defaultException: ApiDefaultException = {
    friendlyMessage: appConfig.coopSettings.ecommerce.errorMessage.general,
    code: null,
    field: null,
    type: 'Default',
};

export const useAddGroupError = (group: ErrorGroup = ErrorGroup.Global) => {
    const dispatch = useAppDispatch();

    const addGroupError = useCallback(
        (error: ApiBaseException = defaultException) =>
            dispatch(errorsActions.addSingle({ group, error })),
        [dispatch, group],
    );

    return addGroupError;
};

export const useGroupErrorEffect = (
    group: ErrorGroup,
    when: boolean,
    error: ApiBaseException | undefined | null = defaultException,
) => {
    const addGroupError = useAddGroupError(group);

    useEffect(() => {
        if (error && when) {
            addGroupError(error);
        }
    }, [error, when, addGroupError]);

    return addGroupError;
};

export const useGroupError = (
    group: ErrorGroup,
    error?: ApiBaseException | null,
    clearErrorCallback?: () => void,
    addErrorWhen: (error: ApiBaseException) => boolean = isLocalApiDefaultException,
) => {
    const firstError = useAppSelector((state) => errorsSelectors.selectFirstByGroup(state, group));

    const dispatch = useAppDispatch();

    const clearGroupErrors = () => {
        dispatch(errorsActions.clear(group));
    };

    const addGroupError = useGroupErrorEffect(group, error ? addErrorWhen(error) : false, error);

    const clearErrorCallbackMemoized = useCallback(() => {
        if (clearErrorCallback) {
            clearErrorCallback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!firstError && clearErrorCallbackMemoized) {
            clearErrorCallbackMemoized();
        }
    }, [firstError, clearErrorCallbackMemoized]);

    return { firstError, clearGroupErrors, addGroupError };
};
