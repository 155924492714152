import type { CncStep } from '../../store/structureDefinitions/uiState';
import { sendInteractionEvent } from '../utils';
import { getEventAction, MINI_CART_EVENT_ACTION } from './utils';

const sendCncHeaderGoToDeliveryOption = () => {
    sendInteractionEvent({
        eventAction: MINI_CART_EVENT_ACTION,
        eventLabel: 'Gå till byte av leveranssätt och leveranstid',
    });
};

const sendCncHeaderGoToZipCode = () => {
    sendInteractionEvent({
        eventAction: MINI_CART_EVENT_ACTION,
        eventLabel: 'Gå till postnummer-sidan',
    });
};

const sendCncHeaderGoToMap = () => {
    sendInteractionEvent({
        eventAction: MINI_CART_EVENT_ACTION,
        eventLabel: 'Gå till sök efter butik',
    });
};

const sendCncHeaderGoToTimeslots = () => {
    sendInteractionEvent({
        eventAction: MINI_CART_EVENT_ACTION,
        eventLabel: 'Gå till byte av leveranstid',
    });
};

const sendCncNavigationGoBack = (currentStep: CncStep) => {
    sendInteractionEvent({
        eventAction: getEventAction(currentStep),
        eventLabel: 'Gå tillbaka pilen',
    });
};

const sendCncNavigationClose = (currentStep: CncStep) => {
    sendInteractionEvent({
        eventAction: getEventAction(currentStep),
        eventLabel: 'Stänger pop up',
    });
};

export default {
    navigationClose: sendCncNavigationClose,
    navigationGoBack: sendCncNavigationGoBack,
    goToDeliveryOption: sendCncHeaderGoToDeliveryOption,
    goToZipCode: sendCncHeaderGoToZipCode,
    goToTimeslots: sendCncHeaderGoToTimeslots,
    goToMap: sendCncHeaderGoToMap,
};
