import { useCallback } from 'react';

import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { uiSelectors } from '../../../common/selectors/uiSelectors';
import gaTracking from '../../../common/tracking/tracking';

export const useNavigationTracking = () => {
    const currentStep = useAppSelector(uiSelectors.cnc.selectCurrentStep);

    const sendGaGoBackTrackingEvent = useCallback(() => {
        if (currentStep) {
            gaTracking.cnc.header.navigationGoBack(currentStep);
        }
    }, [currentStep]);

    const sendGaCloseTrackingEvent = useCallback(() => {
        if (currentStep) {
            gaTracking.cnc.header.navigationClose(currentStep);
        }
    }, [currentStep]);

    return { sendGaGoBackTrackingEvent, sendGaCloseTrackingEvent };
};
