/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { appConfig } from '../../../../../appConfig';
import { isProductionEnvironment } from '../../../../../utility/environments';
import type {
    MunicipalUserInfo,
    MunicipalUserInfoState,
} from '../../structureDefinitions/userState';

const getInitialState = (): MunicipalUserInfoState => {
    return {
        isInitialized: false,
        info: undefined,
        isShoppingForAnother: appConfig.coopUserSettings.isShoppingForAnother,
    };
};

const slice = createSlice({
    name: 'municipalUserInfo',
    initialState: getInitialState(),
    reducers: {
        set: (state: MunicipalUserInfoState, action: PayloadAction<MunicipalUserInfo>) => {
            if (!action.payload) return getInitialState();
            state.isInitialized = true;
            state.info = action.payload;
        },
        mockShoppingForAnother: (state: MunicipalUserInfoState) => {
            if (!isProductionEnvironment) {
                state.isShoppingForAnother = true;
            }
        },
    },
});

export const { actions: municipalUserInfoActions, reducer: municipalUserInfoReducer } = slice;
