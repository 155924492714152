import { AnalyticsHandler } from './analytics/analyticsHandler';
import { bannerResizeHandler } from './globalDomHandlers/bannerResizeHandler';
import { gaNonInteractionUserEventHandler } from './globalDomHandlers/gaNonInteractionUserEventHandler';
import gaPromoViewHandler from './globalDomHandlers/gaPromoViewHandler';
import videoMuteHandler from './globalDomHandlers/videoClickHandler';
import { cleanTokenSessionStorageIfNeeded } from './microApps/common/api/auth/getAuthToken';
import { ga4MiscTracking } from './microApps/common/tracking/ga4/misc';
import initializeDynamicYield from './utility/dynamicYield';
import { syncConsentManagerVendors } from './utility/gdpr';

const globalInvokes = () => {
    initializeDynamicYield();
    AnalyticsHandler.initialize();

    syncConsentManagerVendors();
    cleanTokenSessionStorageIfNeeded();

    handleGlobalClick();
    handleGlobalEvents();

    // it is here to remove old ab test local storage keys. We can remove after some time. The ab tests will now be kept inside single key coop_abtest.
    cleanLocalStorage(['abtest_']);
};

const cleanLocalStorage = (keysToRemove: string[]) => {
    if (!keysToRemove.length) return;

    const storageKeys = Object.keys(window.localStorage);

    const storageKeysToRemove = storageKeys.filter((sk) =>
        keysToRemove.some((ktr) => sk.startsWith(ktr)),
    );

    storageKeysToRemove.forEach((sktr) => window.localStorage.removeItem(sktr));
    // eslint-disable-next-line no-console
    console.warn(`Removed storage Keys: ${storageKeysToRemove.join(',')}`);
};

const handleGlobalClick = () => {
    const onClick = (event: Event) => {
        const element = event.target as HTMLElement;

        const partnerPage = Array.from(
            document.getElementsByClassName('js-gaPartnerName'),
        )?.[0] as HTMLElement;
        const isPartnerPage = !!partnerPage;

        const additionalData = isPartnerPage ? { partner: partnerPage.dataset.name || null } : {};

        if (element instanceof HTMLAnchorElement && element.tagName === 'A' && element.href) {
            ga4MiscTracking.outboundClick(element.href, element.innerText, additionalData);
            return;
        }

        const closestAnchor = (element as HTMLElement).closest('a');
        if (closestAnchor) {
            ga4MiscTracking.outboundClick(
                closestAnchor.href,
                closestAnchor.innerText,
                additionalData,
            );
        }

        if (element && element.classList.contains('js-videoMute')) {
            videoMuteHandler(element);
        }
    };

    document.addEventListener('click', onClick);
};

const handleGlobalEvents = () => {
    bannerResizeHandler();

    setTimeout(gaNonInteractionUserEventHandler, 1000);
    document.querySelectorAll<HTMLElement>('.js-gaPromo').forEach((element) => {
        gaPromoViewHandler(element);
    });
};

export default globalInvokes;
