/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
export class SessionStorageFacade {
    private static _instance: SessionStorageFacade;

    public static get instance(): SessionStorageFacade {
        // eslint-disable-next-line no-return-assign
        return this._instance || (this._instance = new this());
    }

    add(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }

    get(key: string) {
        return sessionStorage.getItem(key);
    }

    public addObject<T>(key: string, value: T): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    public getObject<T>(key: string) {
        const storageItem = sessionStorage.getItem(key);
        if (storageItem === undefined || storageItem === null) return undefined;
        try {
            return <T>JSON.parse(storageItem);
        } catch (ex) {
            return undefined;
        }
    }

    public remove(key: string): void {
        sessionStorage.removeItem(key);
    }
}
