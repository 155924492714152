'use client';

import classNames from 'classnames';
import * as React from 'react';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useDelayedIsLoading } from '../Loader/Loader.hooks';
import styles from './PacmanLoader.module.scss';

interface PacmanLoaderProps {
    isLoading: boolean;
    delay?: number;
    className?: string;
}

const PacmanLoader = (props: PacmanLoaderProps) => {
    const isLoading = useDelayedIsLoading(props.isLoading, props.delay || 0);

    const pacmanRef = useRef<HTMLDivElement>(null);

    return (
        <CSSTransition
            nodeRef={pacmanRef}
            in={isLoading}
            timeout={200}
            classNames={{
                enter: styles.Visible,
                enterDone: styles.Visible,
            }}
            unmountOnExit
        >
            <div ref={pacmanRef} className={classNames(styles.Pacman, props.className)} aria-hidden>
                <div className={classNames(styles['Pacman-icon'])}>
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </CSSTransition>
    );
};

export default PacmanLoader;
