import type { IApiStore } from '../../../../models/ecommerce/store/storeModel';

export const enum DeliveryMode {
    pickup = 'pickup',
    homedelivery = 'homedelivery',
}

export interface StoreState {
    selectedStore: IApiStore | undefined;
    deliveryMode: DeliveryMode | undefined;
    selectedZipCode: string | undefined; // Home delivery only
    selectedPickupPointId: string | undefined;
    productionUnitId: string;
    storeName: string | undefined;
}
