const diactricMap: Record<string, string> = {
    å: 'a',
    ä: 'a',
    á: 'a',
    à: 'a',
    ã: 'a',
    â: 'a',
    ć: 'c',
    č: 'c',
    ç: 'c',
    đ: 'd',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    í: 'i',
    ì: 'i',
    ï: 'i',
    î: 'i',
    ğ: 'g',
    ö: 'o',
    æ: 'o',
    ø: 'o',
    ô: 'o',
    ó: 'o',
    ò: 'o',
    š: 's',
    ş: 's',
    ß: 's',
    ü: 'u',
    û: 'u',
    ú: 'u',
    ù: 'u',
    ž: 'z',
};

const trim = (str: string, ch: string) => {
    let start = 0;
    let end = str.length;

    while (start < end && str[start] === ch) start += 1;
    while (end > start && str[end - 1] === ch) end -= 1;

    return start > 0 || end < str.length ? str.substring(start, end) : str;
};

export const urlFriendlySlug = (str?: string) => {
    if (!str) return undefined;

    const alphaNumericAndDashOnly = new RegExp('[^a-zA-Z0-9-]', 'g');
    const doubleDashes = new RegExp('-{2,}', 'g');
    let sanitizedUrl = str.toLowerCase();

    // this needs to imitate what is in UrlHelper.CleanupUrl on backend
    let cleanedUrl = '';

    for (let i = 0; i < sanitizedUrl.length; i += 1) {
        if (sanitizedUrl[i] === ' ' || !sanitizedUrl[i].match(alphaNumericAndDashOnly)) {
            cleanedUrl += sanitizedUrl[i];
            continue;
        }

        if (diactricMap[sanitizedUrl[i]]) {
            cleanedUrl += diactricMap[sanitizedUrl[i]];
        }
    }

    sanitizedUrl = cleanedUrl;
    sanitizedUrl = sanitizedUrl.replace(alphaNumericAndDashOnly, '-');
    sanitizedUrl = sanitizedUrl.replace(doubleDashes, '-');
    sanitizedUrl = trim(sanitizedUrl, '-');

    return sanitizedUrl;
};
