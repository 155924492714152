import { createDefaultException } from '../../exceptions/exceptionUtils';
import {
    type ApiHybrisErrors,
    type HybrisApiError,
    isApiHybrisBaseError,
} from '../clients/directHybrisAxiosClient';
import type { AxiosException } from '../clients/localApiAxiosErrorInterceptors';

export const editOrderErrorInterceptor = <TException extends ApiHybrisErrors>(
    error: AxiosException<HybrisApiError<TException>>,
) => {
    if (!Array.isArray(error?.response.data.errors)) {
        return null;
    }

    const hybrisErrors = error.response.data.errors;
    for (let i = 0; i < hybrisErrors.length; i += 1) {
        const hybrisError = hybrisErrors[i];
        if (isApiHybrisBaseError(hybrisError)) {
            if (hybrisError.type === 'PaymentError') {
                return createDefaultException(
                    'Uppdatering misslyckades hos Klarna.',
                    'KLARNA_PAYMENT_FAILED',
                );
            }
            if (hybrisError.type === 'PaymentUpdateNotAllowedError') {
                return createDefaultException(
                    'Uppdatering ej tillåten.',
                    'PAYMENT_UPDATE_NOT_ALLOWED',
                );
            }
        }
    }

    // let it flow to global hybrisInterceptor
    return null;
};
