import { useCallback } from 'react';

import { useAsyncDispatcher } from '../../common/hooks/useAsyncDispatcher';
import { useAppSelector } from '../../common/hooks/useThunkDispatch';
import { storeSelectors } from '../../common/selectors/storeSelectors';
import type {
    ApiLoopSearchAndFilter,
    ApiLoopSearchFilter,
    ApiLoopSearchInverseFilter,
    ApiLoopSearchOrFilter,
} from '../api/searchRequests';
import searchFlow from '../flow/searchFlow';
import type { GlobalSearchRequest } from '../models/models';
import { SearchResultType } from '../models/SearchResultType';

export interface SearchResultPageCountPerType {
    products: number;
    recipes: number;
    stores: number;
    articles: number;
    pointshop: number;
}

export const globalSearchAbortable = (
    query: string,
    currentProductionUnit: string,
    resultCountsPerPage: SearchResultPageCountPerType,
    abortSignal?: AbortSignal,
    quickSearch?: boolean,
    personalizeSearch?: boolean,
) => {
    const searchRequest = {
        page: 0,
        resultsPerPage: 0,
        facets: [],
        sortBy: [],
    };

    // PARTNERERBJUDANDEN
    const partnerTypeFilter: ApiLoopSearchFilter = {
        type: 'type',
        comparisonMode: 'equals',
        value: 'PartneroffersEntity',
    };
    const partnererbjudandeAndFilter: ApiLoopSearchAndFilter = {
        and: [],
    };
    partnererbjudandeAndFilter.and.push(partnerTypeFilter);

    // POINTSHOP
    const pointshopValidityStartFilter: ApiLoopSearchFilter = {
        type: 'attribute',
        attributeName: 'pointshop_validityStartDate',
        comparisonMode: 'lessThan',
        value: new Date().toISOString().replace('T', ' '),
    };
    const pointshopValidityEndFilter: ApiLoopSearchFilter = {
        type: 'attribute',
        attributeName: 'pointshop_validityEndDate',
        comparisonMode: 'greaterThan',
        value: new Date().toISOString().replace('T', ' '),
    };
    const pointshopTypeFilter: ApiLoopSearchFilter = {
        type: 'type',
        value: 'PointshopEntity',
        comparisonMode: 'equals',
    };
    const notHiddenFilter: ApiLoopSearchFilter = {
        type: 'attribute',
        attributeName: 'pointshop_category',
        value: 'Dold kategori',
        comparisonMode: 'equals',
    };
    const pointshopNotFilter: ApiLoopSearchInverseFilter = {
        not: notHiddenFilter,
    };

    const pointshopAndFilter: ApiLoopSearchAndFilter = {
        and: [],
    };
    pointshopAndFilter.and.push(pointshopValidityEndFilter);
    pointshopAndFilter.and.push(pointshopValidityStartFilter);
    pointshopAndFilter.and.push(pointshopTypeFilter);
    pointshopAndFilter.and.push(pointshopNotFilter);

    const orFilter: ApiLoopSearchOrFilter = {
        or: [],
    };
    orFilter.or.push(partnererbjudandeAndFilter);
    orFilter.or.push(pointshopAndFilter);

    const searchRequestPointshop = {
        page: 0,
        resultsPerPage: 0,
        facets: [],
        sortBy: [],
        filter: orFilter,
    };

    return searchFlow.searchGlobalLoop(
        {
            searchTerm: query,
            storeId: currentProductionUnit,
            productSearchRequest: {
                ...searchRequest,
                resultsPerPage: resultCountsPerPage.products,
            },
            articleSearchRequest: {
                ...searchRequest,
                resultsPerPage: resultCountsPerPage.articles,
            },
            storeSearchRequest: {
                ...searchRequest,
                resultsPerPage: resultCountsPerPage.stores,
            },
            recipeSearchRequest: {
                ...searchRequest,
                resultsPerPage: resultCountsPerPage.recipes,
            },
            pointshopSearchRequest: {
                ...searchRequestPointshop,
                resultsPerPage: resultCountsPerPage.pointshop,
            },
        },

        abortSignal,
        quickSearch,
        personalizeSearch,
    );
};

export const useGlobalSearchResultState = (
    pageCounts: SearchResultPageCountPerType,
    personalizeSearch?: boolean,
) => {
    const currentProductionUnit = useAppSelector(storeSelectors.currentProductionUnit);
    const { execute, result, error, isLoading, status } = useAsyncDispatcher(
        (_query: string, _currentProductionUnit: string) => {
            return globalSearchAbortable(
                _query,
                _currentProductionUnit,
                pageCounts,
                undefined,
                false,
                personalizeSearch,
            );
        },
    );

    const fetch = useCallback(
        (_query: string) => {
            if (currentProductionUnit) {
                execute(_query, currentProductionUnit);
            }
        },
        [currentProductionUnit, execute],
    );
    return {
        result,
        error,
        isLoading,
        fetch,
        status,
    };
};

const getGlobalSearchSingleTypeRequest = (
    searchTerm: string,
    storeId: string,
    selectedPage: number,
    pageSize: number,
    resultType: SearchResultType,
): GlobalSearchRequest => {
    const filledSingleTypeRquest = {
        page: selectedPage,
        resultsPerPage: pageSize,
        facets: [],
        sortBy: [],
    };

    // PARTNERERBJUDANDEN
    const partnerTypeFilter: ApiLoopSearchFilter = {
        type: 'type',
        comparisonMode: 'equals',
        value: 'PartneroffersEntity',
    };
    const partnererbjudandeAndFilter: ApiLoopSearchAndFilter = {
        and: [],
    };
    partnererbjudandeAndFilter.and.push(partnerTypeFilter);

    // POINTSHOP
    const pointshopValidityStartFilter: ApiLoopSearchFilter = {
        type: 'attribute',
        attributeName: 'pointshop_validityStartDate',
        comparisonMode: 'lessThan',
        value: new Date().toISOString().replace('T', ' '),
    };
    const pointshopValidityEndFilter: ApiLoopSearchFilter = {
        type: 'attribute',
        attributeName: 'pointshop_validityEndDate',
        comparisonMode: 'greaterThan',
        value: new Date().toISOString().replace('T', ' '),
    };
    const pointshopTypeFilter: ApiLoopSearchFilter = {
        type: 'type',
        value: 'PointshopEntity',
        comparisonMode: 'equals',
    };
    const notHiddenFilter: ApiLoopSearchFilter = {
        type: 'attribute',
        attributeName: 'pointshop_category',
        value: 'Dold kategori',
        comparisonMode: 'equals',
    };
    const pointshopNotFilter: ApiLoopSearchInverseFilter = {
        not: notHiddenFilter,
    };

    const pointshopAndFilter: ApiLoopSearchAndFilter = {
        and: [],
    };

    pointshopAndFilter.and.push(pointshopValidityEndFilter);
    pointshopAndFilter.and.push(pointshopValidityStartFilter);
    pointshopAndFilter.and.push(pointshopTypeFilter);
    pointshopAndFilter.and.push(pointshopNotFilter);

    const orFilter: ApiLoopSearchOrFilter = {
        or: [],
    };
    orFilter.or.push(partnererbjudandeAndFilter);
    orFilter.or.push(pointshopAndFilter);

    const filledSingleTypeFilterRquest = {
        page: selectedPage,
        resultsPerPage: pageSize,
        facets: [],
        sortBy: [],
        filter: orFilter,
    };

    const globalSearchRequest: GlobalSearchRequest = {
        searchTerm,
        storeId,
        productSearchRequest:
            resultType === SearchResultType.Products ? filledSingleTypeRquest : undefined,
        articleSearchRequest:
            resultType === SearchResultType.Articles ? filledSingleTypeRquest : undefined,
        pointshopSearchRequest:
            resultType === SearchResultType.Pointshop ? filledSingleTypeFilterRquest : undefined,
        storeSearchRequest:
            resultType === SearchResultType.Stores ? filledSingleTypeRquest : undefined,
        recipeSearchRequest:
            resultType === SearchResultType.Recipes ? filledSingleTypeRquest : undefined,
        recipeCategoriesSearchRequest:
            resultType === SearchResultType.Recipes
                ? { ...filledSingleTypeRquest, page: 0 }
                : undefined, // every time we fetch recipes in GlobalSearchState, we also want recipe categories
    };

    return globalSearchRequest;
};

export const useGlobalSearchSingleTypeState = (
    pageSize: number,
    resultType: SearchResultType,
    page: number,
) => {
    const currentProductionUnit = useAppSelector(storeSelectors.currentProductionUnit);
    const { execute, result, error, isLoading, status } = useAsyncDispatcher(
        (_query: string, _selectedPage: number, _currentProductionUnit: string) => {
            return searchFlow.searchGlobalLoop(
                getGlobalSearchSingleTypeRequest(
                    _query,
                    _currentProductionUnit,
                    _selectedPage,
                    pageSize,
                    resultType,
                ),
                undefined,
            );
        },
        { keepPreviousResult: true },
    );

    const fetch = useCallback(
        (_query: string) => {
            if (currentProductionUnit) {
                execute(_query, page, currentProductionUnit);
            }
        },
        [currentProductionUnit, execute, page],
    );

    return {
        isLoading,
        result,
        error,
        fetch,
        status,
    };
};
