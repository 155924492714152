export const MEMBER_CONSENT_QUERY = 'memberConsent';
export const MEMBER_CONSENT_NEWSLETTERS_EMAIL = [
    'cooOnline',
    'cooDRDigital',
    'cooPartnermail',
    'cooNewsletter',
];
export const MEMBER_CONSENT_NEWSLETTERS_SMS = [
    'cooOnlineSMS',
    'cooDRDigitalSMS',
    'cooPartnerSMS',
    'cooNewsletterSMS',
];
