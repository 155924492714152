import * as React from 'react';

import type { Icon } from '../Icon';
import { AccessibleIcon } from '../Icon';
import Button from './Button';
import type { ButtonProps } from './Button.types';

type IconButtonProps = {
    icon: Parameters<typeof Icon>[0]['icon'];
    label: string;
} & Omit<ButtonProps, 'asIcon' | 'asChild'>;

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ icon, label, children, ...buttonProps }, ref) => {
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Button {...buttonProps} asIcon ref={ref}>
                <AccessibleIcon label={label} icon={icon} />
                {children}
            </Button>
        );
    },
);

export default IconButton;
