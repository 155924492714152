import { Button, ModalHeader, Notification } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { Helpers } from '../../../../../../utility/helpers';
import TextInputRHF from '../../../../../common/components/molecules/TextInputRHF';
import messageRelayFlow from '../../../../../common/flow/messageRelayFlow';
import styles from './FormPhone.module.less';

type PhoneFormInputs = {
    phoneNumber: string;
};

interface FormPhoneProps {
    headline: string;
    phoneDefaultValue?: string;
    showCancelButton: boolean;
    cancelButtonText: string;
    onSubmitSuccess: () => void;
    setPhoneNumber: (arg0: string) => void;
    onClose: (newPhoneNumber: string) => void;
    setTimeLeft: (arg0: number) => void;
}

const FormPhone: React.FC<React.PropsWithChildren<FormPhoneProps>> = (props: FormPhoneProps) => {
    const [apiErrorMessage, setApiErrorMessage] = React.useState('');
    const inputRef = useRef<HTMLElement | null>(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Manually focus on mount
        }
    }, []);

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<PhoneFormInputs>();

    const sendVerificationMutation = useMutation({
        mutationFn: (phone: string) => messageRelayFlow.sendVerification(phone),
        onSuccess: (data: number) => {
            if (data) {
                props.setTimeLeft(data);
            }
            props.onSubmitSuccess();
        },
        onError: (error) => {
            setApiErrorMessage(error.friendlyMessage);
        },
    });

    const onSubmit: SubmitHandler<PhoneFormInputs> = (data) => {
        const formattedPhoneNumber = Helpers.swedishPhoneNumberFullFormat(data.phoneNumber);
        props.setPhoneNumber(formattedPhoneNumber);
        sendVerificationMutation.mutate(formattedPhoneNumber);
    };

    const validationText = (phoneNumber: string) => {
        let text = 'Mobilnumret är felaktigt.';
        if (phoneNumber) {
            const cleanedPhoneNumber = Helpers.swedishPhoneNumberFullFormat(phoneNumber);
            if (cleanedPhoneNumber.length !== 12) {
                text = 'Mobilnumret har inte rätt längd.';
            } else if (!cleanedPhoneNumber.startsWith('+46')) {
                text = 'Det går bara att använda svenska mobilnummer.';
            }
        }

        return text;
    };

    const checkedPhoneNumber = (phoneNumber: string) => {
        // Phonenumber is checked for any way of writing, can include spaces, hyphens, parentheses etc
        const cleanedPhoneNumber = Helpers.swedishPhoneNumberFullFormat(phoneNumber);

        // Starts with 46 (swedish number) and 9 digits after
        const regex = /^\+46\d{9}$/;

        return regex.test(cleanedPhoneNumber);
    };

    return (
        <div>
            <Notification
                header="Något blev fel"
                behaviour="autoclosing"
                type="alert"
                show={!!apiErrorMessage}
                message={apiErrorMessage}
                close={() => setApiErrorMessage('')}
            />

            <ModalHeader className={styles.Headline}>{props.headline}</ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} className="u-sizeFull">
                <div className={styles.InnerContent}>
                    <div className={styles.InputDiv}>
                        <Controller
                            render={({ field, fieldState }) => {
                                return (
                                    <TextInputRHF
                                        label="Mobilnummer"
                                        value={field.value || ''}
                                        name={field.name}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        ref={(e) => {
                                            field.ref(e);
                                            inputRef.current = e;
                                        }}
                                        validationText={validationText(field.value)}
                                        isInvalid={
                                            !!fieldState.error ||
                                            (!!field.value && !checkedPhoneNumber(field.value))
                                        }
                                    />
                                );
                            }}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Mobilnummer måste anges',
                                },
                                validate: { checkedPhoneNumber },
                            }}
                            defaultValue={props.phoneDefaultValue ?? undefined}
                            name="phoneNumber"
                            control={control}
                        />
                    </div>

                    <div className={styles.ButtonContainer}>
                        <Button
                            autoFocus={!!isValid}
                            type="submit"
                            theme="primary"
                            fullWidth
                            isLoading={sendVerificationMutation.isPending}
                        >
                            Skicka verifikationskod
                        </Button>
                        {props.showCancelButton && (
                            <Button
                                type="button"
                                theme="primaryOutline"
                                fullWidth
                                onClick={() => props.onClose('')}
                            >
                                {props.cancelButtonText ?? 'Avbryt'}
                            </Button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FormPhone;
