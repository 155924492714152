/* eslint-disable react/jsx-props-no-spreading */
import type { PropsWithChildren } from 'react';
import * as React from 'react';
import { pick } from 'underscore';

import { Loader } from '../Loader';
import type { ChipProps } from './Chip';
import Chip from './Chip';
import { getSpinnerSizeForChipSize } from './Chip.utils';

type AllOptionalKeys<T> = { [K in keyof T]-?: undefined extends T[K] ? K : never }[keyof T];
type AllNonOptionalKeys<T> = { [K in keyof T]-?: undefined extends T[K] ? never : K }[keyof T];

type OptionalToMaybeUndefined<T> = { [K in AllOptionalKeys<T>]: T[K] | undefined } & {
    [K in AllNonOptionalKeys<T>]: T[K];
};

type ChipButtonBaseProps = {
    isLoading?: boolean;
    loadingText?: string;
} & Omit<ChipProps, 'asChild'>;

export type ChipButtonProps = ChipButtonBaseProps & React.ComponentPropsWithoutRef<'button'>;

const ChipButton = React.forwardRef<HTMLButtonElement, PropsWithChildren<ChipButtonProps>>(
    ({ children, ...props }, ref) => {
        const customProps: OptionalToMaybeUndefined<ChipButtonBaseProps> = {
            asIcon: props.asIcon,
            count: props.count,
            countVariant: props.countVariant,
            fullWidth: props.fullWidth,
            isActive: props.isActive,
            rightSlot: props.rightSlot,
            leftSlot: props.leftSlot,
            size: props.size,
            theme: props.theme,
            isLoading: props.isLoading,
            loadingText: props.loadingText,
            className: props.className,
        };

        const keysNotFromCustomProps = Object.keys(props).filter(
            (key) => !Object.keys(customProps).includes(key),
        );

        const buttonProps = pick(props, keysNotFromCustomProps);

        return (
            <Chip
                {...customProps}
                rightSlot={
                    customProps.isLoading ? (
                        <Loader
                            type="spinner"
                            size={getSpinnerSizeForChipSize(props.size)}
                            ariaHidden
                        />
                    ) : (
                        customProps.rightSlot
                    )
                }
                asChild
            >
                {/* https://twitter.com/housecor/status/1710698679735062741 */}
                <button
                    ref={ref}
                    {...buttonProps}
                    type="button"
                    onClick={
                        customProps.isLoading
                            ? (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                              }
                            : buttonProps.onClick
                    }
                    disabled={buttonProps.disabled}
                >
                    {props.isLoading ? props.loadingText || 'Laddar' : children}
                </button>
            </Chip>
        );
    },
);

export default ChipButton;
