import type { AxiosInstance } from 'axios';

import { appConfig } from '../../../../appConfig';
import axiosClientFactory from './clientFactory';

const getDirectKimProxyAxiosClient = (accessToken: string): AxiosInstance => {
    const directKimProxyAxiosClient = axiosClientFactory.createDirectApiClient({
        baseURL: appConfig.coopSettings.serviceAccess.kimProxyApiUrl,
        headers: {
            Accept: 'application/json',
            'Ocp-Apim-Trace': 'true',
            'Ocp-Apim-Subscription-Key':
                appConfig.coopSettings.serviceAccess.kimProxyApiSubscriptionKey,
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
        params: {
            'api-version': appConfig.coopSettings.serviceAccess.kimProxyApiVersion,
        },
    });

    return directKimProxyAxiosClient;
};

export default getDirectKimProxyAxiosClient;
