import type { ApiException } from '../../exceptions/exceptionDefinitions';
import {
    createDefaultException,
    isLocalApiKnownException,
    isLocalApiOpenIdException,
    isLocalRedirectApiException,
} from '../../exceptions/exceptionUtils';

export interface AxiosException<T> {
    response: { data: T; status: number };
}

// Interceptors job is to convert API's custom error into ApiException that frontend can understand and rely on when implementing error handling
export type CustomErrorInterceptor<TApiError = unknown> = (
    error: AxiosException<TApiError>,
) => ApiException | null;

export const defaultApiErrorInterceptor =
    <TApiError = unknown>(customInterceptors?: CustomErrorInterceptor<TApiError>[]) =>
    (error: AxiosException<TApiError>): Promise<ApiException> => {
        const defaultException = createDefaultException();

        // No response but there was an HTTP error, then create generic exception
        if (!error.response) {
            return Promise.reject(defaultException);
        }

        let exception = defaultException;
        const interceptors = customInterceptors?.filter(Boolean) || [];

        for (let i = 0; i < interceptors.length; i += 1) {
            const interceptorResult = interceptors[i](error);

            if (interceptorResult) {
                exception = interceptorResult;
                break;
            }
        }

        return Promise.reject(exception);
    };

// In our local API we know whats the structure, so its immediately the right TS type
export const localApiExceptionInterceptor: CustomErrorInterceptor = (error) => {
    if (isLocalApiKnownException(error.response.data)) {
        return error.response.data;
    }

    return null;
};

export const localApiOpenIdInterceptor: CustomErrorInterceptor = (error) => {
    const { data } = error.response;
    if (isLocalApiOpenIdException(data)) {
        if (data.isOpenIdError && data.redirectUrl) {
            setTimeout(() => {
                window.location.href = data.redirectUrl;
            }, 3000);
        }

        const defaultException = createDefaultException();
        return defaultException;
    }

    return null;
};

export const localApiRedirectInterceptor: CustomErrorInterceptor = (error) => {
    const { data } = error.response;
    if (isLocalRedirectApiException(data)) {
        if (data.redirectUrl) {
            setTimeout(() => {
                window.location.href = data.redirectUrl;
            }, 3000);
        }

        const defaultException = createDefaultException(data.friendlyMessage);
        return defaultException;
    }

    return null;
};
