export class Cookies {
    static create(name: string, value: string, days?: number, secure?: boolean) {
        let date: Date;
        let expires: string;

        if (days) {
            date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        } else {
            expires = '';
        }
        document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
            value,
        )}${expires}; path=/${secure ? '; secure' : ''}`;
    }

    static read(name: string) {
        let c: string;
        const nameEq = `${name}=`;
        const ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i += 1) {
            c = ca[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEq) === 0) {
                return decodeURIComponent(c.substring(nameEq.length, c.length));
            }
        }
        return undefined;
    }

    static eraseCookie(name: string) {
        Cookies.create(name, '', -1);
    }
}
