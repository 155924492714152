/* eslint-disable react/jsx-props-no-spreading */
import * as Tooltip from '@radix-ui/react-tooltip';
import * as React from 'react';

import styles from './Tooltip.module.scss';

const StyledTooltipContent = React.forwardRef<HTMLDivElement, Tooltip.TooltipContentProps>(
    (props, ref) => (
        <Tooltip.Content {...props} className={styles.Content} ref={ref} sideOffset={5}>
            {props.children}
            <Tooltip.Arrow width={15} height={7} className={styles.Arrow} />
        </Tooltip.Content>
    ),
);

const StyledTooltipArrow = React.forwardRef<SVGSVGElement, Tooltip.TooltipArrowProps>(
    (props, ref) => <Tooltip.Arrow {...props} className={styles.Arrow} ref={ref} />,
);

const StyledTooltip = {
    ...Tooltip,
    Arrow: StyledTooltipArrow,
    Content: StyledTooltipContent,
};

export { Tooltip };

export { StyledTooltip };
