/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface CartLoadingState {
    isLoading: boolean;
    isInitialized: boolean;
    isProductDataAvailable: boolean;
}

export const getInitialState = (): CartLoadingState => ({
    isLoading: false,
    isInitialized: false,
    isProductDataAvailable: false,
});

const slice = createSlice({
    name: 'cartLoading',
    initialState: getInitialState(),
    reducers: {
        initLoading: (state) => {
            state.isLoading = true;
        },
        finishLoading: (state) => {
            state.isLoading = false;
        },
    },
});

export const { initLoading, finishLoading } = slice.actions;

export default slice.reducer;
