import currentProductOffers from './currentProductOffers';
import dynamicYield from './dynamicYield/dynamicYield';
import filter from './filter';
import recipes from './recipes';
import sustainability from './sustainability';

export default {
    sustainability,
    filter,
    currentProductOffers,
    recipes,
    dynamicYield,
};
