import { Icon, OverlayLoader } from '@coop/components';
import { CoopLogoIcon, Menu1Icon } from '@coop/icons';
import classNames from 'classnames';
import * as React from 'react';

import { appConfig } from '../../../../appConfig';
import { FlyIn } from '../../../common/components/atoms/Modal';
import useModal from '../../../common/hooks/useModal';
import { useUserTypeQuery } from '../../../common/hooks/useUserType';
import { FlyInType } from '../../../common/store/structureDefinitions/modalState';
import { UserType } from '../../../common/store/structureDefinitions/userState';
import HeaderIconButton from '../HeaderIconButton/HeaderIconButton';
import type { SiteNavigationItem } from './FeaturedNavigation';

const SiteNavigation = React.lazy(() => import('../SiteNavigation'));

interface HeaderLeftNavProps {
    disableSiteNavigation: boolean;
    navigationItems: SiteNavigationItem[];
}

const HeaderLeftNav = (props: React.PropsWithChildren<HeaderLeftNavProps>) => {
    const { isOpen, close, open } = useModal(FlyInType.SiteNavigation);

    const { data: userType } = useUserTypeQuery();
    const isPunchout = userType === UserType.punchout;

    return (
        <>
            {!props.disableSiteNavigation && (
                <>
                    <HeaderIconButton
                        icon={Menu1Icon}
                        label="Meny"
                        onClick={open}
                        theme="bordered"
                    />
                    <FlyIn
                        isOpen={isOpen}
                        close={close}
                        sizePx={375}
                        alignment="left"
                        initialFocusOnDialog={false}
                    >
                        <React.Suspense fallback={<OverlayLoader isLoading />}>
                            <SiteNavigation onClose={close} />
                        </React.Suspense>
                    </FlyIn>
                </>
            )}
            <a
                className={classNames('Header-logo')}
                href={
                    appConfig.coopUserSettings.isShoppingForAnother || isPunchout
                        ? appConfig.coopSettings.ecommerce.page.url
                        : appConfig.coopSettings.startPage.url
                }
                aria-label="Till startsidan"
            >
                <Icon icon={CoopLogoIcon} aria-hidden />
            </a>
        </>
    );
};

export default HeaderLeftNav;
