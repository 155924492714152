/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { appConfig } from '../../../../../appConfig';
import cartApi from '../../../api/cart/cartApi';
import { isLocalApiBaseException } from '../../../exceptions/exceptionUtils';
import type { DeliveryAddressState } from '../../structureDefinitions/checkoutState';
import { checkoutResetAction } from './checkoutActions';

const initialState: DeliveryAddressState = {
    isInitialized: false,
    isFetching: false,
    error: '',
};

export const selectDeliveryAddress = createAsyncThunk<string, string, { rejectValue: string }>(
    'cart/selectDeliveryAddress',
    async (addressId: string, { rejectWithValue }) => {
        try {
            await cartApi.deliveryAddresses.select(addressId);
            return addressId;
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                return rejectWithValue(err.friendlyMessage);
            }
            return rejectWithValue(appConfig.coopSettings.ecommerce.errorMessage.general);
        }
    },
);

export const getDeliveryAddress = createAsyncThunk<string, void, { rejectValue: string }>(
    'cart/getDeliveryAddress',
    async (args, { rejectWithValue }) => {
        try {
            const deliveryAddress = await cartApi.deliveryAddresses.current();
            return deliveryAddress && deliveryAddress.id;
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                return rejectWithValue(err.friendlyMessage);
            }
            return rejectWithValue(appConfig.coopSettings.ecommerce.errorMessage.general);
        }
    },
);

const slice = createSlice({
    name: 'deliveraddress',
    initialState,
    reducers: {
        set: (state: DeliveryAddressState, action: PayloadAction<DeliveryAddressState>) => {
            return action.payload;
        },
        reset: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(selectDeliveryAddress.pending.type, (state: DeliveryAddressState) => {
            state.isFetching = true;
            state.error = '';
        });
        builder.addCase(selectDeliveryAddress.rejected, (state: DeliveryAddressState, action) => {
            state.error = action.payload ? action.payload : action.error.message;
            state.isFetching = false;
        });
        builder.addCase(
            selectDeliveryAddress.fulfilled.type,
            (state: DeliveryAddressState, action: PayloadAction<string>) => {
                state.id = action.payload;
                state.isInitialized = state.isInitialized || !!action.payload;
                state.isFetching = false;
                state.error = '';
            },
        );
        builder.addCase(getDeliveryAddress.pending.type, (state: DeliveryAddressState) => {
            state.isFetching = true;
            state.error = '';
        });
        builder.addCase(getDeliveryAddress.rejected, (state: DeliveryAddressState, action) => {
            state.isFetching = false;
            state.error = action.payload ? action.payload : action.error.message;
        });
        builder.addCase(
            getDeliveryAddress.fulfilled.type,
            (state: DeliveryAddressState, action: PayloadAction<string>) => {
                state.id = action.payload;
                state.isInitialized = true;
                state.isFetching = false;
                state.error = '';
            },
        );
        builder.addCase(checkoutResetAction, () => {
            return initialState;
        });
    },
});

/** Do not use this one, use checkoutFlow instead
 * */
export const selectedDeliveryAddressActions = {
    select: selectDeliveryAddress,
    fetch: getDeliveryAddress,
    reset: slice.actions.reset,
};

export const { reducer: selectedDeliveryAddressReducer } = slice;
