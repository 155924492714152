/**
 * A file intended to decouple tracking from the massive analyticsHandler.ts file
 *
 * Should contain simple functions that's easy to customize instead of strict events
 *
 * Furthermore, in analyticsHandler.ts a lot of CustomEvents where created, here we just push it
 * directly in the dataLayer - tracking team has accepted this - if in the future they change their mind
 * I recommend just sending a simple CustomEvent after push
 *
 */
import { appConfig } from '../../../appConfig';
import type { GaEvents } from './trackingTypes';

export type GaLoginStatus = 'Inloggad medlem' | 'Inloggad ej medlem' | 'Utloggad';

export const getLoginStatus = (): GaLoginStatus | undefined => {
    const isMember = appConfig.coopUserSettings.isMedmeraCustomer;
    const isLoggedIn = appConfig.coopUserSettings.isAuthenticated;

    if (isMember && isLoggedIn) {
        return 'Inloggad medlem';
    }

    if (isLoggedIn) {
        return 'Inloggad ej medlem';
    }

    if (isLoggedIn === false) {
        return 'Utloggad';
    }

    return undefined;
};

/**
 * Tracking want to combine some parameters sent as a "event key"
 */
export const getGa4EventKey = (parametersToCombine: string[]) => {
    return parametersToCombine
        .filter(Boolean)
        .map((str) => str?.split?.(' ')?.join?.('_')) // use replaceAll instead when possible
        .join('_')
        .toLowerCase();
};

/**
 * Use case 1:
 * sendEvent({ event: 'interaction' })
 *
 * Use case 2:
 * interface SomeEvent extends GaEvent { ..
 * sendInteractionEvent<SomeEvent>({ ... })
 *
 * @param eventData Event data
 */
export const sendEvent = <T extends GaEvents>(eventData: T) => {
    setTimeout(() => {
        const gaEvent: T = {
            ...eventData,
            loginStatus: getLoginStatus(),
        };

        const analyticsData = JSON.parse(JSON.stringify(gaEvent));
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push(analyticsData);
    }, 0);
};

/**
 * Use case 1:
 * sendInteractionEvent({ someAttribute: 'someAttribute' })
 *
 * Use case 2:
 * interface SomeEvent { ... }
 * sendInteractionEvent<SomeEvent>({ ... })
 */
export const sendInteractionEvent = <T extends {}>(eventData: T) => {
    sendEvent({
        event: 'interaction',
        eventCategory: 'Ecommerce',
        ...eventData,
    });
};
