import { combineReducers } from '@reduxjs/toolkit';

import cncUiReducer from './cncUiSlice';
import debugSlice from './debugSlice';
import sharedUiReducer from './sharedUiSlice';

const combined = combineReducers({
    cnc: cncUiReducer,
    shared: sharedUiReducer,
    debug: debugSlice.reducer,
});

export default combined;
