import '../../../utility/window.utils';

import type { QueryKey } from '@tanstack/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { type FC, type ReactNode } from 'react';
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { NotificationStateProvider } from '../../myCoop/myCoopShared/contexts/NotificationContext';
import { CriticalErrorBoundry } from '../components/atoms/ErrorBoundry';
import Portal from '../components/atoms/Portal';
import {
    CriticalErrorNotification,
    GlobalErrorNotification,
} from '../components/molecules/GroupErrorNotifications';
import { useAppSelector } from '../hooks/useThunkDispatch';
import store from '../store';
import { coopQueryClient } from './coopQueryClient';

interface ReactAppWrapperProps {
    children: ReactNode;
    routerBasename?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialQueryClientData?: {
        key: QueryKey;
        data: unknown;
    }[];
}

const ReactAppWrapper: FC<React.PropsWithChildren<ReactAppWrapperProps>> = (props) => {
    if (props.initialQueryClientData?.length) {
        props.initialQueryClientData.forEach((item) => {
            const { key, data } = item;
            coopQueryClient.setQueryData(key, data);
        });
    }

    return (
        <Provider store={store}>
            <QueryClientProvider client={coopQueryClient}>
                <BrowserRouter basename={props.routerBasename}>
                    <NotificationStateProvider>
                        {/* notification state needs to be used for both Mountable Components and Mountable Pages */}
                        <CriticalErrorBoundry>{props.children}</CriticalErrorBoundry>
                    </NotificationStateProvider>

                    <CurrentPageAnnouncer />
                    <CriticalErrorNotification />
                    <GlobalErrorNotification />
                </BrowserRouter>
                {/* By default, React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development', */}
                <Portal domNodeSelector="#react-query-devtools">
                    <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
                </Portal>
            </QueryClientProvider>
        </Provider>
    );
};

const CurrentPageAnnouncer = () => {
    const title = useAppSelector((state) => state.currentPage.title);

    const accessiblePageTitle = title?.replace('-', '').replace('|', '');

    return (
        <Portal domNodeSelector="#accessibilityLogger">
            <div className="u-hiddenVisually" aria-live="assertive" aria-atomic="true">
                {accessiblePageTitle}
            </div>
        </Portal>
    );
};

export default ReactAppWrapper;
