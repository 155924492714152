/* eslint-disable class-methods-use-this */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
import { Cookies } from '../../utility/cookies';

export class LocalStorageFacade {
    private static SESSION_COOKIE_KEY: string = 'StorageSessionId';

    private static _instance: LocalStorageFacade;

    public static get instance(): LocalStorageFacade {
        return this._instance || (this._instance = new this());
    }

    add(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    get(key: string) {
        return localStorage.getItem(key);
    }

    public addObject<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public getObject<T>(key: string) {
        const storageItem = localStorage.getItem(key);
        if (storageItem === null) return undefined;
        try {
            return <T>JSON.parse(storageItem);
        } catch (ex) {
            return undefined;
        }
    }

    public getSessionId(): string {
        const sessionId = Cookies.read(LocalStorageFacade.SESSION_COOKIE_KEY);
        if (sessionId) return sessionId;
        const val = Math.random().toString(36).substring(7);
        Cookies.create(LocalStorageFacade.SESSION_COOKIE_KEY, val);
        return val;
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }
}
