/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
interface QueueObject<T> {
    promise: Promise<T>;
    queueFunc: () => Promise<void>;
    uniqueName?: string;
}

interface RunOptions<T> {
    /** If this is set, all the functions with the same name will be resolved as one */
    uniqueName?: string;
    /** if this is set, unique methods wont be pushed to the back of the queue when added  */
    dontPushBack?: boolean;
    /** what should happen when the event should be pushed back but is running already */
    alreadyRunning?: (promise?: Promise<T>) => void;

    /** accept task that is running on top of queue */
    acceptRunningPromise?: boolean;
}

class SyncronQueue {
    private _queue: Array<QueueObject<any>>;

    private _timeout: number = 20000;

    constructor() {
        this._queue = [];
    }

    /**
     *
     * @param func function that should be unique
     * @param isUnique this function must be unique, if not, put last in queue
     */
    run<T>(func: () => Promise<T>, options?: RunOptions<T>): Promise<T> {
        if (options && options.uniqueName) {
            const index = this._queue.findIndex((queue) => queue.uniqueName === options.uniqueName);

            if (index > 0) {
                if (!options.dontPushBack) {
                    const existingQueueItem = this._queue.splice(index, 1);
                    this._queue.push(existingQueueItem[0]);
                    return existingQueueItem[0].promise;
                }
                return this._queue[index].promise;
            }
            if (index === 0 && this._queue.length === 1) {
                return this._queue[0].promise;
            }
            if (index === 0 && this._queue.length > 1) {
                if (options) {
                    if (options.acceptRunningPromise) {
                        return this._queue[0].promise;
                    }
                    // we are not gona push this back because event is running, trigger event
                    options?.alreadyRunning?.(this._queue[0].promise);
                }
            }
        }

        let queueFunc: () => Promise<void>;
        const promise = new Promise<T>((resolve, reject) => {
            queueFunc = () => {
                const timer = setTimeout(() => {
                    reject(new Error('Anropet tog för lång tid'));
                }, this._timeout);
                return func()
                    .then((t) => resolve(t))
                    .catch((r) => reject(r))
                    .finally(() => {
                        clearTimeout(timer);
                    });
            };
        }).finally(() => {
            this._queue.shift(); //  remove the finished job

            if (this._queue.length > 0) {
                // start next one in queue
                try {
                    this._queue[0].queueFunc();
                    // eslint-disable-next-line no-empty
                } catch {}
            }
        });

        if (this._queue.length === 0) {
            // @ts-ignore
            queueFunc();
        }

        this._queue.push({
            promise,
            // @ts-ignore
            queueFunc,
            uniqueName: options && options.uniqueName,
        });

        return promise;
    }
}

export { SyncronQueue };
