import { appConfig } from '../../appConfig';
import type { PageHeadData } from '../common/components/atoms/PageHead';
import { ensureTrailingSlash } from '../common/utils/urlUtils';
import type { EpiPageTypes } from './components/pages/epiPageTypes';
import type { EpiSitePageType } from './components/pages/epiSitePageType';

export const getCssClassForDisplayOptionWithDefaultTag = (name: DisplayOption) => {
    const potentialCssClass = appConfig.coopSettings.epi.blockDisplayOptionToCssClass[name];

    if (potentialCssClass) {
        return `${appConfig.coopSettings.epi.baseChildrenCssClass} ${potentialCssClass}`;
    }

    return appConfig.coopSettings.epi.baseChildrenCssClass;
};

export const filterContentArea = <T extends IContent>(
    contentArea: ContentAreaItem[],
    predicate: (content: IContent) => content is T,
): T[] => {
    const contents =
        contentArea
            ?.map((x) => x.contentLink?.expanded)
            .filter(Boolean)
            .filter(predicate) || [];
    return contents;
};

export const filterContent = <T extends IContent>(
    contentItems: IContent[],
    predicate: (content: IContent) => content is T,
): T[] => {
    const filteredContents = contentItems?.filter(predicate) || [];
    return filteredContents;
};

export const filterContentType = <T extends EpiPageTypes[keyof EpiPageTypes]>(
    contents: IContent[],
    contentType: keyof EpiPageTypes,
): T[] => {
    return (contents?.filter((item) => item.contentType.includes(contentType)) as T[]) || [];
};

/**
 * Use with caution, EPI adds ?epieditmode=True in edit mode, but so can users
 */
export const isEpiEditMode = () =>
    new URLSearchParams(window.location.search).get('epieditmode') === 'True';

export const editTableProp = (name: string) => (window.epi?.inEditMode ? name : undefined);

export const mapEpiPageHeadData = (page: EpiSitePageType): PageHeadData => {
    const canonicalUrl = page.canonicalUrl && ensureTrailingSlash(page.canonicalUrl);

    return {
        title: page.pageTitle,
        link: {
            canonical: canonicalUrl,
        },
        meta: {
            description: page.metaDescription,
            'og:url': canonicalUrl,
            'og:description': page.metaDescription,
        },
    };
};

export const isEpiPageViewSettings = (page: IContent): page is PageViewSettingsIContent => {
    return 'contentPageSize' in (page as PageViewSettingsIContent);
};

export const isContentReference = (input: unknown): input is ContentReference => {
    return 'url' in (input as ContentReference);
};
