import * as React from 'react';

export const MainPageLayoutContext = React.createContext<
    | {
          /**
           * Example: ArticlePage or BlockListingPage used under EcommerceLayout
           */
          isInsideAnotherLayout: boolean;
      }
    | undefined
>(undefined);
