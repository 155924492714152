import { useRef } from 'react';

type F<T> = { value: T };

export default function useConstant<T>(callback: () => T): T {
    const ref = useRef<F<T>>();

    if (!ref.current) {
        ref.current = { value: callback() };
    }

    return ref.current.value;
}
