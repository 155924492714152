import { createDefaultException } from '../../exceptions/exceptionUtils';
import type { ApiValidateCartError, HybrisApiError } from '../clients/directHybrisAxiosClient';
import type { AxiosException } from '../clients/localApiAxiosErrorInterceptors';

const validateCartErrorInterceptor = (
    error: AxiosException<HybrisApiError<ApiValidateCartError>>,
) => {
    if (!Array.isArray(error?.response.data.errors)) {
        return null;
    }

    const firstError = error.response.data.errors[0];
    switch (firstError.errorCode) {
        // this errorcode is returned by the validation endpoint, to force it, login with punchout, go to last step, empty cart in another window, click "skicka varukorg"
        // https://external-qa.api.coop.se/ecommerce/coop/users/8000002/carts/current/validation
        case 'TOTAL_NOT_VALID':
            return createDefaultException(
                'Av någon anledning är ditt ordervärde för lågt för att lägga en order. Fyll gärna på med något mer du behöver för att uppnå gränsen!',
                firstError.errorCode,
            );
        default:
            return null;
    }
};

export default validateCartErrorInterceptor;
