import { ResourceProvider } from '../../../../utility/resourceProvider';
import { getProductsByIds } from '../../api/products/directProductApi';
import { mapLoopProduct } from './mapProduct';

type GetProductsbyIdsRestParams = ExtractParamsSkipTwo<Parameters<typeof getProductsByIds>>;

export class DirectProductProvider extends ResourceProvider<
    ApiLoopProduct,
    GetProductsbyIdsRestParams
> {
    private static singleInstance: DirectProductProvider;

    static get instance(): DirectProductProvider {
        const i =
            this.singleInstance ||
            (this.singleInstance = new this(getProductsByIds, (item) => item.id));

        return i;
    }

    getItemsById(ids: string[], productionUnit: string, ...params: GetProductsbyIdsRestParams) {
        return super.getItemsById(ids, productionUnit, ...params);
    }

    async getItemsMap(
        ids: string[],
        productionUnit: string,
        ...params: GetProductsbyIdsRestParams
    ) {
        const items = await super.getItemsById(ids, productionUnit, ...params);
        const map = new Map<string, ApiProduct>();
        items.forEach((x) => map.set(this.itemUniqueKey(x), mapLoopProduct(x)));
        return map;
    }
}
