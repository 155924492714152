import { Button, ConfirmInfo } from '@coop/components';
import { useState } from 'react';
import * as React from 'react';

import upscopeUtils from '../../../utility/upscopeUtils';

const UpscopeTriggerButton = () => {
    const [show, setShow] = useState(false);
    const [upscopeCode, setUpscopeCode] = useState('');

    const onClick = () => {
        upscopeUtils.getLookupCode((code) => {
            setUpscopeCode(code);
            setShow(true);
        });
    };
    return (
        <>
            <Button type="button" onClick={onClick} theme="light">
                Skärmdelning via Upscope
            </Button>

            <ConfirmInfo
                show={show}
                heading="Skärmdelning med kundtjänst"
                message={`Vänligen ange kod : ${upscopeCode}`}
                close={() => {
                    setShow(false);
                }}
            />
        </>
    );
};

export default UpscopeTriggerButton;
