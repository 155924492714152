import type {
    ApiLoopDistinctFacet,
    ApiLoopSearchAndFilter,
    ApiLoopSearchFilter,
    ApiLoopSearchOrFilter,
    ApiLoopSort,
} from '../api/searchRequests';
import type {
    SearchRequestAndFilter,
    SearchRequestFacet,
    SearchRequestFilter,
    SearchRequestOrFilter,
    SortOrderType,
} from '../models/models';
import { SearchFacetType } from '../models/models';

const sortOrderMap: Readonly<Record<SortOrderType, ApiLoopSort<string>>> = {
    popular: { order: 'desc', type: 'popularity' },
    'product_name+asc': {
        order: 'asc',
        attributeName: 'name',
        type: 'attribute',
    },
    'product_effectiveprice_cents+asc': {
        order: 'asc',
        attributeName: 'piecePrice',
        type: 'attribute',
    },
    'product_effectiveprice_cents+desc': {
        order: 'desc',
        attributeName: 'piecePrice',
        type: 'attribute',
    },
    'product_effectiveprice_with_promo+asc': {
        order: 'asc',
        attributeName: 'lowestOnlineSinglePiecePrice',
        type: 'attribute',
    },
    'product_effectiveprice_with_promo+desc': {
        order: 'desc',
        attributeName: 'lowestOnlineSinglePiecePrice',
        type: 'attribute',
    },
};

const facetMap: Record<SearchFacetType, ApiLoopDistinctFacet> = {
    categories: {
        attributeName: 'categories',
        type: 'distinct',
        operator: 'AND',
        name: SearchFacetType.RecipeCategories,
        selected: [],
    },
    brand: {
        attributeName: 'manufacturerName',
        type: 'distinct',
        operator: 'OR',
        name: SearchFacetType.Brand,
        selected: [],
    },
    loyaltybrand: {
        attributeName: 'brand',
        type: 'distinct',
        operator: 'AND',
        name: SearchFacetType.Loyaltybrand,
        selected: [],
    },
    loyaltyCategory: {
        attributeName: 'category',
        type: 'distinct',
        operator: 'AND',
        name: SearchFacetType.LoyaltyCategory,
        selected: [],
    },
    categoryIds: {
        attributeName: 'categoryIds',
        type: 'distinct',
        operator: 'OR',
        name: SearchFacetType.CategoryIds,
        selected: [],
    },
    environmentalLabels: {
        attributeName: 'filterLabels',
        type: 'distinct',
        operator: 'AND',
        name: SearchFacetType.EnvironmentalLabels,
        selected: [],
    },
    topCategory: {
        attributeName: 'topCategory',
        type: 'distinct',
        operator: 'OR',
        name: SearchFacetType.TopCategory,
        selected: [],
    },
};

export const mapSortOrder = (sortOrders: string[]) => {
    const sortBy: ApiLoopSort<string>[] = [];
    if (sortOrders) {
        sortOrders.forEach((sort) => {
            if (sort === 'default') {
                sortBy.push(sortOrderMap.popular);
                return;
            }

            const sortOrder = sortOrderMap[sort as SortOrderType];
            if (sortOrder) {
                sortBy.push(sortOrder);
            } else {
                // eslint-disable-next-line no-console
                console.warn(
                    `mapping for sortOrder: ${sort} not found, possible sort orders: ${Object.keys(
                        sortOrderMap,
                    ).join(',')}`,
                );
            }
        });
    }

    return sortBy;
};

export const mapFacets = (facets: SearchRequestFacet[]) => {
    const loopFacets: ApiLoopDistinctFacet[] = [];
    if (facets) {
        facets.forEach((facet) => {
            const loopFacet = facetMap[facet.type];
            const selectedFacets = facet.selected ? [...facet.selected] : [];
            if (loopFacet) {
                loopFacet.selected = selectedFacets;
                if (facet.operator) {
                    loopFacet.operator = facet.operator;
                }
                loopFacets.push(loopFacet);
            } else {
                // eslint-disable-next-line no-console
                console.warn(
                    `mapping for facet: ${facet.type} not found, possible facets: ${Object.keys(
                        facetMap,
                    ).join(',')}`,
                );
            }
        });
    }

    return loopFacets;
};

export const mapFilter = (filter: SearchRequestFilter) => {
    const activeFilter: ApiLoopSearchFilter = {
        type: filter.type,
        attributeName: filter.attributeName,
        comparisonMode: filter.comparisonMode,
        value: filter.value,
    };

    return activeFilter;
};

export const mapAndFilter = (filter: SearchRequestAndFilter) => {
    const activeFilterAnd: ApiLoopSearchAndFilter = {
        and: filter.and,
    };
    return activeFilterAnd;
};

export const mapAndOrFilter = (filter: SearchRequestAndFilter | SearchRequestOrFilter) => {
    if (isAnd(filter)) {
        const activeFilterAnd: ApiLoopSearchAndFilter = {
            and: filter.and,
        };
        return activeFilterAnd;
    }

    const activeFilterOr: ApiLoopSearchOrFilter = {
        or: filter.or,
    };
    return activeFilterOr;
};

type Z = SearchRequestAndFilter | SearchRequestOrFilter;

export const isAnd = (obj: Z): obj is SearchRequestAndFilter => {
    return obj.type === 'And';
};
