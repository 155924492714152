import { Helpers } from '../../../../utility/helpers';
import { sendInteractionEvent } from '../utils';

const sendGaEditOrderCheckoutSummary = (
    editOrderTotalPrice: number,
    total: number,
    editOrderTotalDifference: number,
) => {
    sendInteractionEvent({
        eventAction: 'Edit Order',
        eventLabel: 'Slutför köp - edit order',
        editOrder: 'Editerad order',
        tidigareBetalt_metric9: Helpers.truncateDecimalsWithSeparator(
            editOrderTotalPrice,
            2,
            '',
            '.',
        ).replace(/ /g, ''),
        nyttOrdervärde_metric10: Helpers.truncateDecimalsWithSeparator(total, 2, '', '.').replace(
            / /g,
            '',
        ),
        differensOrdervärde_metric11: Helpers.truncateDecimalsWithSeparator(
            editOrderTotalDifference,
            2,
            '',
            '.',
        ).replace(/ /g, ''),
    });
};

const editOrderTracking = {
    summary: sendGaEditOrderCheckoutSummary,
};

export default editOrderTracking;
