import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRouterContext } from '../contexts/useRouterContext';

type Path = string;

export interface AppHistoryState {
    noScroll?: boolean;
}

interface RouteState {
    state?: AppHistoryState;
    search?: string;
}

const isPath = (arg: Path | RouteState): arg is Path => {
    return typeof arg === 'string';
};

const useReactRouting = () => {
    const navigate = useNavigate();
    const routerContext = useRouterContext();
    const isReactRouted = routerContext !== null;

    const pushPath = useCallback(
        (path: string, isRedirect: boolean = false) => {
            if (!path.startsWith('/')) {
                window.location.href = path;
                return;
            }

            if (isRedirect) {
                window.location.href = path;
                return;
            }

            if (!isReactRouted) {
                window.location.href = path;
                return;
            }

            navigate(path);
        },
        [isReactRouted, navigate],
    );

    const prepareLocation = useCallback(
        (location: RouteState) => {
            const newLocationState = { ...location };

            if (routerContext) {
                newLocationState.state = {
                    ...location.state,
                };
            } else {
                newLocationState.state = {};
            }

            return newLocationState;
        },
        [routerContext],
    );

    const pushLocation = useCallback(
        (location: RouteState) => {
            const newLocation = prepareLocation(location);
            navigate(newLocation);
        },
        [navigate, prepareLocation],
    );

    const push = useCallback(
        (path: Path | RouteState, isRedirect: boolean = false) => {
            if (isPath(path)) {
                pushPath(path, isRedirect);
                return;
            }

            pushLocation(path);
        },
        [pushLocation, pushPath],
    );

    const replace = useCallback(
        (location: Path | RouteState) => {
            if (isPath(location)) {
                navigate(location);
                return;
            }

            const newLocation = prepareLocation(location);
            navigate(newLocation);
        },
        [navigate, prepareLocation],
    );

    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return {
        push,
        pushLocation,
        replace,
        goBack,
    };
};

export default useReactRouting;
