import { trim } from '../../../utils/stringUtils';
import { failSilently } from '../../utils/failSilently';
import { sendGA4Event } from '../shared/sendGa4Event';

const OUTBOUND_DISALLOWED_DOMAINS = [
    'coop.se',
    'login.coop.se',
    'dr.coop.se',
    'local.coop.se',
    'beta.coop.se',
];
const OUTBOUND_DISALLOWED_KEYWORDS = ['tel:', 'mailto:'];

const OUTBOUND_DOMAINS_WITH_URL_IN_QUERYSTRING = ['clk.tradedoubler.com'];

export const getOutboundClickEventData = (href: string) => {
    if (!href) return null;

    const url = new URL(href);
    const domain = url.hostname.replace('www.', '');

    if (OUTBOUND_DOMAINS_WITH_URL_IN_QUERYSTRING.includes(domain)) {
        const queryString = new URLSearchParams(href);
        const urlInQueryString = queryString.get('url');
        if (urlInQueryString) {
            const { hostname } = new URL(urlInQueryString);

            return {
                linkDomain: hostname,
                linkUrl: trim(url.toString(), '/'),
            };
        }
    }

    const isDisallowedToSendEvent =
        domain.includes('www-test') ||
        domain.includes('cooptest') ||
        OUTBOUND_DISALLOWED_KEYWORDS.find((item) => href.includes(item)) ||
        OUTBOUND_DISALLOWED_DOMAINS.find((item) => domain === item);

    if (isDisallowedToSendEvent) {
        return null;
    }

    return {
        linkDomain: domain,
        linkUrl: trim(url.toString(), '/'),
    };
};

const outboundClick = (href: string, clickText: string, additionalData: object) => {
    const data = getOutboundClickEventData(href);
    if (data) {
        const gaEvent: OutboundClickEvent = {
            event: 'outbound_click',
            click_domain: data.linkDomain,
            click_url: data.linkUrl,
            click_text: clickText || '',
            ...additionalData,
        };

        sendGA4Event(gaEvent);
    }
};

export default {
    outboundClick: failSilently(outboundClick),
};
