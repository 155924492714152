import type {
    ApiFullCartItem,
    ApiSubstituteProductResponse,
} from '../../api/cart/responses/cartResponses';

const getVariantName = (varianceCode: string, product: ApiProduct) => {
    if (product.variants && product.variants.length > 0) {
        const variantProduct = product.variants.find((variant) => variant.id === varianceCode);
        return variantProduct && variantProduct.name;
    }
    return undefined;
};

export const mapProductToApiFullCartItem = (
    result: ApiSubstituteProductResponse,
    product: ApiProduct,
): ApiFullCartItem => {
    const cartItem: ApiFullCartItem = {
        id: result.entryNumber.toString(),
        name: result.name,
        productId: result.productIdentifier,
        variantId: result.variantId,
        quantity: result.quantity,
        manufacturer: result.manufacturer,
    };

    if (product) {
        cartItem.image = product.image && product.image.url;
        cartItem.priceData = product.priceData;
        cartItem.productUrl = product.url;
        cartItem.variantName = getVariantName(result.variantId, product);
    }

    if (product && product.details) {
        cartItem.packageSizeInformation = `${product.details.size && product.details.size.size} ${
            product.details.size.unit && product.details.size.unit.toLowerCase()
        }`;
    }

    return cartItem;
};
