import { Button, ModalHeader } from '@coop/components';
import * as React from 'react';

import Image from '../../../../../common/components/atoms/Image';
import styles from './Success.module.less';

const Success = ({
    onClose,
    phoneNumber,
}: {
    onClose: (newPhoneNumber: string) => void;
    phoneNumber: string;
}) => {
    return (
        <div className={styles.Container}>
            <ModalHeader className={styles.Headline}>Klart!</ModalHeader>
            <Image src="/Assets/Icons/paperplane.svg" height="114" width="136" alt="" />
            <p>Ditt mobilnummer är sparat!</p>
            <Button
                autoFocus
                type="submit"
                theme="primary"
                fullWidth
                onClick={() => onClose(phoneNumber)}
                aria-label="Ditt mobilnummer är sparat! Okej, stäng fönster."
            >
                Okej
            </Button>
        </div>
    );
};

export default Success;
