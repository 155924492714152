import { sendInteractionEvent } from '../utils';

const createSendLoginReminderEvent = (eventLabel: string) => () => {
    sendInteractionEvent({
        eventCategory: 'login',
        eventAction: 'Psst-logga-in-puff',
        eventLabel,
    });
};

export default {
    goToLogin: createSendLoginReminderEvent('Logga in'),
    goToCreateAccount: createSendLoginReminderEvent('Skapa inloggning'),
    close: createSendLoginReminderEvent('Stäng'),
};
