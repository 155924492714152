import { useCallback, useEffect, useMemo, useRef } from 'react';

import { appConfig } from '../../../appConfig';
import { cleanupAbTests, isAbTestPreview, setupAbTest } from '../../../utility/abTests';
import gaTracking from '../../common/tracking/tracking';

/**
 * This hook makes the user responsible for triggering interaction tracking
 */
export const useImperativeAbTest = (args: {
    name: string;
    contentId?: string;
    userId?: string;
}) => {
    const { name, contentId, userId } = args;

    const { isVariant: isResultVariant, isEnabled: isTestEnabled } = useMemo(() => {
        const epiAbTest = appConfig.coopSettings.featureFlags.abTests
            ?.filter((item) => !!item.enabled)
            .find(
                (t) =>
                    (t.contentId.length === 0 && t.name === name) ||
                    (t.contentId.length !== 0 &&
                        t.name === name &&
                        !!contentId &&
                        t.contentId.includes(contentId)),
            );

        let isVariant = false;
        let isEnabled = false;

        if (epiAbTest) {
            const abTest = setupAbTest(epiAbTest, userId);
            isVariant = abTest.abTestValue === '1';
            isEnabled = true;
        }

        return { isVariant: isVariant || isAbTestPreview(), isEnabled };
    }, [contentId, name, userId]);

    const trackInteraction = useCallback(
        (variant: string) => {
            // eslint-disable-next-line no-console
            console.logDev(`Triggered ${name} AB test. Result: ${variant}`);

            if (isAbTestPreview()) return;

            gaTracking.abTest.userInteraction(name, variant);
        },
        [name],
    );

    return { isVariant: isResultVariant, trackInteraction, isEnabled: isTestEnabled };
};

/**
 * This hook will automatically trigger the test when component loads
 */
export const useAbTest = ({
    name,
    contentId,
    userId,
    enabled,
    trackOnce = false,
}: {
    name: string;
    contentId?: string;
    userId?: string;
    enabled: boolean;
    trackOnce?: boolean;
}) => {
    const { isVariant, isEnabled, trackInteraction } = useImperativeAbTest({
        name,
        contentId,
        userId,
    });

    const alreadyTrackedRef = useRef(false);

    const trackAbTest = useCallback(
        (variant: string) => {
            if (!trackOnce) {
                trackInteraction(variant);
                return;
            }

            if (!alreadyTrackedRef.current) {
                alreadyTrackedRef.current = true;
                trackInteraction(variant);
            }
        },
        [trackInteraction, trackOnce],
    );

    useEffect(() => {
        cleanupAbTests();
    }, []);

    useEffect(() => {
        if (isEnabled && enabled) {
            if (isVariant) {
                trackAbTest('B');
            } else {
                trackAbTest('A');
            }
        }
    }, [trackAbTest, isEnabled, enabled, isVariant, name, contentId]);

    // to become variant you need to check all flags. We dont want to show variant if someone had previously saved abtest "B" and the test is disabled
    return { isVariant: isEnabled && enabled && isVariant };
};
