/* eslint-disable no-console */
import type { IEventTelemetry } from '@microsoft/applicationinsights-web';
import type { ComponentType, LazyExoticComponent } from 'react';
import { lazy } from 'react';

import { appInsights } from '../../appInsights';
import type { ComponentModule } from '../common/init/componentLoader';
import { trimEnd } from '../common/utils/stringUtils';
import * as EpiEmptyComponent from './components/base/EpiEmptyComponent';

enum EpiComponentTypeEnum {
    Block = 'Block',
    Page = 'Page',
    PagePartial = 'PagePartial',
}

type EpiComponentType =
    | EpiComponentTypeEnum.Block
    | EpiComponentTypeEnum.Page
    | EpiComponentTypeEnum.PagePartial;

const lazyComponents: Record<
    string,
    LazyExoticComponent<ComponentType<React.PropsWithChildren<IContent>>>
> = {};

const getEpiComponentModule = (componentType: EpiComponentType, componentName: string) => {
    let module: Promise<ComponentModule<IContent>>;
    const cacheKey = componentName + componentType;

    if (lazyComponents[cacheKey]) {
        return lazyComponents[cacheKey];
    }

    switch (componentType) {
        case 'Block':
            module = import(`./components/blocks/${componentName}/${componentName}`);
            break;
        case 'Page':
            module = import(`./components/pages/${componentName}/${componentName}`);
            break;
        case 'PagePartial':
            module = import(`./components/pagePartials/${componentName}/${componentName}`);
            break;
        default:
            module = import(`./components/base/EpiEmptyComponent`);
            console.error(`componentLoader - could not load (default) ${componentName}`);
            break;
    }

    const modulePromise = new Promise<ComponentModule<IContent>>((resolve) => {
        module.then(
            (response) => {
                console.logDev(`componentLoader - loaded: ${componentName}`);
                resolve(response);
            },
            (reason) => {
                const messageText = `componentLoader - could not load ${componentName}`;
                console.error(messageText);
                console.logDev(reason);
                const message: IEventTelemetry = {
                    name: messageText,
                };

                appInsights.trackEvent(message, { currentUrl: window.location.href });
                resolve(EpiEmptyComponent);
            },
        );
    });

    const lazyModule = lazy(() => modulePromise);
    lazyComponents[cacheKey] = lazyModule;

    return lazyModule;
};

type ContentType = Pick<IContent, 'contentType'>;

const getEpiComponentType = (content: ContentType, options?: LoadComponentOptions) => {
    let componentType = content.contentType.find(
        (x) => x === EpiComponentTypeEnum.Block || x === EpiComponentTypeEnum.Page,
    ) as EpiComponentType;

    /* check if we are in the context of content area. If so if the content is a page, we need to load a partial page view later on
    consider moving that to content api expand content area resolver */
    if (componentType === EpiComponentTypeEnum.Page && options?.isContentAreaItem) {
        componentType = EpiComponentTypeEnum.PagePartial;
    }

    return componentType;
};

const getEpiComponentData = (content: ContentType, options?: LoadComponentOptions) => {
    if (!content) return null;

    const componentType = getEpiComponentType(content, options);

    let contentTypeToMount = content.contentType.find((contentType) =>
        contentType.endsWith('Type'),
    );

    if (!contentTypeToMount && content.contentType[1]) {
        // eslint-disable-next-line prefer-destructuring
        contentTypeToMount = content.contentType[1];
    }

    if (!contentTypeToMount) {
        return { componentType, componentName: content.contentType?.join() };
    }

    let componentName = `Epi${trimEnd(contentTypeToMount, 'Type')}`;
    if (componentType === EpiComponentTypeEnum.PagePartial) {
        componentName += 'Partial';
    }

    return { componentType, componentName };
};

export interface LoadComponentOptions {
    isContentAreaItem?: boolean;
}

export const getEpiComponent = (content: ContentType, options?: LoadComponentOptions) => {
    const componentData = getEpiComponentData(content, options);

    if (componentData) {
        const LazyComponent = getEpiComponentModule(
            componentData?.componentType,
            componentData?.componentName,
        );
        return LazyComponent;
    }

    return null;
};
