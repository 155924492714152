import type { AxiosInstance, AxiosRequestConfig } from 'axios';

import { appConfig } from '../../../../appConfig';
import axiosClientFactory from '../clients/clientFactory';

const getDirectDynamicYieldProductClient = (timeout?: number): AxiosInstance => {
    const axiosRequestConfig: AxiosRequestConfig = {
        baseURL: appConfig.coopSettings.serviceAccess.personalizationApiUrl,
        headers: {
            'Ocp-Apim-Subscription-Key':
                appConfig.coopSettings.serviceAccess.personalizationApiSubscriptionKey,
        },
        params: {
            'api-version': appConfig.coopSettings.serviceAccess.personalizationApiVersion,
        },
    };

    if (timeout) {
        axiosRequestConfig.timeout = timeout;
    }

    const directDynamicYieldProductClient =
        axiosClientFactory.createDirectApiClient(axiosRequestConfig);

    return directDynamicYieldProductClient;
};

export default getDirectDynamicYieldProductClient;
