'use client';

import { useEffect, useState } from 'react';

export const useDelayedIsLoading = (isLoading?: boolean, delay?: number) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (delay === undefined) {
            return;
        }

        if (!isLoading) {
            setShow(false);
        }

        const timer = setTimeout(() => {
            if (isLoading) {
                setShow(true);
            }
        }, delay);

        return () => clearTimeout(timer);
    }, [delay, isLoading]);

    return show;
};
