import { appInsights } from '../../../appInsights';
import { normalizeCartToReduxState } from '../../../facades/ecommerce/cart/normalizeCartToReduxState';
import cartItemsSelectors from '../../cart/selectors/cartItemsSelectors';
import type { ApiCart } from '../api/cart/responses/cartResponses';
import type {
    ApiAddMultipleRecipesRequest,
    ApiAddMultipleRecipesResponse,
} from '../api/recipe/recipeModels';
import { SyncronQueue } from '../api/SyncronQueue';
import type { ApiHybrisTimeslot } from '../api/timeslots/timeslotsApi';
import { timeslotsApi } from '../api/timeslots/timeslotsApi';
import { isCartException, isLocalApiBaseException } from '../exceptions/exceptionUtils';
import { currentCartFlow } from '../flow/cart/currentCartFlow';
import { productFlow } from '../flow/product/productFlow';
import type { HybrisCart } from '../models/hybris/hybrisCart';
import type { HybrisCartModification } from '../models/hybris/hybrisCartModification';
import type { StoreContextSettings } from '../persistance/cookie/storeContextSettings';
import persistance from '../persistance/persistance';
import { storeSelectors } from '../selectors/storeSelectors';
import type { AppAsyncThunk } from '../store';
import { finishLoading, initLoading } from '../store/slices/cart/cartApiStateSlice';
import { cartActions } from '../store/slices/cart/cartItemsSlice';
import { cartRecipeActions } from '../store/slices/cart/cartRecipesSlice';
import { setFullCart } from '../store/slices/cart/cartSlice';
import { cartTimeslotActions } from '../store/slices/cart/cartTimeslotSlice';
import { errorsActions } from '../store/slices/errors/errorsSlice';
import { storeActions } from '../store/slices/store/storeSlice';
import { ErrorGroup } from '../store/structureDefinitions/errorsState';
import { DeliveryMode } from '../store/structureDefinitions/storeState';
import { storeThunks } from './storeThunks';

const cartQueue = new SyncronQueue();
const timeslotQueue = new SyncronQueue();
const FETCH_CART_QUEUE_NAME = 'fetchcart';

const buildCartState = async (cart: ApiCart, storeId: string) => {
    const cartProducts = await productFlow.getProductsByIds(
        cart.items.map((cartItem) => cartItem.productId),
        storeId,
    );

    const cartState = normalizeCartToReduxState(cart, cartProducts);
    return cartState;
};

const getCartSilent = (): AppAsyncThunk<Awaited<ReturnType<typeof buildCartState>>> => {
    return async (dispatch, getState) => {
        return cartQueue.run(
            async () => {
                const currentStoreId = storeSelectors.currentProductionUnit(getState());
                const cart = await currentCartFlow.getCart();

                const cartState = await buildCartState(cart, currentStoreId);
                dispatch(setFullCart(cartState));
                await dispatch(storeThunks.syncStoreWithCookie());
                return cartState;
            },
            { uniqueName: FETCH_CART_QUEUE_NAME },
        );
    };
};

const getCart = (): ReturnType<typeof getCartSilent> => {
    return async (dispatch) => {
        dispatch(initLoading());
        try {
            const result = await dispatch(getCartSilent());
            dispatch(finishLoading());
            return result;
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
            dispatch(finishLoading());
            return Promise.reject(err);
        }
    };
};

const emptyCart = (): AppAsyncThunk => {
    return async (dispatch) => {
        dispatch(initLoading());
        try {
            await currentCartFlow.emptyCart();
            await dispatch(getCartSilent());
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
            dispatch(finishLoading());
        }
    };
};

const removeRecipe = (recipeId: string): AppAsyncThunk => {
    return async (dispatch) => {
        dispatch(initLoading());
        try {
            await cartQueue.run(() => currentCartFlow.removeRecipe(recipeId));
            dispatch(cartRecipeActions.removeRecipe(recipeId));
            await dispatch(getCartSilent());
            dispatch(finishLoading());
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
            dispatch(finishLoading());
        }
    };
};

const buyMultipleRecipes = (
    request: ApiAddMultipleRecipesRequest,
): AppAsyncThunk<ApiAddMultipleRecipesResponse> => {
    return async (dispatch) => {
        const response = await cartQueue.run(() => currentCartFlow.buyMultipleRecipes(request));
        await dispatch(getCartSilent());
        return response;
    };
};

/** Does not create cart, if we want to use this for anonymous users, that functionality must be added */
const addItems = (
    items: {
        productId: string;
        variantId?: string;
        quantity: number;
    }[],
): AppAsyncThunk => {
    return async (dispatch) => {
        dispatch(initLoading());
        try {
            const cartId = await currentCartFlow.getCurrentCartId();
            if (!cartId) {
                throw new Error('No cart id in updateQuantity');
            }
            await currentCartFlow.addItemsBulk(cartId, items);
            await dispatch(getCartSilent());
            dispatch(finishLoading());
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
            dispatch(finishLoading());
        }
    };
};

const changeRecipePortions = (recipeId: string, portions: number): AppAsyncThunk => {
    return async (dispatch) => {
        dispatch(initLoading());
        try {
            await cartQueue.run(() => currentCartFlow.changeRecipePortions(recipeId, portions));
            dispatch(cartRecipeActions.changeRecipePortions({ recipeId, portions }));
            await dispatch(getCartSilent());
            dispatch(finishLoading());
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
            dispatch(finishLoading());
        }
    };
};

const getCurrentTimeslot = (): AppAsyncThunk<ApiHybrisTimeslot> => {
    return async (dispatch) => {
        return timeslotQueue.run(
            async () => {
                const current = await timeslotsApi.current();
                dispatch(cartTimeslotActions.selectTimeslot(current));
                return current;
            },
            { acceptRunningPromise: true, dontPushBack: true, uniqueName: 'fetchTimeslot' },
        );
    };
};

const selectTimeslot = (timeslotId: string): AppAsyncThunk<ApiHybrisTimeslot> => {
    return async (dispatch) => {
        await timeslotsApi.select(timeslotId);
        const currentTimeslot = await timeslotsApi.current();
        dispatch(cartTimeslotActions.selectTimeslot(currentTimeslot));
        dispatch(getCartSilent()); // update reservation cost

        return currentTimeslot;
    };
};

const selectTimeslotWithStore = (
    timeslotId: string,
    postalCode: string,
    baseStoreId?: string,
): AppAsyncThunk<ApiHybrisTimeslot> => {
    return async (dispatch) => {
        await timeslotsApi.select(timeslotId);
        const currentTimeslot = await timeslotsApi.current();
        dispatch(cartTimeslotActions.selectTimeslot(currentTimeslot));

        await dispatch(storeThunks.updateHomeDeliveryStore(postalCode, baseStoreId));

        dispatch(getCartSilent()); // update reservation cost

        return currentTimeslot;
    };
};

const changeVariant = (
    cartItemId: string,
    productId: string,
    quantity: number,
    newVariantId: string,
): AppAsyncThunk => {
    return async (dispatch) => {
        dispatch(initLoading());
        try {
            await currentCartFlow.changeItemVariant(cartItemId, productId, quantity, newVariantId);
            await dispatch(getCartSilent());
            dispatch(finishLoading());
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
            dispatch(finishLoading());
        }
    };
};

const ensureStoreContextInSync = (cart: HybrisCart): AppAsyncThunk => {
    return async (dispatch, getState) => {
        const state = getState();
        const currentProductionUnitId = storeSelectors.currentProductionUnit(state);
        const currentPostalCode = storeSelectors.currentPostalCode(state);
        const currentPickupPointId = storeSelectors.currentPickupPointId(state);
        let updatedStoreContext: StoreContextSettings | undefined;
        let deliveryMode;
        if (
            !(
                (cart.coopStore?.code && cart.deliveryMode?.code === DeliveryMode.homedelivery) ||
                (cart.pointOfService?.name && cart.deliveryMode?.code === DeliveryMode.pickup)
            )
        ) {
            const cookieSettings = persistance.storeContext.getDefaultStoreContext();
            updatedStoreContext = {
                productionUnitId: cookieSettings.productionUnitId,
                pickupPointId: cookieSettings.pickupPointId || undefined,
                storeName: cookieSettings.storeName || undefined,
                zipCode: cookieSettings.zipCode || undefined,
            };
            if (cart.deliveryMode?.code === 'homedelivery') {
                deliveryMode = DeliveryMode.homedelivery;
            } else if (cart.deliveryMode?.code === 'pickup') {
                deliveryMode = DeliveryMode.pickup;
            }
        } else if (cart.deliveryMode.code === DeliveryMode.homedelivery) {
            deliveryMode = DeliveryMode.homedelivery;
            if (
                currentProductionUnitId !== cart.coopStore?.code ||
                cart.postCode !== currentPostalCode
            ) {
                updatedStoreContext = {
                    zipCode: cart.postCode?.replace(' ', ''),
                    productionUnitId: cart.coopStore?.code,
                    storeName: cart.coopStore?.name,
                };
            }
        } else if (
            cart.deliveryMode.code === DeliveryMode.pickup &&
            (cart.pointOfService?.name !== currentPickupPointId ||
                cart.pointOfService?.storeId !== currentProductionUnitId)
        ) {
            deliveryMode = DeliveryMode.pickup;
            updatedStoreContext = {
                pickupPointId: cart.pointOfService?.name!,
                productionUnitId: cart.pointOfService?.storeId!,
                storeName: cart.pointOfService?.displayName,
            };
        }

        if (updatedStoreContext) {
            persistance.storeContext.set({
                pickupPointId: updatedStoreContext.pickupPointId || null,
                zipCode: updatedStoreContext.zipCode || null,
                productionUnitId: updatedStoreContext.productionUnitId,
                storeName: updatedStoreContext.storeName || null,
            });
            await dispatch(
                storeActions.syncStore({
                    deliveryMode,
                    pickupPointId: updatedStoreContext.pickupPointId,
                    postalCode: updatedStoreContext.zipCode,
                    productionUnitId: updatedStoreContext.productionUnitId,
                    storeName: updatedStoreContext.storeName,
                }),
            );
            appInsights.trackEvent(
                { name: 'ensureStoreContextInSync' },
                {
                    deliveryMode,
                    pickupPointId: updatedStoreContext.pickupPointId,
                    postalCode: updatedStoreContext.zipCode,
                    productionUnitId: updatedStoreContext.productionUnitId,
                    storeName: updatedStoreContext.storeName,
                },
            );
        }
    };
};

const createCart = (): AppAsyncThunk<HybrisCart> => {
    return async (dispatch, getState) => {
        // try resync store context
        const state = getState();
        const deliverMode = storeSelectors.selectedDeliveryMode(state);
        const postalCode = storeSelectors.currentPostalCode(state);
        const pickupPointId = storeSelectors.currentPickupPointId(state);

        const cart = await currentCartFlow.createCartAsync(deliverMode, postalCode, pickupPointId);
        await dispatch(ensureStoreContextInSync(cart));
        return cart;
    };
};

const updateQuantity = (
    productId: string,
    variantId: string | undefined,
    quantity: number,
    silent: boolean = false,
): AppAsyncThunk => {
    return async (dispatch, getState) => {
        if (!silent) {
            dispatch(initLoading());
        }
        try {
            // appSelectors can only be used in thunks, not inside "normal"
            await cartQueue.run(async () => {
                const previousCartItemState = cartItemsSelectors.selectById(getState(), {
                    productId,
                    variantId,
                });

                const cartId = await currentCartFlow.getCurrentCartId();
                if (!cartId) {
                    throw new Error('No cart id in updateQuantity');
                }
                dispatch(
                    cartActions.updateCartItem({
                        productId,
                        variantId,
                        quantity,
                    }),
                );

                try {
                    let response;
                    try {
                        response = await currentCartFlow.updateItemQuantity(
                            cartId,
                            productId,
                            variantId,
                            quantity,
                        );

                        return response;
                    } catch (cartErr) {
                        if (!isCartException(cartErr)) {
                            throw cartErr;
                        }

                        if (cartErr?.code !== 'notFound') {
                            // eslint-disable-next-line @typescript-eslint/no-throw-literal
                            throw cartErr;
                        }
                        const cart = await dispatch(createCart());

                        response = await currentCartFlow.updateItemQuantity(
                            cart.guid!,
                            productId,
                            variantId,
                            quantity,
                        );
                    }

                    return response;
                } catch (err) {
                    dispatch(
                        cartActions.restoreItem({
                            productId,
                            variantId,
                            cartItem: previousCartItemState,
                        }),
                    );
                    return Promise.reject(err);
                }
            });

            if (!silent) {
                await dispatch(getCartSilent());
                dispatch(finishLoading());
            }
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
            dispatch(finishLoading());
            return Promise.reject(err);
        }
    };
};

const substituteProduct = (
    id: string,
    newProductId: string,
    prevProductId: string,
    storeId: string,
    prevVariantId?: string,
): AppAsyncThunk => {
    return async (dispatch) => {
        try {
            const newCartItem = await cartQueue.run(() =>
                currentCartFlow.substituteProduct(id, newProductId, storeId),
            );
            if (newCartItem) {
                dispatch(
                    cartActions.substituteProduct({
                        newCartItem,
                        prevItemId: id,
                        prevProductId,
                        prevVariantId,
                    }),
                );
                await dispatch(getCart());
            }
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
            return Promise.reject(err);
        }
    };
};

const applyVoucher = (id: string): AppAsyncThunk<undefined> => {
    return async (dispatch) => {
        try {
            await cartQueue.run(() => currentCartFlow.applyVoucher(id));
            await dispatch(getCartSilent());
            return await Promise.resolve(undefined);
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                if (!err.friendlyMessage) {
                    err.friendlyMessage = 'Felaktig Kampanjkod.';
                }
                return Promise.reject(err);
            }
            return Promise.reject(err);
        }
    };
};

const removeVoucher = (id: string): AppAsyncThunk => {
    return async (dispatch) => {
        try {
            await cartQueue.run(() => currentCartFlow.removeVoucher(id));
            await dispatch(getCartSilent());
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
        }
    };
};

const changeReplaceability = (
    productId: string,
    variantId: string | undefined,
    quantity: number, // needs to be there since we have one endpoint that does not like empty quantity
    replaceable: boolean,
): AppAsyncThunk<HybrisCartModification> => {
    return async (dispatch) => {
        try {
            dispatch(
                cartActions.changeCartItemReplaceability({
                    productId,
                    variantId,
                    replaceable,
                }),
            );
            return await cartQueue.run(() =>
                currentCartFlow.changeReplaceability(productId, variantId, quantity, replaceable),
            );
        } catch (err) {
            dispatch(
                cartActions.changeCartItemReplaceability({
                    productId,
                    variantId,
                    replaceable: !replaceable,
                }),
            );
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
            return Promise.reject(err);
        }
    };
};

const setItemsReplaceability = (replaceable: boolean): AppAsyncThunk => {
    return async (dispatch) => {
        // we block with loader cause backend response with full cart, which can take a couple of seconds...
        dispatch(initLoading());
        try {
            await cartQueue.run(() => currentCartFlow.setItemsReplaceability(replaceable));
            dispatch(cartActions.setAllCartItemsReplaceability(replaceable));
            dispatch(finishLoading());
        } catch (err) {
            if (isLocalApiBaseException(err)) {
                dispatch(errorsActions.addSingle({ group: ErrorGroup.Cart, error: err }));
            }
            dispatch(finishLoading());
        }
    };
};

const restoreSavedCart =
    (savedCartId: string): AppAsyncThunk<HybrisCart> =>
    async (dispatch) => {
        const response = await currentCartFlow.restoreSavedCart(savedCartId);
        await dispatch(getCartSilent());
        return response;
    };

const addToCartFromOrder =
    (orderId: string): AppAsyncThunk<HybrisCart> =>
    async (dispatch) => {
        const response = await currentCartFlow.addToCartFromOrder(orderId);
        await dispatch(getCartSilent());
        return response;
    };

export const cartThunks = {
    getCartSilent,
    getCart,
    emptyCart,
    removeRecipe,
    buyMultipleRecipes,
    addItems,
    changeRecipePortions,
    getCurrentTimeslot,
    selectTimeslot,
    selectTimeslotWithStore,
    changeVariant,
    updateQuantity,
    substituteProduct,
    applyVoucher,
    removeVoucher,
    changeReplaceability,
    setItemsReplaceability,
    restoreSavedCart,
    addToCartFromOrder,
};
