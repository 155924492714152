import _ from 'underscore';

import { Browser, BrowserSize } from '../utility/browser';

export const bannerResizeHandler = () => {
    const BANNERCELL_DEFAULT_MIN_HEIGHT = '350px';
    const BANNERCELL_NO_MIN_HEIGHT = '100%'; // For easter promotion html block

    const resizeBannerCells = (
        bannerCells: NodeListOf<HTMLDivElement>,
        desktopMinHeight: string,
    ) => {
        bannerCells.forEach((cell) => {
            if (window.innerWidth < Browser.getBreakpoint(BrowserSize.sm).from) {
                cell.style.setProperty('min-height', `${cell.clientWidth}px`);
            } else {
                cell.style.setProperty('min-height', desktopMinHeight);
            }
        });
    };

    const onResize = () => {
        const bannerCellsDefault = document.querySelectorAll<HTMLDivElement>(
            '.Banner:not(.Banner--noMinHeight):not(.Banner--noMinHeightOnDesktop) .Banner-cell',
        );
        const bannerCellsNoMinHeightOnDesktop = document.querySelectorAll<HTMLDivElement>(
            '.Banner.Banner--noMinHeightOnDesktop .Banner-cell',
        ); // For easter promotion html block

        resizeBannerCells(bannerCellsDefault, BANNERCELL_DEFAULT_MIN_HEIGHT);
        resizeBannerCells(bannerCellsNoMinHeightOnDesktop, BANNERCELL_NO_MIN_HEIGHT); // For easter promotion html block
    };

    window.addEventListener('resize', _.throttle(onResize, 50));
    onResize();
};
