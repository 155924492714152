/* eslint-disable no-console */
import type { Ga4Events } from '../ga4';

const resetEcommerceEvent = () => {
    setTimeout(() => {
        window.dataLayer.push({ ecommerce: null });
    }, 0);
};

const sendEvent = <T extends object>(eventData: T) => {
    setTimeout(() => {
        const analyticsData = JSON.parse(JSON.stringify(eventData));
        window.dataLayer.push(analyticsData);
    }, 0);
};

export const sendGA4Event = <T extends Ga4Events & { ecommerce?: unknown }>(eventData: T) => {
    if (eventData.ecommerce) {
        resetEcommerceEvent();
    }
    sendEvent(eventData);
};

export const sendUntypedGA4Event = <T extends object>(eventData: T) => {
    sendEvent(eventData);
};
