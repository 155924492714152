import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { appConfig } from '../../../../appConfig';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { storeSelectors } from '../../../common/selectors/storeSelectors';
import { cartThunks } from '../../../common/thunks/cartThunks';
import { personalizationTracking } from '../../../common/tracking/contextAware/personalizationEvents';
import { ga4UserTracking } from '../../../common/tracking/ga4/user';

export const useInitialCartFetch = () => {
    const dispatch = useAppDispatch();
    const hasActiveStoreSelection = useAppSelector(storeSelectors.hasActiveStoreSelection);
    useEffect(() => {
        if (
            hasActiveStoreSelection ||
            (appConfig.coopUserSettings.isOrdererMunicipal &&
                !appConfig.coopUserSettings.isShoppingForMunicipal)
        ) {
            dispatch(cartThunks.getCart());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
};

const REDIRECT_REASON_QUERY_KEY = 'redirectReason';
export const useRedirectReasonHandler = () => {
    const { search, pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryString = new URLSearchParams(search);
        const redirectReason = queryString.get(REDIRECT_REASON_QUERY_KEY);
        let removeRedirectReasonQuery = true;
        if (!redirectReason) return;

        switch (redirectReason) {
            case 'accountRemoved': {
                ga4UserTracking.accountRemoved();
                break;
            }
            case 'loginSuccess': {
                ga4UserTracking.login(
                    appConfig.coopUserSettings.isAuthenticatedWithBankId ? 'bankid' : 'email',
                    'done',
                );
                personalizationTracking.trackUserLogin();
                break;
            }
            case 'signup': {
                removeRedirectReasonQuery = false;
                ga4UserTracking.signup(
                    appConfig.coopUserSettings.isMedmeraCustomer ? 'membership' : 'account',
                    appConfig.coopUserSettings.isCompany ? 'business' : 'private',
                    appConfig.coopUserSettings.isAuthenticatedWithBankId ? 'bankid' : 'email',
                );
                break;
            }
            case 'loginAndConnectMembership': {
                ga4UserTracking.connectMembership('success', 'login');
                break;
            }

            default: {
                // eslint-disable-next-line no-console
                console.error('Unknown redirect reason: ', redirectReason);
                return;
            }
        }

        if (removeRedirectReasonQuery) {
            queryString.delete(REDIRECT_REASON_QUERY_KEY);
        }

        navigate(
            {
                pathname,
                search: queryString.toString(),
            },
            { replace: true },
        );
    }, [navigate, search, pathname]);
};
