import { useCallback, useEffect, useRef } from 'react';
import type {
    AsyncState,
    AsyncStateStatus,
    UseAsyncOptionsNormalized,
    UseAsyncReturn,
} from 'react-async-hook';
import { useAsyncCallback } from 'react-async-hook';

import type { ApiException } from '../exceptions/exceptionDefinitions';
import { AsyncDispatcherError } from './AsyncDispatcherError';

export interface AsyncDispatcherOptions<Result> {
    initialLoadingState?: boolean;
    initialErrorState?: Error;
    keepPreviousResult?: boolean;
    executeOnMount?: boolean;
    initialResultState?: Result;
}

export type AsyncDispatcherStatus = 'not-requested' | 'loading' | 'success' | 'error';

export interface AsyncDispatcher<Result, Args extends unknown[] = unknown[]> {
    execute: UseAsyncReturn<Result, Args>['execute'];
    result?: Result;
    error: ApiException;
    clearError: () => void;
    reset: () => void;
    isLoading: boolean;
    status: AsyncDispatcherStatus;
}

// TODO waiting for memoization in react-async-hook, for now this is a workaround:
const useEventCallback = <R = unknown, Args extends unknown[] = unknown[]>(
    cb: (...args: Args) => R,
) => {
    const ref = useRef(cb);
    useEffect(() => {
        ref.current = cb;
    });
    return useCallback((...args: Args) => ref.current(...args), [ref]);
};

export const useAsyncDispatcher = <R = unknown, Args extends unknown[] = unknown[]>(
    asyncFun: (...args: Args) => Promise<R>,
    options?: AsyncDispatcherOptions<R>,
): AsyncDispatcher<R, Args> => {
    const asyncCall: UseAsyncReturn<R, Args> = useAsyncCallback<R, Args>(
        async (...args: Args) => asyncFun(...args),
        {
            executeOnMount: options && options.executeOnMount,
            initialState: (opt) => {
                if (!opt) {
                    return opt;
                }

                return {
                    ...opt.initialState,
                    result: options?.initialResultState,
                    loading: options?.initialLoadingState,
                    error: options?.initialErrorState,
                };
            },
            setLoading:
                options && options.keepPreviousResult
                    ? (state) => ({ ...state, loading: true })
                    : () => ({ loading: true }),
            setError: (error: unknown, _asyncState: AsyncState<R>): AsyncState<R> => {
                // this is the default in react-hook-async
                return {
                    status: <AsyncStateStatus>'error',
                    loading: false,
                    result: undefined,
                    error: new AsyncDispatcherError(error as ApiException),
                };
            },
        } as UseAsyncOptionsNormalized<R>,
    );
    const execute = useEventCallback(asyncCall.execute);

    const resetState = useCallback(() => {
        asyncCall.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        status: asyncCall.status,
        result: asyncCall.result,
        execute,
        error: (asyncCall.error as AsyncDispatcherError)?.exception,
        reset: resetState,
        clearError: resetState,
        isLoading: asyncCall.loading,
    };
};
