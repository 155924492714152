import { combineReducers, createReducer } from '@reduxjs/toolkit';

import {
    checkoutClearAction,
    checkoutErrorAction,
    checkoutInitAction,
    checkoutResetAction,
    checkoutSuccessAction,
} from './checkoutActions';
import messageToDriverReducer, { actions as messageToDriverActions } from './messageToDriverSlice';
import { paymentMethodReducer } from './paymentSlice';
import { punchoutReducer } from './punchoutSlice';
import { selectedDeliveryAddressReducer } from './selectedDeliveryAddressSlice';
import { selectedInvoiceAddressReducer } from './selectedInvoiceAddress';
import { subscriptionFrequencyReducer } from './subscriptionSlice';

const errorReducer = createReducer('', {
    [checkoutErrorAction.type]: (_, action) => action.payload,
    [checkoutInitAction.type]: () => '',
    [checkoutSuccessAction.type]: () => '',
    [checkoutClearAction.type]: () => '',
});

const combined = combineReducers({
    selectedDeliveryAddress: selectedDeliveryAddressReducer,
    subscriptionFrequency: subscriptionFrequencyReducer,
    selectedPaymentMethod: paymentMethodReducer,
    selectedInvoiceAddress: selectedInvoiceAddressReducer,
    messageToDriver: messageToDriverReducer,
    punchout: punchoutReducer,
    error: errorReducer,
});

export const checkoutActions = {
    error: checkoutErrorAction,
    init: checkoutInitAction,
    success: checkoutSuccessAction,
    clear: checkoutClearAction,
    reset: checkoutResetAction,
    messageToDriverActions,
};

export default combined;
