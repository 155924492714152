import './DropdownList.module.less';

import classNames from 'classnames';
import type { ReactNode, RefObject } from 'react';
import * as React from 'react';

interface DropdownListProps<T> {
    innerRef?: RefObject<HTMLDivElement>;
    isExpanded: boolean;
    options: T[];
    renderOption: (item: T) => ReactNode;
    style?: React.CSSProperties;
}

const DropdownList = <T,>(props: DropdownListProps<T>) => {
    return (
        <div
            ref={props.innerRef}
            style={props.style}
            className={classNames('DropdownList', props.isExpanded && 'is-expanded')}
        >
            {props.options.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>{props.renderOption(item)}</React.Fragment>
            ))}
        </div>
    );
};

interface DropdownAnchorListProps<T> extends DropdownListProps<T> {
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>, item: T) => void;
}

export const DropdownAnchorList = <T extends { url: string }>(
    props: DropdownAnchorListProps<T>,
) => {
    return (
        <DropdownList
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            renderOption={(item) => (
                <a
                    href={item.url}
                    className="DropdownList-item"
                    onClick={(e) => props.onClick?.(e, item)}
                >
                    {props.renderOption(item)}
                </a>
            )}
        />
    );
};

export default DropdownList;
