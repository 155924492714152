import { Button } from '@coop/components';
import { SearchIcon } from '@coop/icons';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import * as React from 'react';

import { appConfig } from '../../../../appConfig';
import { BrowserSize } from '../../../../utility/browser';
import { HeaderCartErrorNotice } from '../../../cart/components/molecules/CartErrorNotice';
import { useFromBrowserMediaQuery } from '../../../common/components/atoms/ResponsiveBreakpoints';
import { useIsUserAuthenticated, useIsUserCompany } from '../../../common/hooks/useUserProfile';
import { useUserTypeQuery } from '../../../common/hooks/useUserType';
import { UserType } from '../../../common/store/structureDefinitions/userState';
import { ga4UserTracking } from '../../../common/tracking/ga4/user';
import { isInB2BCrossContext, useIsB2BRoute } from '../../../common/utils/b2bUtils';
import { getLoginUrl, getLoginUrlWithReturnUrl } from '../../../common/utils/urlUtils';
import HeaderIconButton from '../HeaderIconButton';
import CncTrigger from './CncTrigger';
import type { SiteNavigationItem } from './FeaturedNavigation';
import FeaturedNavigation from './FeaturedNavigation';
import { useScrolDirection } from './Header.hooks';
import HeaderLeftNav from './HeaderLeftNav';
import HeaderMainNav from './HeaderMainNav';
import MyCoopFlyInTrigger from './MyCoopFlyInTrigger';
import MyFavoritesDropdownTrigger from './MyFavoritesDropdownTrigger';
import PreHeader from './PreHeader';

interface HeaderProps {
    headerTheme: 'black' | 'darkGreen';
    preHeaderText: string;
    preHeaderTheme: 'black' | 'darkGreen';
    showB2BSwitch: boolean;
    featuredNavigationItems: SiteNavigationItem[];
    stayAfterLoginLogoutRedirect: boolean;
    searchBarInHeaderSlot: ReactNode;
    useSearchToggler?: boolean;
    onSearchToggleClick?: () => void;
    searchToggleIsActive?: boolean;
}

const Header = (props: HeaderProps) => {
    const isFromLarge = useFromBrowserMediaQuery(BrowserSize.lg);
    const scrollDirection = useScrolDirection();

    const { data: userType } = useUserTypeQuery();
    const isPunchout = userType === UserType.punchout;

    const disableMyFavoritesMenu = isPunchout || appConfig.coopUserSettings.isShoppingForMunicipal;

    return (
        <>
            {isFromLarge && (
                <PreHeader
                    preHeaderText={props.preHeaderText}
                    showB2BSwitch={props.showB2BSwitch}
                    theme={props.preHeaderTheme}
                />
            )}

            <div
                className={classNames(
                    'Header',
                    props.headerTheme === 'darkGreen' && 'Header--darkGreen',
                    props.headerTheme === 'black' && 'Header--black',
                )}
                style={{ zIndex: 2 }}
            >
                <HeaderMainNav
                    stayAfterLoginLogoutRedirect={props.stayAfterLoginLogoutRedirect}
                    leftSlot={
                        <HeaderLeftNav
                            disableSiteNavigation={false}
                            navigationItems={props.featuredNavigationItems}
                        />
                    }
                    rightSlot={
                        <HeaderRightSlot
                            disableMyFavoritesMenu={disableMyFavoritesMenu}
                            useSearchToggler={props.useSearchToggler}
                            onSearchToggleClick={props.onSearchToggleClick}
                            searchToggleIsActive={props.searchToggleIsActive}
                            stayAfterLoginLogoutRedirect={props.stayAfterLoginLogoutRedirect}
                            headerTheme={props.headerTheme}
                        />
                    }
                    centerSlot={
                        isFromLarge && (
                            <div
                                className={classNames(
                                    'Header-group Header-group--spaceAround16',
                                    'Header-group--translateY',
                                    !!props.searchBarInHeaderSlot &&
                                        scrollDirection === 'down' &&
                                        'is-translated',
                                )}
                            >
                                <div className="Header-group--translatedWrapper">
                                    {props.featuredNavigationItems.length ? (
                                        <FeaturedNavigation items={props.featuredNavigationItems} />
                                    ) : (
                                        <div style={{ height: '100%', minWidth: 500 }} />
                                    )}
                                    {!!props.searchBarInHeaderSlot && (
                                        <div className="SearchWrapper">
                                            {props.searchBarInHeaderSlot}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    }
                />

                <HeaderCartErrorNotice />
            </div>
        </>
    );
};

const HeaderRightSlot = (props: {
    disableMyFavoritesMenu: boolean;
    useSearchToggler?: boolean;
    onSearchToggleClick?: () => void;
    searchToggleIsActive?: boolean;
    stayAfterLoginLogoutRedirect: boolean;
    headerTheme: 'black' | 'darkGreen';
}) => {
    const isAuthenticated = useIsUserAuthenticated();
    const isB2BRoute = useIsB2BRoute();
    const { companyUserOnPrivateSite, privateUserOnCompanySite } = isInB2BCrossContext({
        isB2BRoute,
        isCompany: useIsUserCompany(),
        isAuthenticated,
    });

    const onLoginClickTracking = () => {
        ga4UserTracking.login(null, 'start');
    };

    if (privateUserOnCompanySite) {
        return (
            <Button theme="primary" asChild>
                <a
                    rel="nofollow"
                    href={getLoginUrl('company', 'login')}
                    onClick={onLoginClickTracking}
                >
                    Logga in företag
                </a>
            </Button>
        );
    }

    if (companyUserOnPrivateSite) {
        return (
            <Button theme="primary">
                <a
                    rel="nofollow"
                    href={getLoginUrl('private', 'login')}
                    onClick={onLoginClickTracking}
                >
                    Logga in privat
                </a>
            </Button>
        );
    }

    return (
        <>
            {!isAuthenticated ? (
                <Button theme="primary" asChild onClick={onLoginClickTracking}>
                    <a
                        rel="nofollow"
                        href={getLoginUrlWithReturnUrl(
                            isB2BRoute ? 'company' : 'private',
                            'none',
                            props.stayAfterLoginLogoutRedirect,
                        )}
                    >
                        Logga in
                    </a>
                </Button>
            ) : (
                <>
                    {!props.disableMyFavoritesMenu && <MyFavoritesDropdownTrigger />}
                    <MyCoopFlyInTrigger />
                </>
            )}
            {props.useSearchToggler && props.onSearchToggleClick && (
                <HeaderIconButton
                    icon={SearchIcon}
                    label="Sök"
                    isActive={props.searchToggleIsActive}
                    onClick={props.onSearchToggleClick}
                    theme={isAuthenticated ? 'bordered' : 'solid'}
                />
            )}
            <CncTrigger badgeColor={props.headerTheme === 'darkGreen' ? 'white' : 'ctaGreen'} />
        </>
    );
};

export default Header;
