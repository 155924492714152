import axios from 'axios';

import { getInitialState } from '../../store/slices/cart/cartTimeslotSlice';
import type { TimeslotType } from './timeslotModels';

const mapToProperApiApiHybrisTimeslot = (timeslot: ApiHybrisTimeslot) => {
    if (!timeslot || !timeslot.id) return getInitialState().timeslot;
    return {
        ...timeslot,
        endTime: new Date(timeslot.endTime).valueOf(),
        startTime: new Date(timeslot.startTime).valueOf(),
        maxOrderTime: new Date(timeslot.maxOrderTime).valueOf(),
    };
};

/**
 * Fetch timeslots for current cart's store
 */
const fetchForCurrentCart = async (days?: number): Promise<ApiHybrisTimeslot[]> => {
    const response = await axios.get(
        `/api/hybris/timeslots?maxNumOfDays=${days ? days.toFixed(0) : 30}`,
    );
    return response.data && response.data.map(mapToProperApiApiHybrisTimeslot);
};

const fetchByPostalCode = async (
    postalCode: string,
    days?: number,
): Promise<ApiHybrisTimeslot[]> => {
    const response = await axios.get(
        `/api/hybris/timeslots/postalcode/${postalCode}/?maxNumOfDays=${
            days ? days.toFixed(0) : 30
        }`,
    );
    return response.data && response.data.map(mapToProperApiApiHybrisTimeslot);
};

const current = async () => {
    const response = await axios.get<ApiHybrisTimeslot>('/api/hybris/carts/current/timeslot');
    return mapToProperApiApiHybrisTimeslot(response.data);
};

const select = async (timeslotId: string): Promise<void> => {
    const data = { timeslotId };
    await axios.put('/api/hybris/carts/current/timeslot', data);
};

// TODO rename this to ApiTimeslot, use ApiHybrisTimeslot naming when directly using Hybris api
export interface ApiHybrisTimeslot {
    timeSlotTime: string;
    endTime: number;
    startTime: number;
    maxOrderTime: number;
    timeSlotDay: string;
    shippingType: string;
    fullyBooked: boolean;
    id: string | null;
    cost?: number;
    expirationTime?: Date;
    storeId: string;
    timeslotType: TimeslotType;
    milisecondsToExpire?: number;
    ecoFriendly: boolean;
    expectETANotification?: boolean;
}

export const timeslotsApi = {
    fetchForCurrentCart,
    fetchByPostalCode,
    select,
    current,
};
