/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */

'use client';

import { useInitialFocus } from '@coop/hooks';
import * as React from 'react';

import { Button } from '../Button';
import NotificationContent from './NotificationContent';

const PermanentNotification = (
    props: React.ComponentProps<typeof NotificationContent> & {
        ctaProps?: React.ComponentProps<typeof Button>;
        useInitialCtaFocus?: boolean;
    },
) => {
    const leastDestructiveActionRef = useInitialFocus(
        !!props.useInitialCtaFocus && !!props.ctaProps,
    );

    return (
        <NotificationContent
            show={props.show}
            type={props.type}
            iconSlot={props.iconSlot}
            header={props.header}
            message={props.message}
            size={props.size}
            theme={props.theme}
            asModal={props.asModal}
            bottomSlot={
                !!props.ctaProps && (
                    <Button
                        ref={leastDestructiveActionRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props.ctaProps}
                        theme={(() => {
                            switch (props.type) {
                                case 'alert':
                                    return 'red';
                                default:
                                    return 'primary';
                            }
                        })()}
                    />
                )
            }
        />
    );
};

export default PermanentNotification;
