import { ga4PromotionTracking } from '../ga4/promotion';
import type { IGaPromoData } from '../models/IGaPromoData';
import { getLoginStatus, sendEvent } from '../utils';

const sendGaGlobalSearchPageNavigationEvent = (formattedSearchResultType: string) => {
    sendEvent({
        event: 'interaction',
        eventCategory: 'Globalt Sök',
        eventAction: 'Navigering',
        eventLabel: formattedSearchResultType,
        loginStatus: getLoginStatus(),
    });
};

const sendGaGlobalSearchOverlayNavigationEvent = (formattedSearchResultType: string) => {
    sendEvent({
        event: 'interaction',
        eventCategory: 'Globalt Sök dropdown',
        eventAction: 'Navigering',
        eventLabel: formattedSearchResultType,
        loginStatus: getLoginStatus(),
    });
};

const sendGaGlobalSearchPagePromoImpressionEvent = (
    items: { name: string }[],
    formattedSearchResultType: string,
) => {
    items
        .map<IGaPromoData>((item, index) => ({
            gaPromoId: 'Intern',
            gaPromoName: `globalt-sök_${formattedSearchResultType}`,
            gaPromoCreative: item.name,
            gaPromoPosition: index.toString(),
        }))
        .forEach((promotion) => {
            ga4PromotionTracking.viewPromotion(promotion);
        });
};

const sendGaGlobalSearchOverlayPromoImpressionEvent = (
    items: { name: string }[],
    formattedSearchResultType: string,
) => {
    items
        .map<IGaPromoData>((item, index) => ({
            gaPromoId: 'Intern',
            gaPromoName: `globalt-sök_${formattedSearchResultType} dropdown`,
            gaPromoCreative: item.name,
            gaPromoPosition: index.toString(),
        }))
        .forEach((promotion) => {
            ga4PromotionTracking.viewPromotion(promotion);
        });
};

const sendGaSearchResultClick = (
    name: string,
    position: number,
    formattedSearchResultType?: string,
) => {
    const promotion: IGaPromoData = {
        gaPromoId: 'Intern',
        gaPromoName: `globalt-sök_${formattedSearchResultType}`,
        gaPromoCreative: name,
        gaPromoPosition: position.toString(),
    };

    ga4PromotionTracking.selectPromotion(promotion);
};
const sendGaSearchResultOverlayClick = (
    name: string,
    position: number,
    formattedSearchResultType?: string,
) => {
    const promotion: IGaPromoData = {
        gaPromoId: 'Intern',
        gaPromoName: `globalt-sök_${formattedSearchResultType} dropdown`,
        gaPromoCreative: name,
        gaPromoPosition: position.toString(),
    };

    ga4PromotionTracking.selectPromotion(promotion);
};

const searchTracking = {
    resultsPage: {
        navigation: sendGaGlobalSearchPageNavigationEvent,
        promoImpression: sendGaGlobalSearchPagePromoImpressionEvent,
        resultClick: sendGaSearchResultClick,
    },
    overlay: {
        navigation: sendGaGlobalSearchOverlayNavigationEvent,
        promoImpression: sendGaGlobalSearchOverlayPromoImpressionEvent,
        resultClick: sendGaSearchResultOverlayClick,
    },
};

export default searchTracking;
