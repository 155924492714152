import { useQuery } from '@tanstack/react-query';

import kimProxyFlow from '../../common/flow/kimProxyFlow';
import { kimCustomerDataQueryKeys } from '../memberQueries';

export const useKimCustomerDataQuery = (enabled: boolean, invalidateCache: boolean = false) => {
    const query = useQuery({
        queryKey: kimCustomerDataQueryKeys.defaultDataSet(invalidateCache),
        queryFn: async () => {
            return kimProxyFlow.getCustomerData(invalidateCache);
        },
        enabled,
    });

    return query;
};
