import type { AxiosRequestConfig } from 'axios';

import axiosClientFactory from './clientFactory';

const getLocalApiAxiosClient = (config?: AxiosRequestConfig) => {
    const localApiAxiosClient = axiosClientFactory.createLocalApiClient({
        baseURL: '/api/',
        ...config,
    });

    return localApiAxiosClient;
};

export default getLocalApiAxiosClient;
