export class DefaultProductSection implements ApiProductSection {
    public static NAME = 'Övrigt';
    public static ID = 9999999;

    id: number;
    name: string;
    portion?: number;
    portionOptions?: number[];
    constructor() {
        this.id = DefaultProductSection.ID;
        this.name = DefaultProductSection.NAME;
    }
}

export interface ProductSectionOrderItems<T> {
    items: T[];
    section: ApiProductSection;
}

export type ProductSectionOrderItemsHashMap<T> = Map<string, ProductSectionOrderItems<T>>;
