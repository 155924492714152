import { createAction } from '@reduxjs/toolkit';

import type { IApiStore } from '../../../../../models/ecommerce/store/storeModel';
import type { DeliveryMode } from '../../structureDefinitions/storeState';

export interface SelectPickupStorePayload {
    store: IApiStore;
    pickupPointId: string;
}

export const selectPickupStore = createAction<SelectPickupStorePayload>('store/selectPickupStore');

export interface SelectHomeDeliveryStorePayload {
    store: IApiStore;
    postalCode: string;
}
export const selectHomeDeliveryStore = createAction<SelectHomeDeliveryStorePayload>(
    'store/selectHomeDeliveryStore',
);

export interface SyncStorePayload {
    deliveryMode?: DeliveryMode;
    postalCode?: string;
    productionUnitId: string;
    pickupPointId?: string;
    storeName?: string;
}

export const syncStore = createAction<SyncStorePayload>('store/syncStore');
export const syncStoreWithCookie = createAction('store/syncStoreWithCookie');
