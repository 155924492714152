import { getAuthToken } from '../api/auth/getAuthToken';
import mapOrder, { mapOrderHistory } from '../api/order/mapOrder';
import type { CreateOrderOptions } from '../api/order/orderApi';
import { orderApi, orderDirectApi } from '../api/order/orderApi';
import type { SubscriptionFrequency } from '../store/structureDefinitions/checkoutState';

const orderFlow = {
    createForCurrentCart: async (
        transactionCode: string | null = null,
        subscriptionFrequency: SubscriptionFrequency | null = null,
    ) => {
        const request: CreateOrderOptions = {
            transaction: transactionCode || undefined,
            subscriptionFrequency,
        };
        const order = await orderApi.createForCurrentCart(request);
        return order;
    },
    getOrderHistory: async (currentPage: number, pageSize: number) => {
        const { token, shoppingUserId } = await getAuthToken();
        const resp = await orderDirectApi.getOrderHistory(token)(
            shoppingUserId,
            currentPage,
            pageSize,
        );

        const mapped = resp.map(mapOrderHistory).filter(Boolean);

        return mapped;
    },
    getOrderById: async (orderId: string, fields?: 'FULL') => {
        const { token, shoppingUserId } = await getAuthToken();
        const resp = await orderDirectApi.getOrderById(token, fields)(shoppingUserId, orderId);
        const mapped = mapOrder(resp);
        return mapped;
    },
    cancelOrder: async (orderId: string) => {
        const { token, shoppingUserId } = await getAuthToken();
        const resp = await orderDirectApi.cancelOrder(token)(shoppingUserId, orderId);
        return resp;
    },
    getOrderCount: async () => {
        const { token, shoppingUserId } = await getAuthToken();
        const resp = await orderDirectApi.getOrderCount(token)(shoppingUserId);
        return resp;
    },
    // used when completing an editorder
    orderEdited: async (cartId: string) => {
        const { token, shoppingUserId } = await getAuthToken();
        const resp = await orderDirectApi.orderEdited(token)(shoppingUserId, cartId);
        return resp;
    },
};

export default orderFlow;
