import { combineReducers } from '@reduxjs/toolkit';

import { municipalUserInfoReducer } from './municipalUserInfoSlice';
import { punchoutUserInfoReducer } from './punchoutUserInfoSlice';
import userAddressesReducer from './userAddressesSlice';
import { userInfoReducer } from './userInfoSlice';
import { userProfileReducer } from './userProfileSlice';
import { userTypeReducer } from './userTypeSlice';

const combined = combineReducers({
    addresses: userAddressesReducer,
    type: userTypeReducer,
    userInfo: userInfoReducer,
    municipalUserInfo: municipalUserInfoReducer,
    profile: userProfileReducer,
    punchout: punchoutUserInfoReducer,
});

export default combined;
