/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from '@coop/components';
import { ChevronDownIcon } from '@coop/icons';
import type {
    AccordionContentProps,
    AccordionHeaderProps,
    AccordionItemProps,
    AccordionMultipleProps,
    AccordionSingleProps,
    AccordionTriggerProps,
} from '@radix-ui/react-accordion';
import * as AccordionRadix from '@radix-ui/react-accordion';
import { Slottable } from '@radix-ui/react-slot';
import classNames from 'classnames';
import * as React from 'react';

import styles from './Accordion.module.scss';

const AccordionRoot = React.forwardRef<
    HTMLDivElement,
    (AccordionSingleProps | AccordionMultipleProps) &
        React.RefAttributes<HTMLDivElement> & {
            theme?: 'gray' | 'white' | 'green';
            splitted?: boolean;
            rounded?: boolean;
        }
>(({ theme, splitted, rounded, className, ...props }, ref) => {
    const classes = classNames(
        styles.Accordion,
        className,
        theme === 'gray' && styles['Accordion-gray'],
        theme === 'white' && styles['Accordion-white'],
        theme === 'green' && styles['Accordion-green'],
        splitted ? styles['Accordion--splitted'] : styles['Accordion--default'],
        !!rounded && styles['Accordion--rounded'],
    );

    return <AccordionRadix.Root {...props} ref={ref} className={classes} />;
});

const AccordionItem = React.forwardRef<
    HTMLDivElement,
    AccordionItemProps & React.RefAttributes<HTMLDivElement>
>((props, ref) => {
    const classes = classNames(styles.AccordionItem, props.className);

    return <AccordionRadix.Item {...props} ref={ref} className={classes} />;
});

const AccordionTrigger = React.forwardRef<
    HTMLButtonElement,
    AccordionTriggerProps &
        React.RefAttributes<HTMLButtonElement> & {
            iconClassName?: string;
            iconPosition?: 'left' | 'right';
            withChevron?: boolean;
        }
>(({ iconClassName, iconPosition = 'right', children, withChevron = true, ...props }, ref) => {
    const classes = classNames(styles.AccordionTrigger, props.className);

    return (
        <AccordionRadix.Trigger {...props} ref={ref} className={classes}>
            {iconPosition === 'left' && !props.disabled && withChevron && (
                <Icon
                    icon={ChevronDownIcon}
                    width={20}
                    height={20}
                    aria-hidden="true"
                    className={classNames(iconClassName, styles.AccordionChevron)}
                />
            )}
            <Slottable>{children}</Slottable>
            {!props.disabled && iconPosition === 'right' && withChevron && (
                <Icon
                    icon={ChevronDownIcon}
                    width={20}
                    height={20}
                    aria-hidden="true"
                    className={classNames(iconClassName, styles.AccordionChevron)}
                />
            )}
        </AccordionRadix.Trigger>
    );
});

const AccordionHeader = React.forwardRef<
    HTMLDivElement,
    AccordionHeaderProps & React.RefAttributes<HTMLHeadingElement>
>((props, ref) => {
    const classes = classNames(styles.AccordionHeader, props.className);

    return <AccordionRadix.Header {...props} ref={ref} className={classes} />;
});

const AccordionContent = React.forwardRef<
    HTMLDivElement,
    AccordionContentProps & React.RefAttributes<HTMLDivElement>
>((props, ref) => {
    const classes = classNames(styles.AccordionContent, props.className);

    return <AccordionRadix.Content {...props} ref={ref} className={classes} />;
});

const Accordion = {
    Root: AccordionRoot,
    Item: AccordionItem,
    Trigger: AccordionTrigger,
    Header: AccordionHeader,
    Content: AccordionContent,
};

(Accordion.Root as React.FC<React.ComponentProps<typeof AccordionRoot>>).displayName =
    'Accordion.Root';
(Accordion.Item as React.FC<React.ComponentProps<typeof AccordionItem>>).displayName =
    'Accordion.Item';
(Accordion.Trigger as React.FC).displayName = 'Accordion.Trigger';
(Accordion.Header as React.FC).displayName = 'Accordion.Header';
(Accordion.Content as React.FC).displayName = 'Accordion.Content';

export default Accordion;
