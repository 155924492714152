import type * as React from 'react';

import type { BaseSVGIconProps } from './Icon.types';
import { buildSvgIconProps } from './Icon.utils';

export interface IconProps extends BaseSVGIconProps {
    icon: (props: React.SVGProps<SVGSVGElement>) => React.ReactNode;
}

/**
 * Wrapper for SVG files that embraces bundling SVG files together with JS.
 * Pass imported SVG module into 'icon' prop.
 * That way, the SVG will be bundled together with JS code chunks, so only the necessary SVGs will be downloaded.
 */
const Icon = ({
    role = 'graphics-document',
    colorByStroke = false,
    icon,
    ...rest
}: React.PropsWithChildren<IconProps>) => {
    const svgProps = buildSvgIconProps({ role, colorByStroke, ...rest });

    return icon(svgProps);
};

export default Icon;
