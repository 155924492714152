import type { AxiosInstance } from 'axios';

import { appConfig } from '../../../../appConfig';
import axiosClientFactory from './clientFactory';
import type { CustomErrorInterceptor } from './localApiAxiosErrorInterceptors';

const getDirectAdobeCampaignAxiosClient = <TApiError = unknown>(
    accessToken?: string,
    neoId?: string,
    errorInterceptors?: CustomErrorInterceptor<TApiError>[],
): AxiosInstance => {
    const directShoppingListAxiosClient = axiosClientFactory.createDirectApiClient(
        {
            baseURL: appConfig.coopSettings.serviceAccess.apimBaseUrl,
            headers: {
                Authorization: neoId ? `adobe ${btoa(neoId)}` : `Bearer ${accessToken}`,
            },
            params: {
                'api-version': 'v1',
            },
        },
        errorInterceptors,
    );

    return directShoppingListAxiosClient;
};

export default getDirectAdobeCampaignAxiosClient;
