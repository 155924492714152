import { Accordion } from '@coop/components';
import classNames from 'classnames';
import * as React from 'react';

import styles from './Footer.module.less';
import FooterColumns from './FooterColumns';

const FooterMobile = (props: Pick<FrontendFooter, 'columns'>) => {
    if (window.isPrerenderCrawler) {
        <FooterColumns columns={props.columns} />;
    }

    return (
        <Accordion.Root type="single" collapsible className={styles.FooterAccordion}>
            {props.columns.map((column, index) => (
                <Accordion.Item
                    key={column.headerLink?.contentId}
                    className={classNames(styles.FooterAccordionItem, styles.Divider)}
                    value={column.headerLink?.contentId.toString() || index.toString()}
                >
                    <Accordion.Header asChild>
                        <Accordion.Trigger className={styles.FooterAccordionTrigger}>
                            {column.header}
                        </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Content className={styles.FooterAccordionContent}>
                        <ul className={styles.FooterList}>
                            {column.links.map((link) => (
                                <li className={styles.FooterItem} key={link.contentId}>
                                    <a href={link.url}>{link.name}</a>
                                </li>
                            ))}
                        </ul>
                    </Accordion.Content>
                </Accordion.Item>
            ))}
        </Accordion.Root>
    );
};

export default FooterMobile;
