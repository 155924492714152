import classNames from 'classnames';
import * as React from 'react';

import styles from './Footer.module.less';

const FooterMobileApps = (
    props: Pick<
        FrontendFooter,
        | 'downloadAppAndroidUrl'
        | 'downloadAppIOSUrl'
        | 'downloadAppQRImage'
        | 'downloadAppQRImageAltText'
    >,
) => {
    return (
        <div className="u-textCenter u-flexShrinkNone">
            <div className="u-textCenter">
                <p>
                    <b>Ladda ner Coop-appen</b>
                </p>
            </div>
            <div className={styles.FooterDownloadApp}>
                <div className={classNames(styles.QRCode, 'u-textSmall')}>
                    <div className={styles.ScanQR}>
                        <img
                            src={props.downloadAppQRImage?.url}
                            alt={props.downloadAppQRImageAltText}
                            width="75"
                            height="75"
                        />
                        <br />
                        <b>Scanna QR-koden för att ladda ner appen</b>
                    </div>
                </div>
                <div className={styles.DownloadLinks}>
                    <div>
                        <a href={props.downloadAppIOSUrl}>
                            <img
                                src="/Assets/Images/DownloadApp/appstore.png"
                                height="35"
                                width="102"
                                alt="Hämta i App Store"
                            />
                        </a>
                    </div>
                    <div>
                        <a href={props.downloadAppAndroidUrl}>
                            <img
                                src="/Assets/Images/DownloadApp/googleplay.png"
                                height="35"
                                width="117"
                                alt="Ladda ned på Google Play"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterMobileApps;
