import { failSilently } from '../../utils/failSilently';
import { sendGA4Event } from '../shared/sendGa4Event';

const headerNavigationClick = (linkText: string, linkUrl: string) => {
    const event: NavigationClickEvent = {
        event: 'navigation',
        type: 'header',
        event_key: 'navigation_header',
        click_text: linkText.toLowerCase(),
        click_url: linkUrl.toLowerCase(),
    };

    sendGA4Event(event);
};

const preHeaderNavigationClick = (linkText: string, linkUrl: string) => {
    const event: NavigationClickEvent = {
        event: 'navigation',
        type: 'pre-header',
        event_key: 'navigation_pre_header',
        click_text: linkText.toLowerCase(),
        click_url: linkUrl.toLowerCase(),
    };

    sendGA4Event(event);
};

const sideMenuInteractionClick = (linkText: string, linkUrl: string) => {
    const event: NavigationClickEvent = {
        event: 'navigation',
        type: `side menu click`,
        event_key: `navigation_side_menu_click`,
        click_text: linkText.toLowerCase(),
        click_url: linkUrl.toLowerCase(),
    };

    sendGA4Event(event);
};

const profileMenuInteractionClick = (linkText: string, linkUrl: string) => {
    const event: NavigationClickEvent = {
        event: 'navigation',
        type: `profile menu click`,
        event_key: `navigation_profile_menu_click`,
        click_text: linkText.toLowerCase(),
        click_url: linkUrl.toLowerCase(),
    };

    sendGA4Event(event);
};

const profileMenuWalletInteractionTrigger =
    (type: NavigationTriggerInteractionType | NavigationClickType) =>
    (
        menuLevel1: string | undefined,
        menuLevel2: string | undefined,
        pageTypeNameForAnalytics: string,
        clickText: string | undefined,
        clickUrl: string | undefined,
    ) => {
        const event: NavigationProfileMenuClickEvent = {
            event: 'navigation',
            type: `profile menu ${type}`,
            menu_level_1: menuLevel1 || 'not selected',
            menu_level_2: menuLevel2 || 'not selected',
            click_text: clickText || 'not selected',
            click_url: clickUrl || 'not selected',
            page_type: pageTypeNameForAnalytics,
        };

        sendGA4Event(event);
    };

const profileMenuInteractionTrigger = (type: NavigationTriggerInteractionType) => () => {
    const event: NavigationTriggerEvent = {
        event: 'navigation',
        type: `profile menu ${type}`,
        event_key: `navigation_profile_menu_${type}`,
    };

    sendGA4Event(event);
};

const savedItemsInteractionClick = (linkText: string, linkUrl: string) => {
    const event: NavigationClickEvent = {
        event: 'navigation',
        type: `saved items click`,
        event_key: `navigation_saved_items_click`,
        click_text: linkText.toLowerCase(),
        click_url: linkUrl.toLowerCase(),
    };

    sendGA4Event(event);
};

const savedItemsInteractionTrigger = (type: NavigationTriggerInteractionType) => () => {
    const event: NavigationTriggerEvent = {
        event: 'navigation',
        type: `saved items ${type}`,
        event_key: `navigation_saved_items_${type}`,
    };

    sendGA4Event(event);
};

const hamburgerMenuInteractionClick = (linkText: string, level: number, linkUrl?: string) => {
    const event: GlobalMenuNavigationClickEvent = {
        event: 'navigation',
        type: `hamburger menu click`,
        menu_level: level.toString(),
        click_text: linkText.toLowerCase(),
        click_url: linkUrl?.toLowerCase(),
    };

    sendGA4Event(event);
};

const hamburgerMenuInteractionTrigger = (type: NavigationTriggerInteractionType) => () => {
    const event: GlobalMenuNavigationTriggerEvent = {
        event: 'navigation',
        type: `hamburger menu ${type}`,
    };

    sendGA4Event(event);
};

const deliveryTimeInteractionClick = (linkText: string) => {
    const event: NavigationClickEvent = {
        event: 'navigation',
        type: 'delivery time click',
        click_text: linkText,
        click_url: 'undefined',
        event_key: `navigation_delivery_time_click`,
    };

    sendGA4Event(event);
};

const deliveryTimeInteractionTrigger = (type: NavigationTriggerInteractionType) => () => {
    const event: NavigationTriggerEvent = {
        event: 'navigation',
        type: `delivery time ${type}`,
        event_key: `navigation_delivery_time_${type}`,
    };

    sendGA4Event(event);
};

const onPageNavigationClick = (text: string, url: string) => {
    const event: NavigationClickEvent = {
        event: 'navigation',
        type: 'on page',
        click_text: text,
        click_url: url,
        event_key: `navigation_on_page`,
    };

    sendGA4Event(event);
};

export default {
    headerNavigationClick: failSilently(headerNavigationClick),
    preHeaderNavigationClick: failSilently(preHeaderNavigationClick),
    onPageNavigationClick: failSilently(onPageNavigationClick),
    deliveryTime: {
        click: failSilently(deliveryTimeInteractionClick),
        open: failSilently(deliveryTimeInteractionTrigger('open')),
        close: failSilently(deliveryTimeInteractionTrigger('close')),
    },
    sideMenu: {
        click: failSilently(sideMenuInteractionClick),
    },
    savedItems: {
        click: failSilently(savedItemsInteractionClick),
        open: failSilently(savedItemsInteractionTrigger('open')),
        close: failSilently(savedItemsInteractionTrigger('close')),
    },
    profileMenu: {
        click: failSilently(profileMenuInteractionClick),
        open: failSilently(profileMenuInteractionTrigger('open')),
        close: failSilently(profileMenuInteractionTrigger('close')),
    },
    profileMenuWalletInteractionTrigger: {
        click: failSilently(profileMenuWalletInteractionTrigger('click')),
        open: {
            level1Open: failSilently((clickText: string, pageTypeNameForAnalytics: string) =>
                profileMenuWalletInteractionTrigger('open')(
                    clickText,
                    undefined,
                    pageTypeNameForAnalytics,
                    clickText,
                    undefined,
                ),
            ),
        },
        close: {
            level1Close: failSilently((clickText: string, pageTypeNameForAnalytics: string) =>
                profileMenuWalletInteractionTrigger('close')(
                    clickText,
                    undefined,
                    pageTypeNameForAnalytics,
                    clickText,
                    undefined,
                ),
            ),
        },
    },
    hamburgerMenu: {
        click: failSilently(hamburgerMenuInteractionClick),
        open: failSilently(hamburgerMenuInteractionTrigger('open')),
        close: failSilently(hamburgerMenuInteractionTrigger('close')),
        back: failSilently(hamburgerMenuInteractionTrigger('back click')),
    },
};
