import { UserIcon } from '@coop/icons';
import * as React from 'react';

import useModal from '../../../common/hooks/useModal';
import { FlyInType } from '../../../common/store/structureDefinitions/modalState';
import HeaderIconButton from '../HeaderIconButton/HeaderIconButton';
import MyCoopProfileFlyIn from '../MyCoopProfileFlyIn';

const MyCoopFlyInTrigger = () => {
    const { open } = useModal(FlyInType.MyProfileMenu);

    return (
        <>
            <HeaderIconButton
                icon={UserIcon}
                label="Mina sidor"
                testSelector="user"
                onClick={open}
                theme="bordered"
            />

            <MyCoopProfileFlyIn />
        </>
    );
};

export default MyCoopFlyInTrigger;
