import { appConfig } from '../appConfig';
import trackingFlow from '../microApps/common/flow/trackingFlow';
import persistance from '../microApps/common/persistance/persistance';
import {
    GdprCookieVendors,
    hasAcceptedGdprCookieVendor,
    triggerOnAllConsentAccepted,
    triggerOnAllConsentRejected,
    triggerOnConsentGatheredGdpr,
    triggerOnSingleConsentChange,
} from './gdpr';

const consentRejected = () => {
    // erase cookie that we add ourselves, rest is removed by DY scripts
    persistance.dynamicYield.removeCookies();
};

const updateDYConsent = () => {
    if (
        hasAcceptedGdprCookieVendor(GdprCookieVendors.DynamicYield) &&
        hasAcceptedGdprCookieVendor(GdprCookieVendors.GoogleAnalytics)
    ) {
        trackSpaDY(window.DY.recommendationContext, window.location.href);
    } else {
        consentRejected();
    }
};

export const trackProfileDY = () => {
    if (appConfig.coopUserSettings.isAuthenticated) {
        trackingFlow.profile(appConfig.coopUserSettings.isCompany);
    }
};

export const trackIdentifyDY = () => {
    if (appConfig.coopUserSettings.isAuthenticated) {
        trackingFlow.identify(appConfig.coopUserSettings.shoppingUserId, 'id'); // id is also used as cuidType in the mobile app, thats why its used
    }
};

export const trackSpaDY = (data: DynamicYieldPageContext, url: string) => {
    trackingFlow.trackPageView(data, url);
};

export const updateDyStaticContext = (context: DynamicYieldPageContext) => {
    DY.recommendationContext = context;
};

export const createCartPageContext = (url: string, cartProductIds: string[]) => ({
    location: url,
    type: 'CART',
    data: [...cartProductIds],
});

export const createOtherPageContext = (url: string) => ({
    location: url,
    type: 'OTHER',
    data: [],
});

export const createHomePageContext = (url: string) => ({
    location: url,
    type: 'HOMEPAGE',
    data: [],
});

export const createProductPageContext = (url: string, productId: string) => ({
    location: url,
    type: 'PRODUCT',
    data: [productId],
});

export const createCategoryPageContext = (url: string, categoryNamesList: string[]) => ({
    location: url,
    type: 'CATEGORY',
    data: [...categoryNamesList],
});

const initializeDynamicYield = () => {
    trackIdentifyDY();
    triggerOnConsentGatheredGdpr(() => updateDYConsent());
    triggerOnAllConsentAccepted(() => {
        trackSpaDY(window.DY.recommendationContext, window.location.href);
    });
    triggerOnAllConsentRejected(consentRejected);
    triggerOnSingleConsentChange(() => updateDYConsent());
    trackProfileDY();
};

export default initializeDynamicYield;
