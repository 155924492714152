import classNames from 'classnames';
import type { FC } from 'react';
import * as React from 'react';

import { ga4NavigationTracking } from '../../../common/tracking/ga4/navigation';

export interface SiteNavigationItem {
    isActive: boolean;
    name: string;
    url: string;
    contentLinkId: number;
}

interface FeaturedNavigationProps {
    items: SiteNavigationItem[];
}

const FeaturedNavigation: FC<React.PropsWithChildren<FeaturedNavigationProps>> = (props) => {
    return (
        <div className="Navigation">
            <nav aria-label="Huvudmeny" className="Navigation-list ">
                {props.items.map((item) => (
                    <a
                        href={item.url}
                        key={item.name}
                        className={classNames('Navigation-item', item.isActive && 'is-active')}
                        data-test={`mainnav-${item.name.toLowerCase().replace(' & ', '-')}`}
                        onClick={(e) => {
                            ga4NavigationTracking.headerNavigationClick(
                                item.name,
                                e.currentTarget.href,
                            );
                        }}
                    >
                        {item.name}
                    </a>
                ))}
            </nav>
        </div>
    );
};

export default FeaturedNavigation;
