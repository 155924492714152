import classNames from 'classnames';
import type * as React from 'react';

import styles from './Icon.module.scss';
import type { BaseSVGIconProps, IconColors } from './Icon.types';

const getIconSvgColor = (typed?: IconColors) => {
    switch (typed) {
        case 'white':
            return styles['Icon--white'];
        case 'green':
            return styles['Icon--green'];
        case 'greenDark':
            return styles['Icon--greenDark'];
        case 'transparent':
            return styles['Icon--transparent'];
        case 'black':
            return styles['Icon--black'];
        case 'grayMedium':
            return styles['Icon--grayMedium'];
        case 'yellow':
            return styles['Icon--yellow'];
        case 'red':
            return styles['Icon--red'];
        case 'dsGreen':
            return styles['Icon--ctaGreen'];

        default: // by default icon will get a color of nearest 'color' property in css, via currentColor
            break;
    }
};

type ExtendedSVGProps = React.PropsWithChildren<React.SVGProps<SVGSVGElement>> & {
    title?: string;
};

export const buildSvgIconProps = (props: BaseSVGIconProps): ExtendedSVGProps => {
    const { rotateClockwise, colorByStroke, color, title, ...svgProps } = props;
    let { style } = props;

    if (props.rotateClockwise) {
        style = {
            ...style,
            transform: `rotate(${rotateClockwise})`,
        };
    }

    return {
        ...svgProps,
        style,
        title,
        className: classNames(
            props.className,
            styles.Icon,
            getIconSvgColor(color),
            !colorByStroke && styles.useFill,
        ),
    };
};
