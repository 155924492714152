import { OverlayLoader } from '@coop/components';
import type { FC } from 'react';
import * as React from 'react';

import { useAppSelector } from '../../hooks/useThunkDispatch';

const FlyInOverlayLoader: FC<React.PropsWithChildren<unknown>> = () => {
    const isLoading = useAppSelector((state) => state.modal.isLoading);

    return <OverlayLoader isLoading={isLoading} blocking />;
};

export default FlyInOverlayLoader;
