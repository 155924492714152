import { ga4PromotionTracking } from '../microApps/common/tracking/ga4/promotion';
import { triggerCallbackOnceWhenInViewPort } from '../utility/ViewPortObserver';

const gaPromoViewHandler = (element: HTMLElement) => {
    if (!element) {
        return null;
    }

    const dataGaPromoString = element.dataset.gaPromo;
    if (!dataGaPromoString) {
        return null;
    }

    const dataGaPromos = dataGaPromoString?.split(';') ?? [];
    const [gaPromoId, gaPromoName, gaPromoCreative, gaPromoPosition] = dataGaPromos;

    const analyticsPromoData = { gaPromoId, gaPromoName, gaPromoCreative, gaPromoPosition };

    triggerCallbackOnceWhenInViewPort(element, () => {
        ga4PromotionTracking.viewPromotion(analyticsPromoData);
    });

    element.addEventListener('click', (e) => {
        const target = e.target as HTMLElement;
        const link = target.closest('a');
        if (!link) return true;

        const promoEl = link.closest('.js-gaPromo');
        if (!promoEl || promoEl !== element) {
            return true;
        }

        const href = link.getAttribute('href');
        if (!href || href === '' || href.indexOf('#') === 0) return;

        ga4PromotionTracking.selectPromotion(analyticsPromoData);
    });
};

export default gaPromoViewHandler;
