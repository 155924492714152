import type { EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { productsAdapter } from '../../adapters/productsAdapter';

const slice = createSlice({
    name: 'itemsProductData',
    initialState: productsAdapter.getInitialState(),
    reducers: {
        setAll: (state: EntityState<ApiProduct>, action: PayloadAction<ApiProduct[]>) => {
            productsAdapter.setAll(state, action.payload);
        },
    },
});

export default slice.reducer;

export const cartItemProductDataActions = {
    ...slice.actions,
};
