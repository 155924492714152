export const enum NoticeRibbonType {
    success = 'success',
    warning = 'warning',
    info = 'info',
    editOrder = 'editOrder',
    alertLevel1 = 'alert1',
    alertLevel2 = 'alert2',
    alertLevel3 = 'alert3',
    alertLevel4 = 'alert4',
}
