import type { ApiBaseException } from '../../exceptions/exceptionDefinitions';

// could be Klarna here etc, and its up to component on how to display them
export enum ErrorGroup {
    Critical = 'Critical',
    Global = 'Global',
    Cart = 'Cart',
    Modal = 'Modal',
}

export interface ErrorState extends Record<ErrorGroup, ApiBaseException[]> {}
