import { AnalyticsHelper } from '../../../../analytics/analyticsHandler';
import { DefaultProductSection } from '../../../../models/ecommerce/product/productSectionModel';
import type { OrderReclamationProduct } from '../../../myCoop/orderReclamation/models/OrderReclamationProduct';
import type { Order } from '../../models/domain/order';
import { orderEntryItemEqualsPredicate } from '../../utils/orderUtils';
import { sendEvent } from '../utils';

const ORDER_RECLAMATION_EVENT_CATEGORY = 'Reklamationsflöde';
const REFUND_INTERACTION_EVENT = 'refundInteraction';
const REFUND_EVENT = 'refund';

type OrderReclamationRefundType = 'Återbetalning' | 'Tillgodokvitto';
type OrderReclamationProductEvent = {
    name: string;
    id: string;
    variantId?: string;
    price?: number;
    brand: string;
    quantity: number;
    category: string;
    refundReason?: string;
};

const getStepEventAction = (step: number) => {
    switch (step) {
        case -1:
            return 'Mina beställningar - Reklamation - Påbörjad reklamation';
        case 0:
            return 'Steg 1 - Välj varor att Reklamera';
        case 1:
            return 'Steg 2 - Ange anledning';
        case 2:
            return 'Steg 3 - Välj Kompensation';
        default:
            return 'Mina beställningar - Reklamation - Inskickad reklamation';
    }
};

const getNextStepEventAction = (nextStep: number) => {
    switch (nextStep) {
        case 1:
            return 'Gå vidare till Steg 2 - Ange Anledning';
        case 2:
            return 'Gå vidare till Steg 3 - Välj kompensation';
        case 3:
            return 'Gå vidare - Skicka in';
        default:
            return getStepEventAction(nextStep);
    }
};

const sendInitEvent = () => {
    sendEvent({
        event: REFUND_INTERACTION_EVENT,
        eventCategory: ORDER_RECLAMATION_EVENT_CATEGORY,
        eventAction: getStepEventAction(-1),
        eventLabel: 'Reklamera vara-knapp',
        loginStatus: AnalyticsHelper.getLoginStatus(),
    });
};

const sendGoToStepEvent = (currentStep: number, nextStep: number) => {
    sendEvent({
        event: REFUND_INTERACTION_EVENT,
        eventCategory: ORDER_RECLAMATION_EVENT_CATEGORY,
        eventAction: getStepEventAction(currentStep),
        eventLabel: getNextStepEventAction(nextStep),
        loginStatus: AnalyticsHelper.getLoginStatus(),
    });
};

const sendSelectCompensationEvent = (refundType: OrderReclamationRefundType) => {
    sendEvent({
        event: REFUND_INTERACTION_EVENT,
        eventCategory: ORDER_RECLAMATION_EVENT_CATEGORY,
        eventAction: getStepEventAction(2),
        refundType,
        loginStatus: AnalyticsHelper.getLoginStatus(),
    });
};

const sendAddReclamationProductEvent = () => {
    sendEvent({
        event: REFUND_INTERACTION_EVENT,
        eventCategory: ORDER_RECLAMATION_EVENT_CATEGORY,
        eventAction: getStepEventAction(0),
        eventLabel: 'Lägg till vara',
        loginStatus: AnalyticsHelper.getLoginStatus(),
    });
};

const sendRemoveReclamationProductEvent = (currentStep: number) => {
    sendEvent({
        event: REFUND_INTERACTION_EVENT,
        eventCategory: ORDER_RECLAMATION_EVENT_CATEGORY,
        eventAction: getStepEventAction(currentStep),
        eventLabel: 'Ta bort vara',
        loginStatus: AnalyticsHelper.getLoginStatus(),
    });
};

const sendSaveReclamationProductEvent = (refundReason: string) => {
    sendEvent({
        event: REFUND_INTERACTION_EVENT,
        eventCategory: ORDER_RECLAMATION_EVENT_CATEGORY,
        eventAction: getStepEventAction(1),
        eventLabel: 'Bekräfta',
        refundReason,
        loginStatus: AnalyticsHelper.getLoginStatus(),
    });
};

const sendCancelReclamationProductEvent = (refundReason?: string) => {
    sendEvent({
        event: REFUND_INTERACTION_EVENT,
        eventCategory: ORDER_RECLAMATION_EVENT_CATEGORY,
        eventAction: getStepEventAction(1),
        eventLabel: 'Avbryt',
        refundReason,
        loginStatus: AnalyticsHelper.getLoginStatus(),
    });
};

const sendCancelOrderReclamationEvent = (eventLabel: string, currentStep: number) => {
    sendEvent({
        event: REFUND_INTERACTION_EVENT,
        eventCategory: ORDER_RECLAMATION_EVENT_CATEGORY,
        eventAction: getStepEventAction(currentStep),
        eventLabel: `Avsluta reklamation - ${eventLabel}`,
        loginStatus: AnalyticsHelper.getLoginStatus(),
    });
};

const sendOrderReclamationSubmittedEvent = (
    refundType: OrderReclamationRefundType,
    orderReclamationProducts: OrderReclamationProduct[],
    productList: ApiProduct[],
    order: Order,
) => {
    const products = orderReclamationProducts
        .map((product): OrderReclamationProductEvent | null => {
            const apiProduct = productList.find((pl) => pl.identifier === product.productId);

            if (!apiProduct) {
                return null;
            }

            const topSection =
                apiProduct && apiProduct.sections && apiProduct.sections[0]
                    ? apiProduct.sections[0][0]
                    : new DefaultProductSection();

            const orderItem = order.entries.find(orderEntryItemEqualsPredicate(product));

            return {
                name: apiProduct.name,
                id: product.productId,
                variantId: product.variantId,
                price: orderItem?.totalPrice?.inclTaxPrice,
                brand: apiProduct.manufacturer,
                quantity: product.quantity,
                category: topSection.name,
                refundReason: product.reclamationReason,
            };
        })
        .filter(Boolean);

    const totalRefundAmount = orderReclamationProducts.reduce(
        (currentTotal, orderReclamationProduct) => {
            const orderItem = order.entries.find(
                orderEntryItemEqualsPredicate(orderReclamationProduct),
            );

            if (orderItem && orderItem.piecePrice) {
                return (
                    currentTotal +
                    (orderItem.piecePrice.inclTaxPrice || 0) * orderReclamationProduct.quantity
                );
            }
            return currentTotal;
        },
        0,
    );

    sendEvent({
        event: REFUND_EVENT,
        eventCategory: ORDER_RECLAMATION_EVENT_CATEGORY,
        eventAction: getStepEventAction(3),
        ecommerce: {
            refund: {
                products,
            },
        },
        refundType,
        refundAmount: totalRefundAmount,
        storeId: order.pointOfService?.storeId || order.store,
        transactionId: order.code,
        loginStatus: AnalyticsHelper.getLoginStatus(),
    });
};

const orderReclamationTracking = {
    init: sendInitEvent,
    cancel: sendCancelOrderReclamationEvent,
    goToStep: sendGoToStepEvent,
    submit: sendSelectCompensationEvent,
    submitFinished: sendOrderReclamationSubmittedEvent,
    product: {
        add: sendAddReclamationProductEvent,
        remove: sendRemoveReclamationProductEvent,
        save: sendSaveReclamationProductEvent,
        cancel: sendCancelReclamationProductEvent,
    },
};

export default orderReclamationTracking;
