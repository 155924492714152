// Sync with SearchOverlayCurrentPage on backend
export enum SearchFromPageType {
    StartPage = 'startpage',
    Ecommerce = 'ecommerce',
    Stores = 'stores',
    Recipes = 'recipes',
    Finance = 'finance',
    Member = 'member',
    Other = 'other',
}
