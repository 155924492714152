import type { ApiException } from '../exceptions/exceptionDefinitions';

export class AsyncDispatcherError implements Error {
    constructor(error: ApiException) {
        this.exception = error;
        this.name = 'ApiException';
        this.message = error.friendlyMessage;
    }

    exception: ApiException;

    name: string;

    message: string;

    stack?: string;
}
