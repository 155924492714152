import { TooltipNotification } from '@coop/components';
import type { FC } from 'react';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { appConfig } from '../../../../appConfig';
import { Cookies } from '../../../../utility/cookies';
import { COOKIE_LOGIN_REMINDER } from '../../../common/persistance/cookie/cookieKeys';
import { ga4UserTracking } from '../../../common/tracking/ga4/user';
import Tracking from '../../../common/tracking/tracking';
import { useIsB2BRoute } from '../../../common/utils/b2bUtils';
import { getCreateAccountUrl, getLoginUrlWithReturnUrl } from '../../../common/utils/urlUtils';

interface LoginReminderTooltipProps {
    stayAfterLoginLogoutRedirect: boolean;
    heading: string;
    content: string;
}

const LoginReminderTooltip: FC<React.PropsWithChildren<LoginReminderTooltipProps>> = (props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const isUserLoggedIn = appConfig.coopUserSettings.isAuthenticated;
        const hasSeenTooltipThisSession = () =>
            !!Cookies.read(COOKIE_LOGIN_REMINDER) ||
            window.abtest.loginReminderPopupShown === false;

        let handle: NodeJS.Timeout;
        if (!isUserLoggedIn && !hasSeenTooltipThisSession()) {
            handle = setTimeout(() => {
                if (!hasSeenTooltipThisSession()) {
                    setShow(true);
                }
            }, 40000);
        }
        return () => {
            clearTimeout(handle);
        };
    }, []);

    const close = () => {
        Cookies.create(COOKIE_LOGIN_REMINDER, 'true');
        Tracking.header.loginReminder.close();

        setShow(false);
    };

    const isB2BRoute = useIsB2BRoute();

    const loginUrl = getLoginUrlWithReturnUrl(
        isB2BRoute ? 'company' : 'private',
        'none',
        props.stayAfterLoginLogoutRedirect,
    );

    const createAccountUrl = getCreateAccountUrl(isB2BRoute ? 'company' : 'private');

    return (
        <TooltipNotification
            animated
            isOpen={show}
            close={close}
            header={props.heading}
            content={props.content}
            primaryButtonProps={{
                asChild: true,
                children: (
                    <a
                        rel="nofollow"
                        href={loginUrl}
                        onClick={(e) => {
                            e.preventDefault();
                            Cookies.create(COOKIE_LOGIN_REMINDER, 'true');
                            Tracking.header.loginReminder.goToLogin();
                            ga4UserTracking.login(null, 'start');
                            window.location.href = e.currentTarget.href;
                        }}
                    >
                        Logga in
                    </a>
                ),
            }}
            secondaryButtonProps={{
                asChild: true,
                children: (
                    <a
                        rel="nofollow"
                        href={createAccountUrl}
                        onClick={() => {
                            Cookies.create(COOKIE_LOGIN_REMINDER, 'true');
                            Tracking.header.loginReminder.goToCreateAccount();
                        }}
                    >
                        Skapa konto
                    </a>
                ),
            }}
        />
    );
};

export default LoginReminderTooltip;
