/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialPunchoutState = {
    phoneNumber: undefined as string | undefined,
};

const slice = createSlice({
    name: 'punchout',
    initialState: initialPunchoutState,
    reducers: {
        setPhoneNumber: (state, action: PayloadAction<string | undefined>) => {
            state.phoneNumber = action.payload;
        },
    },
});

export const { actions: punchoutActions, reducer: punchoutReducer } = slice;
