import type { IGaPromoData } from '../../models/IGaPromoData';
import { createBatcher } from '../../utils/createBatcher';
import { failSilently } from '../../utils/failSilently';
import { sendSplittedData } from '../../utils/sendSplittedData';
import { sendGA4Event } from '../shared/sendGa4Event';
import { isPromoDataAcceptable, mapToGaPromotion } from './ga4Promotion.utils';

const viewPromotion = (promotion: IGaPromoData | IGaPromoData[]) => {
    if (Array.isArray(promotion)) {
        const promotions = promotion
            .filter(Boolean)
            .filter(isPromoDataAcceptable)
            .map(mapToGaPromotion);

        const event: ViewPromotionEvent = {
            event: 'view_promotion',
            ecommerce: {
                items: promotions,
            },
        };
        sendGA4Event(event);
        return;
    }

    const mappedPromotion = promotion && mapToGaPromotion(promotion);

    if (mappedPromotion && isPromoDataAcceptable(promotion)) {
        const event: ViewPromotionEvent = {
            event: 'view_promotion',
            ecommerce: mappedPromotion,
        };
        sendGA4Event(event);
    }
};

const MAX_PROMOTIONS_COUNT = 20;
const viewPromotionChunked = createBatcher(
    (promotions: IGaPromoData[], maxSliceCount = MAX_PROMOTIONS_COUNT) => {
        sendSplittedData(promotions, maxSliceCount, viewPromotion);
    },
);

const selectPromotion = (promotion: IGaPromoData) => {
    const mappedPromotion = promotion && mapToGaPromotion(promotion);

    if (mappedPromotion && isPromoDataAcceptable(promotion)) {
        const event: SelectPromotionEvent = {
            event: 'select_promotion',
            ecommerce: mappedPromotion,
        };

        sendGA4Event(event);
    }
};

export default {
    selectPromotion: failSilently(selectPromotion),
    viewPromotion: failSilently(viewPromotionChunked),
};
