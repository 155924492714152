import { useQuery } from '@tanstack/react-query';

import { appConfig } from '../../../appConfig';
import { contentDeliveryApi } from '../../episerver/api/contentDeliveryApi';
import type { EpiMyCoopSettingsPageType } from '../../episerver/components/pages/EpiMyCoopSettingsPage/epiMyCoopSettingsPageType';
import { myCoopProfileMenuKeys } from '../myCoopQueries';

export const useMyCoopSettingsPageQuery = () => {
    const myCoopSettingsId = appConfig.coopSettings.myCoop.myCoopSettingsPageContentId;
    const query = useQuery({
        queryKey: myCoopProfileMenuKeys.settingsPage,
        queryFn: () =>
            contentDeliveryApi.getContentExtended<EpiMyCoopSettingsPageType>(myCoopSettingsId, {
                depth: 1,
            }),
        staleTime: Infinity,
    });

    return query;
};
