import type { LightSearchRecipe, RecipeCategoryFacet } from '../../../search/models/models';
import { sendEvent } from '../utils';
import { useRecipeFilterTrackingContext } from './RecipeFilterPageTrackingContextProvider';

const sendRecipeTeaserClick = ({
    recipe,
    recipeComboHit,
}: {
    recipe: LightSearchRecipe;
    recipeComboHit: string;
}) => {
    sendEvent({
        event: 'interaction',
        eventCategory: 'Receptsidor',
        eventAction: 'Gå till recept',
        eventLabel: recipe.name,
        recipeComboHit,
    });
};

const sendRecipeCategoryInteractionClick = ({
    recipeCategoryFacet,
    parentCategoryFacet,
    recipeComboHit,
}: {
    recipeCategoryFacet: RecipeCategoryFacet;
    parentCategoryFacet: RecipeCategoryFacet;
    recipeComboHit: string;
}) => {
    sendEvent({
        event: 'interaction',
        eventCategory: 'Receptsidor',
        eventAction: 'Tillagd kategori',
        recipeComboHit: recipeComboHit || undefined,
        recipeCategory: `${parentCategoryFacet.item}:${recipeCategoryFacet.item}`,
    });
};

const sendRecipeNonInteractionEvent = ({
    recipeComboHit,
    recipeHits,
}: {
    recipeComboHit: string;
    recipeHits: number | null;
}) => {
    sendEvent({
        event: 'nonInteraction',
        eventCategory: 'Dimensioner',
        eventAction: 'Klar',
        recipeCombo: recipeComboHit || undefined,
        recipeHits: recipeHits && recipeHits > 0 ? recipeHits : undefined,
    });
};

const useRecipeFilterPageTracking = () => {
    const { recipeComboHit } = useRecipeFilterTrackingContext();

    if (recipeComboHit === null) {
        return null;
    }

    return {
        nonInteractionEvent: (data: { recipeHits: number | null }) =>
            sendRecipeNonInteractionEvent({ ...data, recipeComboHit }),

        categoryLinkClick: (
            recipeCategoryFacet: RecipeCategoryFacet,
            parentCategoryFacet: RecipeCategoryFacet,
        ) =>
            sendRecipeCategoryInteractionClick({
                recipeCategoryFacet,
                parentCategoryFacet,
                recipeComboHit,
            }),

        recipeTeaserClick: (recipe: LightSearchRecipe) =>
            sendRecipeTeaserClick({ recipe, recipeComboHit }),
    };
};

export default {
    useFilterPageTracking: useRecipeFilterPageTracking,
};
