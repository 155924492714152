import { appConfig } from '../appConfig';
import abTestStorage from '../microApps/common/persistance/localStorage/abTestStorage';

/** Randomize between 0-99, if a user is logged in, always create same hash */
export const randomizePersistant = (name: string, userId?: string) => {
    if (!name || !userId) {
        return Math.random() * 100;
    }
    const stringToHash = name + userId;
    let hash = 0;
    let i;
    let chr;

    for (i = 0; i < stringToHash.length; i += 1) {
        chr = stringToHash.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + chr;
        // eslint-disable-next-line no-bitwise
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash) % 100;
};

export const isAbTestPreview = () =>
    new URLSearchParams(window.location.search)?.get('abTestPreview') === 'true';

export const calculateAbTestValue = (name: string, percentage: number, userId?: string) => {
    const nameWithPercentage = `${name}_${percentage}`;
    const randomNumber = randomizePersistant(nameWithPercentage, userId);
    return randomNumber < percentage ? '1' : '0';
};

export const setupAbTest = (epiAbTest: RecursiveReadonly<EpiAbTest>, userId?: string) => {
    const { name } = epiAbTest;
    let abTest = abTestStorage.get(name);

    if (!abTest) {
        abTest = {
            name: epiAbTest.name,
            percentage: epiAbTest.percentage,
            created: new Date(),
            modified: new Date(),
            abTestValue: calculateAbTestValue(name, epiAbTest.percentage, userId),
        };
        abTestStorage.set(abTest);
    }
    // if the percentage is changed in CMS on an existing test, we need to recalculate ab test value
    else if (abTest.percentage !== epiAbTest.percentage) {
        abTest.percentage = epiAbTest.percentage;
        abTest.abTestValue = calculateAbTestValue(name, epiAbTest.percentage, userId);
        abTest.modified = new Date();
        abTestStorage.set(abTest);
    }

    return abTest;
};

export const cleanupAbTests = () => {
    const epiAbTests = appConfig.coopSettings.featureFlags.abTests;

    if (epiAbTests) {
        const abTestsToRemove = abTestStorage
            .all()
            .filter((test) => epiAbTests.every((epiTest) => epiTest.name !== test.name));

        abTestStorage.remove(abTestsToRemove.map((test) => test.name));
    }
};
