import type { SpinnerSize } from '../Loader';
import type { DsButtonSize } from './Button.types';

export const getSpinnerSizeForButtonSize = (buttonSize: DsButtonSize): SpinnerSize => {
    switch (buttonSize) {
        case 40:
            return 20;
        case 48:
            return 24;
        case 56:
            return 28;
        default:
            return exhaustiveCheck(buttonSize);
    }
};

const exhaustiveCheck = (value: never): never => {
    throw new Error(`Unhandled value: ${value}`);
};
