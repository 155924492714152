import { Button, Checkbox, ChipIconButton, Modal, ModalHeader } from '@coop/components';
import { CloseIcon } from '@coop/icons';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import * as React from 'react';
import { useEffect } from 'react';

import { appConfig } from '../../../../appConfig';
import {
    IDS_REDIRECTREASON_QUERY,
    IDS_REDIRECTREASON_QUERYVALUE_SIGNUP,
} from '../../../../utility/consts';
import { Helpers } from '../../../../utility/helpers';
import Image from '../../../common/components/atoms/Image';
import { useIsMobile } from '../../../common/components/atoms/ResponsiveBreakpoints';
import { consentSubscriptionFlow } from '../../../common/flow/consentSubscriptionFlow';
import persistance from '../../../common/persistance/persistance';
import { ga4EmailSubscriptionEvents } from '../../../common/tracking/ga4/emailSubscription';
import useEmailSubscriptionQuery from '../../../myCoop/emailSubscriptions/hooks/useEmailSubscriptionQuery';
import { useMyCoopSettingsPageQuery } from '../../../myCoop/hooks/useMyCoopSettingsPageQuery';
import {
    MEMBER_CONSENT_NEWSLETTERS_EMAIL,
    MEMBER_CONSENT_NEWSLETTERS_SMS,
    MEMBER_CONSENT_QUERY,
} from '../../consts';
import { useKimCustomerDataQuery } from '../../hooks/useKimCustomerDataQuery';
import memberUtils from '../../member.utils';
import styles from './Consent.module.less';
import PhoneNumberPopup from './PhoneNumber/PhoneNumberPopup';

const Consent: React.FC = () => {
    const turnOnPhoneConsent = false; // This is a temporary switch waiting for sms service to be tuned on

    const [open, setOpen] = React.useState(false);
    const [openPhonePopup, setOpenPhonePopup] = React.useState(false);
    const [isDisbled, setIsDisbled] = React.useState(true);

    const { hasKimCustomerId, isAuthenticated: isLoggedIn } = appConfig.coopUserSettings;
    const isMobile = useIsMobile();

    const hasRedirectQuery = Helpers.getParameterByName(IDS_REDIRECTREASON_QUERY);
    const hasMemberConsentQuery = Helpers.getParameterByName(MEMBER_CONSENT_QUERY);
    const shouldShowConsent =
        (hasRedirectQuery === IDS_REDIRECTREASON_QUERYVALUE_SIGNUP || !!hasMemberConsentQuery) &&
        isLoggedIn;

    const hasQuery = Helpers.getParameterByName(IDS_REDIRECTREASON_QUERY);

    const { data: emailSubscriptions, isLoading: isLoadingSubscriptions } =
        useEmailSubscriptionQuery(false, hasQuery === 'signup' && isLoggedIn);

    const { data: kimCustomerData } = useKimCustomerDataQuery(
        turnOnPhoneConsent && hasKimCustomerId,
        true,
    );

    const kimPhoneNumberVerified =
        kimCustomerData && memberUtils.kimPhoneNumberVerified(kimCustomerData);

    const subscribeNowMutation = useMutation({
        mutationFn: (service: string | Array<string>) => {
            return consentSubscriptionFlow.subscribe(
                Array.isArray(service) ? service : [service],
                false,
            );
        },
        onSuccess: () => {
            ga4EmailSubscriptionEvents.confirm.sendEmailSubscriptionInteraction();

            setOpen(false);
        },
    });

    const { data: settingsTexts } = useMyCoopSettingsPageQuery();

    // Only run once (or if query changes)
    useEffect(() => {
        if (shouldShowConsent && !isLoadingSubscriptions) {
            const allNewsletters = MEMBER_CONSENT_NEWSLETTERS_EMAIL;
            if (turnOnPhoneConsent) {
                allNewsletters.push(...MEMBER_CONSENT_NEWSLETTERS_SMS);
            }

            // here check for consent in Adobe. If user has ANY consent, then dont show this modal
            const hasConsent = emailSubscriptions?.services?.some((item) =>
                allNewsletters.includes(item.name),
            );

            if (!hasConsent) {
                persistance.consentShownSession.add();
                setOpen(true);
            }
        }
    }, [emailSubscriptions, isLoadingSubscriptions, shouldShowConsent, turnOnPhoneConsent]);

    const handleCheckedChanged = (checked: boolean) => {
        setIsDisbled(!checked);
    };
    const approveClicked = () => {
        const allNewsletters = MEMBER_CONSENT_NEWSLETTERS_EMAIL;

        // if kim has phone number and it is verified, then subscribe to sms consent too
        if (turnOnPhoneConsent && kimCustomerData && kimPhoneNumberVerified) {
            allNewsletters.push(...MEMBER_CONSENT_NEWSLETTERS_SMS);
        }

        // add all emails on the consent list
        subscribeNowMutation.mutate(allNewsletters);

        // if phonenumber is not verified (or missing), open phone popup
        if (turnOnPhoneConsent && kimCustomerData && !kimPhoneNumberVerified) {
            setOpenPhonePopup(true);
        }
    };

    const skippedClicked = () => {
        ga4EmailSubscriptionEvents.skip.sendEmailSubscriptionInteraction();

        if (open) {
            setOpen(false);
        }
    };
    const onClose = () => {
        ga4EmailSubscriptionEvents.close.sendEmailSubscriptionInteraction();

        if (open) {
            setOpen(false);
        }
    };

    return (
        <>
            {openPhonePopup && <PhoneNumberPopup />}
            <Modal
                isOpen={open}
                close={onClose}
                additionalClasses={classNames(
                    styles.OuterContainer,
                    isMobile ? styles.OuterContainerIsMobile : '',
                )}
            >
                <div className={styles.CloseButtoDiv}>
                    <ChipIconButton
                        theme="whiteGreen"
                        onClick={onClose}
                        size={32}
                        icon={CloseIcon}
                        label="Stäng"
                        className={styles.CloseButton}
                    />
                </div>
                <div className={styles.Container}>
                    <ModalHeader className={styles.Headline}>
                        Missa inget av det alldeles särskilt extra
                    </ModalHeader>
                    <div className={styles.InnerContent}>
                        <Image src="/Assets/Icons/paperplane.svg" height="114" width="136" alt="" />
                        <div className={styles.CheckboxDiv}>
                            <Checkbox
                                indicatorVerticalPosition="top"
                                className={styles.Checkbox}
                                aria-label={settingsTexts?.consentCheckboxText}
                                id="jatack"
                                onCheckedChange={handleCheckedChanged}
                                label={
                                    settingsTexts?.consentCheckboxText ??
                                    'Ja tack! Jag vill ha goda kampanjer och personliga erbjudanden från Coop, X:-tra och samarbetspartners.'
                                }
                            />
                            {settingsTexts && (
                                <div className={styles.ConditionText}>
                                    <p>
                                        Och du! Självklart kan du när som helst återkalla ditt
                                        samtycke eller ändra inställningar för utskick.
                                    </p>
                                    <p>
                                        {settingsTexts.personalDataLink.url && (
                                            <>
                                                Läs gärna{' '}
                                                <a
                                                    href={settingsTexts.personalDataLink.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    integritetspolicyn
                                                </a>{' '}
                                                för att se hur Coop hanterar personuppgifter.
                                            </>
                                        )}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className={styles.ButtonContainer}>
                            <Button
                                theme="primary"
                                onClick={approveClicked}
                                fullWidth
                                disabled={isDisbled}
                            >
                                Godkänn
                            </Button>
                            <Button theme="primaryOutline" onClick={skippedClicked} fullWidth>
                                Nej tack
                            </Button>
                        </div>
                        {settingsTexts?.aboutCoopLink?.url &&
                            settingsTexts?.aboutCoopAssociationsLink?.url && (
                                <div className={styles.ReadMore}>
                                    <p>
                                        Vilka är{' '}
                                        <a
                                            href={settingsTexts.aboutCoopLink.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label="Vilka är Coop"
                                        >
                                            Coop
                                        </a>{' '}
                                        och{' '}
                                        <a
                                            href={settingsTexts.aboutCoopAssociationsLink.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label="Vilka är Konsumentföreningarna"
                                        >
                                            Konsumentföreningarna
                                        </a>
                                        ?
                                    </p>
                                </div>
                            )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Consent;
