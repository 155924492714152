import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable no-param-reassign */
import type { InvoiceAddressState } from '../../structureDefinitions/checkoutState';
import type { InvoiceAddress } from '../../structureDefinitions/userState';
import { checkoutResetAction } from './checkoutActions';

const getInitialState = (): InvoiceAddressState => {
    return {
        isInitialized: false,
        isFetching: false,
        invoiceAddress: undefined,
    };
};

const slice = createSlice({
    name: 'selectedInvoiceAddress',
    initialState: getInitialState(),
    reducers: {
        isFetching: (state: InvoiceAddressState, action: PayloadAction<boolean>) => {
            state.isFetching = action.payload;
        },
        set: (state: InvoiceAddressState, action: PayloadAction<InvoiceAddress>) => {
            state.isFetching = false;
            state.isInitialized = true;
            state.invoiceAddress = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(checkoutResetAction.type, () => {
            return getInitialState();
        });
    },
});

export const { reducer: selectedInvoiceAddressReducer, actions: selectedInvoiceAddressActions } =
    slice;
