import type { ApiHybrisTimeslot } from '../../api/timeslots/timeslotsApi';
import { MicroAppContextType } from '../../contexts/microAppContext';
import { DeliveryMode } from '../../store/structureDefinitions/storeState';
import { sendInteractionEvent } from '../utils';

const getGaFormatTimeslotFunction = (microAppType: MicroAppContextType | undefined) => {
    switch (microAppType) {
        case MicroAppContextType.CnC:
            return () => 'Slider - Varukorg - Överblick';
        case MicroAppContextType.Checkout:
            return (deliveryModeText: string, reservationText: string) =>
                `Checkout - Leverans - ${deliveryModeText} - Leveranstid - Reservera ${reservationText}`;
        default:
            return () => 'undefined';
    }
};

const sendGaTimeslotInteractionEvent =
    (microAppType: MicroAppContextType | undefined, deliveryMode: DeliveryMode) =>
    (eventLabel: string, additionalData: object | null) => {
        const isHomeDelivery = deliveryMode === DeliveryMode.homedelivery;
        const deliveryModeText = isHomeDelivery ? 'Hemleverans' : 'Hämta i butik';
        const reservationText = isHomeDelivery ? 'leveranstid' : 'avhämtningstid';

        const gaContextEventFormatted = getGaFormatTimeslotFunction(microAppType)(
            deliveryModeText,
            reservationText,
        );

        sendInteractionEvent({
            eventAction: isHomeDelivery
                ? gaContextEventFormatted
                : `${gaContextEventFormatted} - Hämta`,
            eventLabel,
            ...additionalData,
        });
    };

const sendGaCalendarInteractionClickPrev =
    (microAppType: MicroAppContextType | undefined, deliveryMode: DeliveryMode) => () => {
        sendGaTimeslotInteractionEvent(microAppType, deliveryMode)('Slide bakåt', null);
    };

const sendGaCalendarInteractionClickNext =
    (microAppType: MicroAppContextType | undefined, deliveryMode: DeliveryMode) => () => {
        sendGaTimeslotInteractionEvent(microAppType, deliveryMode)('Slide framåt', null);
    };

const sendGaCalendarInteractionClickOpen =
    (microAppType: MicroAppContextType | undefined, deliveryMode: DeliveryMode) => () => {
        sendGaTimeslotInteractionEvent(microAppType, deliveryMode)('Kalender öppna', null);
    };

const sendGaCalendarInteractionClickSelect =
    (microAppType: MicroAppContextType | undefined, deliveryMode: DeliveryMode) => () => {
        sendGaTimeslotInteractionEvent(microAppType, deliveryMode)('Kalender val', null);
    };

const sendGaTimeslotInteractionClickSelect =
    (microAppType: MicroAppContextType | undefined, deliveryMode: DeliveryMode) =>
    (timeslot: ApiHybrisTimeslot, nextTimeslot: ApiHybrisTimeslot | null) => {
        const deliveryModePretty =
            deliveryMode === DeliveryMode.homedelivery ? 'Hemleverans' : 'Hämta i butik';

        const time = timeslot.timeSlotTime;
        const date = new Date(timeslot.startTime).toLocaleDateString('sv-SE').split('-').join('');

        let hoursUntilNextTimeslot;
        if (nextTimeslot) {
            hoursUntilNextTimeslot = (nextTimeslot.startTime - timeslot.startTime) / (1000 * 3600);
        }

        const additionalData = {
            deliveryDate: date,
            deliveryTime: `${date} ${time}`,
            deliveryMethod: deliveryModePretty,
            nextDelivery: hoursUntilNextTimeslot,
            // TODO : deliveryType: Custom dim: 64. Ordinarie order, Förplock, Express (only for Kungens kurva at the moment) - available in Hybris
        };

        sendGaTimeslotInteractionEvent(microAppType, deliveryMode)(
            `Valt ${
                deliveryMode === DeliveryMode.homedelivery ? 'leveranstid' : 'leveranstid - Hämta'
            }`,
            additionalData,
        );
    };

const timeslotTracking = {
    calendar: {
        prev: sendGaCalendarInteractionClickPrev,
        next: sendGaCalendarInteractionClickNext,
        open: sendGaCalendarInteractionClickOpen,
        select: sendGaCalendarInteractionClickSelect,
    },
    timeslot: {
        select: sendGaTimeslotInteractionClickSelect,
    },
};

export default timeslotTracking;
