import type { ListenerMiddlewareInstance } from '@reduxjs/toolkit';

import cartItemsSelectors, {
    cartItemUniqueIdPredicate,
} from '../../../../cart/selectors/cartItemsSelectors';
import { storeSelectors } from '../../../selectors/storeSelectors';
import { unsyncedCartItemsSelectors } from '../../../selectors/unsyncedCartItemsSelectors';
import { sendGaModifyCartEvent } from '../../../tracking/gaEventCreators';
import { unsyncedCartItemsActions } from '../../slices/unsyncedCartItemsSlice';
import type { RootState } from '../../structureDefinitions/cartState';

export const unsyncedAddToCartGaTrackingMiddleware = (
    middlewareInstance: ListenerMiddlewareInstance<RootState>,
) => {
    middlewareInstance.startListening({
        actionCreator: unsyncedCartItemsActions.removeUnsyncedCartItems,
        effect: async (action, listenerApi) => {
            listenerApi.cancelActiveListeners();

            const previousState = listenerApi.getOriginalState(); // the effect fires before removeUnsyncedCartItems actually changes the state, thats why we can access previousState

            const currentStoreId = storeSelectors.currentProductionUnit(previousState);
            const cartItems = cartItemsSelectors.selectAllCartItems(previousState);
            const unsyncedCartItems =
                unsyncedCartItemsSelectors.selectUnsyncedCartItems(previousState);

            const successfulyAddedCartItems = unsyncedCartItems.filter((unsyncedItem) =>
                cartItems.find((cartItem) =>
                    cartItemUniqueIdPredicate(cartItem.cartItemData, unsyncedItem),
                ),
            );

            successfulyAddedCartItems.filter(Boolean).forEach((cartItem) => {
                sendGaModifyCartEvent(
                    cartItem.productId,
                    cartItem.variantId,
                    cartItem.quantity,
                    0,
                    currentStoreId,
                    cartItem?.analyticsOptions,
                );
            });
        },
    });
};
