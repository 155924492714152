import axios from 'axios';

import { appConfig } from '../../../../appConfig';
import { SyncronQueue } from '../SyncronQueue';
import type { AuthAnonymous, AuthIdentity } from './authIdentity';

export const spaTopkenApiUrl = '/api/spa/token';

const spaTokenApi = {
    getToken: async (): Promise<AuthIdentity> => {
        const response = await axios.get(`${spaTopkenApiUrl}${'?_='}${new Date().getTime()}`);
        return response.data;
    },
    cancelImpersonation: async (): Promise<AuthIdentity> => {
        const response = await axios.get(
            `${'/api/spa/impersonate/cancel?_='}${new Date().getTime()}`,
        );
        return response.data;
    },
    impersonateUser: async (request: {
        userToImpersonate: string;
        userIdentifierType: string;
    }): Promise<AuthIdentity> => {
        const response = await axios.post(`${'/api/spa/impersonate?_='}${new Date().getTime()}`, {
            userToImpersonate: request.userToImpersonate,
            userIdentifierType: request.userIdentifierType,
        });
        return response.data;
    },
};

const SESSION_STORAGE_USER_IDENTITY = 'user.identity';
const tokenFetchQueue = new SyncronQueue();

/** resets authtoken, makes system fetch a new one when needed */
export const resetAuthToken = () => {
    window.sessionStorage.removeItem(SESSION_STORAGE_USER_IDENTITY);
};

export const cleanTokenSessionStorageIfNeeded = () => {
    const userIdentityRaw = window.sessionStorage.getItem(SESSION_STORAGE_USER_IDENTITY);

    if (userIdentityRaw) {
        const userIdentity = JSON.parse(userIdentityRaw) as AuthIdentity;

        const isStoredUserIdOutOfSync =
            appConfig.coopUserSettings.userId !== userIdentity?.userId ||
            appConfig.coopUserSettings.shoppingUserId !== userIdentity?.shoppingUserId;

        const isUserLoggedIn = appConfig.coopUserSettings.isAuthenticated;

        if (!isUserLoggedIn || isStoredUserIdOutOfSync) {
            window.sessionStorage.removeItem(SESSION_STORAGE_USER_IDENTITY);
        }
    }
};
const getAuthTokenOrDefault = async (): Promise<AuthIdentity | AuthAnonymous> => {
    if (!appConfig.coopUserSettings.isAuthenticated) {
        return {
            isBankId: false,
            shoppingUserId: 'anonymous',
            token: undefined,
        };
    }

    return getAuthToken();
};
/** Only return token if its in cache, otherwise it will be null */
const getCachedAuthToken = () => {
    const userIdentityRaw = window.sessionStorage.getItem(SESSION_STORAGE_USER_IDENTITY);

    if (userIdentityRaw) {
        const userIdentity = JSON.parse(userIdentityRaw) as AuthIdentity;
        const { userId, shoppingUserId, at } = appConfig.coopUserSettings;
        if (
            userIdentity &&
            userIdentity.expires &&
            Date.parse(userIdentity.expires) > Date.now() + 30 * 1000 && // If token is expired or will expire within 30 seconds
            userId === userIdentity.userId &&
            shoppingUserId === userIdentity.shoppingUserId &&
            at === userIdentity.at
        ) {
            return userIdentity;
        }
        window.sessionStorage.removeItem(SESSION_STORAGE_USER_IDENTITY);
    }
    return undefined;
};

/** Will fail if user is not signed in */
const getAuthToken = async (): Promise<AuthIdentity> => {
    const userIdentity = getCachedAuthToken();
    if (userIdentity) {
        return userIdentity;
    }

    const spaToken = await tokenFetchQueue.run(
        async () => {
            const token = await spaTokenApi.getToken();
            window.sessionStorage.setItem(SESSION_STORAGE_USER_IDENTITY, JSON.stringify(token));
            return token;
        },
        { acceptRunningPromise: true, dontPushBack: true, uniqueName: 'getspatoken' },
    );

    return spaToken;
};

const impersonateUser = async (params: {
    userToImpersonate: string;
    userIdentifierType: string;
}) => {
    const token = await spaTokenApi.impersonateUser(params);
    window.sessionStorage.setItem(SESSION_STORAGE_USER_IDENTITY, JSON.stringify(token));
    return token;
};

const cancelImpersonation = async () => {
    const token = await spaTokenApi.cancelImpersonation();
    window.sessionStorage.removeItem(SESSION_STORAGE_USER_IDENTITY);
    return token;
};

export {
    cancelImpersonation,
    getAuthToken,
    getAuthTokenOrDefault,
    getCachedAuthToken,
    impersonateUser,
};
