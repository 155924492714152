import axios from 'axios';

import getLocalApiAxiosClient from '../clients/localApiAxiosClient';
import type { ApiUserProfile, CoopUserProfileApiRecipeRatingResponse } from './userProfileModels';

const updateCoopRatingCache = async ({
    recipeId,
    rating1to5,
}: {
    recipeId: number;
    rating1to5: number;
}): Promise<CoopUserProfileApiRecipeRatingResponse> => {
    const response = await axios.put('/api/userprofile/clearratingcache/', {
        recipeId,
        rating: rating1to5,
    });

    return response.data;
};

const getCurrentUserProfilePath = 'userprofile/currentUser/';
const getCurrentUserProfile = async (): Promise<ApiUserProfile> => {
    const response = await getLocalApiAxiosClient().get(getCurrentUserProfilePath);
    return response.data;
};

export default {
    updateCoopRatingCache,
    getCurrentUserProfile,
};
