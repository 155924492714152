import type { ReactNode } from 'react';
import { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
    domNode?: Element;
    domNodeSelector?: string;
    children?: ReactNode;
}

/**
 * Using Portal always replaces what was inside the domNode with Portal's children
 */
const Portal = (props: PortalProps) => {
    const domNode =
        props.domNode ||
        (props.domNodeSelector ? document.querySelector(props.domNodeSelector) : null);

    const [innerHtmlEmptied, setInnerHtmlEmptied] = useState(false);
    useLayoutEffect(() => {
        if (!innerHtmlEmptied && domNode) {
            domNode.innerHTML = '';
            setInnerHtmlEmptied(true);
        }
    }, [innerHtmlEmptied, domNode]);

    if (!innerHtmlEmptied) return null;

    if (!domNode) return null;

    return ReactDOM.createPortal(props.children, domNode);
};

export { Portal as default };
