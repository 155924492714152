/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import classNames from 'classnames';
import * as React from 'react';

import { getCssClassForDisplayOptionWithDefaultTag } from '../../epiUtils';
import EpiComponentLoader from './EpiComponentLoader';

interface EpiContentAreaProps {
    items: ContentAreaItem[];
    epiTag?: DisplayOption;
    itemCssClass?: string;
    /** If you pass renderItem function, you are responsible for generating item itself as well as it's Grid cell.
     * Use contentAreaItem, contentAreaItemClasses parameters to get access to default values for items.
     */
    renderItem?: (
        componentProps: IContent,
        contentAreaItem: ContentAreaItem,
        contentAreaItemClasses: string,
    ) => React.ReactNode;
}

const EpiContentArea = ({ items, epiTag, itemCssClass, renderItem }: EpiContentAreaProps) => {
    const areaItems = items?.filter((x) => !!x && !!x.contentLink.expanded) || [];

    return (
        <>
            {areaItems.map((contentAreaItem, index) => (
                <EpiComponentLoader
                    expandedValue={contentAreaItem.contentLink.expanded!}
                    options={{
                        isContentAreaItem: true,
                    }}
                    key={`{${contentAreaItem.contentLink.guidValue}_${index}}`}
                >
                    {(LazyComponent, epiComponentProps) => {
                        const contentAreaItemClasses = classNames(
                            getCssClassForDisplayOptionWithDefaultTag(
                                contentAreaItem.displayOption || epiTag,
                            ),
                            itemCssClass,
                        );

                        return (
                            <>
                                {(renderItem &&
                                    renderItem(
                                        epiComponentProps,
                                        contentAreaItem,
                                        contentAreaItemClasses,
                                    )) || (
                                    <div className={contentAreaItemClasses}>
                                        <LazyComponent {...epiComponentProps} />
                                    </div>
                                )}
                            </>
                        );
                    }}
                </EpiComponentLoader>
            ))}
        </>
    );
};

export default EpiContentArea;
