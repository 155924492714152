/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { appConfig } from '../../../../../appConfig';

const initialState = () => {
    return {
        isPunchout: appConfig.coopUserSettings.punchout.isPunchout,
        postUrl: appConfig.coopUserSettings.punchout.postUrl,
        specId: appConfig.coopUserSettings.punchout.specId,
    };
};

const slice = createSlice({
    name: 'punchoutUserInfo',
    initialState: initialState(),
    reducers: {},
});

export const { reducer: punchoutUserInfoReducer, actions: punchoutUserInfoActions } = slice;
