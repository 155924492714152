/* eslint-disable no-restricted-globals */

export const isNumeric = (n: unknown): n is number => {
    return typeof n === 'number';
};

export const isNumber = (n: string) => {
    const num = parseFloat(n);
    return !isNaN(num) && isFinite(num);
};
