import { getAuthToken } from '../api/auth/getAuthToken';
import { kimProxyDirectApi } from '../api/finance/kimProxyApi';

const kimProxyFlow = {
    getCustomerData: async (shouldInvalidateCache?: boolean) => {
        const identity = await getAuthToken();
        const details = await kimProxyDirectApi.getCustomerData(
            identity.token,
            shouldInvalidateCache,
        );
        return details;
    },
    getCustomerDataFull: async (shouldInvalidateCache?: boolean) => {
        const identity = await getAuthToken();
        const details = await kimProxyDirectApi.getCustomerDataFull(
            identity.token,
            shouldInvalidateCache,
        );
        return details;
    },
};

export default kimProxyFlow;
