import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store/structureDefinitions/cartState';
import type { ModalType } from '../store/structureDefinitions/modalState';

const isModalTypeOpen = createSelector(
    (state: RootState) => state.modal.isOpen,
    (state: RootState) => state.modal.type,
    (state: RootState) => state.modal.id,
    (_: unknown, type: ModalType | string) => type,
    (_: unknown, _2: unknown, id: string | undefined) => id,
    (isOpen, currentType, currentId, type, id) => {
        return isOpen && currentType === type && (!currentId || currentId === id);
    },
);

const isModalOpen = createSelector(
    (state: RootState) => state.modal.isOpen,
    (state: RootState) => state.modal.type,
    (isOpen, type) => isOpen && !!type,
);

const isAnyModalOpen = (state: RootState): boolean => state.modal.isOpen;

export const modalSelectors = {
    isOpen: isModalOpen,
    isModalTypeOpen,
    isAnyModalOpen,
};
