import { mapLoopSearchRecipe } from '../../../search/flow/mapSearchResponse';
import type {
    ApiDYCustomCampaignResponse,
    ApiDYProductRecommendationsResponse,
    ApiDYRecommendationItem,
    ApiDYRecommendationItemWithType,
} from '../../api/products/apiProductRequests';
import type {
    CustomCampaign,
    Recommendation,
    RecommendationItem,
} from '../../models/product/productDomainModels';
import { RecommendationType } from '../../models/product/productDomainModels';
import { mapLoopProduct } from './mapProduct';

export const mapCustomCampaigns = <TCustomData>(campaignsResponse: ApiDYCustomCampaignResponse) => {
    const campaigns = campaignsResponse.choices.map(
        (campaign) =>
            <CustomCampaign<TCustomData>>{
                id: campaign.id,
                selectedVariationId: campaign.variations[0]?.id,
                selectorName: campaign.name,
                custom: campaign.variations[0]?.payload?.data,
                analyticsMetadata: campaign.variations[0]?.analyticsMetadata,
            },
    );

    return campaigns;
};

const isProductRecommendation = (
    recommendationItem: ApiDYRecommendationItem,
): recommendationItem is ApiDYRecommendationItemWithType<ApiLoopProduct> => {
    return recommendationItem.type === 'Product';
};

const isRecipeRecommendation = (
    recommendationItem: ApiDYRecommendationItem,
): recommendationItem is ApiDYRecommendationItemWithType<ApiLoopSearchRecipe> => {
    return recommendationItem.type === 'RecipesEntity';
};

export const mapProductRecommendations = (
    apiRecommendations: ApiDYProductRecommendationsResponse,
) => {
    const productRecommendations = apiRecommendations.choices.map((apiRecommendation) => {
        const recommendation: Recommendation = {
            recommendationId: apiRecommendation.id,
            selectedVariationId: apiRecommendation.variations[0]?.id,
            selectorName: apiRecommendation.name,
            items: (
                apiRecommendation.variations[0]?.payload?.data?.slots?.map<RecommendationItem | null>(
                    (slot) => {
                        if (isProductRecommendation(slot.productData)) {
                            const mappedProduct = mapLoopProduct(slot.productData);
                            return {
                                ...mappedProduct,
                                slotId: slot.slotId,
                                type: RecommendationType.Product,
                            };
                        }
                        if (isRecipeRecommendation(slot.productData)) {
                            const mappedRecipe = mapLoopSearchRecipe(slot.productData);
                            return {
                                ...mappedRecipe,
                                id: slot.sku,
                                slotId: slot.slotId,
                                type: RecommendationType.Recipe,
                                isFoodBox: mappedRecipe.keywords?.includes('paketeringar') || false, // should be proper field but it would need to be exported by assortment team
                            };
                        }

                        return null;
                    },
                ) || []
            ).filter(Boolean),
            title: apiRecommendation.variations[0]?.payload?.data?.custom?.title || '',
            analyticsMetadata: apiRecommendation.variations[0]?.analyticsMetadata,
        };

        return recommendation;
    });

    return productRecommendations;
};
