// prettier-ignore
const BaseLazyComponents = {
    WideUspBlock: () => import('../components/organisms/WideUspBlock/WideUspBlock'),
    ReplaceableTextBlock: () =>
        import('../components/organisms/ReplaceableTextBlock/ReplaceableTextBlock'),
    SideBarMenu: () => import('../components/organisms/SideBarMenu/SideBarMenu'),
};

const EpiServerBlocks = {
    EpiBlockLoader: () => import('../../episerver/components/base/EpiBlockLoader'),
    CustomerServiceBlock: () => import('../components/organisms/CustomerServiceBlock'),
};

// prettier-ignore
const EcommerceLazyComponents = {
    ImageTextPropertyBlock: () => import('../components/organisms/ImageTextPropertyBlock/ImageTextPropertyBlock'),
    SingleImageTextBlock: () => import('../components/organisms/SingleImageTextBlock/SingleImageTextBlock'),
    DoubleImageTextBlock: () => import('../components/organisms/DoubleImageTextBlock/DoubleImageTextBlock'),
    TripleImageTextBlock: () => import('../components/organisms/TripleImageTextBlock/TripleImageTextBlock'),
    RecipeItemPagePartial: () => import('../../recipe/components/RecipeItemPagePartial/RecipeItemPagePartial'),
};

// prettier-ignore
const MemberLazyComponents = {
    ApplyExtraMMCard: () => import('../../member/bonuscard/ApplyExtraMMCard'),
    MemberPanelBlock: () => import('../../member/components/MemberPanel/MemberPanelBlock'),
    ApplyCardBlock: () => import('../../finance/components/ApplyMatkontoCard/ApplyMatkontoCard'),
};

// prettier-ignore
export const mountableReactComponents = {
    ...BaseLazyComponents,
    ...EcommerceLazyComponents,
    ...MemberLazyComponents,
    ...EpiServerBlocks
};

export type MountableReactComponentsType = keyof typeof mountableReactComponents;

export const isMountableReactComponent = (
    cmpName: string,
): cmpName is MountableReactComponentsType => {
    return Object.keys(mountableReactComponents).includes(cmpName);
};
