import { appInsights } from '../appInsights';

const trackAppInsightsEvent = (name: string, error: Error, el: Element) => {
    appInsights?.trackEvent({
        name,
        properties: {
            name: error?.name,
            stack: error?.stack,
            element: el,
        },
    });
};

/**
 * Returns a function that triggers the callback once when the element is in the viewport.
 */
const createTriggerCallbackOnceWhenInViewPort = (options: { threshold: number }) => {
    const observedElements: Map<Element, () => void> = new Map();

    const observer = new IntersectionObserver((entries, observerOptions) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const el = entry.target;
                const callback = observedElements.get(el);

                try {
                    callback?.();
                } catch (error) {
                    if (error instanceof Error) {
                        trackAppInsightsEvent(
                            'createTriggerCallbackOnceWhenInViewPort - Could not get element from Map',
                            error,
                            el,
                        );
                    }
                }

                observedElements.delete(el);
                observerOptions.unobserve(el);
            }
        });
    }, options);

    return (el: Element, callback: () => void) => {
        observedElements.set(el, callback);
        observer.observe(el);
    };
};

/**
 * Minimum of 50% must be shown before intersection triggers
 */
export const triggerCallbackOnceWhenInViewPort = createTriggerCallbackOnceWhenInViewPort({
    threshold: 0.5,
});
