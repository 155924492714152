/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { appConfig } from '../../../../../appConfig';
import { UserType } from '../../structureDefinitions/userState';

export interface UserTypeState {
    type?: UserType;
    isInitialized: boolean;
}

export const getInitialUserType = (): UserType => {
    if (!appConfig.coopUserSettings.isAuthenticated) {
        return UserType.anonymous;
    }

    // TOD add municipal check

    if (appConfig.coopUserSettings.punchout.isPunchout === true) {
        return UserType.punchout;
    }

    if (appConfig.coopUserSettings.isCompany) {
        return UserType.company;
    }

    return UserType.private;
};

const getInitialState = () => {
    return {
        isInitialized: false,
        type: getInitialUserType(),
    };
};

const slice = createSlice({
    name: 'userType',
    initialState: getInitialState(),
    reducers: {
        set: (state: UserTypeState, action: PayloadAction<UserType>) => {
            if (!action.payload) return getInitialState();

            state.isInitialized = true;
            state.type = action.payload;
        },
    },
});

export const { actions: userTypeActions, reducer: userTypeReducer } = slice;
