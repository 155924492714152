/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */

'use client';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { Modal } from '../Modal';
import AutoClosingNotification from './AutoClosingNotification';
import type { NotificationProps } from './Notification.types';
import NotificationWrapper from './NotificationWrapper';
import PermanentNotification from './PermanentNotification';

export const Notification = (props: React.PropsWithChildren<NotificationProps>) => {
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    const [message, setMessage] = useState('');
    // derived state from props is crap, but its the only way
    // the CSS Transitions will work if 'show' is derived from
    // a message existance, i.e. show={!!message}
    useEffect(() => {
        if (props.message) setMessage(props.message);
    }, [props.message]);

    if (props.behaviour === 'autoclosing' || props.behaviour === undefined) {
        return (
            <NotificationWrapper
                ref={wrapperRef}
                show={props.show}
                noAnimation={props.noAnimation}
                position={props.position}
                ariaLive={props.type !== 'alert' ? 'polite' : undefined} // dont set both role and aria-live or the some screenreaders will double announce
                role={props.type === 'alert' ? 'alert' : undefined}
            >
                <AutoClosingNotification
                    ref={wrapperRef}
                    show={props.show}
                    header={props.header}
                    message={message}
                    size={props.size}
                    type={props.type}
                    theme={props.theme}
                    iconSlot={props.iconSlot}
                    close={props.close}
                    hideAfterMiliseconds={props.hideAfterMiliseconds}
                    hasOutsideClick={props.hasOutsideClick}
                />
            </NotificationWrapper>
        );
    }

    if (props.behaviour === 'permanent') {
        return (
            <NotificationWrapper
                ref={wrapperRef}
                show={props.show}
                noAnimation={props.noAnimation}
                position={props.position}
            >
                <PermanentNotification
                    show={props.show}
                    header={props.header}
                    message={message}
                    size={props.size}
                    type={props.type}
                    theme={props.theme}
                    iconSlot={props.iconSlot}
                    ctaProps={props.ctaProps}
                />
            </NotificationWrapper>
        );
    }

    if (props.behaviour === 'modal') {
        return (
            <Modal isOpen={props.show} close={props.close} disableOutsideClick>
                <NotificationWrapper
                    ref={wrapperRef}
                    show
                    noAnimation // animation is handled by the modal
                    position={props.position}
                >
                    <PermanentNotification
                        show={props.show}
                        header={props.header}
                        message={message}
                        size={props.size}
                        type={props.type}
                        theme={props.theme}
                        iconSlot={props.iconSlot}
                        ctaProps={props.ctaProps}
                        useInitialCtaFocus
                        asModal
                    />
                </NotificationWrapper>
            </Modal>
        );
    }

    return null;
};
