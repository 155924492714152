import type { CustomerData, CustomerDataMinimized } from '../../models/kimProxy/customerData';
import getDirectKimProxyAxiosClient from '../clients/directKimProxyAxiosClient';

export const kimProxyDirectApi = {
    getCustomerData: async (
        token: string,
        shouldInvalidateCache?: boolean,
    ): Promise<CustomerDataMinimized> => {
        const kimCustomerDataUrl = `/customer/data/me/${
            shouldInvalidateCache ? '?invalidateCache=true' : ''
        }`;

        const response =
            await getDirectKimProxyAxiosClient(token).get<CustomerDataMinimized>(
                kimCustomerDataUrl,
            );

        const resp = response.data;
        return resp;
    },
    getCustomerDataFull: async (
        token: string,
        shouldInvalidateCache?: boolean,
    ): Promise<CustomerData> => {
        const kimCustomerDataUrl = `/customer/data/me/${
            shouldInvalidateCache ? '?invalidateCache=true' : ''
        }`;

        const response =
            await getDirectKimProxyAxiosClient(token).get<CustomerData>(kimCustomerDataUrl);

        const resp = response.data;
        return resp;
    },
};
