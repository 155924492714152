import { LocalStorageFacade } from '../../../../facades/storage/localStorageFacade';
import { PriceType } from '../../models/priceData/priceData';

const PRICE_TYPE_STORAGE_KEY = 'priceType';
const getStorageKey = (userId: string) => {
    return `${PRICE_TYPE_STORAGE_KEY}_${userId}`;
};

const priceTypeStorage = {
    setPricesWithTax: (userId: string): void => {
        const storageKey = getStorageKey(userId);
        LocalStorageFacade.instance.add(storageKey, PriceType.IncludeTax);
    },
    setPricesWithoutTax: (userId: string): void => {
        const storageKey = getStorageKey(userId);
        LocalStorageFacade.instance.add(storageKey, PriceType.ExcludeTax);
    },
    getCurrent: (userId: string): PriceType | undefined => {
        const storageKey = getStorageKey(userId);
        const priceType = LocalStorageFacade.instance.get(storageKey);

        switch (priceType) {
            case PriceType.IncludeTax:
                return PriceType.IncludeTax;
            case PriceType.ExcludeTax:
                return PriceType.ExcludeTax;
            default:
                return undefined;
        }
    },
};

export default priceTypeStorage;
