import type { AxiosRequestConfig } from 'axios';

import type { HybrisOrder } from '../../models/hybris/hybrisOrder';
import type { HybrisOrderHistory } from '../../models/hybris/hybrisOrderHistory';
import type { SubscriptionFrequency } from '../../store/structureDefinitions/checkoutState';
import getDirectHybrisAxiosClient, {
    getUserIdUrlSegment,
} from '../clients/directHybrisAxiosClient';
import hybrisAxiosClient from '../clients/hybrisAxiosClient';
import { editOrderErrorInterceptor } from './orderErrorInterceptors';

const getOrdersBaseUrl = (userId: string): string => `users/${getUserIdUrlSegment(userId)}/orders`;

export const orderDirectApi = {
    getOrderById:
        (token: string, fields?: string) =>
        async (userId: string, orderId: string): Promise<HybrisOrder> => {
            const axiosConfig: AxiosRequestConfig | undefined = fields
                ? { params: { fields } }
                : undefined;

            const response = await getDirectHybrisAxiosClient(token).get<HybrisOrder>(
                `${getOrdersBaseUrl(userId)}/${orderId}`,
                axiosConfig,
            );
            const resp = response.data;
            return resp;
        },

    getOrderHistory:
        (spaToken: string) => async (userId: string, currentPage: number, pageSize: number) => {
            const queryString = new URLSearchParams();
            queryString.append('currentPage', currentPage.toString());
            queryString.append('pageSize', pageSize.toString());
            queryString.append('fields', 'FULL');

            const response = await getDirectHybrisAxiosClient(spaToken).get<{
                orders: HybrisOrderHistory[];
            }>(`${getOrdersBaseUrl(userId)}?${queryString.toString()}`);

            const resp = response.data.orders;
            return resp;
        },

    cancelOrder: (token: string) => async (userId: string, orderId: string) => {
        const response = await getDirectHybrisAxiosClient(token).post<{ cancelOk: boolean }>(
            `${getOrdersBaseUrl(userId)}/${orderId}/cancel`,
        );
        const resp = response?.data?.cancelOk || false;
        return resp;
    },
    getOrderCount: (token: string) => async (userId: string) => {
        const response = await getDirectHybrisAxiosClient(token).head(getOrdersBaseUrl(userId));
        const countString = response.headers['x-total-count'] as string;
        const count = countString ? parseInt(countString, 10) : undefined;
        return count;
    },
    orderEdited: (token: string) => async (userId: string, cartId: string) => {
        const params = new URLSearchParams();
        params.append('cartId', cartId);
        const response = await getDirectHybrisAxiosClient(token, [
            editOrderErrorInterceptor,
        ]).post<HybrisOrder>(`${getOrdersBaseUrl(userId)}/edited`, params);
        const resp = response?.data;
        return resp;
    },
};

export const orderApi = {
    createForCurrentCart: async (options?: CreateOrderOptions) => {
        const response = await hybrisAxiosClient.post<{ id: number }>('/orders/create', options);
        return response.data;
    },
    completeEditOrder: async (orderId: string, transactionCode: string) => {
        const response = await hybrisAxiosClient.post<{ id: number }>(
            `/orders/${orderId}/change/complete`,
            {
                transactionCode,
            },
        );
        return response.data;
    },
    editOrder: async (orderId: string) => {
        const response = await hybrisAxiosClient.put(`/orders/${orderId}/change`);
        return response.data;
    },
    cancelEditOrder: async (orderId: string) => {
        const response = await hybrisAxiosClient.delete(`/orders/${orderId}/change`);
        return response.data;
    },
};

export interface CreateOrderOptions {
    securityCode?: string;
    subscriptionFrequency: SubscriptionFrequency | null;
    transaction?: string;
}
