import classNames from 'classnames';
import * as React from 'react';

import RawHtml from '../../../common/components/atoms/RawHtml';
import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { userProfileSelectors } from '../../../common/selectors/userProfileSelectors';
import UserTypeModeSwitch from '../UserTypeModeSwitch';

interface PreHeaderProps {
    preHeaderText?: string;
    showB2BSwitch: boolean;
    theme: 'ctaGreen' | 'black' | 'darkGreen';
}

const PreHeader = (props: PreHeaderProps) => {
    const hidePreHeader = useAppSelector((state) => state.ui.shared.featureFlags.hidePreHeader);
    const profile = useAppSelector(userProfileSelectors.profile);
    const isAuthenticated = useAppSelector(userProfileSelectors.isAuthenticated);

    if (hidePreHeader) {
        return null;
    }
    return (
        <div
            className={classNames(
                'PreHeader',
                props.theme === 'ctaGreen' && 'PreHeader--ctaGreen',
                props.theme === 'black' && 'PreHeader--black',
                props.theme === 'darkGreen' && 'PreHeader--darkGreen',
            )}
        >
            <div>
                {isAuthenticated && profile?.isCompany ? (
                    <>
                        Inloggad som:&nbsp;
                        <span className="u-textWeightBold">Företagskund</span>
                    </>
                ) : (
                    props.preHeaderText && <RawHtml html={props.preHeaderText} />
                )}
            </div>
            {props.showB2BSwitch && <UserTypeModeSwitch theme="white" />}
        </div>
    );
};

export default PreHeader;
