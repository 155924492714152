'use client';

import { AccessibleIcon } from '@radix-ui/react-accessible-icon';
import * as React from 'react';

import { Icon } from '../Icon';
import type { IconProps } from '../Icon/Icon';
import type { ChipButtonProps } from './ChipButton';
import ChipButton from './ChipButton';

type ChipIconButtonProps = {
    icon: IconProps['icon'];
    label: string;
} & Omit<ChipButtonProps, 'asIcon' | 'onClick'> &
    Required<Pick<ChipButtonProps, 'onClick'>>;

const ChipIconButton = React.forwardRef<
    HTMLButtonElement,
    React.PropsWithChildren<ChipIconButtonProps>
>(({ icon, label: title, children, ...chipButtonProps }, ref) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ChipButton ref={ref} {...chipButtonProps} asIcon>
            <AccessibleIcon label={title}>
                <Icon icon={icon} />
            </AccessibleIcon>
            {children}
        </ChipButton>
    );
});

export default ChipIconButton;
