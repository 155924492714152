import { failSilently } from '../../utils/failSilently';
import { sendGA4Event } from '../shared/sendGa4Event';

const addAddress = () => {
    const event: UserProfileEvent = {
        event: 'user_profile',
        type: 'add adress',
        event_key: 'user_profile_add',
    };

    sendGA4Event(event);
};

const editAddress = () => {
    const event: UserProfileEvent = {
        event: 'user_profile',
        type: 'edit adress',
        event_key: 'user_profile_edit',
    };

    sendGA4Event(event);
};

const addContactInfo = () => {
    const event: UserProfileEvent = {
        event: 'user_profile',
        type: 'add contact info',
        event_key: 'user_profile_add',
    };

    sendGA4Event(event);
};

const login = (method: LoginMethodEventType, status: LoginMethodStatus) => {
    const event: LoginEvent = {
        event: 'login',
        status,
        method,
    };

    sendGA4Event(event);
};

const logout = () => {
    const event: LogoutEvent = {
        event: 'logout',
    };

    sendGA4Event(event);
};

const signup = (
    type: SignupMethodEventType,
    customerType: SignupCustomerType,
    method: SignupMethodType,
) => {
    const event: SignupEvent = {
        event: 'sign_up',
        type,
        customer_type: customerType,
        method,
    };

    sendGA4Event(event);
};

const accountRemoved = () => {
    const event: AccountRemovedEvent = {
        event: 'remove_account',
    };

    sendGA4Event(event);
};

const connectMembership = (
    status: ConnectMembershipStatusType,
    method: ConnectMembershipMethodEventType,
) => {
    const event: ConnectMembershipEvent = {
        event: 'connect_membership',
        status,
        method,
    };

    sendGA4Event(event);
};

const accountSettingsInteraction = (clickText: string) => {
    const event: AccountSettingsInteractionEvent = {
        event: 'interaction',
        click_text: clickText,
        page_type: 'account settings',
    };
    sendGA4Event(event);
};

export default {
    address: {
        add: failSilently(addAddress),
        edit: failSilently(editAddress),
    },
    contactInfo: {
        add: failSilently(addContactInfo),
    },
    login: failSilently(login),
    logout: failSilently(logout),
    signup: failSilently(signup),
    accountRemoved: failSilently(accountRemoved),
    connectMembership: failSilently(connectMembership),
    navigation: {
        accountSettingsInteraction: failSilently(accountSettingsInteraction),
    },
};
