import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable no-param-reassign */

interface CurrentPageState {
    title?: string;
    canonicalPathName?: string;
    gaPageTypeName?: string;

    /**
     * Includes leading question mark
     */
    queryString?: string;
    mountCounter: number;
}

const initialState: CurrentPageState = {
    canonicalPathName: undefined,
    title: undefined,
    mountCounter: 0,
    gaPageTypeName: undefined,
    queryString: undefined,
};

const slice = createSlice({
    name: 'currentPage',
    initialState,
    reducers: {
        setPageGaData: (
            state,
            action: PayloadAction<{
                title: string;
                canonicalUrl: string;
                gaPageTypeName?: string;
                queryString?: string;
            }>,
        ) => {
            const { pathname } = new URL(action.payload.canonicalUrl);

            state.title = action.payload.title;
            state.canonicalPathName = pathname;
            state.gaPageTypeName = action.payload.gaPageTypeName;
            state.queryString = action.payload.queryString;

            state.mountCounter += 1;
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
    },
});

export const currentPageActions = slice.actions;

export default slice.reducer;
