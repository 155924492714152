import type { ApiDYBaseRequest } from '../../common/api/products/apiProductRequests';
import getDirectPersonalizationSearchAxiosClient from '../../search/api/directPersonalizationAxiosClient';
import type {
    ApiAnoymousPageviewRequest,
    ApiAnoymousPageviewResponse,
    ApiEventsRequest,
} from './trackingRequests';

const prepareEventRequestParams = (
    storeId: string | undefined,
    customerGroups: string[],
    device: string,
) => {
    const requestParams = {
        store: storeId,
        groups: customerGroups && !!customerGroups.length ? customerGroups.join(',') : null,
        device,
    };

    return requestParams;
};

const trackingDirectApi = {
    track: async (
        eventRequest: ApiEventsRequest | (ApiEventsRequest & ApiDYBaseRequest),
        device: string,
    ) => {
        const client = getDirectPersonalizationSearchAxiosClient(false);
        await client.post('/events', eventRequest, {
            params: prepareEventRequestParams(undefined, [], device),
        });
    },
    trackAnonymousPageView: async (eventRequest: ApiAnoymousPageviewRequest, device: string) => {
        try {
            const client = getDirectPersonalizationSearchAxiosClient(false);
            const response = await client.post<ApiAnoymousPageviewResponse>(
                '/search/choose',
                eventRequest,
                {
                    params: prepareEventRequestParams('', [], device),
                },
            );
            return response.data;
        } catch {
            return { cookies: [] };
        }
    },
    trackPageView: async (eventRequest: ApiAnoymousPageviewRequest, device: string) => {
        const client = getDirectPersonalizationSearchAxiosClient(false);
        const response = await client.post<ApiAnoymousPageviewResponse>(
            '/events/pageview',
            eventRequest,
            {
                params: prepareEventRequestParams(undefined, [], device),
            },
        );
        return response.data;
    },
};

export default trackingDirectApi;
