import type { FC } from 'react';
import * as React from 'react';

import ComponentPortals from '../../../common/components/molecules/ComponentPortals';
import type { USPNoticeType } from '../USPNotices';
import USPNotices from '../USPNotices';

interface HeaderNoticesDOMProps {
    uspNotices: USPNoticeType[];
}

const HeaderNoticesPortal: FC<React.PropsWithChildren<unknown>> = (props) => {
    return (
        <ComponentPortals
            cmpName="HeaderNotices"
            render={(domProps: HeaderNoticesDOMProps) => (
                <>
                    {props.children}
                    <USPNotices data={domProps.uspNotices} />
                </>
            )}
        />
    );
};

export default HeaderNoticesPortal;
