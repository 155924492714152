import AwesomeDebouncePromise from 'awesome-debounce-promise';
import type { AsyncState, AsyncStateStatus, UseAsyncOptions } from 'react-async-hook';
import { useAsyncAbortable } from 'react-async-hook';

import type { ApiException } from '../exceptions/exceptionDefinitions';
import { AsyncDispatcherError } from './AsyncDispatcherError';
import type { AsyncDispatcherOptions } from './useAsyncDispatcher';
import useConstant from './useConstant';
import useEventCallback from './useEventCallback';

interface AsyncAbortableDispatcherOptions<R> extends AsyncDispatcherOptions<R> {
    debounced?: boolean;
    debounceTime?: number;
}

const useAsyncAbortableDispatcher = <R = unknown, Args extends unknown[] = unknown[]>(
    func: (abortSignal: AbortSignal, ...args: Args) => Promise<R>,
    params: Args,
    options: AsyncAbortableDispatcherOptions<R>,
) => {
    const asyncFunction = useConstant(() => {
        if (options.debounced && options.debounceTime) {
            return AwesomeDebouncePromise(func, options.debounceTime);
        }
        return func;
    });

    const {
        result,
        error: asyncError,
        execute: asyncExecute,
        reset,
        loading,
        status,
    } = useAsyncAbortable(
        asyncFunction,
        [...params] as Args,
        {
            executeOnMount: options.executeOnMount,
            initialState: (opt) => {
                return {
                    ...(opt?.initialState || null),
                    result: options?.initialResultState,
                    loading: options?.initialLoadingState,
                    error: options?.initialErrorState,
                } as AsyncState<R>;
            },
            setLoading: options.keepPreviousResult
                ? (state) => ({ ...state, loading: true })
                : () => ({ loading: true }),
            setError: (error: unknown): AsyncState<R> => {
                // this is the default in react-hook-async
                return {
                    status: <AsyncStateStatus>'error',
                    loading: false,
                    result: undefined,
                    error: new AsyncDispatcherError(error as ApiException),
                };
            },
        } as UseAsyncOptions<R>,
    );

    const execute = useEventCallback((...args: Args) => {
        return asyncExecute(...args);
    });

    return {
        execute,
        result,
        status,
        isLoading: loading,
        error: (asyncError as AsyncDispatcherError | undefined)?.exception,
        clearError: reset,
    };
};

export default useAsyncAbortableDispatcher;
