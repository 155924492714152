import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store/structureDefinitions/cartState';
import type { ErrorGroup, ErrorState } from '../store/structureDefinitions/errorsState';

const selectByGroup = createSelector(
    (state: RootState): ErrorState => state.errors,
    (_: RootState, group: ErrorGroup) => group,
    (errors, group: ErrorGroup) => (group ? errors[group] : []),
);

const selectFirstByGroup = createSelector(
    (state: RootState): ErrorState => state.errors,
    (_: RootState, group: ErrorGroup) => group,
    (errors, group: ErrorGroup) => {
        if (group) {
            return errors[group].length ? errors[group][0] : null;
        }
        return null;
    },
);

export const errorsSelectors = {
    selectByGroup,
    selectFirstByGroup,
};
