import type { UserAddress, UserAddresses } from '../../store/structureDefinitions/userState';
import getDirectHybrisAxiosClient from '../clients/directHybrisAxiosClient';
import hybrisAxiosClient from '../clients/hybrisAxiosClient';
import type { ApiModifyUserAddressRequest } from './userAddressModels';

const userAddressApi = {
    // Fetches valid addresses that this user has and is valid for the current cart, user must have zip selected
    fetchForCurrentCart: async (): Promise<UserAddresses[]> => {
        const response = await hybrisAxiosClient.get(
            '/api/hybris/carts/current/addresses/delivery/valid',
        );
        return response.data;
    },

    // Fetches all addresses, does not matter what zipcode is used on a cart
    fetchAll: async (): Promise<UserAddress[]> => {
        const response = await hybrisAxiosClient.get('/user/address/current');
        return response.data;
    },

    // Fetches all addresses, does not matter what zipcode is used on a cart
    fetchAllPunchout:
        (accessToken: string) =>
        async (
            unitId: string,
        ): Promise<{
            addresses: UserAddress[];
        }> => {
            const response = await getDirectHybrisAxiosClient(accessToken).get(
                `/b2b/unit/${unitId}/addresses`,
            );
            return response.data;
        },

    create: async (address: ApiModifyUserAddressRequest): Promise<UserAddress> => {
        const response = await hybrisAxiosClient.post('/user/address/current', address);
        return response.data;
    },

    update: async (address: ApiModifyUserAddressRequest): Promise<void> => {
        await hybrisAxiosClient.put('/user/address/current', address);
    },

    remove: async (id: string): Promise<void> => {
        return hybrisAxiosClient.delete(`/user/address/current/${id}`);
    },
};

export { userAddressApi };
