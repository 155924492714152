/* eslint-disable react/jsx-props-no-spreading */
import type { ReactNode } from 'react';
import * as React from 'react';
import { Suspense } from 'react';

import type { LoadComponentOptions } from '../../epiComponentLoader';
import { getEpiComponent } from '../../epiComponentLoader';

interface EpiComponentLoaderProps {
    options?: LoadComponentOptions;
    expandedValue: IContent;
    children?: (
        epiComponent: React.LazyExoticComponent<
            React.ComponentType<React.PropsWithChildren<IContent>>
        >,
        epiComponentProps: IContent,
    ) => ReactNode;
    fallback?: ReactNode;
}

const EpiComponentLoader = (props: EpiComponentLoaderProps) => {
    const LazyComponent = getEpiComponent(props.expandedValue, props.options);

    if (!LazyComponent) {
        return null;
    }

    return (
        <Suspense fallback={props.fallback || null}>
            {props.children ? (
                props.children(LazyComponent, props.expandedValue)
            ) : (
                <LazyComponent {...props.expandedValue} />
            )}
        </Suspense>
    );
};

export default EpiComponentLoader;
