export const trimEnd = (input: string, toTrim: string): string => {
    if (!input || !toTrim) return input;

    if (!input.endsWith(toTrim)) return input; // stop recursion

    const trimmed = trimEnd(input.slice(0, -toTrim.length), toTrim);
    return trimmed;
};

export const trimStart = (input: string, toTrim: string): string => {
    if (!input || !toTrim) return input;

    if (!input.startsWith(toTrim)) return input; // stop recursion

    const trimmed = trimStart(input.slice(toTrim.length), toTrim);
    return trimmed;
};

export const trim = (input: string, toTrim: string) => {
    const trimmed = trimEnd(trimStart(input, toTrim), toTrim);
    return trimmed;
};

export const ensureLeadingCharacter = (input: string, leadingCharacter: string) => {
    if (!input.startsWith(leadingCharacter)) return leadingCharacter + input;
    return input;
};

export const ensureTrailingCharacter = (input: string, trailingCharacter: string) => {
    if (!input.endsWith(trailingCharacter)) return input + trailingCharacter;
    return input;
};

export const ensureLeadingSlash = (input: string) => {
    return ensureLeadingCharacter(input, '/');
};

export const ensureTrailingSlash = (input: string) => {
    return ensureTrailingCharacter(input, '/');
};

export const ensureSlashes = (input: string) => {
    return ensureLeadingSlash(ensureTrailingSlash(input));
};

export const trimTrailingSlash = (input: string) => {
    return trimEnd(input, '/');
};

export const trimLeadingSlash = (input: string) => {
    return trimStart(input, '/');
};

export const trimSlashes = (input: string) => {
    return trim(input, '/');
};

export const toTitleCase = (input: string) => {
    if (!input || input.length === 0) return input;

    const newInputText = safeSplit(input, ' ')
        .map((val) => firstLetterTitleCase(val))
        .join(' ');

    return newInputText;
};

const firstLetterTitleCase = (input: string) => {
    return input.charAt(0).toUpperCase() + input.substr(1).toLowerCase();
};

interface SplitOptions {
    removeEmptyEntries: boolean;
}

export const safeSplit = (
    input: string,
    separator: string,
    options: SplitOptions | null = null,
) => {
    if (!input || !input.length) return [];

    let values = input.split(separator);

    if (options && options.removeEmptyEntries) {
        values = values.filter(Boolean);
    }

    return values;
};
