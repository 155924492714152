import { useQuery } from '@tanstack/react-query';

import { appConfig } from '../../../../appConfig';
import { consentSubscriptionFlow } from '../../../common/flow/consentSubscriptionFlow';
import { getNeoId } from '../../../common/utils/neoIdUtils';
import { emailSubscriptionsKeys } from '../../myCoopQueries';

const useEmailSubscriptionQuery = (preferNeoId: boolean | undefined, enabled: boolean) => {
    const neoId = (preferNeoId && getNeoId()) || undefined;
    const mmid = appConfig.coopUserSettings.profile?.medmeraId?.toString();
    const email = appConfig.coopUserSettings.profile?.email?.toString();

    let queryKeyName: string[] = [];

    if (neoId) queryKeyName = emailSubscriptionsKeys.emailSubsriptionsByNeoId(neoId);
    else if (mmid) queryKeyName = emailSubscriptionsKeys.emailSubsriptionsByMmId(mmid);
    else if (email) queryKeyName = emailSubscriptionsKeys.emailSubsriptionsByEmail(email);

    const query = useQuery({
        queryKey: queryKeyName,
        queryFn: () => consentSubscriptionFlow.getSubscriptions(preferNeoId),
        retry: 0,
        enabled: enabled && queryKeyName.length > 0 && !!queryKeyName,
    });

    return query;
};

export default useEmailSubscriptionQuery;
