import { productFlow } from '../../../flow/product/productFlow';

export const mapProductToGaEcommerceItem = (
    product: ApiProduct,
    quantity: number,
    variantId?: string,
    index?: number,
) => {
    let beforePromotionPrice = product?.priceData?.inclTaxPrice;

    const selectedVariant = product.variants?.find((item) => item.id === variantId);

    if (selectedVariant) {
        beforePromotionPrice = selectedVariant.priceData?.inclTaxPrice;
    }

    const data: GaEcommerceItem = {
        item_id: product.identifier,
        price: beforePromotionPrice || null,
        quantity,
        index,
        item_variant: selectedVariant?.name?.toLowerCase(),
    };

    return data;
};

export const mapProductToGaItemWithDetails = (
    item: ApiProduct,
    quantity: number,
    variantId?: string,
) => {
    const gaItem = mapProductToGaEcommerceItem(item, quantity, variantId);

    const mapped: GaEcommerceItem & GaEcommerceItemDetails = {
        ...gaItem,
        item_name: item.name,
        item_brand: item.manufacturer,
        item_category: item.sections?.[0]?.[0]?.name,
        item_category2: item.sections?.[0]?.[1]?.name,
        item_category3: item.sections?.[0]?.[2]?.name,
    };

    return mapped;
};

/**
 * Use only in GA context, never in presentational code
 */
export const getGaTotalProductsValue = (items: GaEcommerceItem[]) => {
    return Number(
        items.reduce((acc, curr) => acc + (curr.price || 0) * (curr.quantity || 1), 0).toFixed(2),
    );
};

export const getGaMinimalProductKey = (product: { productId: string; variantId?: string }) =>
    product.variantId ? `${product.productId}-${product.variantId}` : product.productId;

export const getGaProductsWithVariantsMap = async <
    TItem extends {
        productId: string;
        variantId: string | undefined;
    },
>(
    inputProductData: TItem[],
    storeId: string,
) => {
    const productsMap = await productFlow.getProductsByIdsMap(
        inputProductData.map((item) => item.productId),
        storeId,
    );

    const map: typeof productsMap = new Map();

    inputProductData.forEach(({ productId, variantId }) => {
        const product = productsMap.get(productId);

        if (product) {
            map.set(getGaMinimalProductKey({ productId, variantId }), product);
        }
    });

    return map;
};

// Keeps the order of inputData array
export const getGaProductsWithVariantsList = async <
    TItem extends {
        productId: string;
        variantId: string | undefined;
    },
>(
    inputProductData: TItem[],
    storeId: string,
) => {
    const map = await getGaProductsWithVariantsMap(inputProductData, storeId);

    const list = inputProductData
        .map((inputData) => {
            const product = map.get(
                getGaMinimalProductKey({
                    productId: inputData.productId,
                    variantId: inputData.variantId,
                }),
            );

            if (!product) return null;

            return { productData: product, inputData };
        })
        .filter(Boolean);

    return list;
};

// Merge products from previous and current state with previous and new quantity
// Includes removed, added and updated products (looking at quantity to determine)
export const getGaChangedItems = (
    previousProducts: {
        productId: string;
        variantId: string | undefined;
        quantity: number;
    }[],
    currentProducts: {
        productId: string;
        variantId: string | undefined;
        quantity: number;
    }[],
) => {
    let mergedProducts: {
        productId: string;
        variantId: string | undefined;
        previousQuantity: number;
        newQuantity: number;
    }[] = [];

    // Iterate through the previous products array and add the quantities to the map
    const previousProductsMap: Map<string, (typeof mergedProducts)[0]> = new Map();
    previousProducts.forEach((product) => {
        const key = getGaMinimalProductKey(product);
        // If the product already exists in the map, add the quantities
        previousProductsMap.set(key, {
            ...product,
            previousQuantity: product.quantity,
            newQuantity: 0,
        });
    });

    // Iterate through the current products array and add the quantities from previous products
    currentProducts.forEach((product) => {
        const key = getGaMinimalProductKey(product);

        const previousProduct = previousProductsMap.get(key);
        const previousQuantity = previousProduct ? previousProduct.previousQuantity : 0;

        mergedProducts.push({
            productId: product.productId,
            variantId: product.variantId,
            previousQuantity,
            newQuantity: product.quantity,
        });

        if (previousProduct) {
            previousProductsMap.delete(key);
        }
    });

    // Add any remaining products from the previous products array with a new quantity of 0
    previousProductsMap.forEach((product) => {
        mergedProducts.push({
            productId: product.productId,
            variantId: product.variantId,
            previousQuantity: product.previousQuantity,
            newQuantity: 0,
        });
    });

    // if quantity is the same, then it's not a changed product
    mergedProducts = mergedProducts.filter((item) => item.previousQuantity !== item.newQuantity);

    return mergedProducts;
};
