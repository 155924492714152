import { sendInteractionEvent } from '../utils';

const sendGaReplacableItemSwitchInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction: 'Checkout - Varukorg',
        eventLabel,
    });
};

const sendGaReplacableItemSwitchInteractionClickAllCheckbox = () => () => {
    sendGaReplacableItemSwitchInteractionClick('Ersätt varor - Ersätt varor');
};

const sendGaReplacableItemSwitchInteractionClickProductCheckbox = () => () => {
    sendGaReplacableItemSwitchInteractionClick('Ersätt varor - Ersätt vara');
};

const itemReplaceEvents = {
    clickAll: sendGaReplacableItemSwitchInteractionClickAllCheckbox,
    clickProductCheckbox: sendGaReplacableItemSwitchInteractionClickProductCheckbox,
};

export default itemReplaceEvents;
