/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ModalState } from '../../structureDefinitions/modalState';
import { FlyInType } from '../../structureDefinitions/modalState';
import { cncNavigateTo, cncSetTo } from '../ui/uiActions';
import type { OpenModalPayload } from './modalActions';
import { openModal } from './modalActions';

const initialState: ModalState = {
    id: null,
    type: null,
    isLoading: false,
    blocked: false,
    isOpen: false,
};

const slice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        loading: (state: ModalState) => {
            state.isLoading = true;
            state.blocked = true;
        },
        close: (state: ModalState) => {
            state.id = null;
            state.isOpen = false;
            state.isLoading = false;
            state.blocked = false;
        },
        idle: (state: ModalState) => {
            state.isLoading = false;
            state.blocked = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(cncNavigateTo, (state: ModalState) => {
            state.id = null;
            state.type = FlyInType.CncFlyIn;
            state.isOpen = true;
        });
        builder.addCase(cncSetTo, (state: ModalState) => {
            state.id = null;
            state.type = FlyInType.CncFlyIn;
            state.isOpen = true;
        });
        builder.addCase(openModal, (state: ModalState, action: PayloadAction<OpenModalPayload>) => {
            state.id = action.payload.id;
            state.type = action.payload.type;
            state.isOpen = true;
        });
    },
});

const actionsAndReducer = {
    modalActions: { open: openModal, ...slice.actions },
    modalReducer: slice.reducer,
};
export const { modalActions, modalReducer } = actionsAndReducer;
