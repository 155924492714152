import { sendEvent } from '../../utils';

type MatkontoAccountType = 'Matkonto' | 'Matkonto_Credit' | 'Matkonto_Invoice';

const ACCOUNT_TYPE_TO_TEXT: { [key in MatkontoAccountType]: string } = {
    Matkonto: 'Debet',
    Matkonto_Credit: 'Kredit',
    Matkonto_Invoice: 'Faktura',
};

const loginWithBankIdStart = (accountType: MatkontoAccountType) =>
    sendEvent({
        event: 'bankId',
        login: 'bankId',
        loginService: 'Login - matkonto',
        loginAttempt: 'Påbörjad',
        userService: `Matkonto - ${ACCOUNT_TYPE_TO_TEXT[accountType]}`,
    });

const loginWithBankIdSuccess = (accountType: MatkontoAccountType) =>
    sendEvent({
        event: 'bankId',
        login: 'bankId',
        loginService: 'Login - matkonto',
        loginAttempt: 'Slutförd',
        userService: `Matkonto - ${ACCOUNT_TYPE_TO_TEXT[accountType]}`,
    });

export default {
    loginWithBankIdStart,
    loginWithBankIdSuccess,
};
