import { sendInteractionEvent } from '../utils';

export const gaProductListDropdownLabel = 'ProductList - dropdown';

const openProductListDropdown = () => {
    sendInteractionEvent({
        eventAction: 'Visa produkt',
        eventLabel: gaProductListDropdownLabel,
    });
};

export default { openProductListDropdown };
