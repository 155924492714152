import AbTestTracking from './abtest/abTestEvent';
import checkoutTracking from './checkout/checkoutEvents';
import cncTracking from './cnc/cncEvents';
import commonTracking from './common/commonEvents';
import timeslotTracking from './contextAware/timeslotEvents';
import ecommerce from './ecommerce/ecommerceEvents';
import headerEvents from './header/headerEvents';
import myCoop from './myCoop';
import orderReclamationTracking from './orderReclamation/orderReclamationEvents';
import recipe from './recipe/recipeEvents';
import searchTracking from './search/searchEvents';

const gaTracking = {
    abTest: AbTestTracking,
    common: commonTracking,
    header: headerEvents,
    cnc: cncTracking,
    checkout: checkoutTracking,
    contextAware: {
        timeslots: timeslotTracking,
    },
    orderReclamation: orderReclamationTracking,
    search: searchTracking,
    myCoop,
    ecommerce,
    recipe,
};

export default gaTracking;
