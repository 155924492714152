import type { HybrisCompensationVoucher } from '../../models/hybris/hybrisVoucher';
import type {
    InvoiceAddress,
    MunicipalUserInfo,
    UserInfo,
    UserType,
} from '../../store/structureDefinitions/userState';
import getDirectHybrisAxiosClient from '../clients/directHybrisAxiosClient';
import hybrisAxiosClient from '../clients/hybrisAxiosClient';

interface UserGroup {
    name: string;
    uid: string;
}

interface UserCustomerGroups {
    userGroups: UserGroup[];
}

interface UserOrganisationUnit {
    orgUnit: {
        agreementId: string;
        gln: string;
        name: string;
        uid: string;
    };
}

const userApi = {
    // Fetch the type of user that is currently shopping
    getTypeOfUser: async (): Promise<UserType> => {
        const response = await hybrisAxiosClient.get('/user/type');
        return response && response.data && response.data.type;
    },
    getUserInfo: async (noCache: boolean): Promise<UserInfo> => {
        const path = noCache ? '/user/info?noCache=true' : '/user/info';
        const response = await hybrisAxiosClient.get(path);
        return response && response.data;
    },
    getUserPunchoutUnit: (accessToken: string) => async (userId: string) => {
        const response = await getDirectHybrisAxiosClient(accessToken).get<UserOrganisationUnit>(
            `/users/${userId}?fields=orgUnit`,
        );
        return response.data;
    },
    getMunicipalUserInfo: async () => {
        const path = '/user/municipal/info';
        const response = await hybrisAxiosClient.get<MunicipalUserInfo>(path);
        return response && response.data;
    },
    /**
     * Get users invoice address, in hybris, a user can only have one
     */
    getInvoiceAddress: async (): Promise<InvoiceAddress> => {
        const response = await hybrisAxiosClient.get('/user/invoice/current');
        return response && response.data;
    },
    createInvoiceAddress: async (address: InvoiceAddress): Promise<InvoiceAddress> => {
        const response = await hybrisAxiosClient.post('/user/invoice/current', address);
        return response && response.data;
    },
    updateInvoiceAddress: async (address: InvoiceAddress): Promise<InvoiceAddress> => {
        const response = await hybrisAxiosClient.put('/user/invoice/current', address);
        return response && response.data;
    },
    getCustomerGroups:
        (spaToken: string) =>
        async (shoppingUserId: string): Promise<UserCustomerGroups> => {
            const response = await getDirectHybrisAxiosClient(spaToken).get<UserCustomerGroups>(
                `users/${shoppingUserId}/customergroups`,
            );

            const resp = response && response.data;
            return resp;
        },
    getCompensationVochers: async (
        spaToken: string,
        shoppingUserId: string,
    ): Promise<HybrisCompensationVoucher[]> => {
        const response = await getDirectHybrisAxiosClient(spaToken).get(
            `/vouchers/compensation/users/${shoppingUserId}`,
            { params: { fields: 'FULL' } },
        );

        const resp = response && response.data && response.data.compensationVouchers;
        return resp || [];
    },
    updateProfile:
        (spaToken: string) =>
        async (
            shoppingUserId: string,
            redistributorOfFood: 'TRUE' | 'FALSE' | 'NOT_SET',
        ): Promise<boolean> => {
            const response = await getDirectHybrisAxiosClient(spaToken).patch(
                `users/${shoppingUserId}`,
                null,
                { params: { redistributorOfFood } },
            );

            const resp = response && response.data;
            return resp;
        },
};

export { userApi };
