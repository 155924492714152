import { CncStep } from '../../store/structureDefinitions/uiState';

export const POSTAL_CODE_PREVIEW_EVENT_ACTION = 'Slider - Välj leveranssätt';
export const TIMESLOTS_EVENT_ACTION = 'Slider - Välj leveranstid';
export const POSTAL_CODE_SELECTOR_EVENT_ACTION = 'Slider - Ange ditt postnummer';
export const MINI_CART_EVENT_ACTION = 'Slider - Varukorg - Överblick';
export const MAP_EVENT_ACTION = 'Slider - Välj butik';
export const POPUP_CHANGE_DELIVERY_OPTION = 'Slider - Byte av leveranssätt - pop up';
export const OPEN_CNC_EVENT_ACTION = 'Slider - Slider öppnas';

export const getEventAction = (step: CncStep) => {
    const STEP_TO_ACTION = {
        [CncStep.Map]: MAP_EVENT_ACTION,
        [CncStep.TimeSlots]: TIMESLOTS_EVENT_ACTION,
        [CncStep.PostalCodeSelector]: POSTAL_CODE_SELECTOR_EVENT_ACTION,
        [CncStep.PostalCodePreview]: POSTAL_CODE_PREVIEW_EVENT_ACTION,
        [CncStep.MiniCart]: MINI_CART_EVENT_ACTION,
    };

    return STEP_TO_ACTION[step];
};
