import { appInsights } from '../appInsights';

export const logPropertyMissing = (err: Error) => {
    appInsights.trackException({
        exception: {
            ...err,
            message: `Runtime property missing. ${err.message}`,
        },
    });
};

export const logMissingContext = (contextName: string) => {
    const error = new Error(`React Context missing: ${contextName}`);

    appInsights.trackException({
        exception: {
            ...error,
        },
    });
};
