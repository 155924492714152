export const enum ExceptionCode {
    dinnerOnlyStore = 'dinnerOnlyStore',
}

export interface ApiHybrisValidationException {
    errors: {
        message: string;
        reason: string;
        type: string;
        subject: string;
    }[];
}

export type ApiExceptionType =
    | 'Default'
    | 'Validation'
    | 'OpenId'
    | 'Redirect'
    | 'BankId'
    | 'Cart'
    | 'BankIdCollect';

export interface ApiBaseException<TCode = string> {
    friendlyMessage: string;
    code: TCode | null;
    type: ApiExceptionType;
}

export interface ValidationError<TCode = string> {
    field: string;
    friendlyMessage: string;
    code?: TCode | null;
}

export interface ApiValidationException extends ApiBaseException {
    validationErrors: ValidationError[];
    type: 'Validation';
}

export interface ApiCartException<TCode = string> extends ApiBaseException<TCode> {
    type: 'Cart';
    code: TCode | null;
    friendlyMessage: never;
}

export interface ApiDefaultException extends ApiBaseException {
    field: string | null;
    type: 'Default';
}

export interface ApiOpenIdException extends ApiBaseException {
    field: string;
    type: 'OpenId';
    redirectUrl: string;
    isOpenIdError: boolean;
}

export interface ApiRedirectException extends ApiBaseException {
    type: 'Redirect';
    redirectUrl: string;
}

export type ApiException =
    | ApiDefaultException
    | ApiValidationException
    | ApiOpenIdException
    | ApiCartException
    | ApiRedirectException;
