import { createSelector } from '@reduxjs/toolkit';

import type { CartItem } from '../../common/api/cart/responses/cartResponses';
import { productsAdapter } from '../../common/store/adapters/productsAdapter';
import { recipesAdapter } from '../../common/store/adapters/recipesAdapter';
import type {
    CartItemState,
    CartItemUniqueId,
    RootState,
} from '../../common/store/structureDefinitions/cartState';

const getCartItems = (state: RootState) => state.cart.items;
const getCartRecipes = (state: RootState) => state.cart.recipes;
const getCartSummary = (state: RootState) => state.cart.summary;
const getCartSections = (state: RootState) => state.cart.sections;
const selectCartQuantity = (state: RootState) =>
    state.cart.items.reduce((prev, next) => prev + next.quantity, 0);

const cartItemsProductDataSelectors = productsAdapter.getSelectors(
    (state: RootState) => state.cart.itemsProductData,
);

const cartRecipeSelectors = recipesAdapter.getSelectors(getCartRecipes);

const selectCartItemProductDataById = cartItemsProductDataSelectors.selectById;

const selectAllCartItemProductData = cartItemsProductDataSelectors.selectAll;

const selectAllCartItems = createSelector(
    getCartItems,
    selectAllCartItemProductData,
    (cartItems, productData) => {
        const merged = cartItems.map<CartItem>((cartItem) => ({
            cartItemData: cartItem,
            productData: productData.find((product) => product.id === cartItem.productId),
        }));

        return merged;
    },
);

const hasAgeRestrictedProducts = createSelector(selectAllCartItems, (cartItems) => {
    const cartItemWithAgeRestriction = cartItems.find(
        (cartItem) =>
            !!cartItem.productData &&
            cartItem.productData.details.attributes.some(
                (attr) => attr.identifier === '18' || attr.identifier === '18arlakemedel',
            ),
    );

    return !!cartItemWithAgeRestriction;
});

const hasRecipeItems = createSelector(getCartItems, (cartItems) => {
    const isAnyItemFromRecipe = cartItems.some((ci) => !!ci.recipeMetaData);
    return isAnyItemFromRecipe;
});

const selectCartItemsIds = createSelector(getCartItems, (items) =>
    items.map(
        (item): CartItemUniqueId => ({
            productId: item.productId,
            variantId: item.variantId,
        }),
    ),
);

const selectCartItemProductIds = createSelector(selectCartItemsIds, (products): string[] => {
    return (
        products?.map((entity) => entity.productId.toString() || undefined).filter(Boolean) || []
    );
});

const selectCartItemsIdsForRecipeId = createSelector(
    getCartItems,
    (_: RootState, recipeId: string) => recipeId,
    (items, recipeId): CartItemUniqueId[] =>
        items
            .filter(
                (item: CartItemState) =>
                    item.recipeMetaData && item.recipeMetaData.code === recipeId,
            )
            .map(
                (item: CartItemState): CartItemUniqueId => ({
                    productId: item.productId,
                    variantId: item.variantId,
                }),
            ),
);

export const cartItemUniqueIdPredicate = <T extends CartItemUniqueId>(
    item: T,
    cartItemId: CartItemUniqueId,
) =>
    item.productId === cartItemId.productId &&
    (!cartItemId.variantId || (!!cartItemId.variantId && item.variantId === cartItemId.variantId));

const selectCartItemByUniqueId = createSelector(
    getCartItems,
    (_: RootState, cartItemId: CartItemUniqueId) => cartItemId,
    (items, cartItemId) => items.find((item) => cartItemUniqueIdPredicate(item, cartItemId)),
);

const selectAllItemsReplaceability = createSelector(getCartItems, (items) =>
    items.every((item) => item.itemReplaceable),
);

const selectSomeItemsReplaceability = createSelector(getCartItems, (items) =>
    items.some((item) => item.itemReplaceable),
);

const selectFilteredVariants = createSelector(
    getCartItems,
    (_: RootState, cartItem: CartItemState | undefined) => cartItem,
    (items, cartItem) => {
        if (!cartItem) return [];

        const sameProductCartItems = items.filter(
            (ci) => ci.productId === cartItem.productId && ci.variantId !== cartItem.variantId,
        );
        let filteredVariants = cartItem.variants;
        if (sameProductCartItems && cartItem.variants) {
            filteredVariants = cartItem.variants.filter(
                (variant) => !sameProductCartItems.some((spci) => spci.variantId === variant.id),
            );
        }
        return filteredVariants;
    },
);

const selectCartItemsIdsForSectionId = createSelector(
    getCartSections,
    getCartItems,
    (_: RootState, sectionId: number) => sectionId,
    (sections, items, sectionId) => {
        const cartSection = sections.find((section) => section.sectionId === sectionId);
        return (
            cartSection &&
            items
                .filter((item) =>
                    cartSection.productIds
                        ?.filter(Boolean)
                        .some((productId) => item.productId === productId),
                )
                .map(
                    (item: CartItemState): CartItemUniqueId => ({
                        productId: item.productId,
                        variantId: item.variantId,
                    }),
                )
        );
    },
);

const getEditedOrderItems = (state: RootState) => state.cart.editedOrderItems;
const getPreviousOrderItemIds = (state: RootState) => state.cart.previousOrderItemIds;

const selectChangedItemsCount = createSelector(
    [getEditedOrderItems],
    (cartItems) => cartItems.length,
);

const getEditOrderSectionItems = createSelector(
    [getPreviousOrderItemIds, getCartItems],
    (previousItemIds, cartItems) => {
        const cartItemIds = cartItems.map(
            (item): CartItemUniqueId => ({
                productId: item.productId,
                variantId: item.variantId,
            }),
        );

        let previouslyBoughtCartItemIds = cartItemIds.filter((cartItem) =>
            previousItemIds.some(
                (previousItem) =>
                    previousItem.productId === cartItem.productId &&
                    previousItem.variantId === cartItem.variantId,
            ),
        );

        let newlyBoughtCartItemIds = cartItemIds.filter((cartItem) =>
            previousItemIds.every(
                (previousItem) =>
                    previousItem.productId !== cartItem.productId ||
                    previousItem.variantId !== cartItem.variantId,
            ),
        );

        if (
            cartItemIds.length !==
            (newlyBoughtCartItemIds?.length || 0) + (previouslyBoughtCartItemIds?.length || 0)
        ) {
            newlyBoughtCartItemIds = cartItemIds;
            previouslyBoughtCartItemIds = [];
        }

        return { newlyBoughtCartItemIds, previouslyBoughtCartItemIds };
    },
);

const getIsLargeQuantityOrder = createSelector(getCartItems, (items) =>
    items.some((item) => item.quantity >= 50),
);

const cartItemsSelectors = {
    selectCartSummary: getCartSummary,
    selectCartQuantity,
    selectAllCartItems,
    selectIds: selectCartItemsIds,
    selectCartItemProductIds,
    selectById: selectCartItemByUniqueId,
    selectProductDataById: selectCartItemProductDataById,
    selectAllProductData: selectAllCartItemProductData,
    selectIdsForRecipeId: selectCartItemsIdsForRecipeId,
    selectCartRecipes: cartRecipeSelectors.selectAll,
    selectIdsForSectionId: selectCartItemsIdsForSectionId,
    selectAllItemsReplaceability,
    selectSomeItemsReplaceability,
    selectFilteredVariants,
    editOrder: { selectChangedItemsCount },
    getEditOrderSectionItems,
    getIsLargeQuantityOrder,
    hasAgeRestrictedProducts,
    hasRecipeItems,
};

export default cartItemsSelectors;
