import type { IApiStore } from '../../../../models/ecommerce/store/storeModel';
import { sendInteractionEvent } from '../utils';
import { POSTAL_CODE_PREVIEW_EVENT_ACTION } from './utils';

const sendDeliveryOptionEvent = (eventLabel: string, additionalData?: object) => {
    sendInteractionEvent({
        eventAction: POSTAL_CODE_PREVIEW_EVENT_ACTION,
        eventLabel,
        ...additionalData,
    });
};

const createSendDeliveryOptionEvent = (eventLabel: string) => () =>
    sendDeliveryOptionEvent(eventLabel);

const sendDeliveryHomeOptionSelect = (
    zipCode: string | undefined,
    newStore: IApiStore | undefined,
) => {
    sendDeliveryOptionEvent('Hemleverans valt - leveranstid', {
        zipCode,
        deliverySearch: zipCode,
        deliveryMethod: 'Hemleverans - Leveranssätt vald av kund"',
        storeId: newStore?.id,
        storeName: newStore?.name,
        Utbud: 'Fullsortiment',
    });
};

export default {
    goToZipCode: createSendDeliveryOptionEvent('Gå till byt postnummer'),
    readMoreExpress: createSendDeliveryOptionEvent('Läs mer om snabbleverans'),
    home: {
        select: sendDeliveryHomeOptionSelect,
        seeMore: createSendDeliveryOptionEvent('Se fler tider'),
    },
    pickup: {
        select: createSendDeliveryOptionEvent('Hämta valt - gå till tidsbokning - Hämta i butik'), // This doesn't really select - it navigates to store map.
        seeMore: createSendDeliveryOptionEvent('Se fler butiker och tider'),
    },
};
