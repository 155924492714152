import type { VarianceData } from '../hybris/hybrisOrder';
import type { HybrisProduct } from '../hybris/hybrisProduct';
import type { PriceData } from '../priceData/priceData';

export interface Order {
    actualDeliveryDate?: Date;
    code: string;
    subtotalPriceData: PriceData;
    subtotalWithoutDiscountPriceData: PriceData;
    totalProductPriceWithDiscounts: PriceData;
    totalDiscountsPriceData: PriceData;
    totalPriceData: PriceData;
    productDiscountsPriceData: PriceData;
    appliedOrderPromotions: PromotionResult[];
    appliedVouchers: Voucher[];
    totalPriceWithExtraAmountToReservePriceData: PriceData;
    extraAmountToBeReserved: number;
    deliveryCostPriceData: PriceData;
    deliveryMode: OrderDeliveryMode;
    totalTaxValues: TaxValue[];
    totalTax: Price;
    productIds: string[];
    isPartOfSubscription: boolean;
    editable: boolean;
    cancellable: boolean;
    driverMessage?: string;
    timeWindow?: TimeWindow;
    deliveryAddress: Address;
    status: OrderStatus;
    paymentMode: HybrisPaymentName;
    subPaymentInfo: HybrisSubPaymentInfo;
    paymentModeFormatted: string;
    store: string;
    entries: OrderEntry[];
    totalQuantity: number;
    recipeList?: OrderRecipeEntry[];
    stopTime?: Date;
    created: Date;
    subscription?: OrderSubscription | null;
    pointOfService?: OrderPointOfService;
    /**
     * Order code of the first order edited in chain (edit order can be done multiple times whoich creates a chain)
     */
    originalOrderCode?: string;

    /**
     * Previous order id in the chain to the current one - the one that was edited to create this order.
     */
    editedOrderCode?: string;
    user?: OrderUser;
    trackingId?: string;
}

interface OrderUser {
    name: string;
    uid: string;
}
export interface OrderRecipeEntry {
    calculation: string;
    code: string;
    imageUrl: string;
    name: string;
    portions: number;
    recipeEntries: RecipeEntry[];
}

export interface RecipeEntry {
    common: boolean;
    orderEntry: RecipeOrderEntry;
    recipeQuantity: number;
    substituteId: string;
    substituteProductsAmount: string;
}

export interface RecipeOrderEntry {
    basePriceData: PriceData;
    // depositData;
    entryNumber: number;
    originalQuantity: number;
    partOfRecipe: boolean;
    pickBasePriceData: PriceData;
    // product: OrderEntry;
    product: HybrisProduct;
    quantity: number;
    recipeQuantity: number;
    replace: boolean;
    totalPriceData: PriceData;
    updateable: boolean;
}

export interface OrderEntryPrices {
    // basePriceData: PriceData; // do NOT use this price as it does not take weights into account
    /** Price taking weights into account, before promotion */
    piecePrice?: PriceData; //

    newPiecePrice?: PriceData;
    totalPrice?: PriceData;
    totalPriceWithoutDiscount?: PriceData;
    discount?: PriceData;
    recycleFeePiecePrice?: PriceData;
    recycleFeeTotalPrice?: PriceData;
}

export interface OrderEntry extends OrderEntryPrices {
    entryNumber: number;
    replaceable: boolean;
    promo?: EcomPromotion;

    /**
     * The quantity that was actually delivered, might be les than originalQUantity (i.e. due to availability)
     */
    quantity: number;

    /**
     * The quantity that user initailly wanted when creating the order
     */
    originalQuantity: number;

    /** The quantity shipped with the correct unit, so is orderentry unit grams, this will be in grams */
    shippedUnitQuanity: number;

    product: HybrisProduct;
    variance?: VarianceData;

    /** If this item has replaced another item in the order, if so, this is the entrynumber of the replaced item */
    replacesEntryNumber?: number;
    unit: OrderEntryUnit;
}
export type OrderEntryUnit = 'pieces' | 'gram';
export interface OrderDeliveryMode {
    code: DeliveryModeCode;
    deliveryCostPriceData: PriceData;
    description?: string;
    name: string;
}

export interface TaxValue {
    appliedValue: Price;
    rate: string;
}

export interface Voucher {
    voucherCode: string;
    freeShipping: boolean;
    compensationVoucher: boolean;
    externalReason?: string;
    priceData: PriceData;
    name?: string;
    triggeredPromotion: boolean;
    notAppliedHint?: string;
}

// common domain?
export interface Price {
    value: number;
    valueWithoutTax?: number;
}

// common domain?
export interface PromotionResult {
    description: string;
    totalDiscountPriceData: PriceData;
}

export interface PromotionOrderEntryConsumed {
    adjustedUnitPrice?: number;
    adjustedUnitPriceWithoutTax?: number;
    code?: string;
    orderEntryNumber?: number;
    quantity?: number;
}

export interface TimeWindow {
    stopTime?: Date;
    startTime?: Date; // actual time when timeslot is starting from, i.e. in 12:00 - 15:00, its 12:00
    endTime?: Date; // actual time when timeslot is ending, i.e. in 12:00 - 15:00, its 15:00
    timeSlotTime: string;
}

export interface Address {
    companyName?: string;
    phoneNumber: string;
    town: string;
    postalCode: string;
    doorCode?: string;
    street: string;
    lastName: string;
    firstName: string;
    stairs?: string;
    email?: string;
}

export type WeekInterval =
    | 'EVERY_WEEK'
    | 'EVERY_SECOND_WEEK'
    | 'EVERY_THIRD_WEEK'
    | 'EVERY_FOURTH_WEEK';

export interface OrderSubscription {
    frequency?: WeekInterval;
}

export interface OrderPointOfService {
    name?: string; // more like id than a name different than storeId
    storeId?: string;
    displayName?: string;
    address: StoreAddress;
}

export interface StoreAddress {
    id?: string;
    line1?: string;
    postalCode?: string;
    town?: string;
}

export const UnknownPaymentType = 'Okänt betalsätt';

export const enum PaymentType {
    Klarna_INVOICE = 'Få först. Betala sen.',
    Klarna_INVOICE_BUSINESS = 'Få först. Betala sen.',
    Klarna_CARD = 'Kort',
    Klarna_ACCOUNT = 'Klarna konto',
    Klarna_FIXED_AMOUNT = 'Dela upp',
    Klarna_DIRECT_DEBIT = 'Betala direkt',
    Klarna_PAY_IN_X = 'Betala senare',
    Klarna_BANK_TRANSFER = 'Banköverföring',
    Klarna_SWISH = 'Swish',
    Klarna_PAY_BY_CARD = 'Kort',
    Klarna_FIXED_SUM_CREDIT = 'Dela upp',
    Punchout_INVOICE = 'Faktura',
}
