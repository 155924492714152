import { sendEvent } from '../utils';

export interface AbTestGaEvent {
    event: 'abtest';
    experiment_name: string;
    experiment_variant: string;
}

/**
 * experiment_name: Name of experiment
 * experiment_variant: Normal variant should always be A
 */
const userInteraction = (experiment_name: string, experiment_variant: string) => {
    sendEvent<AbTestGaEvent>({
        event: 'abtest',
        experiment_name,
        experiment_variant,
    });
};

const AbTestTracking = {
    userInteraction,
};
export default AbTestTracking;
