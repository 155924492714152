import { isB2BMode } from '../../../utils/b2bUtils';
import { sendInteractionEvent } from '../../utils';

const getServiceEventAction = (service: string): string => {
    const isStoreNewsletter = service.includes('store_');

    if (isStoreNewsletter) {
        return 'Butikserbjudanden från Coop';
    }
    if (service === 'cooNewsletter') {
        return 'Medlemsutskick från Coop';
    }
    if (service === 'cooOnline') {
        return 'Nyhetsbrev från Coop';
    }
    if (service === 'cooPartnermail') {
        return 'Utskick från Coops medlemsprogram';
    }
    if (service === 'cooDRDigital') {
        return 'Veckans reklamblad';
    }

    return service;
};

const subscribe = (service: string) => {
    sendInteractionEvent({
        eventCategory: 'Mina sidor',
        eventAction: getServiceEventAction(service),
        eventLabel: 'På',
    });
};

const unsubscribe = (service: string) => {
    sendInteractionEvent({
        eventCategory: 'Mina sidor',
        eventAction: getServiceEventAction(service),
        eventLabel: 'Av',
    });
};

const subscribeToWeeklyNewsletter = (page: string, storeName?: string) => {
    sendInteractionEvent({
        eventCategory: page,
        eventAction: 'Inskickad E-postadress DR',
        eventLabel: storeName,
        customerSegment: isB2BMode() ? 'Företag' : 'Privat',
    });
};

export default {
    subscribe,
    unsubscribe,
    subscribeToWeeklyNewsletter,
};
