import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';

import { isDevelopmentEnvironment, isTestingEnvironment } from '../../../utility/environments';
import { listenerMiddleware } from './listenerMiddleware';
import rootReducer from './rootReducer';

const enableDevTols = isDevelopmentEnvironment || isTestingEnvironment;

const store = configureStore({
    reducer: rootReducer,
    devTools: enableDevTols,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
};

export type RootState = ReturnType<typeof rootReducer>;

export type DispatchType = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
export type AppAsyncThunk<ReturnType = void> = AppThunk<Promise<ReturnType>>;

export default store;
