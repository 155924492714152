import type { EntityState } from '@reduxjs/toolkit';

import type {
    ApiCart,
    ApiCartRecipe,
    ApiFullCartItem,
} from '../../../microApps/common/api/cart/responses/cartResponses';
import { productsAdapter } from '../../../microApps/common/store/adapters/productsAdapter';
import { recipesAdapter } from '../../../microApps/common/store/adapters/recipesAdapter';
import { getInitialState as getTimeslotInitialState } from '../../../microApps/common/store/slices/cart/cartTimeslotSlice';
import type {
    CartItemState,
    CartRecipe,
    CartSection,
    RootState,
} from '../../../microApps/common/store/structureDefinitions/cartState';
import { getTopProductSection } from '../../../microApps/common/utils/productUtils';
import type { RecipeSectionType } from '../../../microApps/recipe/models';
import { DefaultProductSection } from '../../../models/ecommerce/product/productSectionModel';
import { Helpers } from '../../../utility/helpers';

type CartState = Expand<RootState['cart']>;

const getInitialImmutableState = (cart: ApiCart) => {
    const newCartState: CartState = {
        summary: { ...cart.cartData },
        promos: cart.promos.map((cartPromo) => ({ ...cartPromo })),
        items: cart.items.map((cartItem) => ({ ...cartItem })),
        itemsProductData: productsAdapter.getInitialState(),
        editedOrderItems: cart.editedOrderItems.map((cartItem) => ({ ...cartItem })),
        previousOrderItemIds: cart.previousOrderItemIds.map((cartItemId) => ({
            productId: cartItemId.productId,
            variantId: cartItemId.variantId,
        })),
        recipes: recipesAdapter.getInitialState(),
        sections: [],
        cartLoading: {
            isLoading: false,
            isInitialized: true,
            isProductDataAvailable: true, // because the product data was resolved before, this will change after cartfacade is gone
        },
        editOrder: {
            isInEditOrderMode: cart.cartData.isInEditOrderMode,
            editOrderNumber: cart.cartData.editOrderNumber,
            editOrderTimeout: cart.cartData.editOrderTimeout,
            editOrderSummary: cart.cartData.editOrderSummary,
            editOrderPayment: cart.cartData.editOrderPayment,
        },
        timeslot:
            (cart.cartData &&
                cart.cartData.timeslot &&
                cart.cartData.timeslot.id && {
                    timeslot: {
                        maxOrderTime: new Date(cart.cartData.timeslot.maxOrderTime).valueOf(),
                        startTime: new Date(cart.cartData.timeslot.startTime).valueOf(),
                        endTime: new Date(cart.cartData.timeslot.endTime).valueOf(),
                        timeSlotDay: cart.cartData.timeslot.timeSlotDay,
                        timeSlotTime: cart.cartData.timeslot.timeSlotTime,
                        cost: cart.cartData.timeslot.cost,
                        id: cart.cartData.timeslot.id,
                        fullyBooked: cart.cartData.timeslot.fullyBooked,
                        shippingType: '',
                        storeId: cart.cartData.timeslot.storeId,
                        timeslotType: cart.cartData.timeslot.timeslotType,
                        expirationTime: cart.cartData.timeslot.expirationTime,
                        milisecondsToExpire: cart.cartData.timeslot.milisecondsToExpire,
                        ecoFriendly: cart.cartData.timeslot.ecoFriendly,
                        expectETANotification: cart.cartData.timeslot.expectETANotification,
                    },
                    isSelected: true,
                    isInitialized: true,
                }) ||
            getTimeslotInitialState(),
    };

    return newCartState;
};

export const SHARED_RECIPE_CODE = 'shared';
export const GOODTOHAVE_RECIPE_CODE = 'goodToHave';

const getSectionType = (recipe: ApiCartRecipe): RecipeSectionType => {
    if (recipe.code === SHARED_RECIPE_CODE) {
        return 'shared';
    }

    if (recipe.code === GOODTOHAVE_RECIPE_CODE) {
        return 'common';
    }

    return 'recipe';
};

const normalizeRecipeData = (cart: ApiCart): EntityState<CartRecipe> => {
    let entityState = recipesAdapter.getInitialState();

    const recipes = cart?.recipes?.map<CartRecipe>((cartRecipe) => ({
        code: cartRecipe.code,
        type: getSectionType(cartRecipe),
        group: cartRecipe.group ?? 'recipe',
        name: cartRecipe.name,
        portions: cartRecipe.portions,
        productsMap: cartRecipe.productsMap,
        sharedProductsMap: cartRecipe.sharedProductsMap,
        imageUrl: cartRecipe.imageUrl,
    }));

    entityState = recipesAdapter.setAll(entityState, recipes);
    return entityState;
};

const normalizeRecipeCartItemMetadata = (newCartStateItems: ApiFullCartItem[]): CartItemState[] => {
    const result = newCartStateItems.map((item: ApiFullCartItem): CartItemState => {
        if (!item.recipeData) return item;

        const { recipeData, ...relevantFields } = item;
        return {
            ...relevantFields,
            recipeMetaData: {
                code: recipeData.code,
                substituteIdentifier: recipeData.substituteIdentifier,
                substituteProductsAmount: recipeData.substituteProductsAmount,
                recipeQuantity: recipeData.recipeQuantity,
                includedInRecipes: recipeData.includedInRecipes,
            },
        };
    });
    return result;
};

const normalizeSectionData = (
    cartItems: ApiFullCartItem[],
    products: ApiProduct[],
): CartSection[] => {
    const cartSectionsDictionary: Record<number, CartSection> = {};
    const cartSections: CartSection[] = [];
    const otherSection: CartSection = {
        sectionId: DefaultProductSection.ID,
        name: DefaultProductSection.NAME,
        productIds: [],
    };

    try {
        cartItems.forEach((item) => {
            const connectedProduct = products.find(
                (product) => item.productId === product.identifier,
            );

            // item does not have connected product, add to other section
            if (!connectedProduct && otherSection.productIds) {
                otherSection.productIds.push(item.productId.toString());
                return;
            }

            // item does not have any sections, add to other section
            if (connectedProduct) {
                const productSection = getTopProductSection(connectedProduct);
                if (productSection.id === DefaultProductSection.ID && otherSection.productIds) {
                    otherSection.productIds.push(item.productId.toString());
                    return;
                }

                // eslint-disable-next-line no-prototype-builtins
                if (cartSectionsDictionary.hasOwnProperty(productSection.id)) {
                    cartSectionsDictionary[productSection.id].productIds?.push(
                        item.productId.toString(),
                    );
                } else {
                    cartSectionsDictionary[productSection.id] = {
                        sectionId: productSection.id,
                        name: productSection.name,
                        productIds: [item.productId.toString()],
                    };
                }
            }
        });

        Object.values(cartSectionsDictionary).forEach((section) => {
            cartSections.push(section);
        });

        const sortedSections = cartSections.sort((a, b) =>
            Helpers.alphabeticSortAscPredicate(a.name, b.name),
        );

        if (otherSection.productIds && otherSection.productIds.length > 0) {
            sortedSections.push(otherSection);
        }

        return sortedSections;
    } catch {
        // if anything fails dont care about sections, pack everything into Other section;
        return [
            {
                sectionId: DefaultProductSection.ID,
                name: DefaultProductSection.NAME,
                productIds: cartItems.map((items) => items.productId.toString()),
            },
        ];
    }
};

export const normalizeCartToReduxState = (cart: ApiCart, products: ApiProduct[]) => {
    const newCartState: CartState = getInitialImmutableState(cart);

    const recipes = normalizeRecipeData(cart);
    const sections = normalizeSectionData(newCartState.items, products);
    const items = normalizeRecipeCartItemMetadata(newCartState.items);

    const normalizedState: CartState = {
        summary: {
            ...newCartState.summary,
            quantity: items.reduce((acc, curr) => acc + curr.quantity, 0),
        },
        items,
        itemsProductData: productsAdapter.setAll(newCartState.itemsProductData, products),
        previousOrderItemIds: newCartState.previousOrderItemIds,
        editedOrderItems: newCartState.editedOrderItems,
        recipes,
        sections,
        promos: newCartState.promos,
        editOrder: newCartState.editOrder,
        cartLoading: newCartState.cartLoading,
        timeslot: newCartState.timeslot,
    };

    return normalizedState;
};
