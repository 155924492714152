import type { FC } from 'react';
import * as React from 'react';

import RawHtml from '../../../common/components/atoms/RawHtml';
import Alert from './Alert';
import type { HeaderAlert } from './HeaderAlerts.types';
import { isAlertLevelSupported } from './HeaderAlerts.utils';

interface HeaderAlertsProps {
    alerts: HeaderAlert[];
}

const HeaderAlerts: FC<React.PropsWithChildren<HeaderAlertsProps>> = (props) => {
    if (!props.alerts?.length) return null;

    return (
        <div className="HeaderAlerts">
            {props.alerts.map((alert) => (
                <Alert
                    key={alert.id}
                    id={alert.id}
                    level={isAlertLevelSupported(alert.level) ? alert.level : undefined}
                    details={alert.details && <RawHtml html={alert.details} />}
                >
                    <span className="u-textWeightBold">
                        <RawHtml html={alert.heading} />
                    </span>
                </Alert>
            ))}
        </div>
    );
};

export default HeaderAlerts;
