import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable no-param-reassign */
import type { CncStep, CncUiState } from '../../structureDefinitions/uiState';
import { cncNavigateTo, cncSetTo } from './uiActions';

const initialState: CncUiState = {
    stepTrail: [],
    reloadOnClose: false,
    currentStepNavigationParams: null,
    previewPostalCode: null,
};

const slice = createSlice({
    name: 'cnc',
    initialState,
    reducers: {
        goBack: (state: CncUiState) => {
            if (state.stepTrail.length > 1) {
                state.stepTrail.pop();
            }
            state.currentStepNavigationParams = null;
        },
        forceReloadOnClose: (state: CncUiState, action: PayloadAction<boolean>) => {
            state.reloadOnClose = action.payload;
        },
        clearNavigationParams: (state: CncUiState) => {
            state.currentStepNavigationParams = null;
        },
        setPreviewPostalCode: (state: CncUiState, action: PayloadAction<string>) => {
            state.previewPostalCode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            cncSetTo,
            (state: CncUiState, action: PayloadAction<{ step: CncStep; param?: unknown }>) => {
                state.stepTrail = [action.payload.step];
                state.currentStepNavigationParams = action.payload.param;
                state.previewPostalCode = null;
            },
        );
        builder.addCase(
            cncNavigateTo,
            (state: CncUiState, action: PayloadAction<{ step: CncStep; param?: unknown }>) => {
                // if the action.payload component exists in trail - pop the stack until you find it
                const existingStepInTrail = state.stepTrail.indexOf(action.payload.step);
                if (existingStepInTrail !== -1) {
                    state.stepTrail = [
                        ...state.stepTrail.slice(0, existingStepInTrail),
                        action.payload.step,
                    ];
                } else {
                    state.stepTrail.push(action.payload.step);
                }
                state.currentStepNavigationParams = action.payload.param;
            },
        );
    },
});

const actions = { navigateTo: cncNavigateTo, setTo: cncSetTo, ...slice.actions };
export const cncUiActions = actions;

export default slice.reducer;
