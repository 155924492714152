import type { ReactNode } from 'react';
import * as React from 'react';

import { buildReactComponentSelectorByName, getReactComponentData } from '../../init/reactMounting';
import Portal from '../atoms/Portal';

interface ComponentPortalsProps<TProps extends object = object> {
    cmpName: string;

    render?: (cmpProps: TProps & { id?: string }) => React.ReactNode;
    children?: ReactNode;
}

/** Finds components (can be multiple instances) of a given name and creates a Portal for them */
const ComponentPortals = <TProps extends object = object>({
    cmpName,
    render,
    children,
}: ComponentPortalsProps<TProps>) => {
    const foundComponents = Array.from<HTMLElement>(
        document.querySelectorAll(buildReactComponentSelectorByName(cmpName)),
    )
        .filter(Boolean)
        .map((item) => getReactComponentData<TProps>(item));

    return (
        <>
            {foundComponents.map((item) => (
                <Portal key={item.id} domNode={item.html}>
                    {(render && render({ ...item.props, id: item.id })) || children}
                </Portal>
            ))}
        </>
    );
};

export default ComponentPortals;
