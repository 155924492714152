import type { FC } from 'react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import globalInvokes from '../../../globalFunctionInvokes';

const renderMicroApp = <T extends object = object>(microApp: FC<{ ssrData?: T }>) => {
    const container = document.querySelector<HTMLElement>('#reactMicroApp');
    const reactProps = container?.dataset?.reactProps;
    const props = reactProps ? (JSON.parse(reactProps) as T) : undefined;

    globalInvokes();

    const root = createRoot(container!);
    root.render(React.createElement(microApp, { ssrData: props }));
};

export default renderMicroApp;
