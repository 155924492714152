import * as React from 'react';

import ComponentPortals from '../../../common/components/molecules/ComponentPortals';
import HeaderAlerts from './HeaderAlerts';
import type { HeaderAlertsPortalDOMProps } from './HeaderAlerts.types';

const HeaderAlertsPortal = () => {
    return (
        <ComponentPortals
            cmpName="HeaderAlerts"
            render={(domProps: HeaderAlertsPortalDOMProps) => (
                <HeaderAlerts alerts={domProps.alerts} />
            )}
        />
    );
};

export default HeaderAlertsPortal;
