import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { CartSection } from '../../structureDefinitions/cartState';
import type { UpdateCartItemPayload } from './cartActions';
import { updateCartItem } from './cartActions';

const cartSectionsSlice = createSlice({
    name: 'sections',
    initialState: [] as CartSection[],
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            updateCartItem,
            (state: CartSection[], action: PayloadAction<UpdateCartItemPayload>) => {
                const sectionIndex = state.findIndex(
                    (section) =>
                        section.productIds?.some(
                            (productId) => action.payload.productId.toString() === productId,
                        ) && section.productIds.length === 1,
                );

                if (sectionIndex > -1 && action.payload.quantity === 0) {
                    // last product in section is being removed
                    state.splice(sectionIndex, 1);
                }
            },
        );
    },
});

export const { actions: cartSectionsActions, reducer: cartSectionsReducer } = cartSectionsSlice;
