import classNames from 'classnames';
import * as React from 'react';

export const calculateRowFromIndex = (index: number, elementsInRow: number) => {
    const row = Math.trunc(index / elementsInRow);
    const rowPosition = index % elementsInRow;

    return {
        row,
        rowPosition,
    };
};

export interface ItemGridProps<TItem> {
    cellsInRow: number;
    styles?: React.CSSProperties;
    gridClasses?: string;
    itemClasses?: string;
    noDefaultClasses?: boolean;
    items?: TItem[];
    renderItem: (item: TItem, index: number) => React.ReactNode;
    itemUniqueKey: (item: TItem, index: number) => string;
    renderAtRowStart?: (row: number) => React.ReactNode;
    renderAtRowEnd?: (row: number) => React.ReactNode;
    renderLast?: () => React.ReactNode;
}

const ItemGrid = <T,>(props: ItemGridProps<T>, ref?: React.Ref<HTMLDivElement>) => {
    if (!props.items) {
        return null;
    }

    return (
        <div
            ref={ref}
            className={classNames('Grid Grid-items', props.gridClasses)}
            style={props.styles}
        >
            {props.items.map((item, index, array) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={props.itemUniqueKey(item, index)}>
                    {index % props.cellsInRow === 0 &&
                        props.renderAtRowStart &&
                        props.renderAtRowStart(calculateRowFromIndex(index, props.cellsInRow).row)}
                    <div
                        className={classNames(
                            'Grid-cell',
                            props.noDefaultClasses ? null : `u-size1of${props.cellsInRow}`,
                            props.itemClasses,
                        )}
                    >
                        {props.renderItem(item, index)}
                    </div>

                    {((index + 1) % props.cellsInRow === 0 || index === array.length - 1) && // append block after full row end OR after last item in a partially empty row
                        props.renderAtRowEnd &&
                        props.renderAtRowEnd(calculateRowFromIndex(index, props.cellsInRow).row)}
                </React.Fragment>
            ))}
            {props.renderLast && props.renderLast()}
        </div>
    );
};

export default React.forwardRef(ItemGrid);
