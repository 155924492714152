/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ApiHybrisTimeslot } from '../../../api/timeslots/timeslotsApi';
import type { DeliveryTimeSlotState } from '../../structureDefinitions/timeslotState';
import { checkoutResetAction } from '../checkout/checkoutActions';

export const getInitialState = (): DeliveryTimeSlotState => ({
    timeslot: {
        id: null,
        ecoFriendly: false,
        expectETANotification: false,
        endTime: 0,
        fullyBooked: false,
        maxOrderTime: 0,
        shippingType: '',
        startTime: 0,
        storeId: '',
        timeSlotDay: '',
        timeSlotTime: '',
        timeslotType: 'REGULAR',
    },
    isSelected: false,
    isInitialized: false,
});

const slice = createSlice({
    name: 'timeslot',
    initialState: getInitialState(),
    reducers: {
        selectTimeslot(state: DeliveryTimeSlotState, action: PayloadAction<ApiHybrisTimeslot>) {
            if (!action.payload || !action.payload.id) {
                return {
                    ...getInitialState(),
                    isInitialized: true,
                };
            }

            return {
                ...state,
                isSelected: true,
                isInitialized: true,
                timeslot: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(checkoutResetAction, () => {
            return getInitialState();
        });
    },
});

export const { actions: cartTimeslotActions, reducer: cartTimeslotReducer } = slice;
