import { appConfig } from '../../../appConfig';
import consentSubscriptionsApi from '../../myCoop/emailSubscriptions/api/consentSubscriptionsApi';
import { getAuthToken } from '../api/auth/getAuthToken';
import { getNeoId } from '../utils/neoIdUtils';

const getSubscriptions = async (preferNeoId?: boolean) => {
    const neoId = (preferNeoId && getNeoId()) || undefined;
    const { isAuthenticated } = appConfig.coopUserSettings;
    let token;
    if (isAuthenticated) {
        const spaToken = await getAuthToken();
        token = spaToken?.token;
    }

    const response = await consentSubscriptionsApi.getSubscriptions(token, neoId);
    return response;
};

const subscribe = async (services: string[], preferNeoId?: boolean, email?: string) => {
    const neoId = (preferNeoId && getNeoId()) || undefined;
    const { isAuthenticated } = appConfig.coopUserSettings;
    let token;
    if (isAuthenticated) {
        const spaToken = await getAuthToken();
        token = spaToken?.token;
    }

    const response = consentSubscriptionsApi.subscribe(services, token, neoId, email);
    return response;
};

const unsubscribe = async (services: string[], preferNeoId?: boolean) => {
    const neoId = (preferNeoId && getNeoId()) || undefined;
    const { isAuthenticated } = appConfig.coopUserSettings;
    let token;
    if (isAuthenticated) {
        const spaToken = await getAuthToken();
        token = spaToken?.token;
    }

    const response = consentSubscriptionsApi.unsubscribe(services, token, neoId);
    return response;
};

export const consentSubscriptionFlow = {
    getSubscriptions,
    subscribe,
    unsubscribe,
};
