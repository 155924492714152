import { createAction } from '@reduxjs/toolkit';

import type { ModalType } from '../../structureDefinitions/modalState';

export interface OpenModalPayload {
    id: string | null;
    type: ModalType | string;
}

export const openModal = createAction<OpenModalPayload>('modal/open');
