import { Slot, Slottable } from '@radix-ui/react-slot';
import classNames from 'classnames';
import * as React from 'react';

import styles from './Chip.module.scss';
import type { ChipSize, ChipTheme } from './Chip.types';

export interface ChipProps {
    className?: string;
    theme: ChipTheme;
    size: ChipSize;
    asChild?: boolean;
    asIcon?: boolean;
    isActive?: boolean;
    leftSlot?: React.ReactNode;

    rightSlot?: React.ReactNode;
    count?: number;
    countVariant?: 1 | 2;
    fullWidth?: boolean;
}

const Chip = (props: React.PropsWithChildren<ChipProps>) => {
    const type =
        (props.asIcon === true && 'WithIconOnly') ||
        (props.count !== undefined && 'WithCountBadge') ||
        (props.leftSlot && 'WithLeftSlot') ||
        (props.rightSlot && 'WithRightSlot') ||
        'WithTextOnly';

    const classes = classNames(
        props.className,
        styles.Container,
        styles[`Theme-${props.theme}`],
        styles[`Size-${props.size}`],
        styles[type],
        props.isActive && styles.isActive,
        props.fullWidth && styles.FullWidth,
    );

    const Component = props.asChild ? Slot : 'div';

    return (
        <Component className={classes}>
            {props.leftSlot && React.isValidElement(props.leftSlot)
                ? React.cloneElement(props.leftSlot, {
                      className: classNames(props.leftSlot.props?.className, styles.LeftSlot),
                      'aria-hidden': true,
                  } as React.HTMLAttributes<HTMLElement>)
                : props.leftSlot}

            <Slottable>{props.children}</Slottable>

            {props.count !== undefined && (
                <div
                    className={classNames(
                        styles.CountBadge,
                        (!props.countVariant || props.countVariant === 1) && styles.CountBadgeAlt1,
                        props.countVariant === 2 && styles.CountBadgeAlt2,
                    )}
                >
                    {props.count}
                </div>
            )}

            {props.rightSlot && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {React.isValidElement(props.rightSlot)
                        ? React.cloneElement(props.rightSlot, {
                              className: classNames(
                                  props.rightSlot.props?.className,
                                  styles.RightSlot,
                              ),
                              'aria-hidden': true,
                          } as React.HTMLAttributes<HTMLElement>)
                        : props.rightSlot}
                </>
            )}
        </Component>
    );
};

export default Chip;
