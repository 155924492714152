import type { DynamicYieldAnalyticsMetadataBody } from '../../../models/product/productDomainModels';
import { sendEvent } from '../../utils';

const sendGaCampaignEvent = (
    selectorName: string,
    analyticsData?: DynamicYieldAnalyticsMetadataBody,
) => {
    if (analyticsData && selectorName) {
        sendEvent({
            event: 'experiment',
            type: 'dynamic yield',
            selector_name: selectorName,
            campaign_id: analyticsData.campaignId,
            campaign_name: analyticsData.campaignName,
            experience_id: analyticsData.experienceId,
            experience_name: analyticsData.experienceName,
            variation_id: analyticsData.variationId,
            variation_name: analyticsData.variationName,
        });
    }
};

export default {
    campaign: sendGaCampaignEvent,
};
