import type { AnalyticsVoucherInteractionEvent } from '../../../../analytics/analyticsHandler';
import { Browser } from '../../../../utility/browser';
import { DeliveryMode } from '../../store/structureDefinitions/storeState';
import { sendGaInteractionWithoutCustomerSegment } from '../commonEvents';
import { sendInteractionEvent } from '../utils';
import editOrderTracking from './editOrderEvents';
import homeDeliveryTracking from './homeDeliveryEvents';
import itemReplaceEvents from './itemReplaceEvents';
import pickupTracking from './storeDeliveryEvents';
import subscriptionTracking from './subscriptionEvents';

const sendGaDeliveryStepInit = () => {
    const gaEvent = Browser.createNewEvent('ga:deliveryInfo');
    window.dispatchEvent(gaEvent);
};

const sendGaDeliveryTabClickEvent = (mode: DeliveryMode) => {
    const tab = mode === DeliveryMode.homedelivery ? 'Hemleverans' : 'Hämta i butik';
    sendGaInteractionWithoutCustomerSegment('Ecommerce', 'Checkout - Leverans', tab);
};

const sendGaAddVoucherEvent = (code: string, err: string | null) => {
    if (err) {
        const gaEvent = Browser.createNewEvent(
            'ga:voucherInteraction',
        ) as AnalyticsVoucherInteractionEvent;
        gaEvent.eventLabel = 'Registrering kampanjkod - Felmeddelande';
        gaEvent.campaignCode = err;
        window.dispatchEvent(gaEvent);
    } else {
        const gaEvent = Browser.createNewEvent(
            'ga:voucherInteraction',
        ) as AnalyticsVoucherInteractionEvent;
        gaEvent.eventLabel = 'Registrering kampanjkod';
        gaEvent.campaignCode = code;
        window.dispatchEvent(gaEvent);
    }
};

const sendGaInteractionCheckoutLoginConfirm = () => {
    sendGaInteractionWithoutCustomerSegment('Ecommerce', 'Checkout - Login prompt', 'Login');
};

const sendGaInteractionCheckoutLoginCancel = () => {
    sendGaInteractionWithoutCustomerSegment('Ecommerce', 'Checkout - Login prompt', 'Avbryt');
};

const sendGaSaveCartOpen = () => {
    sendInteractionEvent({ eventAction: 'Checkout - Varukorg', eventLabel: 'Spara varukorg' });
};

const sendGaSaveCartSave = () => {
    sendInteractionEvent({
        eventAction: 'Checkout - Varukorg - Spara din varukorg',
        eventLabel: 'Bekräfta - Spara varukorg',
    });
};

const sendGaSaveCartClose = () => {
    sendInteractionEvent({
        eventAction: 'Checkout - Varukorg - Spara din varukorg',
        eventLabel: 'Stäng - Spara varukorg',
    });
};

const checkoutTracking = {
    cart: {
        replaceItems: itemReplaceEvents,
        voucher: {
            add: sendGaAddVoucherEvent,
        },
        saveCart: {
            save: sendGaSaveCartSave,
            close: sendGaSaveCartClose,
            open: sendGaSaveCartOpen,
        },
    },
    delivery: {
        init: sendGaDeliveryStepInit,
        tabClick: sendGaDeliveryTabClickEvent,
        subscription: subscriptionTracking,
        pickup: pickupTracking,
        homeDelivery: homeDeliveryTracking,
    },
    loginDialog: {
        confirm: sendGaInteractionCheckoutLoginConfirm,
        cancel: sendGaInteractionCheckoutLoginCancel,
    },
    editOrder: editOrderTracking,
};

export default checkoutTracking;
