import { sendInteractionEvent } from '../utils';

interface GaSustainabilityOpenPayload {
    ean: string;
    name: string;
    manufacturer: string;
    price?: number;
}

const open = (data: GaSustainabilityOpenPayload) => {
    sendInteractionEvent({
        eventLabel: 'Hållbarhetsdeklaration - öppna',
        product: {
            id: data.ean,
            name: data.name,
            brand: data.manufacturer,
            price: data.price,
        },
    });
};

export default {
    open,
};
