import { failSilently } from '../../utils/failSilently';
import { sendGA4Event } from '../shared/sendGa4Event';

const emailSubscriptionInteraction = (clickText: string) => {
    const event: EmailSubscriptionInteractionEvent = {
        event: 'interaction',
        type: 'missa inga erbjudanden pop-up',
        click_text: clickText,
    };
    sendGA4Event(event);
};

export default {
    close: {
        sendEmailSubscriptionInteraction: failSilently(() => emailSubscriptionInteraction('Stäng')),
    },
    confirm: {
        sendEmailSubscriptionInteraction: failSilently(() =>
            emailSubscriptionInteraction('Godkänn'),
        ),
    },
    skip: {
        sendEmailSubscriptionInteraction: failSilently(() =>
            emailSubscriptionInteraction('Hoppa över'),
        ),
    },
};
