/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import type { IApiStore } from '../../../models/ecommerce/store/storeModel';
import { useNavigationTracking } from '../../cnc/hooks/tracking/useCncNavigationTracking';
import { storeSelectors } from '../selectors/storeSelectors';
import type { AppThunk } from '../store';
import { cncUiActions } from '../store/slices/ui/cncUiSlice';
import { unsyncedCartItemsActions } from '../store/slices/unsyncedCartItemsSlice';
import type {
    StoreChangeNotificationType,
    WeeklyMenuModalType,
} from '../store/structureDefinitions/modalState';
import { FlyInType } from '../store/structureDefinitions/modalState';
import { CncStep } from '../store/structureDefinitions/uiState';
import { useTopLevelModal } from './useModal';
import { useAppDispatch, useAppSelector } from './useThunkDispatch';

export type MiniCartNotificationType =
    | StoreChangeNotificationType.deliveryTimeslotSelected
    | StoreChangeNotificationType.pickupTimeslotSelected
    | WeeklyMenuModalType.addToCart;

export type MiniCartStepNavigationParams = {
    notification: MiniCartNotificationType;
};

export type TimeslotsStepNavigationParams = {
    expressStore?: IApiStore;
};

type NavigateSetPayload<TParam> =
    | { step: CncStep.MiniCart; param?: MiniCartStepNavigationParams }
    | { step: CncStep.TimeSlots; param?: TimeslotsStepNavigationParams }
    | { step: Exclude<CncStep, CncStep.MiniCart | CncStep.TimeSlots>; param?: TParam };

export const dispatchNavigateTo =
    <TOutgoingParams>(
        arg: NavigateSetPayload<TOutgoingParams>,
        previewPostalCode: string | null,
    ): AppThunk =>
    (dispatch, getState) => {
        const hasActiveStoreSelection = storeSelectors.hasActiveStoreSelection(getState());
        if (hasActiveStoreSelection || previewPostalCode || arg.step === CncStep.Map) {
            dispatch(cncUiActions.navigateTo({ step: arg.step, param: arg.param }));
        } else {
            dispatch(
                cncUiActions.navigateTo({ step: CncStep.PostalCodeSelector, param: arg.param }),
            );
        }
    };

export const dispatchSetTo =
    <TOutgoingParams>(arg: NavigateSetPayload<TOutgoingParams>): AppThunk =>
    (dispatch, getState) => {
        const hasActiveStoreSelection = storeSelectors.hasActiveStoreSelection(getState());
        if (!hasActiveStoreSelection) {
            dispatch(cncUiActions.setTo({ step: CncStep.PostalCodeSelector, param: arg.param }));
            return;
        }
        dispatch(cncUiActions.setTo({ step: arg.step, param: arg.param }));
    };

export const useCncFlyInState = <TIncomingParams = any, TOutgoingParams = any>() => {
    const dispatch = useAppDispatch();
    const { sendGaCloseTrackingEvent } = useNavigationTracking();

    const reloadOnClose = useAppSelector((state) => state.ui.cnc.reloadOnClose);
    const previewPostalCode = useAppSelector((state) => state.ui.cnc.previewPostalCode);

    const navigationParams: TIncomingParams = useAppSelector(
        (state) => state.ui.cnc.currentStepNavigationParams,
    );

    const { isOpen, close } = useTopLevelModal(FlyInType.CncFlyIn);

    const afterClose = useCallback(() => {
        sendGaCloseTrackingEvent();
        dispatch(unsyncedCartItemsActions.removeUnsyncedCartItems());
        if (reloadOnClose) {
            window.location.reload();
        }
    }, [dispatch, reloadOnClose, sendGaCloseTrackingEvent]);

    const navigateTo = useCallback(
        (args: NavigateSetPayload<TOutgoingParams>) => {
            dispatch(dispatchNavigateTo(args, previewPostalCode));
        },
        [dispatch, previewPostalCode],
    );

    const setTo = useCallback(
        (args: NavigateSetPayload<TOutgoingParams>) => {
            dispatch(dispatchSetTo(args));
        },
        [dispatch],
    );

    const initCnc = useCallback(() => {
        dispatch(
            dispatchSetTo({
                step: CncStep.MiniCart,
            }),
        );
    }, [dispatch]);

    const forceReloadOnClose = useCallback(() => {
        dispatch(cncUiActions.forceReloadOnClose(true));
    }, [dispatch]);

    return {
        isOpen,
        close,
        afterClose,
        navigateTo,
        setTo,
        initCnc,
        forceReloadOnClose,
        navigationParams,
    };
};
