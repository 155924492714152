/* eslint-disable @typescript-eslint/no-explicit-any */
export type AppConfig = RecursiveReadonly<{
    coopSettings: CoopSettings;
    coopUserSettings: CoopUserSettings;
}>;

export const appConfig: AppConfig = {
    coopSettings: (window as any).coopSettings as CoopSettings,
    coopUserSettings: (window as any).coopUserSettings,
};
