import dompurify from 'dompurify';
import type { FC } from 'react';
import * as React from 'react';

import useHtmlScriptParser from '../../hooks/useHtmlScriptParser';

interface DangerousHtmlWrapper {
    dangerouslySetInnerHTML?: {
        __html: string;
    };
}

interface RawHtmlProps {
    html: string;
    renderWrapper?: FC<React.PropsWithChildren<DangerousHtmlWrapper>>;
    sanitizeOptions?: DOMPurify.Config;
    parseScripts?: boolean;
    className?: string;
}

const sanitizer = dompurify.sanitize;

const RawHtml = ({
    html,
    renderWrapper,
    sanitizeOptions,
    className,
    parseScripts = false,
}: React.PropsWithChildren<RawHtmlProps>) => {
    const scriptsContainerRef = useHtmlScriptParser(html, parseScripts);

    if (!renderWrapper)
        return (
            <>
                <div
                    className={className}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: sanitizeOptions
                            ? sanitizer(html, sanitizeOptions).toString()
                            : sanitizer(html).toString(),
                    }}
                />
                <div style={{ display: 'none' }} ref={scriptsContainerRef} />
            </>
        );

    return (
        <>
            {renderWrapper({
                dangerouslySetInnerHTML: {
                    __html: sanitizeOptions
                        ? sanitizer(html, sanitizeOptions).toString()
                        : sanitizer(html).toString(),
                },
            })}
            <div style={{ display: 'none' }} ref={scriptsContainerRef} />
        </>
    );
};

export default RawHtml;
