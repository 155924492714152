import { sendEvent } from '../utils';

interface FilterGaEvent {
    event: 'filtrering';
    eventCategory: 'Filtrering';
    filterMärkning: string | null;
    filterVarumärke: string | null;
}

export type GaFilterEvents = FilterGaEvent;

const openFlyin = () => {
    sendEvent({
        event: 'interaction',
        eventCategory: 'Filtrering',
        eventAction: 'Navigation',
        eventLabel: 'Start - Alla filter',
    });
};

const closeFlyin = () => {
    sendEvent({
        event: 'interaction',
        eventCategory: 'Filtrering',
        eventAction: 'Navigation',
        eventLabel: 'Start - Stänger filter',
    });
};

const clearFilters = (view: string) => {
    sendEvent({
        event: 'interaction',
        eventCategory: 'Filtrering',
        eventAction: 'Navigation',
        eventLabel: `Rensa filter - ${view}`,
    });
};

const applyFilter = (selectedFilters: {
    selectedEnvironmentalLabels?: string[];
    selectedBrands?: string[];
}) => {
    let hasAnyFiltersSelected = false;
    let markning = null;
    let varumkare = null;

    if (selectedFilters.selectedEnvironmentalLabels?.length) {
        const filters: string[] = [];
        hasAnyFiltersSelected = true;

        selectedFilters.selectedEnvironmentalLabels?.forEach((f) =>
            filters.push(f.indexOf('onlineEnvironmentalLabels') === -1 ? f : f.split('=')[1]),
        );
        markning = filters.join(',');
    }

    if (selectedFilters.selectedBrands?.length) {
        const filters: string[] = [];
        hasAnyFiltersSelected = true;

        selectedFilters.selectedBrands?.forEach((f) =>
            filters.push(f.indexOf('brand') === -1 ? f : f.split('=')[1]),
        );
        varumkare = filters.join(',');
    }

    const eventPayload: FilterGaEvent = {
        event: 'filtrering',
        eventCategory: 'Filtrering',
        filterMärkning: markning,
        filterVarumärke: varumkare,
    };

    if (hasAnyFiltersSelected) {
        sendEvent(eventPayload);
    }
};

export default {
    openFlyin,
    closeFlyin,
    applyFilter,
    clearFilters,
};
