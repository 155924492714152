import { isB2BMode } from '../../utils/b2bUtils';
import { sendInteractionEvent } from '../utils';

export const sendInteractionClickEventWithCustomerSegment = ({
    eventCategory,
    eventAction,
    eventLabel,
}: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
}) => {
    const customerSegment = isB2BMode() ? 'Företag' : 'Privat';

    if (!eventCategory || !eventAction) {
        return;
    }

    sendInteractionEvent({
        eventCategory,
        eventAction,
        eventLabel,
        customerSegment,
    });
};

export const sendGaInteractionClickEvent = ({
    eventCategory,
    eventAction,
    eventLabel,
}: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
}) => {
    if (!eventCategory || !eventAction) {
        return;
    }

    sendInteractionEvent({
        eventCategory,
        eventAction,
        eventLabel,
    });
};

// Mimics the legacy events, escape hatch to make it easier to refactor to the new way of tracking or when specific events do not make sense
export default {
    clickWithCustomerSegment: sendInteractionClickEventWithCustomerSegment,
    click: sendGaInteractionClickEvent,
};
