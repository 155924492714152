import { useIsInsideModal } from '@coop/components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { modalSelectors } from '../selectors/modalSelectors';
import { modalActions } from '../store/slices/modal/modalSlice';
import type { ModalType } from '../store/structureDefinitions/modalState';
import { useAppDispatch, useAppSelector } from './useThunkDispatch';

/**
 * This is a hook that handles state for top level modals.
 * Top level modal is the first modal opened on a page, like MiniCart.
 * In most situations you won't use this hook directly, use useModal instead.
 */
export const useTopLevelModal = (
    type?: ModalType | string,
    initialState?: boolean,
    uniqueId?: string,
) => {
    const typeOrGenerated = useRef(type || v4());

    const dispatch = useAppDispatch();

    const isOpen = useAppSelector((state) =>
        modalSelectors.isModalTypeOpen(state, typeOrGenerated.current, uniqueId),
    );

    const close = useCallback(() => {
        dispatch(modalActions.close());
    }, [dispatch]);

    const open = useCallback(() => {
        dispatch(modalActions.open({ type: typeOrGenerated.current, id: uniqueId || null }));
    }, [dispatch, uniqueId]);

    useEffect(() => {
        if (initialState === true) {
            open();
        }
        if (initialState === false) {
            close();
        }
    }, [open, close, initialState]);

    return { isOpen, open, close };
};

/**
 * This is a hook that handles state for local modals.
 * Local modal is a modal that is opened from inside another modal.
 * DON'T use this hook directly, always use useModal instead.
 */
const useLocalModal = (initialState?: boolean) => {
    const [isOpen, setIsOpen] = useState(!!initialState);

    const open = useCallback(() => {
        setIsOpen(true);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    return { isOpen, open, close };
};

/**
 * Hook for handling modal state
 * If a modal is opened from inside another modal, it is a local modal and the local modal state will be used
 * If a modal is opened as a first modal on the page, it is a top level modal and the modal state from Redux will be used
 * This hook ensures that there can only be one top level modal at a time. If second top level modal is opened for some reason (i.e. timeslot expiration popup is shown), the first one will be closed.
 *
 * @param type If you want to open the same modal instance from multiple places, set a unique type in ModalType. If you want to control the modal from single place, dom't provide the type and a GUID will be generated for it.
 * @param initialState Initial state of the modal
 * @param uniqueId Use this if you have multiple components at the same time that can trigger the modal
 */
const useModal = (type?: ModalType | string, initialState?: boolean, uniqueId?: string) => {
    const {
        isOpen: topLevelModalIsOpen,
        open: topLevelModalOpen,
        close: topLevelModalClose,
    } = useTopLevelModal(type, initialState, uniqueId);

    const {
        isOpen: localModallIsOpen,
        open: localModalOpen,
        close: localModalClose,
    } = useLocalModal(!!initialState);

    const modalIsInisideAnotherModal = useIsInsideModal();

    const close = useCallback(() => {
        if (modalIsInisideAnotherModal) {
            localModalClose();
        } else {
            topLevelModalClose();
        }
    }, [modalIsInisideAnotherModal, localModalClose, topLevelModalClose]);

    const open = useCallback(() => {
        if (modalIsInisideAnotherModal) {
            localModalOpen();
        } else {
            topLevelModalOpen();
        }
    }, [modalIsInisideAnotherModal, localModalOpen, topLevelModalOpen]);

    const setIsOpen = useCallback(
        (newState: boolean) => {
            if (newState) {
                open();
            } else {
                close();
            }
        },
        [open, close],
    );

    const isOpen = modalIsInisideAnotherModal ? localModallIsOpen : topLevelModalIsOpen;

    return {
        open,
        close,
        isOpen,
        setIsOpen,
    };
};

export default useModal;
