import format from 'date-fns/format';
import getHours from 'date-fns/getHours';

import { DeliveryMode } from '../../../store/structureDefinitions/storeState';

export const mapDeliveryModeToGA = (deliveryMode: DeliveryMode) => {
    switch (deliveryMode) {
        case DeliveryMode.homedelivery:
            return 'home delivery';
        case DeliveryMode.pickup:
            return 'store pickup';
        default:
            return null;
    }
};

export const mapDateRangeToGA = (fromDate: Date, toDate: Date) =>
    `${getHours(fromDate)}-${getHours(toDate)}` as GaHoursRange;

export const mapDateWeekdayToGA = (date: Date): GaWeekday => format(date, 'EEEE').toLowerCase();

export const mapDateToGA = (date: Date): GaDateFormatted => format(date, 'yyyyMMdd');

export const mapGaList = (listName: string): GaListItem => ({
    item_list_id: listName.toLowerCase(),
    item_list_name: listName.toLowerCase().split('|')[0],
});
