/* eslint-disable react/jsx-props-no-spreading */
import classnames from 'classnames';
import * as React from 'react';
import { useContext } from 'react';

import styles from './Modal.module.scss';
import { ModalContextState } from './ModalContext';

interface ModalHeaderProps extends React.HtmlHTMLAttributes<HTMLHeadingElement> {}

const ModalHeader = React.forwardRef<HTMLHeadingElement, React.PropsWithChildren<ModalHeaderProps>>(
    (props, ref) => {
        return (
            // TODO: Use Heading component when moved to packages
            <ModalContextHeader
                ref={ref}
                {...props}
                className={classnames(props.className, styles.ModalHeader)}
            />
        );
    },
);

export const ModalContextHeader = React.forwardRef<
    HTMLHeadingElement,
    React.PropsWithChildren<ModalHeaderProps>
>(({ children, ...props }, ref) => {
    const modalContext = useContext(ModalContextState);

    return (
        <h1 ref={ref} {...props} id={modalContext?.headerId} aria-atomic="true">
            {children}
        </h1>
    );
});

export default ModalHeader;
