import { createSlice } from '@reduxjs/toolkit';

import type { CartItemState } from '../../structureDefinitions/cartState';

const cartEditedOrderItemsSlice = createSlice({
    name: 'editedOrderItems',
    initialState: [] as CartItemState[],
    reducers: {},
});

export const { actions: cartEditedOrderItemsActions, reducer: cartEditedOrderItemsReducer } =
    cartEditedOrderItemsSlice;
