import { sendInteractionEvent } from '../utils';

// Checkout - Step 3 - Leverans - Hemleverans
const sendHomeDeliveryTabInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction: 'Checkout - Leverans - Hemleverans',
        eventLabel,
    });
};

const sendGaHomeDeliveryTabAddressInteractionClickAdd = () => {
    sendHomeDeliveryTabInteractionClick('Adress - Lägg till');
};

const sendGaHomeDeliveryTabAddressInteractionClickChange = () => {
    sendHomeDeliveryTabInteractionClick('Adress - Ändra');
};

const sendGaHomeDeliveryTabDateInteractionClickAdd = () => {
    sendHomeDeliveryTabInteractionClick('Leveranstid - Lägg till');
};

const sendGaHomeDeliveryTabDateInteractionClickChange = () => {
    sendHomeDeliveryTabInteractionClick('Leveranstid - Ändra');
};

const sendGaHomeDeliveryTabMessageToDriverInteractionClick = () => {
    sendHomeDeliveryTabInteractionClick('Meddelande till chauffören');
};

// Checkout - Leverans - Hemleverans - Fakturaadress
const sendHomeDeliveryBillingTabInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction: 'Checkout - Leverans - Hemleverans- Fakturadress',
        eventLabel,
    });
};

const sendGaHomeDeliveryTabBillingAddressInteractionClickAdd = () => {
    sendHomeDeliveryBillingTabInteractionClick('Lägg till fakturaadress');
};

const sendGaHomeDeliveryTabBillingAddressInteractionClickChange = () => {
    sendHomeDeliveryBillingTabInteractionClick('Ändra fakturaadress');
};

// Checkout - Step 3 - Leverans - Hemleverans - Leveransadress
const sendHomeDeliveryAddressInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction: 'Checkout - Leverans - Hemleverans - Leveransadress',
        eventLabel,
    });
};

const sendGaHomeDeliveryAddressInteractionClickAdd = () => {
    sendHomeDeliveryAddressInteractionClick('Lägg till leveransadress');
};

const sendGaHomeDeliveryAddressInteractionClickEdit = () => {
    sendHomeDeliveryAddressInteractionClick('Redigera leveransadress');
};

const sendGaHomeDeliveryAddressInteractionClickSelect = () => {
    sendHomeDeliveryAddressInteractionClick('Ändra leveransadress');
};

const sendGaHomeDeliveryAddressInteractionClickClose = () => {
    sendHomeDeliveryAddressInteractionClick('Stäng');
};

// Checkout - Step 3 - Leverans - Hemleverans - Leveransadress - Redigera adress
const sendHomeDeliveryEditAddressInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction: 'Checkout - Leverans - Hemleverans - Leveransadress - Redigera adress',
        eventLabel,
    });
};

const sendGaHomeDeliveryEditAddressInteractionClickRemoveAddress = () => {
    sendHomeDeliveryEditAddressInteractionClick('Ta bort adress');
};

const sendGaHomeDeliveryEditAddressInteractionClickSaveAddress = () => {
    sendHomeDeliveryEditAddressInteractionClick('Spara ändringar');
};

// Checkout - Step 3 - Leverans - Hemleverans - Leveransadress - Lägg till leveransadress
const sendHomeDeliveryAddAddressInteractionClick = (eventLabel: string) => {
    sendInteractionEvent({
        eventAction:
            'Checkout - Leverans - Hemleverans - Leveransadress - Lägg till leveransadress',
        eventLabel,
    });
};

const sendGaHomeDeliveryAddAddressInteractionClickSaveAddress = () => {
    sendHomeDeliveryAddAddressInteractionClick('Spara ändringar');
};

const homeDeliveryTracking = {
    deliveryAddress: {
        add: sendGaHomeDeliveryTabAddressInteractionClickAdd,
        change: sendGaHomeDeliveryTabAddressInteractionClickChange,
        flyIn: {
            add: sendGaHomeDeliveryAddressInteractionClickAdd,
            editClick: sendGaHomeDeliveryAddressInteractionClickEdit,
            select: sendGaHomeDeliveryAddressInteractionClickSelect,
            close: sendGaHomeDeliveryAddressInteractionClickClose,
            edit: {
                save: sendGaHomeDeliveryEditAddressInteractionClickSaveAddress,
                remove: sendGaHomeDeliveryEditAddressInteractionClickRemoveAddress,
            },
            create: {
                save: sendGaHomeDeliveryAddAddressInteractionClickSaveAddress,
            },
        },
    },
    billingAddress: {
        add: sendGaHomeDeliveryTabBillingAddressInteractionClickAdd,
        change: sendGaHomeDeliveryTabBillingAddressInteractionClickChange,
    },
    timeslot: {
        add: sendGaHomeDeliveryTabDateInteractionClickAdd,
        change: sendGaHomeDeliveryTabDateInteractionClickChange,
    },
    messageToDriverClick: sendGaHomeDeliveryTabMessageToDriverInteractionClick,
};

export default homeDeliveryTracking;
