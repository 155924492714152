import { useCallback, useEffect, useRef } from 'react';

const useEventCallback = <R = unknown, Args extends unknown[] = unknown[]>(
    cb: (...args: Args) => R,
) => {
    const ref = useRef(cb);
    useEffect(() => {
        ref.current = cb;
    });
    return useCallback((...args: Args) => ref.current(...args), [ref]);
};

export default useEventCallback;
